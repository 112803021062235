import React from "react";
import Home from "pages/Home/Home";
import useSession from "hooks/session/session";
import Dashboard from "pages/Dashboard/Dashboard";
import useFeatureFlags from "hooks/utils/featureFlags";

const LandingPage = () => {
  const session = useSession();
  const { user } = session;
  const { accountsLandingPageEnabled } = useFeatureFlags();
  const brandDetails = JSON.parse(`${sessionStorage.getItem("brandDetails")}`);

  if (
    !user.isGuestUser &&
    accountsLandingPageEnabled &&
    ["TSYS", "GPI", "GPN", "CITI"].includes(brandDetails?.brandGuid)
  ) {
    return <Dashboard />;
  } else {
    return <Home />;
  }
};

export default LandingPage;
