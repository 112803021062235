const productTitleDescriptionMapping={
    "Reporting" : {
        "nameKey": "applications.mp-reporting.name",
        "description": "Have detailed reports of your business to help you make better decisions based on information on your transactions, disputes, and much more.",
        "descKey": "applications.mp-reporting.description"
    },
    "Customer Engagement" : {
        "nameKey": "applications.customer-engagement.name",
        "description": "Have access to valuable information about your customers and manage their engagement to help increase your business sales.",
        "descKey": "applications.customer-engagement.description"
    },
    "Sysnet" : {
        "nameKey": "applications.sysnet.planName",
        "desciption": "Have your PCI DSS assessment and vulnerability scan in one place to be compliant and secure to receive PCI-related data of your business.",
        "descKey": "applications.sysnet.description"
    },
    "Dims": {
        "nameKey": "applications.dims.planName",
        "description": "The Global Payments Disputes Management tool can help you better manage disputes, minimize losses, and resolve chargebacks as quickly as possible.",
        "descKey": "applications.dims.description"
    }
}

export default productTitleDescriptionMapping;