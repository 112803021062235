import React from "react";
import useAlertQueue from 'hooks/alertQueue';
import useSession from 'hooks/session/session';
import useTranslations from 'hooks/utils/translations';
import FormSelect from "components/Forms/FormSelect";
import usePreferences from "hooks/use-preferences";
import { Button, ButtonType, LocalizedText, useLocale } from 'portal-common-ui';

const PreferencsTab = ({ user, preferences, preferenceOptionLists, onUpdate }) => {
  const translations = useTranslations();
  const {enqueueAlert} = useAlertQueue();
  const { handleSigninByPopup } = useSession();
  const { translationsEnabled } = usePreferences();
  const { translateToString } = useLocale();

  const configureMfa = async (data) => {
    try {

      let brandApplicationAssociationId = "";
      let brandDetails = JSON.parse(`${sessionStorage.getItem('brandDetails')}`);
      brandApplicationAssociationId = brandDetails && brandDetails.brandApplicationAssociationId;

      const token = await handleSigninByPopup({is_managing: "true", brandApplicationAssociationId});
      if (token) {
          enqueueAlert({ alertType: 'toast', messageType: 'success', messageKey: "editUser.sections.preferences.alerts.mfaUpdated.message" });
      }
    } catch (error) {
      console.log('error with configure MFA', error);
    }
  };

  const languageOptions = preferenceOptionLists.locales;
  const timeFormatOptions = preferenceOptionLists.timeFormats;
  const dateFormatOptions = preferenceOptionLists.dateFormats;
  
  const updateLocale = (updatedLocale) => {
   if(updatedLocale?.value !== preferences?.locale?.value) onUpdate({ ...preferences, locale: updatedLocale});
  }

  const updateTimeFormat = (updatedTimeFormat) => {
    if(updatedTimeFormat?.value !== preferences?.timeFormat?.value) onUpdate({ ...preferences, timeFormat: updatedTimeFormat});
  };

  const updateDateFormat = (updatedDateFormat) => {
    if(updatedDateFormat?.value !== preferences?.dateFormat?.value) onUpdate({ ...preferences, dateFormat: updatedDateFormat});
  };

  return (
    <div className="bg-theme-on-primary rounded-sm border-gray-100" style={{minHeight: 240}}>
      <div className="font-normal text-theme-dark pb-2 sml:p-0">
        {<LocalizedText localeKey="editUser.sections.preferences.locale.title" />}{!translationsEnabled && ` (${translateToString("editUser.sections.preferences.locale.comingSoon")})`}:
      </div>
        <div className="inline-flex flex-col space-y-5 items-start justify-start py-2 px-4 bg-theme-on-primary rounded-sm border sml:border-none lg:w-[774px] lrg:w-full">
          <div className="flex flex-wrap flex-col items-start justify-between w-full pb-8" >
            <FormSelect disabled={!translationsEnabled} containerClass="sml:w-full" name="language" options={languageOptions} label={<LocalizedText localeKey="editUser.sections.preferences.locale.language" />} defaultOption={preferences.locale} onSelection={updateLocale} />
            <FormSelect disabled={!translationsEnabled} containerClass="sml:w-full mt-2" name="timeFormat" options={timeFormatOptions} label={<LocalizedText localeKey="editUser.sections.preferences.locale.timeFormat" />} defaultOption={preferences.timeFormat} onSelection={updateTimeFormat} />
            <FormSelect disabled={!translationsEnabled} containerClass="sml:w-full mt-2" name="dateFormat" options={dateFormatOptions} label={<LocalizedText localeKey="editUser.sections.preferences.locale.dateFormat" />} defaultOption={preferences.dateFormat} onSelection={updateDateFormat} />
          </div>
      </div>
      <div className="font-normal text-theme-dark pb-2 pt-7">
        <LocalizedText localeKey="editUser.sections.preferences.multiFactorAuthentication.title" />:
      </div>
      <div className="inline-flex flex-col space-y-5 items-start justify-start py-2 px-4 bg-theme-on-primary rounded-sm border sml:border-none w-[774px] lrg:w-full" style={{height: 80}}>
          <div className="flex flex-row flex-wrap space-y-1 items-start justify-start pb-8 w-[774px] lrg:w-full" style={{height: 80}}>
          <Button
            buttonClass="sml:hidden"
            buttonType={ButtonType.SecondaryButton}
            onClick={configureMfa}
            child={<LocalizedText localeKey="editUser.sections.preferences.multiFactorAuthentication.configureMFA" />}
          />
          <Button
            buttonClass="hidden sml:block"
            buttonType={ButtonType.MobileSecondary}
            onClick={configureMfa}
            child={<LocalizedText localeKey="editUser.sections.preferences.multiFactorAuthentication.configureMFA" />}
          />
        </div>
      </div>
  </div>  
  )
};

export default PreferencsTab;
