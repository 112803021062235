const navHeaderItems = [
    { label: "support", labelKey: "homepage.help.title",isActive: false, subNavItems: [{ name: "Account Support", url: '' }]},
    { label: "apps", labelKey: "homepage.applications.title", isActive: false, subNavItems: [] },
    { label: "profile", isActive: false, subNavItems: [
        { name: "My Profile", nameKey: "homepage.myProfile.name", url: "/profile" }, 
        { name: "Logout", nameKey: "homepage.logout.name", url: "/logout" }
      ]
    }
];

export default navHeaderItems;