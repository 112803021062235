import { CellShape } from 'portal-common-ui';
import { useState } from 'react';
export interface IDAGroupItems {
    groupId: string,
    name: string,
    createdByFirstName: string,
    createdByLastName: string,
    countOfNodes: number,
    createdByEmail: string,
}
  
export interface IDAGroup {
    groupId: string,
    groupName: string,
    groupOwner: string,
    userCount: string,
    groupOwnerEmail: string,
}

const useDAGList = () => {
    const [dagGroupNameList, setDagGroupNameList] = useState([]);
    const transformRow = (rows: IDAGroupItems[]) => {
        const inRows = rows;
        const outRows = inRows.map(inRow => {
            let outRow: IDAGroup = {
              groupId: inRow.groupId,
              groupName: inRow.name,
              groupOwner: `${inRow.createdByFirstName} ${inRow.createdByLastName}`,
              userCount: inRow.countOfNodes.toString(),
              groupOwnerEmail: inRow.createdByEmail,
            };
            return outRow;
        });
        setDagGroupNameList(outRows);
        return outRows;
    };

    const sortMap = {'default': 'GROUP_NAME', 'groupName': 'GROUP_NAME', 'groupOwner': 'GROUP_OWNER'};

    const loaderColumnDefs = [
        { width: "31%", cellShape: CellShape.Bar },
        { width: "31%", cellShape: CellShape.Bar },
        { width: "31%", cellShape: CellShape.Bar },
        { width: "7%", cellShape: CellShape.Ellipsis },
    ];

    return {
        transformRow,
        sortMap,
        loaderColumnDefs,
        dagGroupNameList

    }
}

export default useDAGList;
