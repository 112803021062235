import React, { useState, useRef, useEffect } from 'react';
import { faMagnifyingGlass, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalizedText, AccessibleIcon, useLocale } from 'portal-common-ui';

export default function CheckList({
  showSearch = true,
  canSelectAll = true,
  fieldLabel = "",
  fieldLabelKey = "",
  lableClass = "text-xs font-medium leading-tight text-theme-dark",
  containerClass = "",
  inputClass = "",  
  options = [], /* {
    label: 'Item 1',
    value: 'id',
    selected: true,
    disabled: false,
  } */
  onChange = (updatedOptions) => { console.log(updatedOptions) },
  locale = 'en-US',
  sorted = true,
  isLoading = false,
}) {
  const { translateToString } = useLocale();
  const [search, setSearch] = useState('');
  const [displayOptions, setDisplayOptions] = useState([])

  useEffect(() => {
    const getOptionLabel = (o) => o.labelKey ? translateToString(o.labelKey) : o.label; 
    const filteredOptions = options.filter(o => {
      if (!search) {
        return true;
      }
      const searchLabel = getOptionLabel(o);
      return searchLabel.toLowerCase().includes(search.toLowerCase())
    });

    const sortedItems = sorted ? filteredOptions?.sort((a, b) => {
      return getOptionLabel(a)?.localeCompare(getOptionLabel(b), locale, { sensitivity: 'accent', ignorePunctuation: true, numeric: true });
    }) : filteredOptions;

    setDisplayOptions(sortedItems);

  }, [options, locale, search]);

  const isAllSelected = displayOptions.filter(o => { return !o.selected; }).length == 0;

  const handleSelectAll = (e) => {
    const allSelected = options.map(o => {
      //see only filtered items
      if (search && !o.label.toLowerCase().includes(search.toLowerCase())) {
        return o;
      }
      
      if (!o.disabled) {
        return { ...o, selected: e.target.checked }
      }
      return o;
    });
    onChange(allSelected);
  }

  const handleSelect = (option, selected) => {
    const updated = options.map(o => {
      if (o.label == option.label) {
        return { ...o, selected }
      }
      return o;
    });
    onChange(updated);
  }

  return (

    <div className={containerClass}>
      <div>
        <label className={lableClass}
        >{fieldLabelKey ? <LocalizedText localeKey={fieldLabelKey} /> : fieldLabel} </label>
      </div>
      {showSearch &&
        <div className={`flex border border-solid border-theme-light-border p-1 xl:w-96 sml:w-full ${inputClass}`}>
          <div className="flex items-center w-full relative">
            <FontAwesomeIcon icon={faMagnifyingGlass} className='text-base absolute ml-3 pointer-events-none'/>
            <input
              className="text-sm border border-solid border-theme-light-border rounded-sm p-2 pl-10 font-normal w-full text-theme-dark bg-theme-on-primary"
              type="text"
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translateToString('createUser.sections.basicInfo.form.selectPermissions.placeholder')}
              disabled={isLoading}
            />
          </div>
        </div>
      }

      {canSelectAll && (
        <div
          className={`flex items-center bg-theme-light-background p-2 border border-solid border-theme-light-border xl:w-96 sml:w-full ${inputClass}`}
          data-test="checklist-select-all"
        >
          <input
            id="AllCheck"
            className="pl-4 cursor-pointer"
            type="checkbox"
            onChange={handleSelectAll}
            checked={isAllSelected}
            disabled={isLoading}
          />
          <label
            htmlFor="AllCheck"
            className="pl-4 text-theme-dark text-sm cursor-pointer font-normal"
          >
            <LocalizedText localeKey="createUser.sections.basicInfo.form.selectPermissions.selectAll" />
          </label>
        </div>
      )}
      <div className={`max-h-48 bg-theme-on-primary overflow-auto border-solid border-theme-light-border xl:w-96 sml:w-full ${inputClass}`} style={{ borderBottomWidth: 1 }} data-test="checklist-items">
        {isLoading 
          ? <div className="flex items-center p-2 border border-solid border-theme-light-border">
              <AccessibleIcon icon={faSpinner} localeKey="shared.loading" classes="fa-spin text-theme-dark"/>
            </div>
          : displayOptions.map((o) => (
            <div key={o.value} className="flex items-center p-2 border border-solid border-theme-light-border">
              <input id={o.value} className="pl-4 cursor-pointer" type="checkbox" onChange={(e) => handleSelect(o, e.target.checked)} checked={o.selected} disabled={o.disabled} />
              <label htmlFor={o.value} className="pl-4 text-theme-dark text-sm font-normal capitalize  cursor-pointer">{o.labelKey ? <LocalizedText localeKey={o.labelKey} /> : o.label }</label>
            </div>
          ))
        }
      </div>
    </div>

  )
}