import localConfig from './env/mqa.js';
import mockApiConfig from './env/mockApi.js';
import constants from './constants';
const jenkinsConfig = window['__app_config__'];

const mockApi = process.env.REACT_APP_MOCKAPI || false;
const isLocal = window.location.hostname === 'localhost' || mockApi;
const defaultConfig = isLocal ? mockApi ? mockApiConfig : localConfig : {};
const config = jenkinsConfig || defaultConfig;
config.mockApi = mockApi && process.env.NODE_ENV !== 'production';
config.constants = constants;

config.API.userUrl2 = config.API.userUrl; // todo: remove this once portal-common-ui and accounts app references can be updated
console.log(config);

export default config;

