import React, { useState, useEffect } from "react";
import { DataAccessEditor, LocalizedText } from "portal-common-ui";
import useDataAccess from "hooks/utils/dataAccess";


const DataAccessTab = ({
  groupDetails,
  onUpdate,
  canEdit,
  allowFullPortfolioAccess
}) => {

  const [canSave, setCanSave] = useState(true);
  const [rowIsEditing, setRowIsEditing] = useState(false);
  const [updatedDataAccessHierarchies, setUpdatedDataAccessHierarchies] = useState([]);
  const { enabledSelectMethods } = useDataAccess();


  useEffect(() => {
    if (groupDetails.dataAccess) {
      const hierarchies = groupDetails.dataAccess.map(r => {
        return {
          rowKey: r.rowKey,
          hierarchyId: r.selectedHierarchy.hierarchyId,
          midHierarchyIds: r.selectedMids,
        };
      });
      setUpdatedDataAccessHierarchies(hierarchies);
    }
  }, [groupDetails.dataAccess]);

  const onRowAddOrUpdate = (updatedRow, mids) => {
    let newHierarchies = updatedDataAccessHierarchies;
    const existingRow = newHierarchies.find(row => row.rowKey === updatedRow.rowKey);
    if (existingRow) {
      existingRow.hierarchyId = updatedRow?.selectedHierarchy?.hierarchyId;
      existingRow.midHierarchyIds = (mids?.length > 0 ?  mids?.map(m => m?.hierarchyId || m) : updatedRow.selectedMids?.map(m => m?.hierarchyId || m))
    } else {
      newHierarchies.push({
        rowKey: updatedRow.rowKey,
        hierarchyId: updatedRow?.selectedHierarchy?.hierarchyId,
        midHierarchyIds: (mids?.length > 0 ?  mids?.map(m => m?.hierarchyId || m) : updatedRow.selectedMids?.map(m => m?.hierarchyId || m)),
      });
    }
    setUpdatedDataAccessHierarchies(newHierarchies);
    onUpdate(newHierarchies);
  };

  const onRowDeleted = (rowKey) => {
    let newHierarchies = [...updatedDataAccessHierarchies];
    newHierarchies = newHierarchies.filter(r => r.rowKey !== rowKey);
    setUpdatedDataAccessHierarchies(newHierarchies);
    onUpdate(newHierarchies);
  };

  const onRowIsEditing = (isEditing) => {
    setRowIsEditing(isEditing);
  };

  return (
    <>
      <div className="bg-theme-on-primary rounded-sm border-gray-100 min-w-full h-full overflow-auto">
        {!rowIsEditing &&
          <div class="flex flex-row">
            <div className="w-full grow font-normal text-theme-dark pb-2">
                <LocalizedText localeKey="editUser.sections.dataAccess.subtitle"/>
            </div>
          </div>
        }
        {rowIsEditing &&
          <div className="ml-[-20px]">
          </div>
        }
     <div className="flex bg-theme-on-primary rounded-sm border-gray-100">
          <div className="flex items-top space-y-5 gap-x-4 items-start justify-start py-4 px-0 bg-theme-on-primary rounded-sm h-full" >
            <div className="flex items-top space-y-5 gap-x-4 items-start justify-start py-0 px-8 pl-0 bg-theme-on-primary rounded-sm  min-w-full h-full" >
              <DataAccessEditor
                // preSelectedPortfolio="T-MAS"
                dataRows={groupDetails.dataAccess}
                allowFullPortfolioAccess={allowFullPortfolioAccess}
                showEmptyAccessError
                setCanSave={setCanSave}
                showViewMids
                hasMidsExcludedFromLoggedInUser={groupDetails.hasMidsExcludedFromLoggedInUser}
                onRowIsEditing={onRowIsEditing}
                lazyLoadItems
                onRowAddOrUpdate={onRowAddOrUpdate}
                onRowDeleted={onRowDeleted}
                canEdit={canEdit}
                onSetPageTitle={() => {}}
                redirectSingleLineageToMidSelector={false}
                hideSelectedMidsText
                enabledSelectMethods={enabledSelectMethods.filter(m => m !== 'DataAccessGroup')}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default DataAccessTab;