import React, { useEffect } from "react";
import StepWizard from 'react-step-wizard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import WizardSections from "./WizardSections";
import WizardStepHeader from "./WizardStepHeader";
import WizardStepFooter from "./WizardStepFooter";
import './CreateWizard.scss';
import { AccessibleIcon, Button, ButtonType, LocalizedText } from 'portal-common-ui';

let goToStep = null;

const CreateWizard = ({ 
    steps,
    backToButtonKey,
    imageSvg,
    title,
    subTitle,
    hideSubtitle,
    onCancel,
    onNextStep,
    onPrevStep,
    showPrevStep,
    onStepChange,
    currentStep,
    wizardSteps,
    nextButtonCaptionKey,
    customIsValid,
    hideHeader,
    hideFooter,
    showButtonLoader,
    children,
    isSaving,
  }) => {

  useEffect(() => {
    if (goToStep) goToStep(currentStep);
  }, [currentStep])

  const transitions = {};

  const currentStepFromSteps = steps.find(step => step.stepName === currentStep);
  const currentStepIndex = wizardSteps.findIndex(step => step.stepName === currentStep) + 1;
  const totalSteps = wizardSteps.length;
  const header = <WizardStepHeader 
    title={currentStepFromSteps.headerText} 
    subtitle={currentStepFromSteps.headerSubtext}
    showToolTip={currentStepFromSteps.showToolTip}
    toolTipText={currentStepFromSteps.toolTipText}
    toolTipHeader={currentStepFromSteps.toolTipHeader}
    toolTipTextKey={currentStepFromSteps.toolTipTextKey}
    hidden={hideHeader}
    toolTipClassName={currentStepFromSteps.toolTipClassName}
    currentStepIndex={currentStepIndex}
    totalSteps={totalSteps}
  />;

  const footer = <WizardStepFooter 
    onCancel={onCancel} 
    enableNextStep={customIsValid} 
    onNextStep={onNextStep} 
    showPrevStep={showPrevStep}
    onPrevStep={onPrevStep} 
    nextButtonCaptionKey={nextButtonCaptionKey}
    hidden={hideFooter}
    showButtonLoader={showButtonLoader}
    isSaving={isSaving}
  />;

  return (
        <div className="flex w-full min-h-[510px] h-full">           

            <div className="flex flex-col hidden">
                <Button
                  id="create-user-button-back"
                  defaultContainerClass="mb-4"
                  buttonType={ButtonType.SecondaryButton}
                  // buttonClass="btn btn-secondary-alt mt-0 min-w-[66%] p-2 border-1"
                  onClick={onCancel}
                  child={[ <AccessibleIcon icon={faArrowLeft} labeledBy="create-backButton-label" classes="mr-1 text-base" />, <span className="w-max ml-2"><LocalizedText localeKey={backToButtonKey} id="create-backButton-label"/></span>]}
                />

                <div className="flex flex-col mb-4 space-y-4 items-start justify-start w-64">
                    <h1 className={`w-60 h-1/3 m-0 text-2xl font-medium ${hideSubtitle ? 'text-blue-900' : 'w-[16.75rem] xl:text-[2rem] text-theme-info'}`}>{title}</h1>
                    <p className={`${hideSubtitle ? 'hidden' : ''} w-[16.75rem] text-sm leading-4 text-theme-dark`}>{subTitle}</p>
                </div>
                <WizardSections currentStep={currentStep} steps={wizardSteps} />
            </div>


            <div 
              className="flex flex-col items-start ml-8 justify-start lrg:ml-0 lrg:left-0 w-full"
              data-test={`${currentStep}-cy`}
            >
              {header}
              <StepWizard nav={<NavHelper />} transitions={transitions} isLazyMount={true} onStepChange={onStepChange} className="w-full flex-1 bg-theme-on-primary create-clone-wrapper">
                {React.Children.map(children, (child) => {
                  if (child) {
                    return React.cloneElement(child, { header, footer });
                  }
                })}
              </StepWizard>
              {footer} 

        </div>

      </div>
  );
}

const NavHelper = ({goToNamedStep}) => {
  useEffect(() => {
    goToStep = goToNamedStep;
  }, [goToNamedStep]);

  return (
    <></>
  );
};

export default CreateWizard;