import { useCallback } from 'react';
import { useGlobal as libGlobalReactN, getGlobal } from 'reactn';
import produce from 'immer';
import { saveState } from 'store/browserStorage';

let globalProp;
let setGlobalProp;

export const useGlobalInternal = (property) => {
  const cb = useCallback((prop) => {
    [globalProp, setGlobalProp] = libGlobalReactN(prop);
    return [globalProp, setGlobalProp, prop];
  }, [property]);
  return cb(property);
};

export const useGlobal = (prop) => {
  const [x, setX, xProp] = useGlobalInternal(prop);
  const wrappedSetX = (draftUpdater) => {
    const p = getGlobal()[xProp];
    if (typeof draftUpdater === 'function') {
      setX(produce(p, (newX) => {
        draftUpdater(newX);
      }));
    } else {
      setX(draftUpdater);
    }
    saveState({ ...getGlobal() });
  };
  return [x, wrappedSetX];
};

export default useGlobal;

