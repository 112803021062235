import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import useFeatureFlags from "hooks/utils/featureFlags";
import { LocalizedText } from "portal-common-ui";

const WizardSections = ({ steps, currentStep }) => {
  const { accountsApplicationManagementEnabled } = useFeatureFlags();

  const renderSections = () => {
    let filterSteps= steps;
    if(accountsApplicationManagementEnabled){
      filterSteps = steps.filter(step => step.stepName !== 'creationComplete');
    }
    return (
      <>
        {filterSteps
        .map((step, idx) => {
          return (
            <div className="inline-flex space-x-4 items-center justify-start w-48 xl:w-52 h-8 ml-0 " key={idx+1}>
              <div className="w-8 h-8">
                  {step.isActive && 
                  <div className="ring-2 ring-theme-primary bg-theme-on-primary border-1 flex items-center justify-center flex-1 h-full px-3 py-1 rounded-full" src="https://via.placeholder.com/28x28">
                    <p className="flex-1 text-sm xl:text-base font-medium text-center text-theme-primary">{idx+1}</p>
                  </div>
                  }
                  {step.isComplete && 
                  <div className="ring-2 ring-gray-300 bg-theme-on-primary border-1 flex items-center justify-center flex-1 h-full px-3 py-1 rounded-full" src="https://via.placeholder.com/28x28">
                    <FontAwesomeIcon icon={faCheck} className="text-center text-sm xl:text-base text-theme-success" />
                  </div>
                  }
                  {step.isPending && 
                  <div className="flex items-center justify-center flex-1 h-full px-3 pt-1.5 pb-1 bg-gray-300 rounded-full">
                    <p className="flex-1 text-sm xl:text-base font-medium text-center text-theme-primary">{idx+1}</p>
                  </div>
                  }
              </div>
              {step.isActive && <p className="w-40 h-6 text-sm xl:text-base font-medium leading-loose text-theme-primary">{step.title}</p>}
              {!step.isActive && <p className="w-40 h-6 text-sm xl:text-base font-medium leading-loose text-gray-300">{step.title}</p>}
            </div>
          );
        })
      }
      </>
    );
  };

  return (
    <div className="inline-flex items-start justify-center w-[16.75rem] xl:ml-0 h-72">
      <div className="inline-flex flex-col space-y-4 items-start justify-start flex-1 pl-4 pr-2 pt-4 pb-6 bg-theme-on-primary border border-gray-300">
          <p className="text-xs font-bold leading-tight text-theme-medium"><LocalizedText localeKey="createUser.sections.title"/></p>
          {renderSections()}
      </div>
    </div>
  );
};

export default WizardSections;
