import useSession from "hooks/session/session";

const usePolicyLinks = (locale: string) => {

    const { user } = useSession();
    const brandDetails = JSON.parse(sessionStorage.getItem('brandDetails') || '{}');
    const additionalInfo = JSON.parse(brandDetails?.additionalInfo || '{}');
    const lang = locale || user?.languageAbbr || 'en-US';
    const getLink = (linkProp: string) => additionalInfo?.[linkProp]?.find((linkObj: any) => linkObj.lang === lang)?.link;
    const privacyPolicyLink = getLink('privacyPolicy');
    const termsOfUseLink = getLink('tnc');
    const regulatoryDisclosuresLink = getLink('regulatoryDisclosures');


    return {
        privacyPolicyLink,
        termsOfUseLink,
        regulatoryDisclosuresLink
    }

}

export default usePolicyLinks;