import { CellShape } from 'portal-common-ui';
import config from 'config/config';

export interface IDAGroup {
    hierarchyId: string,
    mid: number,
    groupNumber: number,
    merchantName: string,
    actions: any
}

const useMerchantList = () => {

    const transformRow = (rows: any[]) => {
        const inRows = rows;
        const outRows = inRows.map(inRow => {
            const grpLin = inRow.lineageDetails.find((d:any) => d.name == 'group')
            let outRow: IDAGroup = {
                hierarchyId: inRow.hierarchyId,
                mid: inRow.merchantDemographics.merchantNumber,
                groupNumber: grpLin?.value || config?.constants?.nullGroupValue,
                merchantName: inRow.merchantDemographics.dbaName,
                actions: 'View Details',
            };
            return outRow;
        });
        return outRows;
    };

    const sortMap = {}//{'default': 'GROUP_NAME', 'groupName': 'GROUP_NAME', 'groupOwner': 'GROUP_OWNER'};

    const loaderColumnDefs = [
        { width: "31%", cellShape: CellShape.Bar },
        { width: "31%", cellShape: CellShape.Bar },
        { width: "31%", cellShape: CellShape.Bar },
        { width: "7%", cellShape: CellShape.Ellipsis },
    ];

    return {
        transformRow,
        sortMap,
        loaderColumnDefs,
    }
}

export default useMerchantList;