const themeItems = {
    // brandPrimary: '#005C9E',
    // white: '#fff',
    // black: '#25262D',
    // brandSecondary: '#003C66',
    // accentLighter: '#f1f2f4',
    // accentLight: '#0074c7',
    // accentDark: '#003052',  // not used
    // brandTertiary: '#004a80',
    // disabled: '#72727f',
    // info: '#005C9E',
    // success: '#579E00',
    // successAlt: '#468000',
    // warning: '#FFA538',
    // danger: '#E12619',
    // dangerAlt: '#B31E14',
    // greyLight: '#F1F2F4',
    // grey: '#9296A5',
    // greyDark: '#394046',
	// greyMediumDark: '#707689',
	// greyLightBorder: '#D7DCE1',
    // textLight: '#9296A5', // not used
    // normal: '#5A5E6D',
    // dark: '#403F3F', // not used
    // muted: '#9296A5',
    // pill: '#579E00',
    // alertContent: '#5A5E6D',
    // alertClose: '#9296A5',
    // alertDanger: '#E12619',
    // alertWarning: '#F98700',
    // alertInfo: '#005C9E', done(needs to be removed)


    // Colors after new naming ->
    themePrimary: '#0074C7',
    themePrimaryHover: '#148EE6',
    themeOnPrimary: '#FFFFFF',
    themeSecondary: '#003C66',
    themeLightBorder: '#D7DCE1',
    themeDisabled: '#C2C1C2',
    themeMedium: '#5A5E6D',
    themeDark: '#394046',
    themeLightBackground: '#F1F2F4',
    themeInfo: '#005C9E',
    themeInfoBackground: '#E5F4FF',
    themeSuccess: '#468000',
    themeWarning: '#C76D00',
    themeWarningBackground: '#FFFAE5',
    themeDanger: '#B31E14',

    // Mobile Support Colors
    themeDisabledMobile: '#707689',
};

export default themeItems;