import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import { ColumnFunctionCallbackParams } from 'ag-grid-community';
import { gridInterfaces, filter } from "portal-common-ui/dist/interfaces";
import {
  CustomFilter,
  FilterBar,
  Grid,
  GridHeader,
  SearchByMid,
  useDataAccessGroup,
  useTranslations,
  usePortalAppContext,
  useLocale
} from "portal-common-ui";
import 'portal-common-ui/dist/portal-common-ui.css';
import useAlertQueue from "hooks/alertQueue";
import useDAGList from 'hooks/use-dag-list';
import useFeatureFlags from "hooks/utils/featureFlags";
import config from 'config/config';
import { useGlobal } from 'libs/reactn';
import { localeKeys } from "typings/translate";

const DataAccessManagement: React.FC = () => {
  const history = useHistory();
  const [filtersUpdatedToggle, setFiltersUpdatedToggle] = useState<boolean>(false);
  const [filtersReceived, setFiltersReceived] = useState<boolean>(false);
  const [, setFilters, filtersRef] = useStateRef<{[key: string]: any}>({});
  const [, setOmniSearch, omniSearchRef] = useStateRef<string[]>([]);
  const [ownedDAGRowCount, setOwnedDAGRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const userUrl = config.API.userUrl2;
  const [auth, setAuth] = useGlobal('auth');
  const relativeUrl = '/hierarchy/data-access-groups/search';
  const { transformRow, sortMap, loaderColumnDefs, dagGroupNameList } = useDAGList();
  const { isSaving, deleteDataAccessGroup } = useDataAccessGroup();
  const { translate } = useTranslations();
  const { enqueueAlert } = useAlertQueue();
  const [totalRowCount, setTotalRowCount] = useState(0);
  const { translateToString } = useLocale();
  const { accountsPrivacyTermsLinksEnabled } = useFeatureFlags();

  const serverDatasource: gridInterfaces.IServerDatasource = {
    url: `${userUrl}${relativeUrl}`,
    reqType: 'post',
    sortMap,
    transformRow,
    reqBody: {
      omniSearchValues: omniSearchRef.current,
      includeOnlyOwnedDAGs: true,
      userName: "",
      ownerName: "",
      merchantNumbers: [],
      ...filtersRef.current,
    },
    filtersUpdatedToggle,
  }

  useEffect(() => {
    const includeOnlyOwnedDAGs = filtersRef.current?.includeOnlyOwnedDAGs !== undefined ? filtersRef.current.includeOnlyOwnedDAGs : true;
    if (includeOnlyOwnedDAGs) {
      setOwnedDAGRowCount(totalRowCount);
      setAuth(newAuth => {
            newAuth.session.user.dags = dagGroupNameList?.filter(m => m?.groupOwnerEmail === auth.session.user.email)?.map(m => m?.groupName?.toLowerCase());          
          });
        }
  }, [totalRowCount]);

  const showDeleteDAGWarning = (rowData: ColumnFunctionCallbackParams['data'], api: ColumnFunctionCallbackParams['api']) => {
    const deleteGroup = async () => {
      const response = await deleteDataAccessGroup(rowData.groupId);
      if (response.status >= 200 && response.status <= 204) {
        enqueueAlert({ alertType: 'toast', messageType: 'info', messageKey: "dataAccessManagementPage.toasts.success.delete.message", transitionDuration: 6000});
        api.refreshServerSideStore({purge: true});
      } 
    };

    enqueueAlert({ 
      alertType: 'modal', 
      titleKey: "dataAccessManagementPage.alerts.warning.delete.title", 
      messageType: 'warning', 
      continueCaptionKey: "dataAccessManagementPage.alerts.warning.delete.continueCaption",
      cancelCaptionKey: "shared.alerts.cancel",
      continueAction: deleteGroup,
      cancelAction: () => {},
      messageKey: "dataAccessManagementPage.alerts.warning.delete.message", 
    });
  }

  const viewGroup: (rowData: ColumnFunctionCallbackParams['data']) => void = (rowData) => {
    history.push(`/data-access-group/view/${rowData.groupId}`);
  }

  const getActionItems: gridInterfaces.IActionsColumn['getActionItems'] = (data: ColumnFunctionCallbackParams['data']) => {
    
      const isGroupOwner = data.groupOwnerEmail === auth.session.user.email;

      return [
          {
              iconClass: `${isGroupOwner ? 'fa fal fa-edit' : 'fa fa-regular fa-eye'} text-theme-info`,
              captionKey: isGroupOwner ? 'dataAccessManagementPage.actions.viewEditGroup': 'dataAccessManagementPage.actions.viewGroup',
              captionClass: 'text-theme-info',
              handler: viewGroup,
          },
          {
              iconClass: 'fa fa-trash-alt text-theme-danger pr-[5px]',
              captionKey: 'dataAccessManagementPage.actions.deleteGroup',
              captionClass: 'text-theme-danger',
              handler: showDeleteDAGWarning,
              disabled: isSaving,
              hidden: !isGroupOwner,
          }
      ]
  }

  const onGoToPage = () => history.push('/data-access-group/new');
  
  const onFiltersChanged = (newFilters: filter.IFilter[], newOmniSearch: string[]) => {
    if (!filtersReceived) setFiltersReceived(true);
    setFilters(newFilters);
    setOmniSearch(newOmniSearch.filter(str => !!str));
    setFiltersUpdatedToggle(!filtersUpdatedToggle);
  }

  const limitExceeded = ownedDAGRowCount >= config.constants.dataAccessGroupLimit;

  const filterOptions: { nameKey: localeKeys, [key: string]: any }[] = [
    {
      id: 1,
      nameKey: "dataAccessManagementPage.filterBar.filters.mid",
      type: "CUSTOM",
      component: function (
        onRemove: any,
        onFilter: (val: any, field: string) => void,
        initialValue: filter.FilterValueType
      ) {
        return (
          <CustomFilter
            key={this.name}
            filter={this}
            onRemove={onRemove}
            onFilter={(val: any) => {
              onFilter(val, this.field)
            }}
            formatValue={(val: any) => val.map((item: any) => item?.value.trim() || item)}
            initialValue={ initialValue }
            dropdownRenderer={({ handleFilters }: { handleFilters: (val: any) => void }) => (
              <SearchByMid
                onMidSelection={(val: any) => handleFilters(val)}
                showSelectButton={false}
                initialValue={initialValue?.map((mid: any) => { return { value: mid } })}
              />
            )}
            classes="midFilter"
          />
        );
      },
      field: "merchantNumbers",
    },
    {
      id: 2,
      nameKey: "dataAccessManagementPage.filterBar.filters.groupOwner",
      type: "TEXT",
      field: "ownerName",
      placeholder: "Group Owner",
    },
    {
      id: 3,
      nameKey: "dataAccessManagementPage.filterBar.filters.ownedGroupToggle",
      type: "CHECKBOX_TOGGLE",
      field: "includeOnlyOwnedDAGs",
      alwaysShown: true
    }
  ];

  const onGridLoadingChange = (gridState: { [key: string]: any }) => setTotalRowCount(gridState.totalRowCount);

  return (
    <div data-test="data-access-management-page">
      <GridHeader 
        headingKey="homepage.sidebar.dataAccessManagement"
        rowCount={totalRowCount}
        rowCountCaptionKey='dataAccessManagementPage.header.rowCountCaption'
        actionButtonPermission 
        actionButtonCaptionKey="dataAccessManagementPage.header.actionButtonCaption"
        actionButtonHandler={onGoToPage}
        showMessage={limitExceeded}
        messageKey="dataAccessManagementPage.header.message"
        hidden={loading}
        data-test="data-access-management-create-group"
      />
      <FilterBar
        filterOptions={filterOptions}
        onClearHandler={() => setFilters([])}
        onSearchHandler={onFiltersChanged}
        omniSearchEnable={true}
        omniSearchProps={{placeholder: translateToString("dataAccessManagementPage.filterBar.omnisearch.searchBy")}}
        className="ag-grid__filter bg-theme-on-primary border-b-0 border border-solid border-gray-300"
        retainFilters
      />
      {filtersReceived && 
        <Grid
          rowModelType="serverSide"
          serverDatasource={serverDatasource}
          rowIdFieldName="groupId"
          wrapperClass={`ag-grid__container w-full ${accountsPrivacyTermsLinksEnabled ? 'h-grid-height-for-footer' : ''} grid-common-ag-grid bg-theme-on-primary border border-solid border-gray-300 rounded-sm`}
          agGridClassName={`ag-grid ${accountsPrivacyTermsLinksEnabled ? '' : 'ag-grid-height'} w-full overflow-auto`}
          sortDefault="groupName"
          onGridLoadingChange={onGridLoadingChange}
          loaderColumnDefs={loaderColumnDefs}
          loading={isSaving}
          setLoading={setLoading}
          retainSortings
          paginationClass="ag-grid__footer flex justify-between items-center p-4 bg-white border border-solid border-white ring-1 ring-gray-300"
        >
          <Grid.TextColumn
            field='groupName'
            headerNameKey="dataAccessManagementPage.columns.groupName"
            minWidth={350}
            width={350}
            accentuate
            sortable
            resizable
            cellStyle={{ overflow: 'hidden'}}
          />
          <Grid.TextColumn
            field='groupOwner'
            headerNameKey="dataAccessManagementPage.columns.groupOwner"
            sortable
            resizable
            width={650}
          />
          <Grid.ActionsColumn
            field='actions'
            headerNameKey='userManagementPage.columns.actions'
            minWidth={85}
            maxWidth={140}
            editable
            singleClickEdit
            pinned='right'
            suppressMovable
            getActionItems={getActionItems}
          />
          <Grid.Pagination />
        </Grid>
      }
    </div>
  );
};

export default DataAccessManagement;