import CheckToggle from "components/Forms/CheckToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import AppIcon from "components/Application/AppIcon";
import React, { useState } from "react";
import useTranslations from "hooks/utils/translations";
import { Button, ButtonType, useLocale } from 'portal-common-ui';
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { useGlobal } from 'libs/reactn';
import { useDataAccessGroup, BVLoader20 } from 'portal-common-ui';
import { useRoles, LocalizedText, useApi } from "portal-common-ui";
import config from 'config/config';
import ApplicationRoleDropdown from 'components/UserWizard/ApplicationRoleDropdown';
import { ReactComponent as PenImage } from "assets/icons/pen.svg";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import {IndexTooltip} from 'index-design-system-react';


interface TableProps {
  children: any;
  type?: string;
  className?: string;
}

const TableCell = ({ children, type, className = "" }: TableProps): JSX.Element => {
    return <td className={`py-2.5 px-5 ${type === "name" ? "font-medium" : "font-normal"} ${className}`}>{children}</td>;
  };
  
const TableRow = ({ children }: TableProps): JSX.Element => {
    return <tr className="border-t">{children}</tr>;
  };
  
const roleNameDisplay = (isActive: boolean, status: any, assignedApplication: any, translate: Function, getRoleDisplayName: Function) => {
  const role = assignedApplication.applicationRoleAssociations?.[0]?.role;
  if (role?.name || role?.label) {
    return role.name ? <div className={!isActive ? "text-theme-disabled" : ""} title={!isActive ? "Application Access was revoked due to inactivity" : ""}><LocalizedText localeKey={role?.name}/></div> : role.label;
  } else if (role?.roleGuid) {
    return getRoleDisplayName(role?.roleGuid);
  } else {
    return <span className="text-theme-disabled">N/A</span>;
  }
};
  
const ApplicationRow = ({
    app,
    type,
    handleToggleChange,
    targetUser,
    onRolePermissionChanged,
    editAssignedApplication,
    canEditApplicatinsAndRoles,
  }: any) => {
    const { applicationId, assigned, disabled, applicationGuid, name, newlyAdded, brandUpdated, status, targetUserUserid, userApps } = app;
    const handleCheckToggleChange = (checked: boolean) => handleToggleChange(checked, applicationGuid);
  
    const handleEditAssignedApplication = () => editAssignedApplication(app);

    const userPermissionActive = !(type === "CREATE" && disabled) && canEditApplicatinsAndRoles && !(brandUpdated && applicationGuid !== "MERCHANT_PORTAL_ACCOUNTS");
    const [auth, setAuth] = useGlobal('auth');
    const [apps] = useGlobal('apps');
    const userUrl = config.API.userUrl;
    const loggedInUserApps = apps.appList
    
    const options = {
      cachePolicy: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const activeStatusApi = useApi(userUrl, options);
    const [isActive, setIsActive] = useState(status === "ACTIVE");
    const [isLoading, setIsLoading] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [isHoveringRole, setIsHoveringRole] = useState(false);
    const { translateToString } = useLocale();

    const updateApplicationStatus = async (userId: string, applicationId: string) => {
      const payload = {
        applicationId: applicationId,
        status: 'ACTIVE'
      };
      setIsLoading(true);
      try {
        const activeStatusResponse = await activeStatusApi.put(`application/assignments/users/${userId}/applications/status`, payload);
        if(activeStatusResponse.status === 200){
          setIsActive(true);
        }else setIsActive(false);      
      } catch (error) {
        console.error('Failed to update status', error);
      }
      setIsLoading(false);
    };
    
    const inactiveStatusChange = (applicationId: string) => {
      if (!auth || !auth.session || !auth.session.user) {
        console.error('Authentication data is not available');
        return;
      }
      const userId = targetUserUserid;
      updateApplicationStatus(userId, applicationId);
    }
    
    return (
      <TableRow key={applicationId}>
        <>
          <TableCell
            type="name"
            className={`${!assigned && type === "EDIT" ? "text-theme-disabled" : ""} w-[300px] max-w-[300px]`}
          >
            <div className="flex h-[40px] items-center">
              <AppIcon id={applicationGuid} disabled={(!assigned && type === "EDIT") || (!isActive && type === "EDIT")} height={26} width={26} />
              <span className={`ml-3 w-[200px] ${type === "EDIT" && !isActive ? "text-theme-disabled" : ""}`}>{name}</span>
              {newlyAdded && !assigned && (
                <span className="bg-theme-success text-white text-xs rounded-full font-normal py-0.5 px-1.5 float-right">
                  NEW
                </span>
              )}
            </div>
          </TableCell>
          <TableCell className="w-[300px]">
            <div
              onMouseEnter={() => setIsHoveringRole(true)}
              onMouseLeave={() => setIsHoveringRole(false)}
              className="relative"
            >
              <RoleCell app={app} type={type} targetUser={targetUser} onRolePermissionChanged={onRolePermissionChanged} status={status} isActive={isActive} />
              {(isHoveringRole && !isActive && type === "EDIT" && app.status &&
                <IndexTooltip
                  arrow="bottom"
                  error="false"
                  controlled="true"
                  tooltip-content-align="left"
                  text={translateToString("editUser.sections.applicationAndRoles.assignedRole.accessRevokedTooltip")}
                  theme="light"
                  className="absolute text-sm text-theme-dark font-normal roles-tooltip-left-align rounded-lg w-64 tooltip-left-align h-56 z-20"
                />
              )}
            </div>
          </TableCell>
          {type === "EDIT" && (
            <TableCell className="text-sm">
              <CheckToggle
                defaultChecked={assigned}
                disabled={status && !isActive ? true : disabled}
                onChange={handleCheckToggleChange}
              />
            </TableCell>
          )}
          <TableCell className={`${userPermissionActive ? 'text-theme-primary' : 'text-theme-disabled'} text-center`}>
            {app.isPermissionRequired && app.assigned ? (
              (type === "EDIT" ? !isActive ? (
                isLoading ? <FontAwesomeIcon icon={faSpinner} size="lrg" spin className="text-sm pl-[4px]" />
                  : !isActive && app.status ? (
                    <div
                      onMouseEnter={() => setIsHovering(true)}
                      onMouseLeave={() => setIsHovering(false)}
                      className="relative"
                    >
                      <Button
                        title={translateToString("editUser.sections.applicationAndRoles.actions.reactivateButton")}
                        buttonType={ButtonType.LinkButton}
                        onClick={() => userPermissionActive && inactiveStatusChange(applicationId)}
                        className={`${userPermissionActive && 'cursor-pointer'} text-center ${(loggedInUserApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE") && userApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE") ? 'text-theme-disabled' : '')}`}
                        data-test={`${app.applicationGuid}-reactivate`}
                        disabled={isLoading || (loggedInUserApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE") && userApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE"))}
                      />
                      {(isHovering && (loggedInUserApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE") && userApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE")) &&
                        <IndexTooltip
                          arrow="bottom"
                          error="false"
                          controlled="true"
                          tooltip-content-align="left"
                          text={translateToString("editUser.sections.applicationAndRoles.actions.inactiveAppTooltip")}
                          theme="light"
                          className="absolute text-sm text-theme-dark font-normal rounded-lg w-64 tooltip-left-align h-56 z-20"
                        />
                      )}
                    </div>
                  )
                    : <PenImage className="cursor-pointer mx-auto" style={{ width: '24px', height: '24px' }} onClick={() => userPermissionActive && handleEditAssignedApplication()} data-test={`${app.applicationGuid}-edit-role`}/>
              ) : (
                <PenImage className="cursor-pointer mx-auto" style={{ width: '24px', height: '24px' }} onClick={() => userPermissionActive && handleEditAssignedApplication()} data-test={`${app.applicationGuid}-edit-role`}/>
              ) :
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  onClick={() => userPermissionActive && handleEditAssignedApplication()}
                  data-test={`${app.applicationGuid}-edit-role`}
                />)
            ) : (
              type === "EDIT" && app.status && !isActive ? <Button
                title={translateToString("editUser.sections.applicationAndRoles.actions.reactivateButton")}
                buttonType={ButtonType.LinkButton}
                onClick={() => userPermissionActive && inactiveStatusChange(applicationId)}
                className={`${userPermissionActive && 'cursor-pointer'} text-center ${(loggedInUserApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE") && userApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE") ? 'text-theme-disabled' : '')}`}
                data-test={`${app.applicationGuid}-reactivate`}
                disabled={isLoading || (loggedInUserApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE") && userApps.some(app => app.applicationId === applicationId && app.status === "INACTIVE"))}
              /> : ""
            )}
          </TableCell>
        </>
      </TableRow>
    );
  };
  
  const RoleCell = ({ app, type, targetUser, onRolePermissionChanged, status, isActive }: any) => {
    if (type === "EDIT") {
      return app.assigned && <EditRoleCell app={app} status={status} isActive={isActive}/>;
    } else if (type === "CREATE" && app.isPermissionRequired) {
      return (
        <ApplicationRoleDropdown
          key={app.applicationGuid}
          app={app}
          targetUser={targetUser}
          onRolePermissionChanged={onRolePermissionChanged}
        />
      );
    } else {
      return <span className="text-theme-disabled"><LocalizedText localeKey="createUser.sections.applicationRoles.form.notApplicable"/></span>;
    }
  };
  
  const EditRoleCell = ({ app, status, isActive }: any) => {
    const { translate } = useTranslations();
    const { getRoleDisplayName } = useRoles();
    if (app.isPermissionRequired) {
      if (app?.assignedApplication?.applicationRoleAssociations) {
        return roleNameDisplay(isActive, status, app?.assignedApplication, translate, getRoleDisplayName);
      } else {
        return (
          <div className="flex items-center px-4 py-1 bg-theme-warning-background">
            <div className="text-theme-warning">
              <FontAwesomeIcon className="text-2xl" icon={faInfoCircle} />
            </div>
            <div className="text-base pl-4 leading-4"><LocalizedText localeKey="editUser.sections.applicationAndRoles.assignedRoleWarning"/></div>
          </div>
        );
      }
    } else {
      return <span className="text-theme-disabled"><LocalizedText localeKey="createUser.sections.applicationRoles.form.notApplicable"/></span>;
    }
  };

  export default ApplicationRow;