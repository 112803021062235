import { useEffect, useState, useRef } from 'react';
import { useGlobal } from 'libs/reactn';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useMerchantList, BVLoader20, Button, ButtonType, LocalizedText, useDateTimeFormats } from 'portal-common-ui';
import useFeatureFlags from 'hooks/utils/featureFlags';
import ViewHideButton from './ViewHideButton';
import config from 'config/config';
import usePermissions from 'hooks/session/permissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faXmark } from '@fortawesome/pro-light-svg-icons';

const DemoGraphicsDetail = () => {

    const { isLoading, getDetails } = useMerchantList('', '/hierarchy/profile/merchants');
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const { accountsMerchantDemographicsPrimaryContactEnabled } = useFeatureFlags();
    const midFilterApplied = location.state?.midFilterApplied;
    const [details, setDetails] = useState({})
    const [lockTAXID, setLockTAXID] = useState(true);
    const [lockOwnerName, setLockOwnerName] = useState(true);
    const [ownerNameValue, setOwnerNameValue] = useState("********************");
    const [tAXIDvalue, setTAXIDvalue] = useState("****************");
    const { hasPermission } = usePermissions();
    const tid = useRef(null);
    const owner = useRef(null);
    const { formatDateTime } = useDateTimeFormats();
    const [totalRowCount] = useGlobal('totalRowCount');

    useEffect(() => {
        getDetails(params.hierarchyId, false, false)
            .then(data => {
                setDetails(data.content);
            });
        window.addEventListener('blur', () => {
            handleClickLockTaxID();
            handleClickLockOwnerName();
        });

        return () => {
            window.removeEventListener('blur', () => {
                handleClickLockTaxID();
                handleClickLockOwnerName();
            });
        }

    }, []);

    if (isLoading) {
        return (<BVLoader20 />);
    }

    const handleClickUnlockTaxID = async () => {
        const answer = await getDetails(params.hierarchyId, false, true);
        setTAXIDvalue(answer?.content?.taxId);
        setLockTAXID(false);
        clearInterval(tid?.current);
        tid.current = setInterval(handleClickLockTaxID, config.constants.unlockFieldIntervalSeconds * 1000);
    };

    function handleClickLockTaxID() {
        setLockTAXID(true);
        clearInterval(tid?.current);
        setTAXIDvalue("****************");
    }

    const handleClickUnlockOwnerName = async () => {
        const answer = await getDetails(params.hierarchyId, true, false);
        setOwnerNameValue(answer?.content?.ownerName);
        setLockOwnerName(false);
        clearInterval(owner?.current);
        owner.current = setInterval(handleClickLockOwnerName, config.constants.unlockFieldIntervalSeconds * 1000);
    };

    function handleClickLockOwnerName() {
        setLockOwnerName(true);
        clearInterval(owner?.current);
        setOwnerNameValue("********************");
    }

    return (
        <div>
            <div className="flex items-center bg-theme-on-primary justify-start min-h-[56px] t-xl:min-h-[72px] min-w-[1150]  mr-[-16px] ml-[-16px] xl:mr-[-30px] xl:ml-[-30px] gap-4 xl:gap-8 border-theme-light-border border sml:hidden">
                <Button
                    id="button-back"
                    buttonType={ButtonType.SecondaryButton}
                    onClick={totalRowCount > 1 || midFilterApplied ? history.goBack : () => history.push('/')}
                    buttonClass="sml:hidden ml-8"
                    child={<FontAwesomeIcon icon={faArrowLeft} className='font-light text-base' />}
                />
                <div className="text-2xl font-medium text-theme-dark min-w-[105px]">
                     <LocalizedText localeKey="homepage.sidebar.merchantDemographics" /><span>&nbsp; - {details.dbaName}</span>
                </div>
            </div>
            <div className='hidden sml:flex bg-theme-on-primary text-lg text-theme-secondary font-medium p-4 items-center justify-between'>
                <span className='mx-auto'><LocalizedText localeKey="homepage.sidebar.merchantDemographics"/></span>
                <button className='text-[20px]' onClick={totalRowCount > 1 || midFilterApplied ? history.goBack : () => history.push('/')}>{<FontAwesomeIcon className="text-theme-primary-hover" icon={faXmark} />}</button>
            </div>
            {/* {!} */}
            <div className="mx-4 my-8 lrg:my-4 sml:m-0" data-test="merchant-demographics-details">     
                <div>
                    <div className="text-sm border border-theme-light-border font-medium leading-tight text-theme-dark bg-theme-light-background px-4 py-3" data-test="merchant-demographics-section-title"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.title" /></div>
                    <div className="mb-5 bg-theme-on-primary sml:p-4 border-theme-light-border border border-t-0 sml:border-none sm:p-3" >
                            <div className="flex lrg:flex-col">
                                <div>
                                    <div className="flex sml:flex-col mb-4">
                                        <div className="text-sm text-theme-medium font-medium w-40 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.merchantNumber" /></div>
                                        <div className="text-sm  text-theme-dark font-medium w-80 lrg:w-60"> {details.merchantNumber} </div>
                                        <span class="w-4 lrg:w-24"></span>
                                    </div>
                                     
                                    <div className="flex sml:flex-col mb-4">
                                        <div className="text-sm text-theme-medium font-medium w-40 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.DBAName" /></div>
                                        <div className="text-sm  text-theme-dark font-medium w-80 lrg:w-60">  {details.dbaName} </div>
                                    </div>
                                    <div className="flex sml:flex-col mb-4">
                                        <div className="text-sm text-theme-medium font-medium w-40 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.locationAddress" /></div>
                                        <div className="text-sm  text-theme-dark font-medium w-80 lrg:w-60">
                                            {details.dbaAttention && <div> {details.dbaAttention}   </div>}  
                                            {details.dbaAddress1 && <div> {details.dbaAddress1} </div>} 
                                            {details.dbaAddress2 && <div> {details.dbaAddress2} </div>} 
                                            {details.dbaCity && <span> {details.dbaCity},   </span>}  
                                            {details.dbaState}  {details.dbaPostalCode} 
                                            </div>
                                    </div>
                                    <div className="flex sml:flex-col mb-4">
                                        <div className="text-sm text-theme-medium font-medium w-40 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.phone" /></div>
                                        <div className="text-sm text-theme-dark font-medium w-40">  {details.dbaPrimaryPhoneNumber} </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex sml:flex-col mb-4">
                                        <div className="text-sm text-theme-medium font-medium w-48 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.billingAddress" /></div>
                                        <div className="text-sm  text-theme-dark font-medium w-80 lrg:w-60">
                                            {details.billingAttention && <div> {details.billingAttention}   </div>}  
                                            {details.billingAddress1 && <div> {details.billingAddress1} </div>} 
                                            {details.billingAddress2 && <div> {details.billingAddress2} </div>} 
                                            {details.billingCity && <span> {details.billingCity}, </span>} 
                                            {details.billingState} {details.billingPostalCode}
                                        </div>
                                    </div>
                                    {accountsMerchantDemographicsPrimaryContactEnabled && <div className="flex sml:flex-col mb-4">
                                        <div className="text-sm text-theme-medium font-medium w-48 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.faxNumber" /></div>
                                        <div className="text-sm text-theme-dark font-medium w-40">  {details.dbaFaxNumber} </div>
                                    </div>}
                                    {accountsMerchantDemographicsPrimaryContactEnabled && <div className="flex sml:flex-col mb-4">
                                        <div className="text-sm text-theme-medium font-medium w-48 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.sicCode" /></div>
                                        <div className="text-sm text-theme-dark font-medium w-40">  {details.dbaSICCode} </div>
                                    </div>}
                                </div>
                            </div>
                            <hr className='sml:hidden'/>
                        
                        <div className="sm:my-4">
                            <div className="flex lrg:flex-col">
                                <div className="flex lrg:flex-col text-sm text-theme-medium font-medium mr-4 w-[200px]"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.groupNumberName" /><span className='text-theme-dark lg:ml-4'>{details.group}</span> </div>
                                <div className="flex lrg:flex-col text-sm text-theme-medium font-medium mr-4 w-[200px]"><LocalizedText localeKey="merchantDemographicsView.sections.merchantInformation.associationNumberName" /> <span className='text-theme-dark lg:ml-4'>{details.association}</span></div>
                            </div>
                        </div>

                    </div>
                </div>

                <div>
                    <div className="text-sm font-medium leading-tight text-theme-dark border border-theme-light-border bg-theme-light-background px-4 py-3" data-test="merchant-demographics-section-title"><LocalizedText localeKey="merchantDemographicsView.sections.ownerAccountInformation.title" /></div>
                    <div className="flex lrg:flex-col mb-5 p-3 bg-theme-on-primary border-theme-light-border border border-t-0 sml:border-none sml:p-3" >
                                <div className=''>
                                    {hasPermission("VIEW_OWNER_NAME") && (
                                        <div className="mb-4">
                                            <div className="flex">
                                                <div className="text-sm text-theme-medium font-medium w-40 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.ownerAccountInformation.ownerName" /></div>
                                                <div className="text-sm  text-theme-dark font-medium w-40" data-private data-test="merchant-demographics-owner-name">
                                                    {ownerNameValue}  </div>
                                                <ViewHideButton
                                                    onView={handleClickUnlockOwnerName}
                                                    onHide={handleClickLockOwnerName}
                                                    lockedField={lockOwnerName}
                                                    data-test="merchant-demographics-owner-name-btn"
                                                />
                                            </div>
                                        </div>
                                    )}  
                                    {hasPermission("VIEW_TAX_ID") && (
                                        <div className="mb-4">
                                            <div className="flex">
                                                <div className="text-sm text-theme-medium font-medium w-40 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.ownerAccountInformation.taxId" /></div>
                                                <div className="text-sm  text-theme-dark font-medium w-40" data-private data-test="merchant-demographics-tax-id">{tAXIDvalue}</div>
                                                <ViewHideButton
                                                    onView={handleClickUnlockTaxID}
                                                    onHide={handleClickLockTaxID}
                                                    lockedField={lockTAXID}
                                                    data-test="merchant-demographics-tax-id-btn"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="mb-4">
                                        <div className="flex">
                                            <div className="text-sm text-theme-medium font-medium w-40 lrg:w-32"><LocalizedText localeKey="merchantDemographicsView.sections.ownerAccountInformation.ownershipType" /></div>
                                            <div className="text-sm  text-theme-dark font-medium w-80 lrg:w-60">  {details.ownershipType} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='px-4 lrg:px-0'>
                                    <div className="mb-4">
                                        <div className="flex sml:flex-col">
                                            <div className="text-sm text-theme-medium font-medium w-48 lrg:w-44"><LocalizedText localeKey="merchantDemographicsView.sections.ownerAccountInformation.visaDescriptor" /></div>
                                            <div className="text-sm  text-theme-dark font-medium w-60 lrg:w-32">   {details.visaDescriptor} </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex sml:flex-col">
                                            <div className="text-sm text-theme-medium font-medium w-48 lrg:w-44"><LocalizedText localeKey="merchantDemographicsView.sections.ownerAccountInformation.mastercardDescriptor" /></div>
                                            <div className="text-sm  text-theme-dark font-medium w-60 lrg:w-32">  {details.mastercardDescriptor} </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex sml:flex-col">
                                            <div className="text-sm text-theme-medium font-medium w-48 lrg:w-44"><LocalizedText localeKey="merchantDemographicsView.sections.ownerAccountInformation.descriptorCityStateZip" /></div>
                                            <div className="text-sm  text-theme-dark font-medium w-60 lrg:w-32">  {details.descriptorCity} {details.descriptorState} {details.descriptorZip} </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>

                {accountsMerchantDemographicsPrimaryContactEnabled && <div>
                    <div className="text-sm font-medium leading-tight text-theme-dark border border-theme-light-border bg-theme-light-background px-4 py-3" data-test="merchant-demographics-section-title"><LocalizedText localeKey="merchantDemographicsView.sections.primaryContact.title" /></div>
                    <div className="mb-5 p-3 bg-theme-on-primary sml:px-4 border-theme-light-border border border-t-0 sml:border-none sml:p-3" >
                        {/* <div className="flex justify-between lrg:flex-col"> */}
                        <div className="flex lrg:flex-col">
                            <div className='mb-4 w-1/3 lrg:w-full'>
                                <div className="text-sm text-theme-medium font-medium mr-4 w-30"><LocalizedText localeKey="userManagementPage.columns.firstName" />
                                    : <span className="text-sm  text-theme-dark font-medium w-40 ml-3"> {details.dbaFirstName} </span>
                                </div>
                                
                            </div>
                            <div className='mb-4 w-1/3 lrg:w-full'>
                                <div className="text-sm text-theme-medium font-medium mr-4 w-30"><LocalizedText localeKey="userManagementPage.columns.lastName" />
                                    : <span className="text-sm  text-theme-dark font-medium w-40 ml-3"> {details.dbaLastName} </span>
                                </div>
                                
                            </div>
                            <div className='mb-4 w-1/3 lrg:w-full'>
                                <div className="text-sm text-theme-medium font-medium mr-4 w-30"><LocalizedText localeKey="userManagementPage.columns.email" />
                                    : <span className="text-sm  text-theme-dark font-medium w-40 ml-3"> {details.dbaEmail} </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>}

                <div>
                    <div className="text-sm font-medium leading-tight text-theme-dark border border-theme-light-border bg-theme-light-background px-4 py-3" data-test="merchant-demographics-section-title"><LocalizedText localeKey="merchantDemographicsView.sections.activityStatus.title" /></div>
                    <div className="mb-5 p-3 bg-theme-on-primary sml:px-4 border-theme-light-border border border-t-0 sml:border-none sml:p-3">
                        <div className="flex justify-between lrg:flex-col">
                            <div className='mb-4 w-1/3 lrg:w-full'>
                                <div className="text-sm text-theme-medium font-medium mr-4 w-30" ><LocalizedText localeKey="merchantDemographicsView.sections.activityStatus.merchantStatus" />
                                    : <span className="text-sm  text-theme-dark font-medium w-40 ml-3"> {details.merchantStatus} </span>
                                </div>
                                
                            </div>
                            <div className='mb-4 w-1/3 lrg:w-full'>
                                <div className="text-sm text-theme-medium font-medium mr-4 w-30"><LocalizedText localeKey="merchantDemographicsView.sections.activityStatus.dateOpened" />
                                    : <span className="text-sm  text-theme-dark font-medium w-40 ml-3"> {formatDateTime(details.dateOpen)?.formattedDate} </span>
                                </div>
                            </div>
                            <div className='mb-4 w-1/3 lrg:w-full'>
                                <div className="text-sm text-theme-medium font-medium mr-4 w-30"><LocalizedText localeKey="merchantDemographicsView.sections.activityStatus.dateClosed" />
                                    : <span className="text-sm  text-theme-dark font-medium w-40 ml-3"> {formatDateTime(details.dateClosed)?.formattedDate} </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemoGraphicsDetail;
