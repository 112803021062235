import React from 'react';
import { useHistory } from "react-router-dom";
import { Button, ButtonType, LocalizedText } from 'portal-common-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/pro-regular-svg-icons';

const ProductRedirectionBanner =() =>{
    const history = useHistory();
    const onGoToProductOfferings = () => history.push('/product-offerings');

    return(
        <div data-test="product-offering-banner" className="flex w-full mid-lg:w-[54rem] p-4 bg-white border border-solid">
            <div className="flex flex-col mid-lg:flex-row">
                <div className="w-full mid-lg:w-3/5 flex flex-col justify-center">
                    <h2 data-test="product-banner-title" className="text-theme-secondary font-semibold">
                        <LocalizedText localeKey="productOfferings.homePageBanner.title"/>
                    </h2>
                    <p className="w-full mid-lg:w-5/6">
                        <LocalizedText localeKey="productOfferings.homePageBanner.message"/>
                    </p>
                </div>
                <Button
                    buttonType={ButtonType.SecondaryButton}
                    defaultContainerClass={"flex items-center"}
                    buttonClass={"inline-block w-full mid-lg:w-auto mt-4 mid-lg:mt-0 h-fit-content p-3 items-center justify-center"}
                    onClick={onGoToProductOfferings}
                    testDataAttribute="go-to-product-offerings"
                    child={[
                    <span className="w-fit-content ml-2"><LocalizedText localeKey="productOfferings.homePageBanner.button"/></span>,
                    <FontAwesomeIcon icon={faArrowRight} size="lg" className="ml-3" />,
                    ]}
                />
            </div>
        </div>
    )
};

export default ProductRedirectionBanner;
