import React from 'react';
import { Button, ButtonType,LocalizedText, Pill, useLocale } from 'portal-common-ui';
import { useProductModalStore } from 'store/productModalStore';

interface ProductCardProps {
    title: string;
    titleKey: string;
    description: string;
    descriptionKey: string;
    price: any;
    isSubscribed :boolean;
    isUpgrade: boolean;
    isBundle:boolean;
    isNewApplication:boolean;
    dataTestAttribute:string;
    cachedData:any;
    toggleModal(): void;
  }
  
const ProductCard: React.FC<ProductCardProps> = ({
    title,
    titleKey,
    description,
    descriptionKey,
    price,
    isSubscribed,
    isUpgrade,
    isBundle,
    isNewApplication,
    dataTestAttribute,
    cachedData,
    toggleModal
}) => {
  const {translateToString} = useLocale();
  const { setModalData } = useProductModalStore();
  const buttonTitle = isUpgrade
    ? translateToString("productOfferings.mainPage.buttons.upgrade")
    : isSubscribed
    ? translateToString("productOfferings.mainPage.buttons.subscribed")
    : translateToString("productOfferings.mainPage.buttons.subscribe");

  const openModal = () => {
    setModalData({
      buttonTitle,
      title,
      offeringData: cachedData,
      removeApplication: false,
      myCurrentSubsription: false,
    });
    toggleModal();
  };

    return (
        <div data-test={dataTestAttribute} className="flex-wrap bg-white py-4 m-4 w-96 h-80 border border-solid">
          <div className="flex items-center h-14 max-h-14 px-4">
            {/* {!isBundle &&
            // <img src={AnalyticsIcon} alt={title} className="w-16 h-16 rounded-full mr-4" />
            } */}
            <h2 className="text-2xl font-medium text-theme-primary font-roboto contents">{titleKey ? <LocalizedText localeKey={titleKey}/>: title}</h2>
            <span className='ml-auto'>{(isUpgrade || isBundle || isNewApplication) &&
              <Pill 
              labelText={isUpgrade ? "UPGRADE" : isNewApplication ? "NEW" :  "BUNDLE"}
              labelKey={isUpgrade ? "productOfferings.mainPage.pills.upgrade" : isNewApplication ? "productOfferings.mainPage.pills.new" :  "productOfferings.mainPage.pills.bundle"}
              themeColor={isUpgrade ? 'theme-primary' : isNewApplication ? "theme-success" :  'theme-warning'}
              minWidth='80'
              showBullet={false}
              />}</span> 
          </div>
          <div className="my-2 border-b mx-4"></div>
          <p className="text-gray-600 mb-2 px-4 h-36 max-h-36 font-roboto overflow-auto">{descriptionKey ? <LocalizedText localeKey={descriptionKey}/>: description }</p>
          <div className="border-b my-2"></div>
          <div className="flex justify-between px-4">
            <span className="text-theme-secondary font-roboto font-medium text-base mt-2">${price.price}
            <p className="text-xs text-black">{price.currency==="USD" ? translateToString("productOfferings.mainPage.cards.price.usd") : price.currency}/{price.frequency==="MONTHLY" ? translateToString("productOfferings.mainPage.cards.price.monthly") : price.frequency}</p>
            </span>
            <Button
              buttonType={ButtonType.PrimaryButton}
              title={buttonTitle}
              buttonClass={"px-6 py-3 disabled:bg-white disabled:w-24 disabled:pl-8 border border-solid w-28 justify-center whitespace-nowrap"}
              disabled={isSubscribed}
              defaultContainerClass={"mt-2 w-28 border border-solid"}
              testDataAttribute="subscribe-product"
              onClick={openModal}
            />
          </div>
        </div>
    );
  };
  
  export default ProductCard;

