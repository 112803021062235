import React, { useRef, useEffect, useState } from "react";
import config from "config/config";
import CheckToggle from "components/Forms/CheckToggle";
import useStateRef from "react-usestateref";
import useFeatureFlags from "hooks/utils/featureFlags";
import {
  Pill,
  useRoles,
  NotificationCard,
  HierarchyDescriptorLabel,
  LocalizedText,
  useLocale
} from "portal-common-ui";
import { ReactComponent as PenImage } from "assets/icons/pen.svg";

export interface UserProfileOverviewProps {
  onPrevStep?: any;
  basicInfoState: any;
  dataAccessState: any;
  changeStep?: any;
  selectedGroups: any;
  editable?: boolean;
  userProfile: any;
  latestApplications: any;
  reviewConfigState: any;
  saveReviewConfigState: any;
  type?: "create" | "clone";
}

export const UserProfileOverview: React.FC<UserProfileOverviewProps> = ({
  onPrevStep,
  basicInfoState,
  dataAccessState,
  changeStep,
  selectedGroups,
  editable = false,
  userProfile,
  latestApplications,
  reviewConfigState,
  saveReviewConfigState,
  type = "create",
}) => {
  const showSysnet = config.features.hasOwnProperty("showSysnet")
    ? config.features.showSysnet
    : true;
  const showReporting = config.features.showReporting;
  const userInfoRef = useRef(null);
  const [assignedApps, setAssignedApps] = useState([]);
  const { translateToString } = useLocale();
  const { getLocalizedName } = useRoles();
  const { accountsApplicationManagementEnabled, accountsBrandListEnabled } = useFeatureFlags();
  const [sendEmail, setSendEmail, sendEmailRef] = useStateRef(
    reviewConfigState?.sendEmail || false
  );
  useEffect(() => {
    getApplicationsList();
  }, [userProfile, latestApplications]);

  const toggleEmailSent = () => {
    const nextState = !sendEmailRef.current;
    setSendEmail(nextState);
    const newReviewConfigState = reviewConfigState;
    newReviewConfigState.sendEmail = nextState;
    saveReviewConfigState(newReviewConfigState);
  };

  const isFullPortfolioSelected = (selectedHierarchyRow) => {
    return (
      selectedHierarchyRow.nodes &&
      selectedHierarchyRow.nodes[0] &&
      selectedHierarchyRow.nodes[0].selectedChild &&
      selectedHierarchyRow.nodes[0].selectedChild.label === "All"
    );
  };

  const roleNameDisplay = (assignedApplication) => {
    if (assignedApplication.applicationRoleAssociations?.[0]?.role?.nameKey) {
      return getLocalizedName(assignedApplication.applicationRoleAssociations?.[0]?.role?.nameKey);
    } else if (assignedApplication.applicationRoleAssociations?.[0]?.role?.name) {
      return getLocalizedName(assignedApplication.applicationRoleAssociations?.[0]?.role?.name);
    } else {
      return (
        <span className="text-theme-medium text-sm italic">
          {<LocalizedText localeKey="applications.applicationRoleAssociations.noRole"/>}
        </span>
      );
    }
  };

  const getPillData = (selectedHierarchyRow) => {
    let midsPillSkin = "theme-info";
    let midsPillLabel = <LocalizedText localeKey="shared.dataAccess.fullPortfolio" />
    if (!isFullPortfolioSelected(selectedHierarchyRow)) {
      if (
        selectedHierarchyRow.selectedMids === undefined ||
        selectedHierarchyRow.selectedMids.length === 0
      ) {
        midsPillLabel = <LocalizedText localeKey="shared.dataAccess.all" />;
      } else {
        midsPillLabel = (<>{selectedHierarchyRow.selectedMids.length} <LocalizedText localeKey="shared.dataAccess.mids" /></>); // of ${availableMids.length}
        midsPillSkin = "theme-success";
      }
    }
    return {
      midsPillSkin,
      midsPillLabel,
    };
  };

  const renderRows = () => {
    return (
      <div className="flex flex-col flex-grow flex-shrink">
        {dataAccessState &&
          dataAccessState.length &&
          dataAccessState.map((row: any, idx: number) => {
            const directMIDPortfolios = row?.searchBy?.value === "MID" && row?.selectedMids && Array.from(new Set(row?.selectedMids.map((m: any) => m.affiliation)));
            if (row?.searchBy?.value !== "DataAccessGroup") {
              if (row) {
                const pillData = getPillData(row);
                return (
                  <div
                    className={`flex items-center ${
                      dataAccessState.length - 1 !== idx ? "border-b" : ""
                    }`}
                    key={idx}
                  >
                    <div className="flex flex-1 justify-start">
                      <HierarchyDescriptorLabel 
                        tiers={row.nodes} 
                        selectedPortfolios={directMIDPortfolios || [row.selectedPortfolio]} 
                        portfolioClass="min-w-[40px] mx-2 my-auto text-theme-info font-medium text-lg"
                      />
                    </div>
                    <Pill
                      themeColor={pillData.midsPillSkin}
                      labelText={pillData.midsPillLabel}
                      minWidth={0}
                    />
                  </div>
                );
              }
            } else if (row?.searchBy?.value === "DataAccessGroup") {
              return (
                <div
                  className={`flex flex-col justify-center mt-px h-full text-base font-medium text-gray-600 p-1 ${
                    dataAccessState.length - 1 !== idx ? "border-b" : ""
                  }`}
                  key={idx}
                >
                  {row?.selectedGroup?.name}
                </div>
              );
            }
          })}
      </div>
    );
  };

  const emailSetupNewUser = () => {
    return (
      <>        
        <div className="flex not-italic pt-2 font-bold text-sm leading-6 text-theme-dark">
          <LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfAdditionalOpt.additionalOption"/>
        </div>
        <div className="flex-1 px-6 xl:px-6 py-4 text-sm border lrg:border-0 text-theme-dark font-normal w-[872px] xl:w-[872px] lrg:w-auto">
          <div className="w-[630px] xl:min-w-[777px] flex justify-start py-2 t-xl:py-4 lrg:w-auto">
            <CheckToggle
              defaultChecked={sendEmail}
              onChange={toggleEmailSent}
            />
            <div className="text-sm font-medium text-gray-600 px-6">
              <LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfAdditionalOpt.adtionalTabToggle"/>
            </div>
          </div>
        </div>
      </>
    );
  }

  const onEditPersonalInfo = () => {
    if (changeStep) {
      changeStep("basicInfo");
    }
  };

  const onApplicationsRoles = () => {
    if (changeStep) {
      changeStep("applications");
    }
  };

  const onDataAccessTab = () => {
    if (changeStep) {
      changeStep("dataAccess");
    }
  };

  const { brand, brands, role } = basicInfoState;
  let brandDisplay = "";
  if (brand) {
    brandDisplay = brand.length
      ? brand.map((b: any) => b.label).join(", ")
      : brand.label;
  } else if (brands) {
    brandDisplay = brands.length ? brands.join(", ") : "";
  }

  const getApplicationsList = () => {
    const hasBankAssigned = (bankLabel: string) =>
      !!userProfile.dataAccess?.find(
        (h: any) => h?.nodes[0].selectedChild.label === bankLabel
      );
    const hasExternalRole = userProfile?.roleObject?.roleType === "EXTERNAL";
    if(type === "clone" || !accountsApplicationManagementEnabled ) {
      const appList = userProfile?.userApplicationAssignments?.results.filter(
        (app: any) => {
          const isSysnet = app.assignedApplication.applicationGuid === "SYSNET";
          const isReporting =
            app.assignedApplication.applicationGuid ===
            "MERCHANT_PORTAL_REPORTING";
          const isSynovusApp = ["ACE_CUSTOMER_ENGAGEMENT"].includes(
            app.assignedApplication.applicationGuid
          );
          let includeApp = true;
          if (brand === "synovus") {
            includeApp = isSynovusApp;
          } else if (isSysnet) {
            includeApp = showSysnet && hasBankAssigned("9305") && hasExternalRole;
          } else if (isReporting) {
            includeApp = showReporting;
          }
          return includeApp;
        }
      );
      setAssignedApps(appList);
    }else{
      setAssignedApps(latestApplications);
    }
  };

  return (
    <div className={type === "clone" ? "relative w-full" : "relative w-full p-4"}>
      <div className="inline-flex flex-col items-start justify-start w-full">
        <div className="flex lrg:flex-col items-stretch justify-start flex-wrap w-full">
          <div className="text-sm flex justify-between xl:text-base text-theme-dark font-medium bg-theme-light-background xl:w-[172px] py-4 min-w-92 px-2 xl:px-4 border">
           <LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfUserInfoTab.userInfoTab"/>
           <PenImage className="hidden lrg:block cursor-pointer" onClick={onEditPersonalInfo}/>
          </div>
          <div
            className="flex-1 px-2 xl:px-4 py-4 text-base border text-theme-dark font-normal w-[700px] lrg:w-full"
            ref={userInfoRef}
          >
            <div className="flex-row flex">
              <span className="text-theme-medium uppercase">{translateToString("dataAccessGroup.columns.name")?.trim()}:&nbsp;</span>
              <div className="flex-1 font-bold">
                {basicInfoState.firstName} {basicInfoState.lastName}
              </div>
              <div className="flex-1 grid justify-end mt-0.5">
                {basicInfoState.email}
              </div>
            </div>
            <div className="flex-row flex">
              <div className="flex-1">
                <div className="mt-4">
                  <span className="text-theme-medium"><LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfUserInfoTab.accountRole"/>: </span>
                  <span className="font-semibold">
                    {basicInfoState.role && basicInfoState.role.label}
                  </span>
                </div>
                {accountsBrandListEnabled && (role && role.roleType === "INTERNAL") && (
                  <div className="mt-4">
                    <span className="text-theme-medium"><LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfUserInfoTab.brands"/>: </span>
                    <span className="font-semibold">{brandDisplay}</span>
                  </div>
                )}
              </div>
              {type === "clone" && <div className="flex flex-row">
                <div className="flex flex-auto flex-row items-end">
                  <span className="uppercase text-theme-medium"><LocalizedText localeKey="cloneUser.sections.selectUserCloneTab.cloneViewIcon.status"/></span>
                  &nbsp;
                  <div style={{ paddingTop: "2px" }}>
                    {
                    userProfile.status && <Pill
                      labelText={<LocalizedText localeKey={`userManagementPage.filterBar.filters.status.`+userProfile.status.toLowerCase()} />} 
                      themeColor={
                        userProfile.status === "ACTIVE"
                          ? "theme-success"
                          : "theme-danger"
                      }
                      minWidth={0}
                    />
                    }
                  </div>
                </div>
              </div>}
            </div>
            {basicInfoState.userHasMoreBrands && (
              <div className="mt-4">
                <NotificationCard
                  containerClass="align-center"
                  type="warning"
                  messageLine1="This user has access to brands outside of your current data set. Only brands you have access to are displayed here."
                  messageLine1Key="cloneUser.warnings.brandWarning"
                />
              </div>
            )}
          </div>

          {editable && (
            <div
              className="p-4 border ml-4 cursor-pointer self-start lrg:hidden"
              onClick={onEditPersonalInfo}
              data-test="edit-user-info-cy"
            >
              <PenImage />
            </div>
          )}
        </div>

        <div className="flex lrg:flex-col items-stretch justify-start flex-wrap w-full">
          <div className="text-sm flex justify-between xl:text-base text-theme-dark font-medium bg-theme-light-background xl:w-[172px] py-4 min-w-92 px-2 xl:px-4 border">
            <LocalizedText localeKey="createUser.sections.dataAccess.title" />
            <PenImage className="hidden cursor-pointer lrg:block" onClick={accountsApplicationManagementEnabled ? onDataAccessTab : onPrevStep ? onPrevStep : () => null}/>
          </div>
          <div className="flex-1 px-2 xl:px-4 py-2 min-h-[54px] border text-theme-dark overflow-y-auto overflow-x-hidden w-[700px] lrg:w-auto">
            <div className="flex">{dataAccessState && renderRows()}</div>
            {selectedGroups
              ?.filter((m) => m)
              ?.map((item) => (
                <div className="flex flex-col justify-center mt-px h-full text-base font-medium text-gray-600 px-1">
                  {item.name}
                </div>
              ))}
            {userProfile?.hasMidsExcludedFromLoggedInUser && (
              <div className="mt-4">
                <NotificationCard
                  containerClass="align-center"
                  type="warning"
                  messageLine1="This user has access to MIDs outside of your current data set. Only MIDs you have access to are displayed here."
                  messageLine1Key= "cloneUser.warnings.midWarning"
                />
              </div>
            )}
          </div>
          {editable && type === "create" && (
            <div
              className="p-4 border ml-4 cursor-pointer self-start lrg:hidden"
              onClick={accountsApplicationManagementEnabled ? onDataAccessTab : onPrevStep ? onPrevStep : () => null}
              data-test="edit-data-access-cy"
              aria-label="Edit Data Access"
            >
              <PenImage />
            </div>
          )}
          {type === "clone" && (
            <div
              className="p-4 border ml-4 cursor-pointer self-start invisible lrg:hidden"
              data-test={"edit-data-access-cy"}
            >
              <PenImage />
            </div>
          )}
        </div>

        <div className="flex lrg:flex-col items-stretch justify-start flex-wrap w-full">
          <div className="text-sm flex justify-between xl:text-base text-theme-dark font-medium bg-theme-light-background xl:w-[172px] py-4 min-w-92 px-2 xl:px-4 border">
            <LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfApplicationTab.applicationTab"/>
            <PenImage className="hidden cursor-pointer lrg:block" onClick={onApplicationsRoles}/>
          </div>
          <div
            className="flex-1 px-2 xl:px-4 py-4 text-base border text-theme-dark font-normal w-[700px] lrg:w-full"
            ref={userInfoRef}
          >
            {assignedApps &&
              assignedApps.length &&
              assignedApps.filter((app:any) => app?.assignedApplication.applicationGuid !== "MERCHANT_PORTAL_ACCOUNTS").map((app: any) => (
                <div className="font-semibold text-sm xl:text-base mb-2">
                  {type === "create" ? app?.name : <LocalizedText localeKey={app?.assignedApplication.name}/>}
                  <div className="float-right font-normal">
                    {roleNameDisplay(app?.assignedApplication)}
                  </div>
                </div>
              ))}
          </div>

          {editable && type === "create" && accountsApplicationManagementEnabled && (
            <div
              className="p-4 border ml-4 cursor-pointer self-start lrg:hidden"
              onClick={onApplicationsRoles}
              data-test={"edit-user-application-cy"}
            >
              <PenImage />
            </div>
          )}
          {type === "clone" && (
            <div
              className="p-4 border ml-4 cursor-pointer self-start invisible lrg:hidden"
              style={{visibility : 'hidden'}}
              data-test={"edit-user-application-cy"}
            >
              <PenImage />
            </div>
          )}
        </div>
        {type === "create" && accountsApplicationManagementEnabled && emailSetupNewUser()}
      </div>
    </div>
  );
};

export default UserProfileOverview;
