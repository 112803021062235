import React from 'react'
import UserCreationSuccessImg from '../../assets/img/user-creation-success.png'
import { useHistory } from "react-router-dom";
import { Button, ButtonType, LocalizedText } from 'portal-common-ui';

interface Props {
    formData:any;
    sendEmail:boolean;
    onCreateAnotherUser: () => void;
    cloneCaption:boolean;
}

const UserCreationSuccess: React.FC<Props> = ({ formData, sendEmail, onCreateAnotherUser, cloneCaption }) => {

    const history = useHistory();
    const buttonCaption = 
    cloneCaption === true ? <LocalizedText localeKey="cloneUser.sections.cloneUserButton"/> : <LocalizedText localeKey="createUser.buttons.createAnotherUser"/>;
    return (
        <div className="flex flex-col h-full">
            <div className="bg-theme-on-primary border border-theme-light-border border-t-0 w-full flex-1 xl:min-w-[774px]">
                <div className="flex flex-col space-y-5 items-start justify-start py-4 px-8 bg-theme-on-primary text-theme-dark font-normal relative w-full">
                    <h1 className="font-medium text-theme-success"><LocalizedText localeKey="createUser.sections.creationComplete.successPageData.successMessage"/></h1>
                    <p className="text-lg font-medium"><LocalizedText localeKey="createUser.sections.creationComplete.successPageData.userCreateMessage"/> <span className="font-semibold" data-test="create-user-name-success-display" >{formData.firstName} {formData.lastName}</span></p>

                    <p className="font-medium"><LocalizedText localeKey="createUser.sections.creationComplete.successPageData.userCreateSuccess"/></p>
                    <br/>
                    {sendEmail &&
                    <>
                      <p className="text-sm"><LocalizedText localeKey="createUser.sections.creationComplete.successPageData.emailSuccessMessage"/></p>
                      <p className="font-medium text-sm"><em>{formData.email}</em></p>
                    </>}
                    <img className="absolute right-0 bottom-0" alt="user creation success" style={{height:400}} src={UserCreationSuccessImg} />
                </div>
            </div>
			<div className="flex items-center justify-end p-2 w-full gap-6 xl:min-w-[774px] border border-theme-light-border border-t-0" style={{height:60, backgroundColor:"#F4F5F6"}}>
				<div style={{cursor:"pointer"}} role="button" onClick={()=>history.push('/user-management')} id="button-back" className="mx-2 text-sm font-medium text-theme-info" data-test="user-management-back-to-users">
                     <LocalizedText localeKey="createUser.buttons.backToUsers"/>
				</div>

                <Button 
                    onClick={onCreateAnotherUser} id="button-back" 
                    buttonType= {ButtonType.PrimaryButton}
                    testDataAttribute="create-another-user-cy"
					child={<span>{buttonCaption}</span>}
				/>
			</div>
        </div>
    )
}

export default UserCreationSuccess
