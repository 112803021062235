import React from "react";
import {useGlobal} from "libs/reactn";

const Overlay = props => {

    let overlay, setOverlay;
    [overlay, setOverlay] = useGlobal('overlay');

    return (
        <div className={`fixed w-full h-full top-0 left-0 ${props.dark ? "bg-gray-900 bg-opacity-30" : ""} ${props.className ? props.className : "z-10"}`} onClick={() => {}} />
    )
};

export default Overlay;