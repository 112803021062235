import { LocalizedText } from "portal-common-ui";

const steps = [
  {
    stepName: 'basicInfo',
    title: <LocalizedText localeKey="createUser.sections.basicInfo.title"/>,
    headerText: <LocalizedText localeKey="createDAG.sections.basicInfo.title"/>,
    headerSubtext: '',
    nextStep: 'dataAccess',
    prevStep: null,
    isActive: true,
    isComplete: false,
    isPending: false,
    showToolTip: true,
    toolTipTextKey: "createDAG.sections.basicInfo.toolTip",
    toolTipHeader:<LocalizedText localeKey="createDAG.sections.basicInfo.groupInfoTitle"/>,
    toolTipClassName:"data-access-groups-tooltip",
  },
  {
    stepName: 'dataAccess',
    title: <LocalizedText localeKey="createDAG.sections.selectDataAccess.title"/>,
    headerText: <LocalizedText localeKey="createDAG.sections.selectDataAccess.header"/>,
    headerSubtext: <LocalizedText localeKey="createDAG.sections.selectDataAccess.headerSubText"/>,
    nextStep: 'success',
    prevStep: 'basicInfo',
    isActive: false,
    isComplete: false,
    isPending: true,
  },
  {
    stepName: 'success',
    title: <LocalizedText localeKey="createDAG.sections.addDataAccess.title"/>,
    prevStep: 'dataAccess',
    isActive: false,
    isComplete: false,
    isPending: true
  },
];

export default steps;