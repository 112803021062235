import React, { useEffect } from 'react';
import ApplicationButton from 'components/Application/ApplicationButton';
import AppIcon from 'components/Application/AppIcon';
import useApplications from 'hooks/applications';
import useTranslations from 'hooks/utils/translations';
import { LocalizedText } from 'portal-common-ui';

const ApplicationsPanel: React.FC = () => {
    const applications = useApplications();
    const { translate } = useTranslations();
    
    return (
        <div>
            <ul className='flex flex-col sml:p-2'>
                { applications.loading 
                    ? <li className="ml-6 mb-4">Loading...</li> 
                    : applications.appList.map( ( item ) => {
                        return applications.appLinksMap[item.assignedApplication.applicationGuid] && (
                            <div key={item.assignedApplication.applicationGuid}>
                                <ApplicationButton
                                    applicationGuid={item.assignedApplication.applicationGuid}
                                    ssoType={item.assignedApplication.ssoType}
                                    appLinksMap={applications.appLinksMap}
                                    extraClass='p-2 hover:bg-theme-info hover:bg-opacity-20'
                                >
                                    <li className="w-full whitespace-nowrap" key={item.assignedApplication.applicationGuid}>
                                        <div className="flex items-center">
                                            <AppIcon id={item.assignedApplication.applicationGuid} height={48} width={48}/>
                                            <span className="text-theme-dark text-sm font-normal ml-2">{<LocalizedText localeKey={item.assignedApplication.name} />}</span>
                                            {/* { hasUnreadTooltip('onboarding.products.analytics') && <span className="pill ml-3">NEW</span>} */}
                                        </div>
                                    </li>
                                </ApplicationButton>
                            </div>
                        )
                    }) 
                }
            </ul>
        </div>
    );
}

export default ApplicationsPanel;