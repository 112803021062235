import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useModali } from "modali";
import { useGlobal } from 'libs/reactn';
import useStateRef from "react-usestateref";
import CreateWizard from "components/CreateWizard/CreateWizard";
import BasicInformationCreateUser from "components/UserWizard/BasicInformationCreateUser";
import BasicInformation from "components/UserWizard/BasicInformation";
import DataAccessTab from "components/UserWizard/DataAccessTab";
import ApplicationsTab from "components/UserWizard/ApplicationsTab";
import ReviewConfigurationTab from "components/UserWizard/ReviewConfigurationTab";
import UserCreationSuccess from "components/UserWizard/UserCreationSuccess";
import { useUser, NavigationWarning, LocalizedText, useLocale } from "portal-common-ui";
import { ReactComponent as UserWizardSvg } from "assets/img/user-wizard.svg";
import useAlertQueue from "hooks/alertQueue";
import useWizard from "hooks/wizard";
import oldSteps from "./steps";
import stepsWithApplication from "./stepsWithApplication";
import useFeatureFlags from 'hooks/utils/featureFlags';  
import ApplicationRoles from 'components/UserWizard/ApplicationRoles';
import useApplications from "hooks/applications";
import { shouldAddVTHierarchy, getVTHierarchyToAdd } from "utils/virtualTerminalUtils";
import hierarchies from "mirage/fixtures/hierarchies";

const CreateUser = () => {
  const [unmounted, setMount] = useState(false);
  const [dataAccessState, setDataAccessState] = useState(null);
  const [basicInfoState, setBasicInfoState] = useState({});
  const [reviewConfigState, setReviewConfigState] = useState({
    sendEmail: true,
  });
  const [allowFullPortfolioAccess, setAllowFullPortfolioAccess] =
    useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { createUser, getAuthenticatedSessionUser } = useUser();
  const alertQueue = useAlertQueue();
  const { getNextStep, getPrevStep, updateWizardSteps } = useWizard();
  const { accountsCloneUserEnabled, accountsApplicationManagementEnabled, accountsBrandListEnabled } = useFeatureFlags();
  const { getApplicationsList, getAvailableAppsForSubscription, appList: externalAppList, internalAppList } = useApplications();
  const [loadingSubscribedApps, setLoadingSubscribedApps] = useState(false);
  const [apps] = useGlobal('apps');
  const [auth] = useGlobal('auth');
  const { translateToString } = useLocale();
  // const unblock = useRef();
  const history = useHistory();
  const methods = useForm({ mode: "onChange" });
  const [basicInfoModal, toggleBasicInfoModal] = useModali({
    overlayClose: false,
    centered: true,
    large: true,
  });
  const imageSvg = (
    <UserWizardSvg aria-labelledby="createUser-title-label" />
  );
  // const { isValid } = useFormState({ control: methods.control });
  const steps = accountsApplicationManagementEnabled ? stepsWithApplication : oldSteps;
  const [currentStep, setCurrentStep] = useState(steps[0].stepName);
  const [wizardSteps, setWizardSteps] = useState(steps);
  const [showNotification, setShowNotification] = useState(false);
  const [updatedDataRows, setUpdatedDataRows] = useState();
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [canSaveDataAccess, setCanSaveDataAccess] = useState(false);
  const [_selectedRole, setSelectedRole, selectedRoleRef] = useStateRef(null);
  const[saveGroup,setSavedGroup] =useState(false);
  const [pemissions, setPemissions] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [sessionUser, setSessionUser] = useState(null);
  const appList = sessionUser?.roleObject?.roleType === "INTERNAL" ? internalAppList : externalAppList;
  const [searchAppError, setSearchAppError] = useState(null);
  const allDefaultTrue = [
    appList.find((obj) => obj.assignedApplication.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS")?.applicationId,
  ];
  const externalNonAdminUsersRoles = [
    "ACCOUNT_USER",
    "THIRD_PARTY_USER",
    "LIMITED_PARTNER",
  ];
  const [selectedCards, setSelectedCards] = useState([]);
  const [availableApplications, setAvailableApplications] = useState([]);
  const [unfilteredAvailableApplications, setUnfilteredAvailableApplicatons] = useState([]);

  useEffect(() => {
    const getAndSetSessionUser = async() => {
      const loggedInUser = await getAuthenticatedSessionUser();
      setSessionUser(loggedInUser);
    };

    getAndSetSessionUser();
    getApplicationsList();

  }, []);

  useEffect(() => {
    const steps = accountsApplicationManagementEnabled ? stepsWithApplication : oldSteps;
    setWizardSteps(steps)
  }, [accountsApplicationManagementEnabled]);

  useEffect(() => {
    updateAvailableApplications(appList);
  }, [appList, selectedRoleRef?.current?.roleGuid, updatedDataRows]);

  const isLoggedInUserInternal = () => {
    return auth?.session?.user?.internal;
  };

  const userHasDifferentSubscription = () => {
    return (
      ["ACCOUNT_ADMINISTRATOR", "ACCOUNT_USER"].includes(basicInfoState?.role?.roleGuid) &&
      auth?.session?.user?.role?.key === "THIRD_PARTY_ADMINISTRATOR"
    );
  };

  const targetRoleIsExternal = () => {
    return basicInfoState?.role?.roleType === 'EXTERNAL';
  }

  useEffect(() => {
   const getSubbedApps = async () => {
    if (currentStep === 'applications') {
     if ((isLoggedInUserInternal() || userHasDifferentSubscription()) && targetRoleIsExternal()) {
      // if the role or data access changed since last call
      setSearchAppError(null);
      const assignableSubscribedApps = await getAssignableSubscribedApps();
      let filteredApps = unfilteredAvailableApplications;
      if (basicInfoState?.role?.roleId && updatedDataRows?.length > 0) {
        filteredApps = unfilteredAvailableApplications?.filter(a => assignableSubscribedApps?.some(s => s.applicationId === a.applicationId) || a.applicationGuid === 'MERCHANT_PORTAL_ACCOUNTS');
      }
      updateSelectedCards(filteredApps);
      setAvailableApplications(filteredApps);  
    } else {  
      setSearchAppError(null);
      setAvailableApplications(unfilteredAvailableApplications);
    }
   }
  };
  getSubbedApps();
  }, [currentStep]);

  const onDataAccessUpdate = (updatedDataRows) => {
    setUpdatedDataRows(updatedDataRows);
    setSelectedGroups(updatedDataRows?.filter(m=>m && m?.searchBy?.value === 'DataAccessGroup' && m?.selectedGroup)?.map(m => m?.selectedGroup));
  };

  const nextButtonCaptionKey = (currentStep === "reviewConfiguration") ? "createUser.buttons.completeUserCreation" : "createUser.buttons.nextStep";

  const onNextStep = () => {
    let nextStep = getNextStep(wizardSteps, currentStep);
    if(nextStep=="applicationRoles" && selectedApplications.length===1)
    {
      nextStep = getNextStep(wizardSteps, "applicationRoles")
    }
    setCurrentStep(nextStep);
  };

  const showPrevStep =
    currentStep !== steps[0].stepName && !basicInfoModal.isShown;

  const onPrevStep = () => {
    let prevStep = getPrevStep(wizardSteps, currentStep);
    if (currentStep === "dataAccess") {
      setDataAccessState(updatedDataRows);
    }
    else if(prevStep==="applicationRoles" && selectedApplications.length===1)
    {
      prevStep = getPrevStep(wizardSteps,"applicationRoles")
    }
    setCurrentStep(prevStep);
  };

  const changeStep = (step) => {
    setCurrentStep(step);
  };

  const onStepChange = (wizardStepStatus) => {
    const updatedWizardSteps = updateWizardSteps(wizardSteps, wizardStepStatus);
    setWizardSteps(updatedWizardSteps);
  };

  const defaultFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: null,
  };

  const isDirty = () => {
    // manually check if form is dirty, methods.formState.isDirty is bugged
    const formValues = methods.getValues();
    const dirty = !Object.keys(formValues)
      .concat(Object.keys(defaultFormValues))
      .every(
        (key) =>
          formValues[key] === defaultFormValues[key] ||
          (formValues[key] === undefined &&
            defaultFormValues[key] === undefined)
      );
    return dirty;
    // return methods.formState.isDirty;
  };

  const onCancel = () => {
    history.push("/user-management");
  };

  const onCreateAnotherUser = () => {
    clearState();
    history.push('/user/new');
  }

  const clearState = () => {
    setCurrentStep(steps[0].stepName);
    setBasicInfoState(defaultFormValues);
    setDataAccessState(null);
    setReviewConfigState({ sendEmail: true });
    setUpdatedDataRows();
  };

  const disableWarning =
    currentStep === steps[steps.length - 1].stepName || !isDirty();

  const onWarningConfirmed = () => {
    if (currentStep === steps[steps.length - 1].stepName) {
      clearState();
      setReload();
    }
  };

  const hasFullPortfolioAccess = () => {
    const result =
      dataAccessState &&
      dataAccessState.some(
        (row) => row && row.nodes?.[0]?.selectedChild?.label === "All"
      );
    return result;
  };

  const onSetBasicInfo = (newState) => {
    if (newState.role.roleType !== basicInfoState.role?.roleType) {
      const newHasFullPortfolioAccess = newState.role.roleType === "INTERNAL";
      setAllowFullPortfolioAccess(newHasFullPortfolioAccess);
      if (
        currentStep === "reviewConfiguration" &&
        hasFullPortfolioAccess() &&
        !newHasFullPortfolioAccess
      ) {
        onPrevStep();
      }
    }
    newState.firstName = newState.firstName?.trim();
    newState.lastName = newState.lastName?.trim();
    newState.email = newState.email?.trim();
    setBasicInfoState(newState);
  };

  const resetRole = () => {
    setBasicInfoState({ ...basicInfoState, role: null });
  };

  const customIsValid = () => {
    if (currentStep === "dataAccess") {
      return canSaveDataAccess;
    } else if(currentStep === 'applications') {
      return !searchAppError;
    } else if(currentStep === "applicationRoles") {
      return !selectedApplications.some(
        (app) => app.isPermissionRequired && app.assignedApplication.applicationRoleAssociations.length === 0
      );
    } else if (!showPrevStep) {
      return methods.formState.isValid && selectedRoleRef.current !== null || (saveGroup);
    } else {
      return true;
    }
  };

  const hasFullPortfolio = hasFullPortfolioAccess();

  const setReload = () => {
    setMount(true);
    setTimeout(() => {
      setMount(false);
    }, 200);
  };

  const onSaveAndContinue = () => {
    switch (currentStep) {
      case "basicInfo":
        onSetBasicInfo(methods.getValues());
        setShowNotification(false);
        onNextStep();
        break;
      case "dataAccess":
        setDataAccessState(updatedDataRows);
        if (accountsApplicationManagementEnabled) {
          onNextStep();
        } else {
          setCurrentStep('reviewConfiguration');
        }
        break;
      case "applications":
        onNextStep();
        break;  
      case "applicationRoles":
        onNextStep();
        break;  
      case "reviewConfiguration":
        if (basicInfoModal.isShown) {
          onSetBasicInfo(methods.getValues());
          setShowNotification(false);
          toggleBasicInfoModal();        
        } else {
          onCreateUser();
        }
    }
  };

  const getHierarchies = useCallback(() => {
    const nodeHierarchies = dataAccessState
      ?.filter((row) => row && row.selectedMids.length === 0)
      .map((row) => row?.selectedHierarchy?.hierarchyId);
    const midHierarchies = dataAccessState
      ?.filter((row) => row && row.selectedMids.length > 0)
      .flatMap((row) => row?.selectedMids)
      .map(m => m?.hierarchyId || m);

    const hierarchies = nodeHierarchies.concat(midHierarchies);

    // below 2 lines for auto assigning VT hierarchy.  see US432910
    const vtHierarchyToAdd = shouldAddVTHierarchy(sessionUser, selectedApplications, dataAccessState) && getVTHierarchyToAdd(sessionUser);
    if (vtHierarchyToAdd) hierarchies.push(vtHierarchyToAdd);

    return hierarchies;
  }, [dataAccessState, sessionUser, selectedApplications]);

  const onCreateUser = async () => {
    const brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));
    const hierarchies = getHierarchies();
    const selectDAG = dataAccessState?.map(m =>m && m?.selectedGroup?.id);
    let createUserApplicationAssignmentPayloads;
    const accountsOptionalPermissions = pemissions.filter(p => p.selected && !p.disabled).map(p => p.value);
    if(accountsApplicationManagementEnabled){
      createUserApplicationAssignmentPayloads = selectedApplications.map(app => ({
        applicationId: app.applicationId,
        enabled: true,
        roleId: app.roleId || null,
        status: "ACTIVE",
        optionalPermissionIds: app.applicationGuid === 'MERCHANT_PORTAL_ACCOUNTS' ? accountsOptionalPermissions : app.optionalPermissionIds,
        applicationPlanIds: app.applicationPlanIds,
      }));
    }else{
      createUserApplicationAssignmentPayloads= [{
        applicationId: "734fe31a-e670-11eb-ba80-0242ac130004",
        enabled: true,
        roleId: basicInfoState.role.roleId,
        status: "ACTIVE",
        optionalPermissionIds: accountsOptionalPermissions,
        applicationPlanIds: [
          "734fe31a-e670-11eb-bc80-0242bc133334"
        ],
      }];
    }

    try {
      setIsSaving(true);
      const createUserData = {
        firstName: basicInfoState.firstName,
        lastName: basicInfoState.lastName,
        email: basicInfoState.email,
        sendSetupEmail: reviewConfigState.sendEmail,
        roleId: basicInfoState.role.roleId,
        optionalPermissionIds: pemissions.filter(p => p.selected && !p.disabled).map(p => p.value),
        hierarchies: hierarchies.filter(m => m ),
        selectDAG: selectDAG.filter(m => m ),
        locale: sessionUser.locale,
        createUserApplicationAssignmentPayloads,
      };
      if (
        accountsBrandListEnabled &&
        basicInfoState.brand &&
        basicInfoState.brand.length &&
        basicInfoState.role.roleType === "INTERNAL"
      ) {
        createUserData.brands = basicInfoState.brand.map((b) => b.value);
      } else {
        createUserData.brands = [brandDetails.brandGuid.toUpperCase()]
      }
      const resp = await createUser(createUserData);
      setIsSaving(false);
      if (resp.errorCode) {
        let err = resp.errorCode;
        let titleKey = "";
        if (err === "user.email.alreadyInUse") {
          titleKey = "cloneUser.alreadyUsedEmail";
          err = translateToString("cloneUser.emailMessage");
        } else {
          titleKey = "Sorry, something has gone wrong.";
          err = "Our system has generated an error. Please try again.";
        }
        alertQueue.enqueueAlert({
          alertType: "modal",
          titleKey,
          messageType: "error",
          message: `${err}`,
        });
      } else {
        onNextStep();
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };

  if (unmounted) {
    return null;
  }

  {/* todo: remove once clone user feature goes livem and just use BasicInformationCreateUser always */}
  const BasicInfoComponent = accountsCloneUserEnabled ? BasicInformationCreateUser : BasicInformation;

  const hasBankAssigned = (bankLabel) => {
    let hasAssigned = false;
    updatedDataRows?.forEach((h) => {
      if (
        h?.searchBy?.value === "MID" &&
        h?.selectedMids?.length &&
        h?.selectedMids?.find((m) => m?.lineage?.split("|")?.[0] === bankLabel)
      ) {
        hasAssigned = true;
      } else if (
        h?.searchBy?.value === "Hierarchy" &&
        h?.nodes?.length &&
        h?.nodes[0].selectedChild.label === bankLabel
      ) {
        hasAssigned = true;
      }
    });
    return hasAssigned;
  };

  const updateSelectedCards = (apps) => {
    let card;
    if (externalNonAdminUsersRoles.includes(selectedRoleRef?.current?.roleGuid)) {
      card = allDefaultTrue;
    } else {
      const allCardIds = apps.filter(app => app.assignedApplication.status === 'ACTIVE').map(
        (application) => application.applicationId
      );
      card = allCardIds;
    }
    setSelectedCards(card)
  }

  const getAssignableSubscribedApps = async () => {
    const roleId = basicInfoState?.role?.roleId; // selectedRoleRef?.current?.roleId;
    const nodeHierarchies = updatedDataRows
      ?.filter((row) => row && row.selectedMids.length === 0)
      .map((row) => row?.selectedHierarchy?.hierarchyId);
    const midHierarchies = updatedDataRows
      ?.filter((row) => row && row.selectedMids.length > 0)
      .flatMap((row) => row?.selectedMids)
      .map(m => m?.hierarchyId || m);
  
    if ((nodeHierarchies || midHierarchies) && roleId) {
      const hierarchies = nodeHierarchies.concat(midHierarchies).filter(h => h);
      const payloadHierarchies = hierarchies.map(h => {
        return {
          hierarchyId: h,
          accessType: "ORGANIC",
          status: "ACTIVE",  
        }
      });
      setLoadingSubscribedApps(true);
      const response = await getAvailableAppsForSubscription(roleId, payloadHierarchies);
      if (response?.status === 400) {
        setSearchAppError(response?.resourceKey);
      }
      setLoadingSubscribedApps(false);
      return response?.status === 400 ? [] : response;
    } else {
      return [];
    }
  };

  const updateAvailableApplications = async (appList) => {
    const formData = methods.getValues();
    const apps = appList.reduce((result, app) => {
      const applicationGuid = app.assignedApplication.applicationGuid;
      const isPermissionRequired = app.assignedApplication?.applicationRoleAssociations?.length > 0;
      let assignedApplication = {
        applicationRoleAssociations: [],
        applicationGuid,
        applicationId: app.applicationId,
        description: app.assignedApplication.description,
        name: app.assignedApplication.name,
        status: app.status
      };
      let roleId = "";
      if (formData && formData[applicationGuid]) {
        assignedApplication.applicationRoleAssociations = [{ role: formData[applicationGuid] }];
        roleId = formData[applicationGuid].roleId;
      }
      if (applicationGuid === "MERCHANT_PORTAL_ACCOUNTS" && selectedRoleRef?.current) {
        assignedApplication.applicationRoleAssociations = [{ role: selectedRoleRef?.current }];
        roleId = selectedRoleRef?.current?.roleId;
      }
      const isInternal = selectedRoleRef?.current?.roleType === "INTERNAL";
      if (hasBankAssigned("9305") || applicationGuid !== "SYSNET" || isInternal) {
        result.push({
          assignedApplication,
          isPermissionRequired,
          applicationGuid,
          name: <LocalizedText localeKey={app.assignedApplication.name} />,
          disabled: applicationGuid === "MERCHANT_PORTAL_ACCOUNTS",
          applicationId: app.applicationId,
          assigned: true,
          optionalPermissionIds: [],
          roleId,
          userApplicationAssignmentId: "",
          applicationPlanIds: app.applicationPlanIds,
        });
      } 
      return result;
    }, []);
    updateSelectedCards(apps);
    setUnfilteredAvailableApplicatons(apps);
  };

  const onRolePermissionChanged = (
    selectedApp,
    role,
    optionalPermissionIds
  ) => {
    const updateApplications = (app) => {
      if (app.applicationGuid === selectedApp.applicationGuid) {
        return {
          ...app,
          optionalPermissionIds,
          roleId: role.roleId,
          roleGuid: role.roleGuid,
          assignedApplication: { ...app.assignedApplication, applicationRoleAssociations: [{ role }] },
        };
      }
      return app;
    };

    const newSelectedApplications = selectedApplications.map(updateApplications);
    const newAvailableApplications = availableApplications.map(updateApplications);

    setSelectedApplications(newSelectedApplications);
    setAvailableApplications(newAvailableApplications);
  };

  const targetHierarchies = dataAccessState ? getHierarchies() : [];

  return (
    <FormProvider {...methods}>
      {accountsApplicationManagementEnabled !== undefined && 
      <CreateWizard
        steps={steps}
        backToButtonKey="createUser.buttons.backToUsers"
        imageSvg={imageSvg}
        title={
          currentStep === "creationComplete"
            ? <LocalizedText localeKey="createUser.sections.creationComplete.heading"/>
            : <LocalizedText localeKey="createUser.title" />
        }
        subTitle={<LocalizedText localeKey="createUser.subtitle" />}
        hideSubtitle={currentStep === "creationComplete"}
        onCancel={basicInfoModal.isShown ? toggleBasicInfoModal : onCancel}
        onNextStep={onSaveAndContinue}
        showPrevStep={showPrevStep}
        onPrevStep={onPrevStep}
        onStepChange={onStepChange}
        currentStep={currentStep}
        wizardSteps={wizardSteps}
        nextButtonCaptionKey={nextButtonCaptionKey}
        customIsValid={customIsValid}
        hideFooter={currentStep === "creationComplete"}
        showButtonLoader={isSaving}
      >
        {/* todo: remove once clone user feature goes livem and just use BasicInformationCreateUser always */}
        <BasicInfoComponent
          stepName="basicInfo"
          hasFullPortfolio={hasFullPortfolio}
          formData={basicInfoState}
          resetRole={resetRole}
          setShowNotification={setShowNotification}
          showNotification={showNotification}
          setSelectedRole={setSelectedRole}
          selectedRoleRef={selectedRoleRef}
          setPemissions={setPemissions}
          pemissions={pemissions}
        />
        <DataAccessTab
          stepName="dataAccess"
          allowFullPortfolioAccess={allowFullPortfolioAccess}
          dataRows={dataAccessState}
          setDataAccessState={setDataAccessState}
          onSaveAndContinue={onSaveAndContinue}
          setSavedGroup={setSavedGroup}
          saveStep={setDataAccessState}
          updatedDataRows={updatedDataRows}
          onDataAccessUpdate={onDataAccessUpdate}
          setCanSave={setCanSaveDataAccess}
          selectedGroups={selectedGroups}
          sessionUser={sessionUser}
        />
        <ApplicationsTab
          stepName="applications"
          setSelectedApplications={setSelectedApplications}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          availableApplications={availableApplications}
          updateAvailableApplications={updateAvailableApplications}
          loadingSubscribedApps={loadingSubscribedApps}
          serverError={searchAppError}
        />
        <ApplicationRoles
          stepName="applicationRoles"
          formData={methods.getValues()}
          selectedApplications={selectedApplications}
          onRolePermissionChanged={onRolePermissionChanged}
          targetHierarchies={targetHierarchies}
        />
        <ReviewConfigurationTab
          stepName="reviewConfiguration"
          hasFullPortfolio={hasFullPortfolio}
          basicInfoState={basicInfoState}
          resetRole={resetRole}
          selectedGroups={selectedGroups}
          saveBasicInfoState={onSetBasicInfo}
          dataAccessState={dataAccessState}
          reviewConfigState={reviewConfigState}
          saveReviewConfigState={setReviewConfigState}
          isSaving={isSaving}
          setShowNotification={setShowNotification}
          showNotification={showNotification}
          basicInfoModal={basicInfoModal}
          toggleBasicInfoModal={toggleBasicInfoModal}
          setSelectedRole={setSelectedRole}
          selectedRoleRef={selectedRoleRef}
          onPrevStep={onPrevStep}
          changeStep={changeStep}
          latestApplications={selectedApplications}
        />
        <UserCreationSuccess
          stepName="creationComplete"
          formData={basicInfoState}
          sendEmail={reviewConfigState.sendEmail}
          onCreateAnotherUser={onCreateAnotherUser}
        />
      </CreateWizard>
      }
      <NavigationWarning
        warningLine1Key="createUser.alerts.navigationWarning.message"
        warningLine2Key="createUser.alerts.navigationWarning.message2"
        continueCaptionKey="createUser.alerts.navigationWarning.continueCaption"
        cancelCaptionKey="createUser.alerts.navigationWarning.cancelCaption"
        onWarningConfirmed={onWarningConfirmed}
        disableWarning={disableWarning}
        skipWarningRouteList={["/loggedOut"]}
      />
    </FormProvider>
  );
};

export default CreateUser;
