import React from 'react';
import useAlertQueue from 'hooks/alertQueue';
import { useState, useEffect } from 'reactn';
import Toast from 'components/Toast/Toast';
import Alert from 'components/Alert/Alert';
import { useLocale } from 'portal-common-ui';

const AlertManager = (props) => {
  const { hasAlerts, dequeueAlert } = useAlertQueue();
  const [currentAlert, setCurrentAlert] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { translateToString } = useLocale();

  useEffect(() => {
    if (hasAlerts && !currentAlert) {
      const alert = dequeueAlert();
      setCurrentAlert(alert);
      if (alert.alertType === 'toast') {
        setTimeout(() => {
          setCurrentAlert(null);
        }, alert.transitionDuration || 3000);
      }
    }
  },
  [hasAlerts, currentAlert]);

  const onContinue = async () => {
    if (currentAlert.hasProcessing && currentAlert.continueAction) {
      setIsProcessing(true);
      await currentAlert.continueAction();
      setIsProcessing(false);
      clearAlert();
    } else {
      if (currentAlert.continueAction) currentAlert.continueAction();
      clearAlert();
    }
  };

  const onCancel = () => {
    if (currentAlert.cancelAction) currentAlert.cancelAction();
    else if (currentAlert.continueAction) currentAlert.continueAction();
    clearAlert();
  };

  const clearAlert = () => {
    setCurrentAlert(null);
  };

  const hasValidKeys = () => {
    const keyProperties = ['titleKey', 'messageKey'];
    
    return keyProperties.every(keyProperty => currentAlert[keyProperty] && translateToString(currentAlert[keyProperty])); 
  }

  if (currentAlert !== null) {
    const alertType = currentAlert.alertType;
    return (
      <>
        {alertType === 'toast' && <Toast 
          message={currentAlert.message} onClose={clearAlert}
          messageKey={currentAlert.messageKey} 
          transitionDuration={currentAlert.transitionDuration} 
          messageType={currentAlert.messageType} />}
        {alertType === 'modal' && 
          <Alert 
            type={currentAlert.messageType}
            isProcessing={isProcessing}
            onContinue={onContinue} 
            onCancel={onCancel}
            title={currentAlert.title} 
            message={currentAlert.message} 
            message2={currentAlert.message2} 
            continueCaption={currentAlert.continueCaption || 'Ok'} 
            cancelCaption={currentAlert.cancelCaption}
            processingCaption={currentAlert.processingCaption}
            titleKey={currentAlert.titleKey} 
            messageKey={currentAlert.messageKey} 
            message2Key={currentAlert.message2Key} 
            continueCaptionKey={currentAlert.continueCaptionKey ? currentAlert.continueCaptionKey : !currentAlert.continueCaption && 'alerts.default.continueCaption.ok'} 
            cancelCaptionKey={currentAlert.cancelCaptionKey}
            processingCaptionKey={currentAlert.processingCaptionKey}
          />
        }
      </>
    );
  };

  return (
    <div></div>
  );
};

export default AlertManager;
