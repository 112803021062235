import * as reactn from 'reactn';
import { useGlobal as libUseGlobal } from 'libs/reactn/useGlobal';

export const useGlobal = libUseGlobal;
export const addCallback = reactn.addCallback;
export const addReducer = reactn.addReducer;
export const getDispatch = reactn.getDispatch;
export const getGlobal = reactn.getGlobal;
export const removeCallback = reactn.removeCallback;
export const resetGlobal = reactn.resetGlobal;
export const setGlobal = reactn.setGlobal;
export const useDispatch = reactn.useDispatch;
