import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useGlobal } from 'libs/reactn';
import { useUser, BVLoader20 } from "portal-common-ui";
import useAlertQueue from 'hooks/alertQueue';
import UserViewerEditor from 'components/UserViewerEditor/UserViewerEditor';

const ViewUser = () => {
  const [auth] = useGlobal('auth');
  const { getUserById, isLoading} = useUser();
  const alertQueue = useAlertQueue();
  const params = useParams();
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [hasUpdateOthersProfilePermission] = useState(auth.session.user.role.permissions.some(p => p.key === 'UPDATE_OTHER_USERS'));
  const [, setSavedGroup] = useState(false);
  
  useEffect(() => {
    const fetch = async () => {
      const userResult = await getUserById(params.userId, true);
      if (userResult?.ok) {
        setUser(userResult.user);
      } else if (userResult?.error?.status) {
        alertQueue.enqueueAlert({
          alertType: "modal",
          title: "Sorry, something has gone wrong",
          titleKey: "userManagementPage.alerts.errors.viewEditError.title",
          messageKey: "userManagementPage.alerts.errors.viewEditError.message",
          messageType: "error",
          message:
            "Our system has generated an error while attempting to retrieve this record.  It’s not you, it’s us!  Please try again later.",
          continueCaption: "Back to User List",
          continueCaptionKey: "userManagementPage.alerts.errors.viewEditError.buttonCaption",
          continueAction: () => history.goBack(),
        });
      }
    };
    fetch();
  }, [params.userId]);

  const onSaveSuccess = () => {
    alertQueue.enqueueAlert({ 
      alertType: 'toast',
      messageType: 'success',
      message: 'User has been successfully updated. Please note that it may take a few minutes for the changes to propagate completely throughout our system.',
      messageKey: 'editUser.sections.preferences.alerts.userUpdated.message',
      transitionDuration: 6000,
    });
  };

  if (isLoading) {
    return (
    <div className="relative min-h-[600px]">
      <BVLoader20 />
    </div>
    );
  }
  
  if (!isLoading && user) {
    return ( 
      <UserViewerEditor 
        user={user}
        onSaveSuccess={onSaveSuccess}
        canEditBasicInfo={hasUpdateOthersProfilePermission}
        canEditRole={hasUpdateOthersProfilePermission}
        canEditDataAccess={hasUpdateOthersProfilePermission}
        setSavedGroup={setSavedGroup}
        >

      </UserViewerEditor>
    );  
  }

  return <div />;
};

export default ViewUser;

