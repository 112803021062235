import { Button, ButtonType, LocalizedText } from 'portal-common-ui';
import { useHistory } from 'react-router-dom';
import config from 'config/config';

const LoggedOut = () => {
  const history = useHistory();
  const brandLogo = JSON.parse(sessionStorage.getItem('brandDetails'))?.logo;
  const brandName = config?.activeBrand?.businessName;

  const redirectToLogin = () => {
    history.push('/');
  };

  return (
    <article className="flex justify-center items-center h-screen w-screen bg-theme-on-primary relative" role="main">
      {brandLogo && <img src={brandLogo} className="absolute top-10 left-10 z-20 w-logo w-[220px] h-[35px]" alt={`${brandName} Logo`} />}
      <div className="absolute inset-0 bg-theme-on-primary bg-full
        bg-skyline bg-no-repeat bg-bottom fixed opacity-30 z-10"></div>
      <section className="max-w-1/2 z-20">
        <h1 className="mb-16 text-theme-secondary text-3xl font-medium"><LocalizedText localeKey="logOut.loggedOutMessage"/></h1>
        <p className="flex justify-center text-theme-dark text-lg font-medium mb-10"><span><LocalizedText localeKey="logOut.loginAgainMessage"/></span></p>
          <Button
            buttonType={ButtonType.PrimaryButton}
            defaultContainerClass={'flex justify-center z-20 mb-10'}
            onClick={redirectToLogin}
            child={<span className='text-md font-normal'><LocalizedText localeKey="logOut.button.login"/></span>}
          />
      </section>
    </article>
  );
};

export default LoggedOut;



