import React, { useRef, useState, useEffect } from "react";
import FormField from 'components/Forms/FormField';
import FormSelect from 'components/Forms/FormSelect';
import CheckList from "components/CheckList/CheckList";
import useFeatureFlags from 'hooks/utils/featureFlags';
import { Option } from '../UserWizard/SelectOption';
import { useUser, useRoles, NotificationCard, LocalizedText, useLocale, AccessibleIcon} from 'portal-common-ui';
import useValidateEmail from 'hooks/user/email';
import { useDebounce } from "react-use";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { validateName, validateEmail as validateEmailHelper} from "utils/Helper";
import { useFormContext} from "react-hook-form";

const BasicInformation = ({
  resetRole,
  onBrandSelected,
  multiBrandAvailable,
  isInternal,
  formData,
  selectedBrands,
  brands,
  selectedRoleRef,
  showRoleSelector,
  methods,
  defaultOption,
  onRoleSelected,
  pemissions,
  handlePermission,
  validateEmailAddressClone,
  emailError = false,
  setEmailError = (value: boolean) => {},
  loadingPermissions 
}) => {

  const user = useUser();
  const { translateToString } = useLocale();
  const { accountsBrandListEnabled, accountsOptionalPermissionsEnabled } = useFeatureFlags();
  let showBrandsDropdown = showRoleSelector
    ? accountsBrandListEnabled && isInternal
    : accountsBrandListEnabled && !!selectedRoleRef?.current && selectedRoleRef?.current.roleType === "INTERNAL";
  const formRef = useRef()
  const [email, setEmail] = useState(formData.email);
  const [roles, setRoles] = useState([]);
  const { validate } = useValidateEmail();
  const { getRoles } = useRoles();
  const [debouncedEmail, setDebouncedEmail] = useState(formData.email?.trim());
  const [loadingRoles, setLoadingRoles] = useState(false);

  const {watch} = useFormContext();
  const watchedEmail = watch('email');
  useEffect(() => {
    if (validateEmailHelper(watchedEmail)) {
      setDebouncedEmail(watchedEmail);
      setLoadingRoles(true);
    } else {
      setDebouncedEmail(null);
      setRoles([]);
    }
  }, [watchedEmail]);

  // validates that the logged in  user is allowed to create another user for a given email (to prevent external users from creating internal ones)
  const validateEmailAddress = async (input: string) => {
    
    const emailInput = input?.trim();
    // setEmail(emailInput);
    
    // do not check to see if the logged in user is allowed to create a particular email address is the email address doesn't pass the validation check
    if (!validateEmailHelper(emailInput)) return true;

    if (user.authenticatedUser?.internal) {
      return true
    }

    const isValidEmail = await validate(emailInput)
    if (!isValidEmail) {
      return true
    }
    setRoles([]);
    return 'This email domain is not allowed.'
  }

  const validateEmail = (input: string) => {
    const emailInput = input?.trim();
    const emailTest = validateEmailHelper(emailInput);
    return emailTest;
  };
  const validateRole = () => {
    return (selectedRoleRef.current !== null);
  }

  useEffect(() => {
    const fetchRoles = async () => {
      methods?.setValue('role', null);
      if (debouncedEmail !== formData.email) {
        if (resetRole) resetRole();
      }

      if (debouncedEmail && !showRoleSelector) {
        selectedRoleRef.current = null;
        const userResult = await user.getAuthenticatedSessionUser();
        let roleGuid = '';
        userResult.userApplicationAssignments.results.forEach((app: any) => {
          if (app.assignedApplication.applicationGuid === 'MERCHANT_PORTAL_ACCOUNTS') {
            roleGuid = app.assignedApplication.applicationRoleAssociations?.[0]?.role?.roleGuid;
          }
        });
        const responseRoles = await getRoles(debouncedEmail, roleGuid);
        setRoles(responseRoles);
        setLoadingRoles(false);
      } else {
        setRoles([]);
      }
    };

    if (validateEmailHelper(debouncedEmail)) {
      fetchRoles();
    }

  }, [debouncedEmail]);


  return (
    <>
      <div ref={formRef} className="w-full">
        <div className="flex flex-col space-y-2 t-xl:space-y-5" style={{ minHeight: 290 }}>

          <div className="flex sml:flex-col flex-wrap space-y-1 items-start justify-start pb-4 w-[785px] med:w-auto">
            <FormField 
              name="firstName" 
              label="First Name" 
              labelKey="userManagementPage.columns.firstName" 
              defaultValue={formData.firstName} 
              required 
              maxLength={50} 
              inputClass="w-72 sml:w-full xl:w-96" 
              containerClass="sml:w-full"
              customValidate={(e) => validateName(e, 'firstName')}
              errorKeys={{"required": "createUser.sections.basicInfo.form.errors.fnameBlank", 
                          "validate": "createUser.sections.basicInfo.form.errors.invalidFName", 
                          "maxLength":"createUser.sections.basicInfo.form.errors.fnameMaxLength" }}
            />
            <FormField 
              name="lastName" 
              label="Last Name" 
              labelKey="userManagementPage.columns.lastName" 
              defaultValue={formData.lastName} 
              required 
              maxLength={75} 
              containerClass="ml-4 sml:w-full sml:ml-0 !my-0" 
              inputClass="w-72 sml:w-full xl:w-[370px] 2xl:w-96" 
              customValidate={(e) => validateName(e, 'lastName')} 
              errorKeys={{"required" : "createUser.sections.basicInfo.form.errors.lnameBlank",
                          "validate": "createUser.sections.basicInfo.form.errors.invalidLName",
                          "maxLength":"createUser.sections.basicInfo.form.errors.lnameMaxLength"}}
            />
            <FormField 
              name="email" 
              label="Email" 
              labelKey="userManagementPage.columns.email" 
              defaultValue={formData.email} 
              required 
              maxLength={254} 
              inputClass="w-96 sml:w-full"
              containerClass="sml:w-full"
              customValidate={{ validate: validateEmail, custom: showRoleSelector ? validateEmailAddressClone : validateEmailAddress }} 
              attached={<FontAwesomeIcon icon={faAt} style={{ color: '#D7DCE1'}}/>} 
              errorKeys={{"required" : "createUser.sections.basicInfo.form.errors.emailBlank", 
                          "validate": "createUser.sections.basicInfo.form.errors.invalidEmail",
                          "maxLength":"createUser.sections.basicInfo.form.errors.emailMaxLength"}}
            />
          </div>

          {
            <div>
              <hr className="border-theme-light-border w-full" style={{ height: 1.18 }} />
              <div className="flex items-start pb-1 pt-2 xl:pb-8  xl:w-[785px] xl:h-[70px]">

                <div className="w-1/2">
                  {!showRoleSelector &&
                  <FormSelect
                      name='role'
                      labelKey="createUser.sections.basicInfo.form.selectRoles.title"
                      defaultOption={defaultOption}
                      required inputClass="w-96"
                      inputContainer="lrg:w-auto"
                      containerClass="w-full"
                      options={roles}
                      sorted={false}
                      onSelection={onRoleSelected}
                      customValidate={validateRole}
                      disabled={!debouncedEmail || !roles.length}
                      placeholder={
                        <div>
                        {!loadingRoles && translateToString('createUser.sections.basicInfo.form.selectRoles.placeholder')}
                        {loadingRoles && 
                        <>
                          <LocalizedText localeKey="common.loading" id="loading-label" />
                          <AccessibleIcon icon={faSpinner} labeledBy="dba-searching-label" size="md" spin classes="text-sm pl-[8px]" />
                        </>
                        }
                      </div>}
                      id='add-user-role'
                      instanceId='add-user-role'
                      inputId='add-user-role-input'
                    />
                  }
                  <div className="pt-1">
                      {showBrandsDropdown && (
                        <FormSelect
                          components={{
                            Option,
                          }}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          name="brand"
                          labelKey="createUser.sections.basicInfo.form.selectBrands.title"
                          defaultOption={selectedBrands}
                          required
                          inputClass="w-96"
                          inputContainer="lrg:w-full"
                          options={brands}
                          onSelection={onBrandSelected}
                          placeholder={translateToString(
                            "createUser.sections.basicInfo.form.selectBrands.placeholder"
                          )}
                          isMulti={multiBrandAvailable}
                          sorted={false}
                          disabled={!multiBrandAvailable}
                          containerClass="!mt-0"
                          id="add-user-brand"
                          instanceId="add-user-brand"
                          inputId="add-user-brand-input"
                        />
                        )}
                  </div>

                </div>

                {!showRoleSelector && accountsOptionalPermissionsEnabled && methods.getValues().role?.roleId &&
                  <CheckList
                    fieldLabelKey="createUser.sections.basicInfo.form.selectPermissions.title"
                    options={pemissions}
                    onChange={handlePermission}
                    containerClass="ml-4 xl:!my-0 z-50 w-1/2"
                    inputClass="w-96 lrg:w-auto"
                    isLoading={loadingPermissions}                 
                    />
                }
              </div>
            </div>
          }
          <div style={{ width: 624 }}>
            {emailError &&
              <NotificationCard
                type="error"
                title={<LocalizedText localeKey="cloneUser.warnings.roleNotAllowed"/>}
                messageLine1={<LocalizedText localeKey="cloneUser.warnings.emailWarning"/>}
                messageLine2={<LocalizedText localeKey="cloneUser.warnings.checkMail"/>}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicInformation;