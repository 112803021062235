import React, { useEffect } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useGlobal } from 'libs/reactn';
import useSession from 'hooks/session/session';
import { BVLoader20 } from 'portal-common-ui';

const Login = () => {
  const session = useSession();
  const [auth] = useGlobal('auth');
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (session.authenticated) {
      setTimeout(() => history.push('/'), 0);
    }
    if (!session.authenticated && !session.isLoggingIn() && !session.isRegistering()) {
      session.handleLogin(location?.state?.postLoginRedirect);
    }    
  }, [session.authenticated]);

  return (
    <BVLoader20 />
  );
};

export default Login;

