import React from "react";

const Documentation = () => (
    <div className="flex">
        <div className="col-xs-7 mb-5">
            <h1 className="text-theme-success">Documentation - Coming Soon</h1>
        </div>
    </div>
);

export default Documentation;