/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { useGlobal } from 'libs/reactn';
import config from 'config/config';
import { useApi, authSession, useDebug } from 'portal-common-ui';
import configureStore from 'store/configureStore';
import useAuthSettings from './authSettings';
import useAlertQueue from 'hooks/alertQueue';
import useFeatureFlags from 'hooks/utils/featureFlags';
import { pendoInit } from '../../core/Pendo/pendoInit'
import LogRocket from 'core/LogRocket/logrocket';
import setupLogRocketReact from 'logrocket-react';

let auth, setAuth, logoutTimer, setLogoutTimer;

const useSession = () => {
  [auth, setAuth] = useGlobal('auth');
  [logoutTimer, setLogoutTimer] = useGlobal('logoutTimer');
  const debug = useDebug(false);
  const { msalConfig } = useAuthSettings();
  const {enqueueAlert} = useAlertQueue();
  const history = useHistory();
  const { accountsTranslationsEnabled, accountsEnabledLanguages, accountsGuestUserEnabled } = useFeatureFlags();
  let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));
  const options = {
    headers: {
      'Cache-Control': 'no-cache',
    },
  };
  const userApi = useApi(`${config.API.authUrl}${'/auth2/mfa/login'}`, options);

  // setup login options that retry up to 2 additional times on failure to give backend time to finish provisioning
  // todo: remove and replace once backend SSE work is completed
  const loginOptions = {
    headers: {
      'Cache-Control': 'no-cache',
    },
    cachePolicy: 'no-cache',
    retries: 3,
    retryDelay: 5000,
  };
  const loginApi = useApi(`${config.API.authUrl}${'/auth2/mfa/login'}`, loginOptions);

  // const registerApi = useApi(config.API.selfRegisterUrl);
  const registerApi = useApi(`${config.API.userUrl2}/selfregistration`);
  const mockToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2NTQwNzc5MDQsImV4cCI6MTY4NTYxNzUwNCwiYXVkIjoiNzNjMTk1ZWQtOTI3My00MTU4LWI3MTEtZGQxNzRjYzQyMjEzIiwic3ViIjoiMjExZGJjMjItOTgxZi00ZGZjLThiZjYtZjRmMGYzNDA2NjQ5IiwibmFtZSI6IkN5cHJlc3MgVGVzdHVzZXIiLCJnaXZlbl9uYW1lIjoiQ3lwcmVzcyIsIkVtYWlsIjoiY3lwcmVzcy50ZXN0dXNlckBwdXRzYm94LmNvbSIsImZhbWlseV9uYW1lIjoiVGVzdHVzZXIiLCJtZmFfdGltZSI6IjE2NTQwOTI4ODciLCJpZHAiOiJBenVyZUFEVGVzdEdsb2JhbHBheSJ9.bV3oN6TBxtp6xRKvE97BCUlKlVGZInxhRM3cO1Gog5s';

  const isLoggingIn = () => {
    return auth.isLoggingIn;
  };

  const isRegistering = () => {
    return auth.isRegistering;
  };
  const logRocketSessionInitializer = (user) => {
    LogRocket.startNewSession();
    const {
      name,
      email
    } = user;
    LogRocket.identify(email,{
      name: `${name}`,
      email,

      // Add your own custom user variables here, ie:
      subscriptionType: 'pro'
    })
  } 

  const clearAuth = () => {
    setAuth(newAuth => {
      newAuth.isLoggingIn = false;
      newAuth.isRegistering = false;
      newAuth.authenticated = false;
      newAuth.session = {};
    });
  }
  const register = async (merchantNumber, ddaOrTaxId, existingUser, locale) => {
    debug(auth);
    await registerApi.post('', { merchantNumber, dda: ddaOrTaxId, locale, brand: brandDetails.brandGuid.toUpperCase(), existingUser });
    debug(JSON.stringify(registerApi.response));
    if (registerApi.response.ok) {
      debug('register succeeded, preparing to call login');
      const data = await loginApi.get();
      if (loginApi.response.ok) {
        debug('login call succeded');
        setAuth(newAuth => {
          newAuth.session.user = data;
          newAuth.isRegistering = false;
          newAuth.authenticated = true;
          newAuth.session.firstVisit = true;
          newAuth.session.startDate = new Date();
        });    
      } else {
        history.push('/SelfRegisterError');
      }
    } else {
      return { message: registerApi.response.data };
    }
  };

  const getElapsedSecondsSinceSelfRegistering = () => {
    const diffInMs = new Date() - auth?.session?.startDate;
    return Math.floor((diffInMs / 1000) / 60); 
  };

  const onRenew = (token) => {
    setAuth(newAuth => {
      newAuth.session.sessionToken = token;
    });
  };

  const onLogout = () => {
    sessionStorage.removeItem('state');  
    configureStore({});
  };

  const { login, logout, signinByPopup, resetTimer } = authSession({
    msalConfig, 
    onTimerChange: (newState) => setLogoutTimer(newLogoutTimer => {
      newLogoutTimer.timerIsRunning = newState.timerIsRunning 
      newLogoutTimer.countdownSeconds = newState.countdownSeconds
    }),
    onLogout: onLogout,
    onRenew: onRenew,
    inactiveTimeoutSeconds: 900, 
    countdownTimeoutSeconds: 60,
    renewalBufferSeconds: 900,
    defaultPostLogoutRedirect: '/loggedOut',
    retainSessionOnTimeout: true
  });

  const handleLogout = async(customRedirect) => {
    try {
      await logout(customRedirect);
    } catch (err) {
      console.log('error logging out:', err);
    }
  }

  const initializeSession = async (token, postLoginRedirect) => {
    if (!config.mockApi) {
      LogRocket.init(config.logRocket.appID, {
        network: {
          responseSanitizer: (response) => {
            if (response.body?.includes('taxId') || response.body?.includes('ownerName')) {
              delete response.body;
            }
            return response;
          },
          requestSanitizer: request => {
            request.headers['Authorization'] = null;
            return request;
          },
        },
      });
      console.log("**************** Logrocket initialized *********");
      setupLogRocketReact(LogRocket);
    }
    debug(`token: ${token}`);
    const parsedToken = jwt_decode(token);
    if (!config.mockApi) logRocketSessionInitializer(parsedToken);
    const isInternalEmployee = parsedToken.idp && parsedToken.idp === 'google.com';
    const isMfaDisabled = parsedToken.mfa_disabled;
    setAuth(newAuth => {
      newAuth.isLoggingIn = true;
      newAuth.session = newAuth.session || {};
      newAuth.session.sessionToken = token;
      newAuth.session.isMfaDisabled = isMfaDisabled === true;
    });

    const d = await userApi.get();
    const userResponse = userApi.response?.data;
    const inactiveUser = userApi.response.ok && userResponse.status.toUpperCase() === 'INACTIVE';
    const b2cLocale = parsedToken.lang === 'en' ? 'en-US': parsedToken.lang === 'fr' ? 'fr-CA' : parsedToken.lang;
    localStorage.setItem('userLocale', userResponse?.languageAbbr || b2cLocale || 'en-US');

    if (userApi.response.ok && !inactiveUser) {
      setAuth(newAuth => {
        newAuth.session.firstVist = false;
        newAuth.session.user = userResponse;
        newAuth.isLoggingIn = false;
        newAuth.isRegistering = false;
      });
      setAuth(newAuth => {
        newAuth.authenticated = true;
        newAuth.postLoginRedirect = postLoginRedirect;
      });
      pendoInit(userResponse);
    }
    else if (isInternalEmployee || userResponse?.errorCode === 'internal') {
      clearAuth();
      await handleLogout('/UserNotFound');
    }
    else if (['User not found', 'User found with non-interactive brands true'].some(errMsg => userResponse?.content?.error?.includes(errMsg))) {
      if(accountsGuestUserEnabled) {
        setAuth(newAuth => {
          newAuth.session.firstVist = false;
          newAuth.session.user = {firstName: parsedToken.given_name, lastName: parsedToken.family_name, email: parsedToken.email, brand: "GPI", role: {permissions: [], key: []}, isGuestUser: true, languageAbbr: b2cLocale, externalId:""};
          newAuth.isLoggingIn = false;
          newAuth.isRegistering = false;
        });
        setAuth(newAuth => {
          newAuth.authenticated = true;
          newAuth.postLoginRedirect = postLoginRedirect;
        });
      }else{
        const existingUser = userResponse?.content?.error?.includes('User found with non-interactive brands true');
        setAuth(newAuth => {
          newAuth.isLoggingIn = false;
          newAuth.isRegistering = true;
          newAuth.session.firstVist = true;
          newAuth.authenticated = false;
        });
        history.push('/selfRegister', { existingUser, locale: b2cLocale } );
      }
    }else if(userApi.response.status === 403){
      clearAuth();
      await handleLogout('/forbidden');
    } else {
      clearAuth();
      const errorRoute = inactiveUser ? '/inactive' : '/LoginError';
      await handleLogout(errorRoute);
    } 
  };

  const handleLogin = async(loginRedirect) => {

    try {
      const languagesFromLD = accountsEnabledLanguages.map(langObj => langObj.value);
      const languages = encodeURIComponent(languagesFromLD.join(','));

      const { token, postLoginRedirect, postLogoutRedirect } = await login(loginRedirect, languages);
      const tokenForInitialize = config.mockApi ? mockToken : token
      if (tokenForInitialize) {
        initializeSession(tokenForInitialize, postLoginRedirect);
      } else if (postLogoutRedirect) {
        history.push(postLogoutRedirect);
      } else {
        sessionStorage.removeItem('msal.interaction.status');
      }
    } catch (err) {
      if (config.mockApi && err?.message.includes('endpoints_resolution_error')) {
        initializeSession(mockToken);  
      } else {
        console.log('login error:', err);
        history.push('/LoginError');
      }
    }
  }

  const handleSigninByPopup = async(extraQueryParameters) => {
    try {
      const token = await signinByPopup(extraQueryParameters);
      return token;
    } catch (err) {
      throw err;
    }
  }

  return {
    authenticated: auth.authenticated,
    firstVist: auth.session ? auth.session.firstVisit : true,
    handleLogin,
    register,
    handleLogout,
    handleSigninByPopup,
    isLoggingIn,
    isRegistering,
    user: auth.session ? auth.session.user : null,
    getElapsedSecondsSinceSelfRegistering,
    resetTimer,
    postLoginRedirect: auth.postLoginRedirect,
  };
};

export default useSession;

// TODO: Fix typo for first visit in session object ("firstVist")