import React, { useState, useEffect, useRef, useCallback } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import {useDeepCompareEffect} from 'react-use';
import { useGlobal } from 'libs/reactn';
import useFeatureFlags from 'hooks/utils/featureFlags';
import useProcessTranslations from "hooks/utils/processTranslations";
import useApplications from "hooks/applications";
import usePermissions from 'hooks/session/permissions';
import useAlertQueue from 'hooks/alertQueue';
import usePreferences from "hooks/use-preferences";
import useDataAccess from "hooks/utils/dataAccess";
import { useUser, Button, ButtonType, BVLoader20, Pill, LocalizedText, AccessibleIcon, useDateTimeFormats } from "portal-common-ui";
import PersonalInformationTab from "./PersonalInformationTab";
import RoleTab from "./RoleTab";
import ApplicationsRolesTab from "./ApplicationsRolesTab";
import DataAccessTab from "./DataAccessTab";
import PreferencesTab from './PreferencesTab';
import UserEditorFooter from './UserEditorFooter';
import 'react-tabs/style/react-tabs.css';
import './ProfileEditor.scss';

import { isDeepEqual } from "react-use/lib/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faUserGear, faUser, faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { faCircle, faChevronLeft } from "@fortawesome/free-solid-svg-icons";


const UserViewerEditor = ({ 
    user, 
    onMidRegister,
    isSessionUser = false,
    canEditBasicInfo = false,
    canEditRole = false,
    canEditDataAccess = false,
    onSaveSuccess,
    setSavedGroup,
  }) => {
  const [auth, setAuth] = useGlobal('auth');  
  const { accountsBrandListEnabled, accountsApplicationManagementEnabled, accountsPrivacyTermsLinksEnabled } = useFeatureFlags();
  const { enabledLocales = [], dateFormats, timeFormats } = usePreferences();
  const { formatDateTime } = useDateTimeFormats();
  const history = useHistory();
  const alertQueue = useAlertQueue();
  const { updateUser, updateMyProfile, isSaving } = useUser();
  const { getApplicationList, getAvailableAppsForSubscription } = useApplications();
  const {hasPermission} = usePermissions();
  const { fetchAndSaveTranslations, error: transError } = useProcessTranslations();
  const { formatInitialDataAccess } = useDataAccess();

  const [targetUser, setTargetUser] = useState(user);
  const methods = useForm({
    mode: 'onChange',
  });
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(null);
  const [updatedDataAccess, setUpdatedDataAccess] = useState(null);
  const [updatedDataAccessGroup, setUpdatedDataAccessGroup] = useState(null);
  const [hasFullPortfolioAssigned, setHasFullPortfolioAssigned] = useState(false);
  const [allowFullPortfolioAccess, setAllowFullPortfolioAccess] = useState(user.roleObject.roleType === 'INTERNAL');
  const [canRegisterMid] = useState(isSessionUser && hasPermission('REGISTER_MID'));
  const [allTabsValid, setAllTabsValid] = useState(true);
  const location = useLocation();
  const unblock = useRef();
  const ignoreCancelWarning = useRef(false);
  const [hasFormChanges, setHasFormChanges] = useState(false);
  const [permissionsChanged, setPermissionsChanged] = useState(false);
  const [applicationsChanged, setApplicationsChanged] = useState(false);
  const [optionalPemissions, setOptionalPemissions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [preferences, setPreferences] = useState({ 
    locale: enabledLocales?.find(locale => locale.value === user?.locale) || {label: 'English (US)', value: 'en-US'},
    timeFormat: timeFormats?.find(timeFormat => timeFormat.value === user.timeFormat) || timeFormats[0],
    dateFormat: dateFormats?.find(dateFormat => dateFormat.value === user.dateFormat) || dateFormats[0],
  });
  const [updatedApplications, setUpdatedApplication] = useState([]);
  const [subscribedAppIdFilter, setSubscribedAppIdFilter] = useState(undefined);
  const [appFilters, setAppFilters] = useState(undefined);
  const [loadingSubscribedApps, setLoadingSubscribedApps] = useState(false);
  const [isSavingAfterLoadingSubscribedApps, setIsSavingAfterLoadingSubscribedApps] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);

  const showApplicationRoleTab = accountsApplicationManagementEnabled && !isSessionUser;
  const canEditApplicatinsAndRoles = hasPermission("VIEW_APPLICATIONS_AND_ROLES");

  const methodValues = methods.getValues();

  useEffect(() => {
    // capture default values to compare later to see if form is dirty
    const formValues = methods.getValues();
    if (user.roleObject.roleType === 'INTERNAL' && location.pathname !== '/profile' && !accountsApplicationManagementEnabled && accountsBrandListEnabled) {
      const brands = user.brands.map(brand => ({value: brand, label: brand})).sort((a, b) => (a.value > b.value) ? 1 : -1);
      setInitialFormValues({ ...formValues, brand: brands });
    } else {
      setInitialFormValues(formValues);
    }
    if (targetUser.dataAccess && targetUser.dataAccess.some(x => x.nodes[0]?.selectedChild?.label === 'All')) {
      setHasFullPortfolioAssigned(true);
    }
  }, [targetUser]);

  useEffect(() => {
    if (isDiscarding) {
      setTargetUser(user);
      methods.reset(initialFormValues);
      setUpdatedDataAccess(null);
      setUpdatedDataAccessGroup(null);
      setPermissionsChanged(false);
      setApplicationsChanged(false);
      setUpdatedApplication([]);
      setIsDiscarding(false);
      setPreferences({ 
        locale: enabledLocales?.find(locale => locale.value === user?.locale) || {label: 'English (US)', value: 'en-US'},
        timeFormat: timeFormats?.find(timeFormat => timeFormat.value === user.timeFormat) || timeFormats[0],
        dateFormat: dateFormats?.find(dateFormat => dateFormat.value === user.dateFormat) || dateFormats[0],
      });
    }
  }, [isDiscarding]);

  useEffect(() => {
    let methodData = methodValues;
    if (user.roleObject.roleType === 'INTERNAL' && location.pathname !== '/profile' && !accountsApplicationManagementEnabled) {
      methodData = {...methodValues, brand: methodValues.brand && methodValues.brand.map(b => b.value ?? b).sort()};
    }

    setHasFormChanges(!isDeepEqual(methods.getValues(), initialFormValues) || hasDataAccessChanges() || permissionsChanged || applicationsChanged);
  }, [updatedDataAccess, methodValues, initialFormValues, optionalPemissions, applicationsChanged]);

  const isLoggedInUserInternal = () => {
    return auth?.session?.user?.internal;
  };

  const userHasDifferentSubscription = () => {
    const targetRole = getTargetRole();
    return (
      ["ACCOUNT_ADMINISTRATOR", "ACCOUNT_USER"].includes(targetRole?.role?.roleGuid) &&
      auth?.session?.user?.role?.key === "THIRD_PARTY_ADMINISTRATOR"
    );
  };

  const getTargetRole = () => {
    let appsList = updatedApplications?.length > 0 ? updatedApplications : [];

    const result = appsList?.find(
      (x) =>
        x?.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS" ||
        x?.assignedApplication?.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS"
    )?.assignedApplication?.applicationRoleAssociations?.[0];

    return result ? result : { role: targetUser?.roleObject };
  };

  const getAssignableSubscribedApps = async (roleId, dataAccess) => {

    let currentAccess = dataAccess || targetUser?.dataAccess;
    if (currentAccess?.length > 0 && roleId) {
      const nodeHierarchies = currentAccess
        ?.filter((row) => {
          return row && (row?.midHierarchyIds?.length === 0 || row?.selectedMids?.length === 0);
        })
        .map((row) => row?.hierarchyId || row?.selectedHierarchy?.hierarchyId);

      const midHierarchies = currentAccess
        ?.filter((row) => {
          return row && (row?.midHierarchyIds?.length > 0 ||  row?.selectedMids?.length > 0);
        })
        .flatMap((row) => row?.midHierarchyIds || row?.selectedMids)
        .map((m) => m?.hierarchyId || m);

      const dataChanges = nodeHierarchies.concat(midHierarchies).filter(h => h);
      const payloadHierarchies = dataChanges.map(h => {
        return {
          hierarchyId: h,
          accessType: "ORGANIC",
          status: "ACTIVE",  
        }
      });
      const response = await getAvailableAppsForSubscription(roleId, payloadHierarchies);
      if (response.status === 400) {
        setSubscriptionError(true);
        return null;
      }
      setSubscriptionError(false);
      return response;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (isSavingAfterLoadingSubscribedApps && !loadingSubscribedApps) {
      setIsSavingAfterLoadingSubscribedApps(false);
      onSaveChanges();
    };
  }, [isSavingAfterLoadingSubscribedApps, loadingSubscribedApps]);

  const getSubbedApps = async (newDataAccess) => {
    const targetRole = getTargetRole();
    // if logged in user is internal, and the targetUser was either originally external or being changed to external, need to get subscription apps
    if ((isLoggedInUserInternal() || userHasDifferentSubscription()) && (targetRole?.role?.roleType === 'EXTERNAL' || (!targetRole && targetUser?.roleObject?.roleType === 'EXTERNAL'))) {
      // if the role or data access changed since last call
      setLoadingSubscribedApps(true);
      const assignableSubscribedApps = await getAssignableSubscribedApps(targetRole?.role?.roleId || targetUser?.roleObject?.roleId, newDataAccess);
      setLoadingSubscribedApps(false);
      const currentApps = targetUser.userApplicationAssignments.results;
      if (assignableSubscribedApps) setSubscribedAppIdFilter(assignableSubscribedApps?.map(x => x.applicationId) || undefined);
    } else {  
      setSubscribedAppIdFilter(undefined);
    }
  };
  
  useDeepCompareEffect(() => {
    getSubbedApps(updatedDataAccess);
  }, [updatedApplications, updatedDataAccess]);

   // todo: clean this up, this is a workaround to resolve a state management bug for last minute changes to integrate subscriptions into app management
   useDeepCompareEffect(() => {
    setAppFilters(subscribedAppIdFilter);
   }, [subscribedAppIdFilter]);

  useEffect(() => {
    const userLocale = enabledLocales?.find(locale => locale.value === user?.locale);
    if ( userLocale && userLocale.value !== preferences?.locale.value ) setPreferences({ ...preferences, locale: userLocale });
  }, [enabledLocales[0]?.value]);

  // workaround to enable isDirty proxy before first render per react-form-hook docs
  // this will subscribe us to formState changes so we re-render
  const { isValid } = methods.formState;
  
  const getDataAccessChanges = () => {  
    if (updatedDataAccess) {
      const nodeHierarchies = updatedDataAccess
        .filter(row => row && !row?.midHierarchyIds?.length)
        .map(row => row?.hierarchyId);
      const midHierarchies = updatedDataAccess
        .filter(row => row && row?.midHierarchyIds?.length > 0)
        .flatMap(row => row?.midHierarchyIds);
      const hierarchies = nodeHierarchies.concat(midHierarchies);
      return hierarchies; 
    }
    return null;
  };

  const onPermissionChaged = () => {
    setPermissionsChanged(true)
  }

  const hasValidChanges = ({newRoleType, newDataAccess, brands}, fullPortfolioAssigned) => {
    const updatedForm = methods.getValues();
    const updatedAccountsRoleType = updatedApplications.find(app => app?.applicationGuid === 'MERCHANT_PORTAL_ACCOUNTS')?.assignedApplication?.applicationRoleAssociations?.[0]?.role?.roleType;
    const roleType = accountsApplicationManagementEnabled 
      ? updatedAccountsRoleType || targetUser.roleObject.roleType
      : newRoleType || updatedForm?.role?.roleType || targetUser.roleObject.roleType;
    const rulesValid = !fullPortfolioAssigned || roleType === "INTERNAL";
    const hasDataAccess = newDataAccess ? newDataAccess.length > 0 : 
                          updatedDataAccess ? updatedDataAccess.length > 0 : true; // (!newDataAccess && updatedDataAccess && updatedDataAccess.length > 0) || newDataAccess.length > 0;
    const brandValid = !accountsBrandListEnabled || accountsApplicationManagementEnabled || roleType === "EXTERNAL" || (brands ? brands.length > 0 : updatedForm?.brand?.length > 0);
    const result = isValid && rulesValid && hasDataAccess && brandValid;
    return result;
  };

  const hasDataAccessChanges = () => {
    const changes = getDataAccessChanges();
    return changes !== null; // === null ? false : changes.newRows.length > 0 || changes.updatedRows.length > 0 || changes.deletedRows.length > 0;
  };

  const onPreferencesUpdate = (updatedPreferences) => {
    setPreferences(updatedPreferences);
  };

  const onBrandUpdate = (brands) => {
    setAllTabsValid(hasValidChanges({brands}));
  };

  const onRoleUpdate = (newRole) => {
    const valid = hasValidChanges({newRoleType: newRole.roleType}, hasFullPortfolioAssigned);
    setAllowFullPortfolioAccess(newRole.roleType === 'INTERNAL');
    setAllTabsValid(valid);
  };

  const validApplicationData = (changedApp, app) => {
    const newValidApplication = (changedApp.new && changedApp.assigned) && (changedApp.isPermissionRequired ? changedApp.roleId : true);
    const applicationDeleted = !changedApp.new && !changedApp.assigned;
    const roleChanged = changedApp.roleId !== app?.roleId;
    const optionalPemissionsChanged = !isDeepEqual(changedApp.optionalPermissionIds?.sort(), app?.optionalPermissionIds?.sort());
    const brandUpdated = changedApp.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS" && targetUser.brands.length !== changedApp.brands.length;
    return newValidApplication || applicationDeleted || (changedApp.assigned && (roleChanged || optionalPemissionsChanged)) || brandUpdated;
  };

  const onApplicationUpdate = (changedApplications) => {
    setApplicationsChanged(false);
    setUpdatedApplication([]);
    const currentApps = targetUser.userApplicationAssignments.results;
    const roleRequired = changedApplications.filter(app => app.assigned && app.isPermissionRequired && !app.roleId);
    if (roleRequired.length) {
      return;
    }
    const updatedApps = [];
    changedApplications.forEach((changedApp) => {
      const app = currentApps.find(
        (item) => item.assignedApplication.applicationGuid === changedApp.applicationGuid
      );
      if (validApplicationData(changedApp, app)) {
        setApplicationsChanged(true);
        updatedApps.push(changedApp);
      }
      setUpdatedApplication(updatedApps);
    });
    const isInternalAccountsRole = changedApplications.find(app => app?.applicationGuid === 'MERCHANT_PORTAL_ACCOUNTS')?.assignedApplication?.applicationRoleAssociations?.[0]?.role?.roleType === 'INTERNAL';
    if (isInternalAccountsRole !== allowFullPortfolioAccess) setAllowFullPortfolioAccess(isInternalAccountsRole);
  };

  const onDataAccessUpdate = (newDataAccess, fullPortfolioAssigned) => {
    setUpdatedDataAccess(newDataAccess);
    getSubbedApps(newDataAccess);
    setSelectedGroups(newDataAccess?.filter(m=>m && m?.dagId)?.map(m => { return {id: m?.dagId}}));

    setHasFullPortfolioAssigned(fullPortfolioAssigned);
    const valid = hasValidChanges({newDataAccess}, fullPortfolioAssigned);
    setAllTabsValid(valid);
  }

  const showCancelWarning = (continueAction) => {
    alertQueue.enqueueAlert({ 
      alertType: 'modal', 
      titleKey: 'shared.alerts.titles.warning', 
      messageType: 'warning', 
      continueCaptionKey: 'shared.alerts.discardChanges.continueCaption',
      cancelCaptionKey: 'shared.alerts.discardChanges.cancelCaption',
      continueAction: continueAction,
      cancelAction: () => {},
      messageKey: 'shared.alerts.discardChanges.message' 
    });
  };

  const showError = () => {
    alertQueue.enqueueAlert({ 
      alertType: 'modal', 
      messageType: 'error',
      titleKey: "editUser.sections.preferences.alerts.unableToUpdate.title",
      messageKey: "shared.alerts.unableToUpdate.message"
    });
  };

  const onDiscardChanges = () => {
    showCancelWarning(() => {
      setIsDiscarding(true);
    });
  };

  useEffect(() => {
    if (initialFormValues) {
      unblock.current = history.block(tx => {
        if (isDeepEqual(methods.getValues(), initialFormValues) && !hasDataAccessChanges() && !permissionsChanged) {
          return true;
        } else if (ignoreCancelWarning.current) {
          return true;
        } else if (tx.pathname === '/loggedOut') {
          return true;
        } else if (tx.pathname === location.pathname) {
          return true;
        } else {
          showCancelWarning(() => {
            unblock.current();
            history.push(tx);
          });
          return false;
        }
      });
      return ()=>unblock.current()
    }
  }, [initialFormValues, hasFormChanges]);

  const goBack = () => {
    ignoreCancelWarning.current = true;
    if (isSessionUser) {
      history.goBack();
    } else {
      history.push(`/user-management`);
    }
  };

  const getTargetHierarchies = useCallback(() => {

    if(updatedDataAccess) {
      return getDataAccessChanges()?.map(m => m).filter(m => m);
    } else {
      const targetUserAccess = formatInitialDataAccess(targetUser.dataAccess);
      return targetUserAccess.flatMap(h => h?.midHierarchyIds?.length > 0 ? h?.midHierarchyIds : h.hierarchyId).filter(h => h);
    }
  },[updatedDataAccess, targetUser.dataAccess]);

  const targetHierarchies = getTargetHierarchies();

  const onSaveChanges = async () => {
    if (loadingSubscribedApps) {
      setIsSavingAfterLoadingSubscribedApps(true);
      return;
    }
    const updatedForm = methods.getValues();
    const updateApi = isSessionUser ? updateMyProfile : updateUser;

    const updatedUser = {
      userId: targetUser.userId,
      firstName: updatedForm.firstName?.trim(),
      lastName: updatedForm.lastName?.trim(),
      locale: updatedForm.language?.value,
      timeFormat: updatedForm.timeFormat?.value,
      dateFormat: updatedForm.dateFormat?.value,
      status: user.status,
    };
    if (updatedForm?.brand?.length && updatedForm.role.roleType === "INTERNAL" && !accountsApplicationManagementEnabled && accountsBrandListEnabled) {
      updatedUser.brands = updatedForm.brand.map(b => b.value);
    } 
    if (canEditRole && !showApplicationRoleTab) {
      updatedUser.roleId = updatedForm.role.roleId;
    }
    updatedUser.optionalPermissionIds = optionalPemissions.filter(p => p.selected && !p.disabled).map(p => p.value)
    if (canEditDataAccess) {
      const dataChanges = getDataAccessChanges();
      updatedUser.dataChanges = dataChanges?.map(m => m).filter(m => m); 
      updatedUser.selectedDags = updatedDataAccess?.map(m => m?.dagId).filter(m => m);
    }
    if (applicationsChanged && showApplicationRoleTab && updatedApplications.length) {
      const currentApps = targetUser.userApplicationAssignments.results;
      updatedUser.updatedApplications = { new: [], deleted: [], updated: [] };
      updatedApplications.forEach(changedApp => {
        if (changedApp.new && changedApp.assigned) {
          updatedUser.updatedApplications.new.push(changedApp);
        } else if (!changedApp.new && !changedApp.assigned) {
          updatedUser.updatedApplications.deleted.push(changedApp);
        } else {
          const app = currentApps.find((item) => item.assignedApplication.applicationGuid === changedApp.applicationGuid);
          if (
            changedApp.assigned &&
            !changedApp.new &&
            (changedApp.roleId !== app?.roleId ||
              !isDeepEqual(changedApp.optionalPermissionIds?.sort(), app?.optionalPermissionIds?.sort()))
          ) {
            updatedUser.updatedApplications.updated.push(changedApp);
          }
        }
        if (changedApp.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS") {
          const isInternalRole = changedApp.assignedApplication.applicationRoleAssociations?.[0]?.role?.roleType === "INTERNAL";
          if (isInternalRole && (targetUser.brands.length !== changedApp.brands.length) && changedApp.brands.length > 0) {
            updatedUser.brands = changedApp.brands;
          }
        }
      });
    }

      try { 
        if (targetUser.locale !== updatedForm.language?.value && isSessionUser) {
          await fetchAndSaveTranslations(updatedForm.language?.value);
          localStorage.setItem('userLocale', updatedForm.language?.value);
          setAuth(newAuth => {
            newAuth.session.user.languageAbbr = updatedForm.language?.value;
          });
        } 
        const resp = await updateApi(targetUser, updatedUser, hasFullPortfolioAssigned);
        if (resp.errorCode) {
          showError();
          if(isSessionUser){
            await fetchAndSaveTranslations(targetUser.locale);
            setAuth(newAuth => {
              newAuth.session.user.languageAbbr = targetUser.locale;
            });
          }
        } else {
          onSaveSuccess();
          goBack();
        }
      }
      catch(err) {
        console.log(err);
        showError();
      }
  };

  const onClickBackButton = () => {
    goBack();
  };

  useEffect(() => {
    const fetchApps = async () => {
      await getApplicationList();
    }
  }, []);

  const HeaderField = ({label, value, noSeparator = false, children}) => (
    <div className={`flex flex-col text-theme-medium min-h-[44px] px-6 lrg:px-2 ${noSeparator ? '' : 'border-r border-theme-light-border'} sml:first-of-type:hidden`}>
      <div className="text-xs pb-1 t-xl:pb-2">{label}</div>
      <div className="text-xs font-semibold text-theme-medium">{value}</div>
      {children}
    </div>
  );

  const userNameCaption = isSessionUser ? <LocalizedText localeKey="editUser.accountInfo.userEmail" /> : <LocalizedText localeKey="editUser.accountInfo.userName" />;

  const isEditing = canEditBasicInfo || canEditRole || canEditDataAccess;
  const tabPanelStyles = `react-tabs__tab-panel ${accountsPrivacyTermsLinksEnabled ? 't-xl:min-h-profile-with-footer-xl min-h-profile-with-footer' : 't-xl:min-h-profile-no-footer-xl min-h-profile-no-footer'}`

  return(
    <>
    {isSaving && 
    <div className="relative min-h-[600px] min-w-full">
      <BVLoader20 />
    </div>
    }
    <div className={`${isSaving ? 'hidden' : 'lrg:mt-4'}`}>
      <div className="flex items-center justify-start sml:justify-between bg-theme-on-primary min-h-[56px] t-xl:min-h-[72px] min-w-[1150]  mr-[-16px] ml-[-16px] mt-[-16px] xl:mr-[-30px] xl:ml-[-30px] t-xl:mt-[-30px] gap-4 xl:gap-8 border-theme-light-background border">
        <div className="pl-4 xl:pl-8">
          <Button 
            id="button-back" 
            onClick={onClickBackButton}
            buttonType={ButtonType.SecondaryButton}
            buttonClass="sml:hidden"
            child={<AccessibleIcon icon={faArrowLeft} localeKey="createUser.buttons.backToUsers" classes="mr-1 text-base font-light" />}
          />
          {/* Below button for Mobile support */}
          <Button 
            id="button-back" 
            onClick={onClickBackButton}
            buttonType={ButtonType.LinkButton}
            buttonClass="hidden sml:block"
            child={<FontAwesomeIcon icon={faChevronLeft} className="fa-lg" />}
          />
        </div>
        <div className="text-2xl sml:text-lg font-medium text-theme-dark min-w-[105px]" data-test="my-profile-cy">
          {isSessionUser ? <LocalizedText localeKey="homepage.myProfile.name" /> : targetUser.fullName}
        </div>
        <div>
          {!isSessionUser &&
          <div className="pr-4">
            <div className={`pill-status ${targetUser.status !== 'ACTIVE' && 'border-theme-danger text-theme-danger'}`}>
              <AccessibleIcon icon={faCircle} labeledBy="account-status-label" classes={`${targetUser.status === 'ACTIVE' ? 'text-theme-success' : 'text-theme-danger'} leading-0 text-8px mr-1.5`} />
              <span className="font-medium uppercase"><LocalizedText id="account-status-label" localeKey={`userManagementPage.filterBar.filters.status.${targetUser.status.toLowerCase()}`} /></span>
            </div>
          </div>
          }
        </div>        
      </div>
      <div className="flex">
        <FormProvider {...methods}>
          <section className="w-full xl:min-w-[1100px] max-w-[1780px]" data-test="view-user-form-cy">
            <div className="flex items-start pt-2 t-xl:pt-4 justify-start bg-theme-on-primary my-3 t-xl:my-5 min-h-[58px] t-xl:min-h-[77px] border-theme-light-border border lrg:rounded lrg:hidden">
              {isSessionUser ?
                <AccessibleIcon icon={faUserCircle}  localeKey="userManagementPage.actions.viewUser" classes="text-[2rem] t-xl:text-5xl ml-6 mr-2 text-theme-info mt-[4px] t-xl:mt-0 sml:hidden" />:
                <AccessibleIcon 
                  icon={isEditing ? faUserGear : faUser}
                  localeKey={isEditing ? "userManagementPage.actions.viewEditUser" : "userManagementPage.actions.viewUser"}
                  classes="text-[2rem] t-xl:text-5xl ml-6 mr-2 text-theme-info mt-[4px] t-xl:mt-0 sml:hidden" 
                />
              }
              <HeaderField label={userNameCaption} value={targetUser.email} />
              <HeaderField label={<LocalizedText localeKey="editUser.accountInfo.accountsRole" />} value={targetUser.role ? null : 'Unknown'}>
                <>
                  <Pill labelText={<LocalizedText localeKey={targetUser.roleObject?.nameKey} />} themeColor="theme-info" />
                </>
              </HeaderField>
              <HeaderField label={<LocalizedText localeKey="editUser.accountInfo.creationDate" />} value={formatDateTime(targetUser.createdDate)?.formattedDate} />
              <HeaderField 
                label={<LocalizedText localeKey="editUser.accountInfo.lastLogin" />} 
                value={`${formatDateTime(targetUser.lastLoginDate)?.formattedDate || 'N/A'} ${formatDateTime(targetUser.lastLoginDate)?.formattedTime}`} 
                noSeparator 
              />
            </div>  
            <div id="tier-dropdown-portal" className="relative">
            </div>
            <div className="pt-0 t-xl:pt-2">
                <Tabs selectedTabClassName="react-tabs__tab--selected text-theme-dark" data-test= "tab">
                  {/* <div className="border border-solid border-gray-200 h-16 absolute w-full border-b-0"/> */}
                  <TabList className="flex lrg:justify-between border border-theme-light-border lrg:bg-theme-light-background lrg:rounded p-1">
                    <Tab className={`react-tabs__tab w-full`}><LocalizedText localeKey="editUser.sections.personalInformation.title" /></Tab>
                    {canEditRole &&
                      (showApplicationRoleTab ? (
                        <Tab data-test="profile-roles-tab" className={`react-tabs__tab w-full`}>
                          <LocalizedText localeKey="editUser.sections.applicationAndRoles.title" />
                        </Tab>
                      ) : (
                        <Tab data-test="profile-roles-tab" className={`react-tabs__tab w-full`}>
                          <LocalizedText localeKey="editUser.sections.roles.title" />
                        </Tab>
                      ))}
                    <Tab data-test="profile-data-access-tab" className={`react-tabs__tab w-full`}><LocalizedText localeKey="createUser.sections.dataAccess.title" /></Tab>
                    {isSessionUser && <Tab data-test="profile-preferences-tab" className={`react-tabs__tab w-full`}><LocalizedText localeKey="editUser.sections.preferences.title" /></Tab>}
                  </TabList>

                  <TabPanel forceRender className={tabPanelStyles} >
                    <PersonalInformationTab 
                      user={targetUser} 
                      isEditing={canEditBasicInfo} 
                      formValues={methods.getValues()}
                      onUpdate={onBrandUpdate}
                      isDiscarding={isDiscarding}
                      targetUser={targetUser}
                    />
                  </TabPanel>                  
                  {canEditRole && (
                    <TabPanel forceRender className={tabPanelStyles}>
                      {!isDiscarding &&
                        (showApplicationRoleTab ? (
                          <ApplicationsRolesTab
                            targetUser={targetUser}
                            onUpdate={onApplicationUpdate}
                            hasFullPortfolioAssigned={hasFullPortfolioAssigned}
                            isDiscarding={isDiscarding}
                            canEditApplicatinsAndRoles={canEditApplicatinsAndRoles}
                            targetHierarchies={targetHierarchies}
                            appFilters={appFilters}
                            loadingSubscribedApps={loadingSubscribedApps}
                            subscriptionError={subscriptionError}
                          />
                        ) : (
                          <RoleTab
                            user={targetUser}
                            onPermissionChaged={onPermissionChaged}
                            optionalPemissions={optionalPemissions}
                            setOptionalPemissions={setOptionalPemissions}
                            hasFullPortfolioAssigned={hasFullPortfolioAssigned}
                            onUpdate={onRoleUpdate}
                          />
                        ))}
                    </TabPanel>
                  )}
                  <TabPanel forceRender className={tabPanelStyles}>
                    {!isDiscarding && 
                    <DataAccessTab 
                      user={targetUser}
                      onSuccessfulMidRegister={onMidRegister}
                      canRegisterMid={canRegisterMid}
                      onUpdate={onDataAccessUpdate}
                      allowFullPortfolioAccess={allowFullPortfolioAccess}
                      canEdit={canEditDataAccess} 
                      setSavedGroup={setSavedGroup} 
                      selectedGroups={selectedGroups}
                      subscriptionError={subscriptionError}
                    />
                    }
                  </TabPanel>
                  {isSessionUser &&
                  <TabPanel forceRender className={tabPanelStyles}>
                    <PreferencesTab 
                      user={targetUser}
                      preferences={preferences}
                      preferenceOptionLists={ { locales: enabledLocales, timeFormats, dateFormats } }
                      onUpdate={onPreferencesUpdate} />
                  </TabPanel>
                  }
                </Tabs>
            </div>
            {(canEditRole || canEditBasicInfo || canEditDataAccess) &&
            <div className={`lrg:sticky ${accountsPrivacyTermsLinksEnabled ? 'lrg:bottom-0' : 'bottom-0'} lrg:left-0 lrg:w-full`}>
              <UserEditorFooter hasFormChanges={hasFormChanges} isValid={isValid && allTabsValid && !subscriptionError} isSaving={isSaving || isSavingAfterLoadingSubscribedApps} onDiscardChanges={onDiscardChanges} onSaveChanges={onSaveChanges} userStatus={user.status}/>
            </div>
            }
          </section>
        </FormProvider>
      </div>
    </div>
    </>
  )
};

export default UserViewerEditor;
