import { useEffect, useState } from "react";
import { mapAppLinkQueryString } from "./helper";
import useApplications from "hooks/applications";
import AppIcon from "components/Application/AppIcon";
import { AccessibleIcon, useUser } from "portal-common-ui";
import useProvisionedLink from "hooks/utils/provisionedLink";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons";

interface QuickLinkProps {
  segment: string;
  appId: string;
  label: string;
  ssoType: string;
  active: boolean;
  height: string;
}

const QuickLink = (props: QuickLinkProps) => {
  const [user, setUser] = useState({});
  const { appLinksMap } = useApplications();
  const { getAuthenticatedSessionUser } = useUser();

  useEffect(() => {
    getAuthenticatedSessionUser().then((data: any) => {
      setUser(data);
    });
  }, []);

  const url = appLinksMap[props.appId];

  const { handleClick: provisionedLinkClick } = useProvisionedLink(`${url}&applicationGuid=${props.appId}`, user);

  const appsClickHandler = (event: any) => {
    event.stopPropagation();
    if (props.ssoType === "MP_CUSTOM") {
      provisionedLinkClick(event);
    } else {
      window.open(mapAppLinkQueryString(url, user, props.segment));
    }
  };

  return (
    <div
      className={`py-4 px-2 flex items-center ${props.height} ${props.active && "cursor-pointer"} gap-4`}
      onClick={appsClickHandler}
    >
      <AppIcon id={props.appId} height={40} width={40} disabled={!props.active} />
      <div className={`flex-1 font-bold text-base ${props.active ? "text-theme-primary" : "text-theme-disabled"}`}>
        {props.label}
      </div>
      <div className="text-lg">
        <AccessibleIcon icon={faAngleRight} labeledBy="quick-action" />
      </div>
    </div>
  );
};

export default QuickLink;
