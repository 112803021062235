import React, { FC, memo } from 'react';
import ProductTable from './ProductTable';
import { ModalProps } from './types';
import { useLocale } from 'portal-common-ui';

// Helper function to find the upgraded product
const findUpgradedProduct = (products, productTitle, myCurrentSubsription, removeApplication) => {
  let UpgradedProduct = products.find(product => product.name === productTitle);
  if (myCurrentSubsription && !removeApplication) {
    let currentPriority = UpgradedProduct?.offeringPriorityInGroup;
    let nextPriority = currentPriority + 1;
    
    while (nextPriority <= Math.max(...products.map(p => p.offeringPriorityInGroup))) {
      const nextProduct = products.find(product => 
        product.groupId === UpgradedProduct?.groupId && 
        product.offeringPriorityInGroup === nextPriority
      );
      
      if (nextProduct) {
        UpgradedProduct = nextProduct;
        break;
      }
      
      nextPriority++;
    }
  }
  return UpgradedProduct;
}

// ... existing code ...

// Helper function to find the new products
const findNewProducts = (products, productTitle, UpgradedProduct, removeApplication) => {
  // If removeApplication is true, filter products by productTitle
  const currentProducts = products.filter(product => product.isSubscribed);
  const filteredProducts = removeApplication
    ? currentProducts.filter(product => product.name !== productTitle)
    : products.filter(product => product.isSubscribed || product.name === productTitle);

  // If removeApplication is false, filter products by subscription status or productTitle
  // Then map the products, replacing any product with the same groupId as UpgradedProduct with UpgradedProduct
  // Finally, reduce the array to remove duplicates

  return filteredProducts
    .map(product => UpgradedProduct && product.groupId === UpgradedProduct.groupId ? UpgradedProduct : product)
    .filter((product, index, self) =>
      index === self.findIndex(item => JSON.stringify(item) === JSON.stringify(product))
    );
}

// Helper function to reorder the products if needed
const reorderProducts = (newProducts, productTitle, buttonTitle, UpgradedProduct) => {
  if (buttonTitle === "Subscribe") {
    const productToMove = newProducts.filter(product => product.name === productTitle);
    const remainingProducts = newProducts.filter(product => product.name !== productTitle);
    newProducts = [...remainingProducts, ...productToMove];
  } else {
    const productToMove = newProducts.filter(product => product.name === UpgradedProduct.name);
    const remainingProducts = newProducts.filter(product => product.name !== productTitle);
    newProducts = [...productToMove, ...remainingProducts]
      .filter((product, index, self) =>
        index === self.findIndex(item => JSON.stringify(item) === JSON.stringify(product))
      );
  }
  return newProducts;
}

const ProductSelection: FC<ModalProps> = ({ products, productTitle, buttonTitle, myCurrentSubsription, removeApplication }) => {
  const UpgradedProduct = findUpgradedProduct(products, productTitle, myCurrentSubsription, removeApplication);
  // const currentProducts = products.filter(product => product.isSubscribed);
  const currentProducts = products
  .filter(product => product.isSubscribed)
  .sort((a, b) => (a.name === productTitle ? -1 : b.name === productTitle ? 1 : 0));

  const currentProductsWithSameGroupId = currentProducts.filter(product => product.groupId === UpgradedProduct.groupId);
  if (!myCurrentSubsription) {
    currentProducts.sort((a, b) => {
      if (currentProductsWithSameGroupId.includes(a)) {
        return -1;
      } else if (currentProductsWithSameGroupId.includes(b)) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  const {translateToString} = useLocale();

  let newProducts = findNewProducts(products, productTitle, UpgradedProduct, removeApplication);
  newProducts = reorderProducts(newProducts, productTitle, buttonTitle, UpgradedProduct);

  if (myCurrentSubsription) {
    productTitle = UpgradedProduct.name;
  }

  return (
    <div className="flex justify-between space-x-4 px-4">
      <ProductTable title={translateToString("productOfferings.modal.table.currentHeader")} products={currentProducts} offeringData={[]} productModal={[]} productTitle={productTitle} myCurrentSubsription={myCurrentSubsription} removeApplication={removeApplication}/>
      <ProductTable title={translateToString("productOfferings.modal.table.newHeader")} products={newProducts} offeringData={[]} productModal={[]} productTitle={productTitle} myCurrentSubsription={myCurrentSubsription}/>
    </div>
  );
};

export default memo(ProductSelection);