import { LocalizedText } from "portal-common-ui";

const stepsWithApplication = [
    {
      stepName: 'basicInfo',
      title: <LocalizedText localeKey="createUser.sections.basicInfo.title" id="createUser-title-label"/>,
      headerText: <LocalizedText localeKey="createUser.sections.basicInfo.heading" />,
      nextStep: 'dataAccess',
      prevStep: null,
      isActive: true,
      isComplete: false,
      isPending: false,
    },
    {
      stepName: 'dataAccess',
      title: <LocalizedText  localeKey="createUser.sections.dataAccess.title" />,
      headerText: <LocalizedText localeKey="createUser.sections.dataAccess.heading" />,
      headerSubtext: <LocalizedText localeKey="createUser.sections.dataAccess.headingSubtext" />,
      nextStep: 'applications',
      prevStep: 'basicInfo',
      isActive: false,
      isComplete: false,
      isPending: true,
    },
    {
      stepName: 'applications',
      title: <LocalizedText localeKey="createUser.sections.Applications.title" />,
      headerText: <LocalizedText localeKey="createUser.sections.Applications.heading" />,
      headerSubtext: <LocalizedText localeKey={"createUser.sections.Applications.subHeading"}/>,
      nextStep: 'applicationRoles',
      prevStep: 'dataAccess',
      isActive: false,
      isComplete: false,
      isPending: true,
    },
    {
      stepName: 'applicationRoles',
      title: <LocalizedText localeKey="createUser.sections.applicationRoles.title" />,
      headerText: <LocalizedText localeKey="createUser.sections.applicationRoles.heading" />,
      headerSubtext: <LocalizedText localeKey="createUser.sections.applicationRoles.subHeading"/>,
      nextStep: 'reviewConfiguration',
      prevStep: 'applications',
      isActive: false,
      isComplete: false,
      isPending: true,
    },
    {
      stepName: 'reviewConfiguration',
      title: <LocalizedText  localeKey="createUser.sections.reviewConfiguration.title" />,
      headerText: <LocalizedText  localeKey="createUser.sections.reviewConfiguration.heading" />,
      nextStep: 'creationComplete',
      prevStep: 'applicationRoles',
      isActive: false,
      isComplete: false,
      isPending: true,
    },
    {
      stepName: 'creationComplete',
      title: <LocalizedText  localeKey="createUser.sections.creationComplete.title" />,
      headerText: <LocalizedText  localeKey="createUser.sections.creationComplete.heading" />,
      nextStep: null,
      prevStep: 'reviewConfiguration',
      isActive: false,
      isComplete: false,
      isPending: true,
    },
];

export default stepsWithApplication;