import React, { useState } from "react";
import { DataAccessEditor } from 'portal-common-ui';
import useDataAccess from "hooks/utils/dataAccess";

const DataAccessTab = ({ dataRows, allowFullPortfolioAccess, onDataAccessUpdate, setCanSave }) => {
  const [pageTitle, setPageTitle] = useState('');
  const { enabledSelectMethods } = useDataAccess();
  return (
    <>
      <div id="tier-dropdown-portal" className="relative">
      </div>
      <div className="bg-theme-on-primary flex items-top p-4 border border-theme-light-border border-t-0 border-b-0 overflow-y-auto overflow-x-hidden w-full h-[380px] t-xl:h-full" data-test="data-access-management-dataaccesstab">
          <DataAccessEditor 
            // preSelectedPortfolio="T-MAS"
            allowFullPortfolioAccess={allowFullPortfolioAccess} 
            dataRows={dataRows} 
            onDataAccessUpdate={onDataAccessUpdate} 
            setCanSave={setCanSave} 
            onSetPageTitle={setPageTitle}
            redirectSingleLineageToMidSelector={false}
            enabledSelectMethods={enabledSelectMethods.filter(m => m !== 'DataAccessGroup')}
          />
      </div> 
    </>
  );
}

export default DataAccessTab;
