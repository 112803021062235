/*  
This file serves as a structural template for the translation keys and a fall back if translations are not available via the api. It provides intellisense support
for developers to boost productivity and ensure correct usage of translation keys. This file should be maintained with current keys and English values.
*/

const masterTranslations = {
    "globalTranslate": {
      "applications": {
        "customer-engagement": {
          "name": "Customer Engagement",
          "description": "Have access to valuable information about your customers and manage their engagement to help increase your business sales.",
        },
        "ces-gift": {
          "name": "Gift Card",
          "description": "Gift helps the merchant's business prosper by either upgrading a current program to build  brand awareness or establishing a program that drives customer engagement.",
        },
        "reputation-management": {
          "name": "Social Reputation",
          "description": "Enables effective reputation management with A single dashboard providing access to customer reviews from popular online and social media sites,One-click access to view and respond to customer reviews.Online reputation competitive comparison, to see how your business stacks up against the competition"
        },
        "competitive-benchmark": {
          "name": "Benchmark",
          "description": "Benchmark behavior, spend and reach - your customers and your local competitors. Benchmark is a premium Analytics module that provides advanced Analytics and actionable insights."
        },
        "sysnet": {
          "name": "PCI Compliance",
          "planName": "Sysnet",
          "description": "Have your PCI DSS assessment and vulnerability scan in one place to be compliant and secure to receive PCI-related data of your business."
        },
        "dims": {
          "name": "Disputes Management",
          "planName": "Dims",
          "description": "The Global Payments Disputes Management tool can help you better manage disputes, minimize losses, and resolve chargebacks as quickly as possible."
        },
        "merlink": {
          "name": "Merlink"
        },
        "vt": {
          "name": "Virtual Terminal",
          "description": "",
          "role": {
            "manager": {
              "name": "Manager"
            },
            "ADMIN": {
              "name": "Admin"
            },
            "AGENT": {
              "name": "Agent"
            },
            "SUPERVISOR": {
              "name": "Supervisor"
            },
            "INTERNALEMPLOYEE": {
              "name": "Internal Employee"
            },
            "PRODUCTIONSUPPORT": {
              "name": "Production Support"
            },
            "SYSTEMADMINISTRATOR": {
              "name": "System Administrator"
            }
          },
          "perm": {
            "CANREADONLY": {
              "name": "Can Read Only"
            },
            "VT_CANCREATESALE": {
              "name": "Can Create Sale"
            },
            "VT_CANAPPLYDISCOUNT": {
              "name": "Can Apply Discount"
            },
            "VT_CANAPPLYREFUNDLINKED": {
              "name": "Can Apply Refund Linked"
            },
            "VT_CANVIEWRECENTTRANSACTIONS": {
              "name": "Can View Recent Transactions"
            },
            "VT_CANAPPLYTIPSURCHARGE": {
              "name": "Can Apply Tip Surcharge"
            },
            "viewMyProfileInformation": {
              "name": "View My Profile Information"
            },
            "VT_CANAPPLYSTANDALONEREFUND": {
              "name": "Can Apply Stand Alone Refund"
            },
            "viewMyUserProfile": {
              "name": "View My User Profile"
            }
          }
        },
        "mp-accounts": {
            "name": "Accounts",
            "description": "This is dummy Accounts description",
            "perm": {
              "viewMyProfileInformation": {
                "name": "View My Profile Information"
              },
              "updateMyUserProfile": {
                "name": "Update My User Profile"
              },
              "registerMid": {
                "name": "Register MID"
              },
              "viewUsers": {
                "name": "View Users"
              },
              "activateDeactivateUserAccountStatus": {
                "name": "Activate Deactivate User Account Status"
              },
              "createUsers": {
                "name": "Create Users"
              },
              "updateOtherUsers": {
                "name": "Update Other Users"
              },
              "manageLockedStatus": {
                "name": "Manage Locked Status"
              },
              "manageDataAccessGroups": {
                "name": "Manage Data Access Groups"
              },
              "viewTaxId": {
                "name": "View Tax ID"
              },
              "viewOwnerName": {
                "name": "View Owner Name"
              },
              "deleteUser": {
                "name": "Delete Users"
              },
              "assignDeleteUsers": {
                "name": "Assign Delete Users"
              },
              "canAssignDataAccessHierarchy": {
                "name": "Can Assign Data Access Hierarchy"
              },
              "viewApplicationsAndRoles": {
                "name": "View Applications And Roles"
              }
            },
            "role": {
                "merchantAccountUser": {
                  "name": 'Account User'
                },
                "merchantAccountAdministrator": {
                  "name": "Account Administrator"
                },
                "merchantThirdPartyUser": {
                  "name": "Third Party User"
                },
                "merchantThirdPartyAdministrator": {
                  "name": "Third Party Administrator"
                },
                "merchantLimitedPartner": {
                  "name": "Limited Partner"
                },
                "merchantMasterFileAnalyst": {
                  "name": "Master File Analyst"
                },
                "merchantCustomerService": {
                  "name": "Customer Service"
                },
                "merchantInternalEmployee": {
                  "name": "Internal Employee"
                },
                "merchantSalesSupportManager": {
                  "name": "Sales Support Manager"
                },
                "merchantRegionalProductManager": {
                  "name": "Regional Product Manager"
                },
                "merchantGlobalProductManager": {
                  "name": "Global Product Manager"
                },
                "merchantSystemAdministrator": {
                  "name": "System Administrator"
                }
            },
        },
        "mp-reporting": {
          "name": "Reporting",
          "description": "Have detailed reports of your business to help you make better decisions based on information on your transactions, disputes, and much more.",
          "perm": {
            "viewMyUserProfile": {
              "name": "View My User Profile"
            },
            "viewRole": {
              "name": "View Role"
            },
            "canLogin": {
              "name": "Can Log in"
            },
            "editPersonalInformation": {
              "name": "Edit Personal Information"
            },
            "viewStatements": {
              "name": "View Statements"
            },
            "canViewReports": {
              "name": "Can View Reports"
            },
            "canExportReports": {
              "name": "Can Export Reports"
            },
            "canView32DaysOfReconciliationReports": {
              "name": "Can View 32 Days of Reconciliation Reports"
            },
            "canView3MonthsOfEstatements": {
              "name": "Can View 3 Months of Estatements"
            },
            "canScheduleReports": {
              "name": "Can Schedule Reports"
            },
            "canViewCustomReports": {
              "name": "Can View Custom Reports"
            },
            "canCreateCustomReports":  {
              "name": "Can Create Custom Reports"
            },
            "canShareCustomReports": {
              "name": "Can Share Custom Reports"
            },
            "canViewDefaultReports": {
              "name": "Can View Default Reports"
            },
            "canViewPortfolioMerchantActivityReport": {
              "name": "Can View Portfolio Merchant Activity Report"
            },
            "canViewFullPAN": {
              "name": "Can View Full PAN"
            },
            "canExportFilesWithFullPAN": {
              "name": "Can Export Files With Full pan"
            },
            "canScheduleOnBehalfOfOthers": {
              "name": "Can Schedule on Behalf of Others"
            }
          },
          "role": {
            "acquirerAdmin": {
              "name": 'Third Party Administrator'
            },
            "acquirerUser": {
              "name": 'Third Party User'
            },
            "customerService": {
              "name": 'Customer Service'
            },
            "customUser": {
              "name": "Custom User"
            },
            "internalEmployee": {
              "name": "Internal Employee"
            },
            "limitedPartner": {
              "name": "Limited Partner"
            },
            "masterFileAnalyst": {
              "name": "Master File Analyst"
            },
            "merchantAccountAdministrator": {
              "name": "Account Administrator"
            },
            "merchantApiUser": {
              "name": "System Administrator"
            },
            "merchantSalesSupportManager": {
              "name": "Sales Support Manager"
            },
            "merchantUser": {
              "name": "Account User"
            },
            "regionalProductManager": {
              "name": "Regional Product Manager"
            },
            "techSupportAdmin": {
              "name": "System Administrator"
            },
            "worldwideProductManager": {
              "name": "Global Product Manager"
            },
          }
        },
        "applicationRoleAssociations": {
          "noRole": "No roles available"
        }
      },

      "homepage": {
        "applicationTitle": "Account",
        "title": "Welcome, %firstName%!",
        "titleFirstVisit": "You're all done, Welcome!",
        "subtitle": "How can we help you today?",
        "products": {
          "title": "Your Products",
          "mobileInfo": "Please note that some of the products listed below might not be optimized for mobile devices.",
          "mobileError" : {
            "title" : "This application is unavailable on this device",
            "message" : "A mobile-friendly version of this application will be available soon.  In the meantime, please use a desktop device to access the application."
          }
        },
        "sidebar": {
          "home": "Home",
          "userManagement": "User Management",
          "dataAccessManagement": "Data Access Management",
          "merchantDemographics": "Merchant Demographics",
          "productOfferings": "Product Offerings"
        },
        "help": {
          "title": "Account Support",
          "subTitle": "Need Help?",
          "description": "You can get most of your questions answered by consulting our My Account Help Center.",
          "callToActionText": "Check it out here:",
          "linkCaption": "My Account Help Center",
          "helpCenter": "Help Center",
          "contact": {
            "title": "Contact Us",
            "message1": "If you need help with your account, please reference the contact number found on your statements.",
            "message2": "You can find your statements by navigating to the Statements section of the Reporting application."
          },
          "helpAndFaq": "Help and FAQ's",
          "goToHelpCenter": "Go to Help Center",
          "helpAndFaqMessage": "Looking for more help and support? Check out our My Account Help Center!",
          "helpAndFaqMessageNew": "Looking for more help and support? Check out our Help Center!"
        }, 
        "applications": {
          "title": "Applications"
        },
        "myProfile": {
          "name": "My Profile"
        },
        "logout": {
          "name": "Logout" 
        },
        "appManagement": {
          "heading" : {
            "title" : "NEW APPLICATION ALERT!",
            "message" : "You now have access to the following application(s)"
          },
          "info" : "As an administrator, you automatically have access to these applications. All other users will need to be granted access. Use the “Assign to All Users” button to bulk assign to all, or you can assign users individually through User Management.",
          "alerts": {
            "title" : "Confirm Bulk Application Assignment",
            "message" : "You are granting access of the %appName% application to all users. Is this correct?",
            "continueCaption" : "Yes, Assign to All Users",
            "successAlert" : "You have successfully assigned %appName% to all of your active users"
          },
          "assignment" : {
            "heading" : "%appName% - Bulk Assign Users",
            "info" : "Choose role below to assign %appName% to all users with the selected role by default. You can always change roles and application access individually in each user's profile."
          },
          "buttons" : {
            "assignToAll" : "Assign To All Users",
            "learnMore": "Learn More"
          },
          "dismiss" : {
            "dismiss": "Dismiss",
            "forNow" : "Dismiss For Now",
            "forever" : "Dismiss Forever"
          }
        }
      },
      "cloneUser": {
        "title": "Let’s create a user",
        "subTitle": "We just need to collect a little information. This whole process shouldn’t take more than a minute.",
        "alerts":{
          "navigationWarnings":{
            "message": "This will exit the Create New User Wizard and you will lose all of the information you have entered.",
          }
        },
        "sections": {
          "selectUserCloneTab":{
            "title": "Select User To Clone",
            "header": "Clone User - Select User To Clone",
            "searchPlaceholder": "Search by full/partial name or email",
            "subTitle": "Please select the user you would like to clone:",
            "cloneViewIcon":{
              "header": "User Profile",
              "status": "Status:"
            },
            "searchResults": "Search Results"
          },
          "newUserInfoTab":{
            "title": "New User Information",
            "header": "Clone User - New User Information",
            "viewTitle": "User being cloned:",
            "subTitle": "Please enter the information for the new user below"
          },
          "reviewConfTab":{
            "title": "Review Configuration",
            "header": "Clone User - Review User Profile",
            "subTitle": "PROFILE OVERVIEW",
            "additionalToggle": "Do you wish to deactivate the user you are cloning (%fullName%)?",
            "cloneSupport": "Clone Scheduled Reports",
          },
          "cloneUserButton": "Clone Another User",
        },
        "columns": {
          "lastName": "Last Name",
          "firstName": "First Name",
          "email": "Email Address",
          "role": "User Role",
          "status": "Status",
          "view": "View"
        },
        "warnings":{
          "emailWarning": "The email address you have supplied is not allowed for the role of the user you are cloning.",
          "roleNotAllowed":"Email address not allowed for role",
          "checkMail": "Please check the email address or select a different user to clone.",
          "cannotClone": "User Cannot Be Cloned",
          "restrictClone": "You do not have the ability to clone this user due to data access limitations. Please select another user to clone or use the Create New User wizard to proceed.",
          "midWarning": "This user has access to MIDs outside of your current data set. Only MIDs you have access to are displayed here.",
          "retrieveProfile": "Error Retrieving User Profile",
          "unableRetrieve": "We're sorry, but we were unable to retrieve this user's profile information. Please try again. If this issue persists, please contact our support team.",
          "brandWarning": "This user has access to brands outside of your current data set. Only brands you have access to are displayed here."
        },
        "alreadyUsedEmail": "Email Address Already In Use",
        "emailMessage":"A user with the email address you have entered already exists in our system. A user with the email address you have entered already exists in our system. Please check your entry and try again. If you believe you have received this message in error, please contact our support team."
      },
      "dataAccessManagementPage": {
        "actions": {
          "viewGroup": "View Group",
          "viewEditGroup": "View/Edit Group",
          "deleteGroup": "Delete Group"
        },

        "alerts": {
          "warning": {
            "delete": {
              "title": "Deleting Data Access Group",
              "message": "Are you sure you want to delete this data access group?",
              "continueCaption": "Yes, Delete",
            }
          }
        },
        "header": {
          "rowCountCaption": "lists found",
          "actionButtonCaption": "Create New Data Access Group",
          "message": "You have reached your data access group limit."
        },
        "columns": {
          "groupName": "Group Name",
          "groupOwner": "Group Owner",
          "actions": "Actions"
        },
        "filterBar": {
          "filters": {
            "mid": "MID",
            "groupOwner": "Group Owner",
            "ownedGroupToggle": "Show only groups I own"
          },
          "omnisearch": {
            "searchBy": "Search by full/partial group name"
          }
        },
        "toasts": {
          "success": {
            "delete": {
              "message": "Data Access group has been successfully deleted.  Please note it may take a few minutes to see the changes reflected on the screen."
            }
          }
        }
      },
      "dataAccessGroup": {
        "columns": {
          "name": "Name",
          "email": "Email Address",
        },
      },
      "createDAG": {
        "title": "Let’s create a data access group",
        "subTitle": "This will allow you to create a custom group of MIDs that you can then assign to users easily.",
        "sections":{
          "basicInfo":{
            "title": "Create Data Access Group",
            "groupInfoTitle": "What are data access groups?",
            "toolTip": "If you use a group of MIDs frequently to assign data access \n to users, you can create a Data Access Group.  You can then assign \n this group to multiple users. \n Every time you add or remove a MID from a Data Access group, the data access of all of the associated users will also be updated immediately.",
            "groupName": "Group Name",
            "groupPlaceholder": "Give your group a name that is memorable and descriptive.",
            "groupDescription": "Group Description",
            "groupDesPlaceholder": "Describe the purpose and functionality of this group",
            "characterLength": "%enteredCharacter%/%maxLength% characters",
            "errors":{
              "groupNameBlank": "Group Name cannot be blank",
              "invalidGroupName": "Invalid Group Name",
              "groupNameMaxLength": "Group Name cannot exceed %maxLength% characters",
              "groupDesMaxLength": "Group Description cannot exceed %maxLength% characters",
              "invalidGroupDes": "Invalid Group Description"
            }
          },
          "selectDataAccess": {
            "title": "Select Data Access",
            "header": "Create New Data Access Group - Select Data Access",
            "headerSubText": "Select MIDs by hierarchy or individual MID",
          },
          "addDataAccess":{
            "title": "Add Users (Optional)",
            "header": "Add User To Data Access Group",
            "assignMessage": "You can now assign users to this data access group by clicking",
            "otherGroupMessage": "on the “Add Users To Group” now, or create another group.",
            "successNote": "NOTE: Groups without users will be automatically deleted in 7 days.",
            "successGroup": "The group",
            "hasBeen":"has been",
            "createSuccess":"successfully created.",
            "searchMessage":"Search by name or email and press enter"

          }
        },
        "buttons": {
          "backToDAGList": "Back to data access list",
          "createGroup": "Create Group",
          "creatingGroup": "Creating Group...",
          "addGroup": "Add Users To Group",
          "returnToDAG": "Return to Data Access Management",
          "createAnotherGroup": "Create Another Group"
        },
        "warnings": {
          "groupNameExist":"A data access group with this name already exists.  Please select another name.",
          "cancelWarning": "This will exit the Create New Data Access Group Wizard and you will lose all of the information you have entered.",
          "discardWarning": "Do you wish to cancel and discard your changes?"
        }
      },
      "editDAG":{
        "header":{
          "groupOwner":"GROUP OWNER",
          "createDate":"CREATION DATE",
          "lastModified": "LAST MODIFIED"
        },
        "sections":{
          "groupInfo":{
            "header": "Group Information",
          },
          "userAssigned":{
            "header":"Users Assigned",
            "subHeader": "Users Assigned To Group",
            "processingCaption": "Adding User...",
            "noMatchingMessage": "No matching users found",
            "noUsersMessage" : "No users assigned to this group.",
            "addMessage": "Please use the “Add User” button to assign this group’s data access to users."
          }
        },
        "button":{
          "addUser":"Add User",
        }
      },
      "merchantDemographicsPage": {
        "actions": {
          "viewDetails": "View Details"
        },
        "columns": {
          "merchantName": "Merchant Name (DBA Name)",
          "groupNumber": "Group  #",
          "actions": "Actions",
        },
        "filterBar": {
          "filters": {
            "mid": "MID",
            "groupNumber": "Group #",
            "errors": {
              "onlyNumbers": "You can only enter numbers into this field.",
            }
          },
          "omnisearch": {
            "searchBy": "Search by full/partial merchant name",
            "searchByName": "Search by name"
          }
        }
      },
      "merchantDemographicsView": {
        "sections": {
          "merchantInformation": {
            "title": "Merchant Information",
            "merchantNumber": "Merchant #",
            "DBAName": "DBA Name",
            "locationAddress": "Location Address",
            "groupNumberName": "Group #/Name",
            "billingAddress": "Billing Address",
            "phone": "Phone",
            "associationNumberName": "Association #/Name",
            "faxNumber": "Fax Number",
            "sicCode": "SIC Code"
          },
          "ownerAccountInformation": {
            "title": "Owner & Account Information",
            "ownerName": "Owner Name",
            "taxId": "Tax ID",
            "ownershipType": "Ownership Type",
            "visaDescriptor": "Visa Descriptor",
            "mastercardDescriptor": "Mastercard Descriptor",
            "descriptorCityStateZip": "Descriptor City/State/Zip"
          },
          "activityStatus": {
            "title": "Activity Status",
            "merchantStatus": "Merchant Status",
            "dateOpened": "Date Opened",
            "dateClosed": "Date Closed"
          },
          "primaryContact": {
            "title": "Primary Contact"
          }
        },
        "buttons": {
          "captions": {
            "hide": "Hide",
            "view": "View"
          }
        }
      },
      "userManagementPage": {
        "alerts": {
          "warning": {
            "lockUser": {
              "message": "This will lock the selected user and they will no longer be able to access their account and information.  Do you wish to continue?",
              "continueCaption": "Yes, Lock"
            },
            "deActivateUser": {
              "message": "This will deactivate the selected user and they will no longer be able to access their account and information.  Do you wish to continue?",
              "continueCaption": "Yes, Deactivate"
            },
            "activateLockedUser": {
              "message": "This user account has been locked due to security issues.  Are you sure  you want to activate this user?",
              "continueCaption": "Yes, Activate"
            },
            "activateDeActivatedUser": {
              "message": "This user account has been deactivated due to security issues. Are you sure you want to activate this user?",
            },
            "deleteUser": {
              "message": "You are about to delete this user.  Their account will be immediately deactivated and all of their information will be removed from the system. Please enter the Service Ticket # below to continue and confirm this action.",
              "continueCaption": "Confirm User Deletion"
            },
            "denyRequestAccess": {
              "message": "Are you sure you want to deny this user's request for access?",
              "continueCaption": "Yes, Decline Access"
            },
          },
          "errors":{
            "viewEditError": {
              "title": "Sorry, something has gone wrong",
              "message": "Our system has generated an error while attempting to retrieve this record. It’s not you, it’s us! Please try again later.",
              "buttonCaption": "Back to User List"
            }
          }        
        },
        "toasts": {
          "info": {
            "lockUser": {
              "message": "User has been successfully locked.  Please note that changes may not immediately be reflected in the user list as our system processes the update."
            },
            "deleteUser": {
              "message": "User has been successfully deleted.  Please note that changes may not immediately be reflected in the user list as our system processes the update."
            },
            "deActivateUser": {
              "message": "User has been successfully deactivated.  Please note that changes may not immediately be reflected in the user list as our system processes the update."
            },
            "requestAccessApprovalUser": {
              "message": "Please complete the remaining fields and assign data access to approve this user."
            },
            "userDeniedAccess": {
              "message": "User denied access"
            }
          },
          "success": {
            "activateUser": {
              "message": "User has been successfully activated.  Please note that changes may not immediately be reflected in the user list as our system processes the update."
            },
            "accessApproveUser": {
              "message": "User approved and activated!"
            },
          },
          "error": {
            "activateUser": {
              "message": "We’re sorry, but this user cannot be activated at this time. Please contact customer support for further assistance."
            },
            "deleteUser": {
              "message": "We’re sorry, but an error occurred while attempting to delete the user. Please try again or contact customer support for further assistance."
            }
          }
        },
        "actions": {
          "viewUser": "View User",
          "viewEditUser": "View/Edit User",
          "cloneUser": "Clone User",
          "assignAccess": "Assign Access",
          "declineAccess": "Decline Access",
          "activate": "Activate User",
          "deactivate": "Deactivate User",
          "lock": "Lock User",
          "delete": "Delete User"
        },
        "columns": {
          "lastName": "Last Name",
          "firstName": "First Name",
          "email": "Email Address",
          "role": "User Role",
          "lastLogin": "Last Login",
          "brand": "Brand",
          "status": "Status",
          "actions": "Actions"
        },
        "filterBar": {
          "filters": {
            "mid": {
              "mid": "MID"
            },
            "status": {
              "status": "Status",
              "active": "Active",
              "inactive": "Inactive",
              "approval_pending": "Pending",
              "deactivated": "Deactivated",
              "locked": "Locked"
            },
            "role": {
              "role": "Role",
            },
            "brand": {
              "brand": "Brand",
              "brands": {
                "allBrands": "All BRANDS",
                "gpi": "GPI",
                "synovus": "SYNOVUS",
                "tsys": "TSYS",
                "gpn": "GPN",
                'hpy': "HPY"
              },
            },
            "dba": {
              "dba": "DBA Name",
              "placeholder": "Start typing name to see results"
            }
          },
          "omnisearch": {
            "searchBy": "Search by full/partial name or email",
            "searchByNameOrEmail" : "Search name/email"
          }
        },
        "header": {
          "totalUsers": "Total Users",
          "createUser": "Create User",
          "cloneExistingUser": "Clone Existing User",
          "createNewUser": "Create New User"
        },
      },
      "shared": {
        "emptyString": "",
        "alerts": {
          "titles": {
            "warning": "Warning"
          },
          "cancel": "Cancel",
          "cancelAction": "No, Cancel",
          "closeButtonAltText": "close button",
          "discardChanges": {
            "message": "Are you sure you wish to discard your changes without saving?",
            "continueCaption": "Yes, Discard",
            "cancelCaption": "No, Continue Editing"
          },
          "unableToUpdate": {
            "message": "We're sorry, but we were unable to make the updates you requested.  It's not you, it's us!  Please try again later."
          }
        },
        "loading": "Loading",
        "dataAccess": {
          "fullPortfolio": "FULL PORTFOLIO",
          "mids": "MIDs",
          "all": "ALL",
          "allMIDS" : "ALL MIDS"
        },
        "description": "Description",
      },
      "createUser": {
        "title": "Let’s create a user",
        "subtitle": "We just need to collect a little information. This whole process shouldn’t take more than a minute.",
        "stepHeader": {
          "title": "User Creation Wizard",
          "heading": "Create a new user quickly and easily, step by step!",
          "steps": "Step %currentStepIndex% of %totalSteps%",
        },
        "sections": {
          "title": "SECTIONS",
          "basicInfo": {
            "title": "Basic Information",
            "heading": "Create New User - Basic Information",
            "form": {
              "selectRoles": {
                "title": "Select a role for this user",
                "placeholder": "-SELECT ROLE-"
              },
              "selectPermissions": {
                "title": "Role Permissions:",
                "placeholder": "Search available permissions",
                "selectAll": "Select All"
              },
              "selectBrands": {
                "title": "Brands(s)",
                "placeholder": "-SELECT BRANDS-",
                "allBrands": "ALL BRANDS"
              },
              "errors":{
                "fnameBlank": "First Name cannot be blank",
                "lnameBlank": "Last Name cannot be blank",
                "emailBlank": "Email Address cannot be blank",
                "invalidFName": "Invalid First Name",
                "invalidLName": "Invalid Last Name",
                "invalidEmail": "Invalid Email Address",
                "fnameMaxLength": "First Name cannot exceed %maxLength% characters",
                "lnameMaxLength": "Last Name cannot exceed %maxLength% characters",
                "emailMaxLength": "Email Address cannot exceed %maxLength% characters"
              }
            }, 
          },
          "dataAccess": {
            "title": "Data Access",
            "heading": "Create New User - Assign Data Access",
            "headingSubtext": "Grant user access to specific accounts or account groups"
          },
          "Applications": {
            "title": "Applications",
            "heading": "Create New User - Applications",
            "subHeading": "Choose applications to assign to user", 
            "subtitle":{
              "availableApplications": "Available Applications",
              "selectAll": "Select All",
            },
            "inactiveAppTooltip": "Sorry, you can not update the application access due to inactivity. Please contact customer service to regain access.",
          },
          "applicationRoles": {
            "title": "Application Roles",
            "heading": "Create New User - Application Roles",
            "subHeading": "Assign user a role for all available applications listed",
            "form":{
              "name": "Application Name",
              "role": "Application Role",
              "permission": "Permissions",
              "notApplicable": "N/A",
              "roleTitle": "Role",
            },
            "editRole":{
              "rolesAndPermission" : "Roles and Permissions",
            },
          },
          "reviewConfiguration": {
            "title": "Review Configuration",
            "heading": "Create New User - Review Configuration",
            "reviewConfUserInfoTab":{
              "userInfoTab": "User Information",
              "accountRole": "ACCOUNT ROLE",
              "role": "ROLE:",
              "brands": "BRAND(S)"
            },
            "reviewConfApplicationTab":{
              "applicationTab": "Applications"
            },
            "reviewConfAdditionalOpt":{
              "additionalOption": "ADDITIONAL OPTIONS",
              "adtionalTabToggle": "Send setup email to new user",              
            }
          },
          "creationComplete": {
            "title": "Creation Complete",
            "heading": "User Creation Complete",
            "successPageData": {
              "successMessage": "Success!",
              "userCreateMessage": "The user",
              "userCreateSuccess": "has been successfully created.",
              "emailSuccessMessage": "An email confirmation has been sent to:"
            }
          }
        },
        "buttons": {
          "nextStep": "Next Step",
          "previousStep": "Previous Step",
          "backToUsers": "Back to Users",
          "updateRole": "Update Role",
          "assignRole": "Assign Role",
          "completeUserCreation": "Complete User Creation",
          "createAnotherUser": "Create Another User"
        },
        "alerts": {
          "navigationWarning": {
            "message": "This will exit the Create New User Wizard and you will lose all of the information you have entered.",
            "message2": "Do you wish to cancel and discard your changes?",
            "continueCaption": "Yes, Cancel",
            "cancelCaption": "No, Return to wizard"
          },
          "cannotCreateUserBeforeAdmin": {
            "title": "Unable to Assign",
            "message1": "The selected MID(s) does not have an Administrator.  Please create or assign an existing Account Administrator/Third Party Administrator before creating this Account/Third Party user."
          }
        },
      },
      "editUser": {
        "accountInfo": {
          "userName": "USERNAME",
          "userEmail": "USER EMAIL",
          "accountsRole": "ACCOUNTS ROLE",
          "creationDate": "CREATION DATE",
          "lastLogin": "LAST LOGIN"
        },
        "sections":{
          "applicationAndRoles":{
            "title": "Applications & Roles",
            "applicationTable":{
              "applicationEnabled": "Enabled",
              "appAssignedRole": "Assigned Role",
              "appAssignedEdit": "Edit",
              "assignedRole": {
                "accessRevokedTooltip": "Application Access was revoked due to inactivity"
              },
            },
            "actions":{
              "title": "Actions",
              "inactiveAppTooltip":"Sorry, you can not update the application access due to inactivity. Please contact customer service to regain access.",
              "reactivateButton": "Reactivate",
            },
            "assignedRoleWarning":"You must choose a role for this application.",
            "brandLabel": "Brand(s)",
          },
          "personalInformation": {
            "title": "Personal Information",
            "subtitle": "Basic Information",
            "brands": {
              "outsideOfAccess": "This user has access to other brands outside of your data access",
              "placeholder": "You must choose at least one brand"
            }
          },
          "dataAccess": {
            "subtitle": "Assigned Data Access",
            "registerMID": {
              "merchantIDNumber": "Merchant ID Number",
              "description": "You can get most of your questions answered by consulting our My Account Help Center. Check it out here.",
              "messages": {
                "registeredMsg": "MID successfully registered. Please note that changes may take a few minutes to reflect on the screen"
              }
            },
            "registerMIDErrors": {
              "midNumberBlank" : "Merchant ID Number cannot be blank",
              "invalidMidNumber": "Invalid Merchant ID Number",
              "midNumberMaxLength": "Merchant ID Number cannot exceed 16 characters",
              "bankACNumberBlank": "Bank Account Number or Tax ID cannot be blank",
              "invalidBankACNumber": "Invalid Bank Account Number or Tax ID",
              "bankACNumberMaxLength": "Bank Account Number or Tax ID cannot exceed 16 characters"
            },
            "mobileError" : {
              "title": "Data Access editing not available",
              "message" : "This user has too many MIDs to edit in our mobile version.  Please use the desktop version to edit this user."
            }
          },
          "roles": {
            "title": "Roles"
          },
          "preferences": {
            "title": "Preferences",
            "locale": {
              "title": "Locale Preferences",
              "comingSoon": "coming soon",
              "language": "Language",
              "timeFormat": "Time Format",
              "dateFormat": "Date Format"
            },
            "timeFormats": {
              "timeFormat24": "Show 24-hour clock (16:30)",
              "timeFormatAmPm": "Show AM/PM (4:30 PM)"
            },
            "multiFactorAuthentication": {
              "title": "Multi-Factor Authentication",
              "configureMFA": "Configure MFA"
            },
            "alerts": {
              "unableToUpdate": {
                "title": "Update User Profile Error"
              },
              "alreadyHasDataAccess": {
                "message": "The Merchant Number you have entered is already part of your data access."
              },
              "mfaUpdated": {
                "message": "Multi-Factor authentication settings have been updated successfully."
              },
              "userUpdated": {
                "message": "User has been successfully updated. Please note that it may take a few minutes for the changes to propagate completely throughout our system."
              },
              "profileUpdated": {
                "message": "Profile changes were successfully updated."
              }
            }
          }
        },
        "buttons": {
          "discard": "Discard Changes",
          "save": "Save Changes",
          "registerMID": "Register New MID",
          "processingCaptions": {
            "registering": "Registering..."
          }
        }
      },
      "selfRegistration": {
        "header": "You’re almost there!",
        "subHeader": "We just need a little bit more information to get you into our system.",
        "subHeaderMessage": "Please enter your Merchant Number and Bank Account Number or Tax ID below.",

        "merchantNumber": {
          "title": "Merchant Number",
        },
        "requestAccess": {
          "title": "Request Access",
          "welcomeTitle": "Welcome, %firstName%!",
          "subTitle": "Discover a world of opportunities to elevate your business with our comprehensive suite of applications and services",
          "clickRequestAccess": "If you know your Admin's Email address or MID, Click on Request Access.",
          "alerts": {
              "accessRequestReceived": "You access request has been received. If your information matches with our records, access will be provided. Please contact your Account Owner or Admin for further details",
              "fieldCompletionNotification": "Please complete the remaining fields and assign data access to approve this user.",
              "userRequestdenialQuestion": "Are you sure you want to deny this user's request for access?",
              "userApproved": "User approved and activated!",
              "userDeniedAccess": "User denied access",
            },
          "EmailAccountOwner": "Account Owner/Admin Email Address",  
          "midAccOwner": "MID of Account Owner/ Account Admin",
          "email": "email",
          "assignAccess": "Assign Access",
          "declineAccess": "Decline Access",
          "pending": "Pending",
        },
        "taxID": {
          "title": "Bank Account Number or Tax ID"
        },
        "alerts": {
          "noMatch": {
            "title": "Merchant Number or DDA/ TAX ID does not match our records. Please try again or contact your local customer care department."
          },
          "invalid": {
            "merchantNumberInvalid": "The Merchant Number is incorrect. Check the Merchant Number you provided and try again.",
            "taxIdInvalid": "The TAX ID/DDA entered can include a - z, A - Z, 0 - 9 and hyphen. Please correct your entry.",
          }
        },
        "button":{
          "completeRegistration" : "Complete Registration",
          "completeCaption": "Registering..."
        }
      },
      "ErrorPage": {
        "UnknownEmployee": {
          "Title": "Sorry, you are not authorized to view this site",
          "SubText": "config.activeBrand.unknownUserMessage",
          "ErrorCode": "ERR518112347IA"
        },
        "InactiveUser": {
          "ErrorCode": "ERR518112347IA / MPERR059"
        },
        "DeactivatedUser": {
          "SubText": "Please call your account administrator or customer support and provide the following error code:",
          "ErrorCode": "ERR518112347IA / MPERR060"
        },
        "Error": {
          "Title": "Sorry, an error has occurred while attempting to log you into the system.",
          "SubText": "Please call the support number listed on your statement and provide the following error code:",
          "ErrorCode": "MPERR068"
        },
        "UnexpectedLoginError": {
          "Title": "Sorry, we were unable to log you into the system.",
          "SubText": "We are temporarily unable to log you in at this time. Please try again later.",
          "ErrorCode": "ERR518112347IA"
        },
        "SelfRegisterError": {
          "ErrorCode": "MPERR069"
        },
        "apologize": "We apologize for any inconvenience.",
        "returnToLogin": "Return to Login",
        "copyright2": "Privacy Policy and Terms of Use"
      }, 
      "logOut":{
        "loadingMessage": "You are being logged out of My Account...",
        "loggedOutMessage": "You have been logged out of your account.",
        "loginAgainMessage": "If you wish to log in again, click the button below:",
        "button":{
          "login": "Account Login"
        }

      },
      "roleChange": {
        "notifications": {
          "titles": {
            "dataAccessWarning": "DATA ACCESS WARNING:",
            "dataAccessConfigError": "DATA ACCESS CONFIGURATION ERROR"
          },
          "messages": {
            "reconfigureDataAccess": "Because you are changing the role from %originalRoleName% to %newRoleName%, the Data Access for this user may need to be reconfigured.",
            "reviewTab": "Please review the Data Access tab before you save changes.",
            "removeFullPortfolio": "In order to save this change, you need to remove full portfolio from the Data Access screen.",
            "cloneAccess": "The user you have selected to clone has access to MIDs outside of your data set.",
            "accessGiven" : "The new user you are creating will only be given access to the MIDs you both share."
          }
        }
      },
      "productOfferings": {
        "homePageBanner": {
          "title": "Looking to enhance your account experience?",
          "message": "Check out our Product Offerings for upgrades and add-ons to help you run and grow your business better than ever!",
          "button": "Go to Product Offerings"
        },
        "mainPage": {
          "buttons": {
            "currentSubscription": "My Current Subscription Plan",
            "subscribe": "Subscribe",
            "subscribed": "Subscribed",
            "upgrade": "Upgrade",
            "remove": "Remove",
          },
          "cards": {
            "price" : {
              "monthly": "month",
              "usd": "USD"
            }
          },
          "pills": {
            "new": "NEW",
            "upgrade": "UPGRADE",
            "bundle": "BUNDLE"
          }
        },
        "currentSubscription": {
          "title": "Current Subscriptions",
          "upgradeAvailable": "Upgrade available!",
          "headers": {
            "name": "Application / Bundle Name",
            "cost": "Cost",
            "actions": "Actions"
          },
          "monthlyTotal": "Current monthly total :",
        },
        "modal": {
          "titles": {
            "add": "Add Application",
            "remove": "Remove Application",
            "successAdd": "Application Successfully Added",
            "successRemove": "Application Removed",
            "success": "Success!"
          },
          "messages": {
            "configure": "If you want to configure access to this application for other users on your account, you can find instructions on the Accounts Dashboard.",
            "addition": "You are adding %appName% to your Application Suite",
            "removal": "You are removing %appName% from your Product Suite.",
            "remove": "Please note that all users under your Merchant Account will lose access to this product immediately.",
            "successRemove": "%appName% has been removed from your account.",
            "successAdd": "You now have access to %appName%",
            "resubscribeMessage": "If you wish to resubscribe to %appName% in the future, you can do so through the Product Offerings selection at any time.",
            "addConfirmation": "If this looks correct, click on Confirm Purchase and %appName% will be added to your account.",
            "removeConfirmation": "If this looks correct, click on Confirm Removal and %appName% will be removed from your account."
          },
          "buttons": {
            "purchase": "Confirm Purchase",
            "removal": "Confirm Removal",
            "return": "Return to Product Offerings",
            "configure": "Configure Users Now"
          },
          "table": {
            "currentHeader": "Current Product Selection",
            "newHeader": "New Product Selection",
            "currentCost": "Current Monthly Cost:",
            "newCost": "New Monthly Cost:",
            "price" : "$%amount%/mo"
          }
        },
        "infoBanners": {
          "noProducts": {
            "title": "No Products Available",
            "message": "There are no product offerings available at this time. Please check back in the future for new applications and bundles to help you run and grow your business!"
          },
          "noSubscriptions": {
            "title": "You are not currently subscribed to any applications.",
            "message": "Please return the Product Offerings screen to add applications to your account."
          }
        }
        
      },
      "footer": {
        "links": {
          "privacyPolicy": "Privacy Policy",
          "termsOfUse": "Terms of Use"
        }
      },
      "quickLinks": {
        "note": "This space will fill with more actions as you use your products.",
        "title": "Quick Actions",
        "createMessage":	"Create Message",
        "searchCases":	"Search Cases",
        "createQueue":	"Create Queue",
        "viewPaymentReports":	"View Payment Reports",
        "viewAnalytics":	"View Analytics",
        "createCustomReport":	"Create Custom Report",
        "viewDocuments":	"View documents",
        "viewStatements":	"View statements",
        "viewDisputesManagement":	"View Disputes Management",
        "viewCustomerEngagement":	"View Customer Engagement",
        "viewSettledBatches":	"View settled batches",
      },
      "plan": {
        "name": {
          "benchmark": {
            "uk": "Benchmark"
          },
          "analyticsCore": {
            "uk": "Analytics Core",
            "ca": "Analytics Core",
          },
          "paperStatement": {
            "uk": "Paper Statement",
            "ca": "Paper Statement",
            "us": "Paper Statement",
            "ap": "Paper Statement",
          },
          "reportingLite": {
            "uk": "Reporting LITE",
            "ca": "Reporting LITE",
            "us": "Reporting LITE",
            "ap": "Reporting LITE",
          },
          "reportingStandard": {
            "uk": "Reporting Standard"
          },
          "reportingPro": {
            "uk": "Reporting PRO",
            "ca": "Reporting PRO",
            "us": "Reporting PRO",
            "ap": "Reporting PRO",
            "citi": "Reporting PRO",
          },
          "socialReputation": {
            "ca": "Social Reputation",
          },
          "reportingCore": {
            "ca": "Reporting CORE",
            "us": "Reporting CORE",
            "mx": "Reporting CORE",
            "bm": "Reporting CORE",
          },
          "reportingProAu": {
            "ap": "Reporting PRO"
          }
        },
        "description": {
          "benchmark": {
            "uk": "Benchmark behavior, spend and reach - your customers and your local competitors. Benchmark is a premium Analytics module that provides advanced Analytics and actionable insights."
          },
          "analyticsCore": {
            "uk": "Track repeat versus new customers. Pinpoint busiest and slowest periods during the day and week. View total sales volume, visits and average buyer ticket. Learn which advertisements impact your bottom line",
            "ca": "Tracks key metrics for new vs. returning customers including Sales volume, Total visits, Average ticket size, Visits and sales by day and time",
          },
          "paperStatement": {
            "uk": "Paper statements will be mailed to you in addition to the eStatements you may download at any time.",
            "ca": "Paper statements will be mailed to you in addition to the eStatements you may download at any time.",
            "us": "Paper statements will be mailed to you in addition to the eStatements you may download at any time.",
            "ap": "Paper statements will be mailed to you in addition to the eStatements you may download at any time.",
          },
          "reportingLite": {
            "uk": "Manage your chargeback cases online at anytime, Monthly settled transactions, Notifications, 25 Months - eStatement",
            "ca": "Manage your chargeback cases online at anytime, 32 days - transactions, disputes, deposit activit. Ability to quick troubleshoot transaction, Notification, 3 Months - eStatement",
            "us": "Manage your chargeback cases online at anytime, 32 days - transactions, disputes, deposit activit. Ability to quick troubleshoot transaction, Notification, 3 Months - eStatement",
            "ap": "Manage your chargeback cases online at anytime, 32 days - Payment Advice Reports, 3 Months - eStatement, Notifications",
          },
          "reportingStandard": {
            "uk": "Manage your chargeback cases online at anytime, 25 Months -  Reconciliation Reports,  Ability to quick troubleshoot transactions, Notifications, 25 Months - eStatement"
          },
          "reportingPro": {
            "uk": "Manage your chargeback cases online at anytime, 25 Months - Reconciliation Reports, Ability to quick troubleshoot transactions, Notifications, 25 Months - eStatement, Report Builder & Scheduler, Access to Analytics Core and Benchmark Modules.",
            "ca": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity, Ability to quick troubleshoot transactions, Notifications,	25 Months - eStatement, Report Builder & Scheduler",
            "us": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity, Ability to quick troubleshoot transactions, Notifications,	25 Months - eStatement, Report Builder & Scheduler",
            "ap": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity, Ability to quick troubleshoot transactions, Notifications,	25 Months - eStatement, Report Builder & Scheduler",
            "citi": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity, Ability to quick troubleshoot transactions, Notifications, Report Builder & Scheduler",
          },
          "socialReputation": {
            "ca": "Enables effective reputation management with A single dashboard providing access to customer reviews from popular online and social media sites,One-click access to view and respond to customer reviews.Online reputation competitive comparison, to see how your business stacks up against the competition",
          },
          "reportingCore": {
            "ca": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity reports, Ability to quick troubleshoot transactions, Notifications, 25 Months - eStatement, Report Builder & Scheduler",
            "us": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity reports, Ability to quick troubleshoot transactions, Notifications, 25 Months - eStatement, Report Builder & Scheduler",
            "mx": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity reports, Ability to quick troubleshoot transactions, Notifications, 25 Months - eStatement, Report Builder & Scheduler",
            "bm": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity reports, Ability to quick troubleshoot transactions, Notifications, 25 Months - eStatement, Report Builder & Scheduler",
          },
          "reportingProAu": {
            "ap": "Manage your chargeback cases online at anytime, 25 Months - transactions, disputes, deposit activity, Ability to quick troubleshoot transactions, Notifications,	25 Months - eStatement, Report Builder & Scheduler",
          }
        }
      },
      "calender": {
        "duration": {
          "today": "Today",
          "yesterday": "Yesterday",
          "lastSevenDays": "Last 7 Days",
          "lastThirtyDays": "Last 30 Days",
          "thisMonth": "This Month",
          "lastMonth": "Last Month",
        }
      }
    }
  }

export default masterTranslations;