import React, { useMemo, useState, useEffect } from 'react';
import Modali from 'modali';
import config from 'config/config';
import { useApi, BVLoader20, useLocale, LocalizedText } from 'portal-common-ui';
import useAlertQueue from 'hooks/alertQueue';
import ProductModalContent from './ProductModalContent';
import ApplicationSuccessModal from './ApplicationSuccessModal';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import { convertOfferingDataToProducts } from './utils';
import useApplications from "hooks/applications";
import { useProductModalStore } from 'store/productModalStore';

const ProductModal: React.FC<any> = ({ toggleModal, productModal }) => {
  const options = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userListUrl = config.API.userUrl2;
  const userListApi = useApi(userListUrl, options);
  const alertQueue = useAlertQueue();
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { title, offeringData, buttonTitle, myCurrentSubsription, removeApplication } = useProductModalStore();
  const products = useMemo(() => convertOfferingDataToProducts(offeringData || []), [offeringData]);
  const [selectedId, setSelectedId] = useState(null);
  const [latestSubscribedTitle, setLatestSubscribedTitle] = useState(title);
  const {translateToString} = useLocale();
  const { getApplicationsList, pollForSubscribedAppsChange } = useApplications();

  // Add keydown event listener
  // useEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (event.key === 'Escape') {
  //       setShowSuccessModal(false); // Reset to default state on Esc key event
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyDown);

  //   // Remove event listener on cleanup
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);


  const getCurrentSubscriptionAppIds = () => {
    return Array.from(new Set(offeringData.filter(o => o.isSubscribed).flatMap(o => o.offeringApps)));
  };

  const getUpdatedSubscriptionAppIds = (selectedPlanId: number, isRemoved: boolean) => {
    if (isRemoved) {
      return getCurrentSubscriptionAppIds();
    } else {
      let appIdsBeforeAddition = getCurrentSubscriptionAppIds();
      let appIdsAfterAddition = Array.from(new Set(offeringData.filter(x => x.offeringId === selectedPlanId).flatMap(o => o.offeringApps)));
      return Array.from(new Set(appIdsBeforeAddition.concat(appIdsAfterAddition)));
    }
  };

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      let response;
      const currentApps = getCurrentSubscriptionAppIds();
      if (removeApplication) {
        response = await userListApi.del(`users/owner/subscriptions/${selectedId}`);
      } else {
        response = await userListApi.post(`users/subscriptions`, { planId: selectedId });
      }
      setIsLoading(false);
      if (response.status === 201 || response.status === 200) {
        setShowSuccessModal(true);
        const newApps = getUpdatedSubscriptionAppIds(selectedId, removeApplication);
        if (removeApplication || !currentApps.every(a => newApps.includes(a)) || !newApps.every(a => currentApps.includes(a))) {
          // newApps is different from current, so need to poll the profile API for the updated app list
          pollForSubscribedAppsChange(removeApplication, 3000, 5);
        } 
      } else {
        alertQueue.enqueueAlert({ alertType: 'modal', title: 'Error', messageType: 'error', message: 'An error occurred while processing your request.  Please try again later.' });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const renderModalContent = () => {
    if (showSuccessModal) {
      return <ApplicationSuccessModal response={!removeApplication ? translateToString("productOfferings.modal.titles.success") : ""} removeApplication={removeApplication} title={!myCurrentSubsription ? title : latestSubscribedTitle} textBody={!removeApplication ? <LocalizedText localeKey="productOfferings.modal.messages.configure"/> : <LocalizedText localeKey="productOfferings.modal.messages.resubscribeMessage" templateVariables={{appName: title}}/>} />;
    }
    return <ProductModalContent title={title} products={products} buttonTitle={buttonTitle} onProductClick={setSelectedId} myCurrentSubsription={myCurrentSubsription} removeApplication={removeApplication} setLatestSubscribedTitle={setLatestSubscribedTitle}/>;
  };

  return (
    <Modali.Modal {...productModal}>
      {isLoading &&
        <BVLoader20 />
      }<div className='overflow-y-auto' style={{ maxHeight: '90vh' }}>
        <ModalHeader title={!showSuccessModal ? (!removeApplication ? translateToString("productOfferings.modal.titles.add") : translateToString("productOfferings.modal.titles.remove")) : !removeApplication ? translateToString("productOfferings.modal.titles.successAdd") : translateToString("productOfferings.modal.titles.successRemove")} />
        {renderModalContent()}
        <ModalFooter showSuccessModal={showSuccessModal} setShowSuccessModal={setShowSuccessModal} toggleModal={toggleModal} onConfirm={onConfirm} />
      </div>
    </Modali.Modal>
  );
};

export default React.memo(ProductModal);