import React, { useState, useEffect } from "react";
import { useUser, BVLoader20 } from 'portal-common-ui';
import useAlertQueue from "hooks/alertQueue";
import usePermissions from 'hooks/session/permissions';
import UserViewEditor from 'components/UserViewerEditor/UserViewerEditor';

const MyProfile = () => {
  const { getAuthenticatedSessionUser, isLoading } = useUser();
  const alertQueue = useAlertQueue();
  const {hasPermission} = usePermissions();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const userResult = await getAuthenticatedSessionUser();
      setUser(userResult);  
    };
    fetch();
  }, []);

  const onMidRegister = async () => {
    const userResult = await getAuthenticatedSessionUser({forceReload: true});
    setUser(userResult);
  };

  if (isLoading || !user) {
    return (
    <div className="relative min-h-[600px]">
      <BVLoader20 />
    </div>
    );
  }
  
  const onSaveSuccess = () => {
    alertQueue.enqueueAlert({ 
      alertType: 'toast',
      messageType: 'success',
      message: 'Profile changes were successfully updated.',
      messageKey: 'editUser.sections.preferences.alerts.profileUpdated.message'
    });
  };

  const canUpdateProfile = hasPermission('UPDATE_MY_USER_PROFILE');
  return ( 
    <div>
      <UserViewEditor 
        user={user}
        onMidRegister={onMidRegister}
        isSessionUser
        canEditBasicInfo={canUpdateProfile}
        onSaveSuccess={onSaveSuccess}
        >
      </UserViewEditor>
    </div>
  );  
  
};

export default MyProfile;

