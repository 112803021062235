export const openHelpCenterLink = () => {
  window.open("https://help.globalpaymentsintegrated.com/merchantportal/accounts/", "_blank");
};

export function mapAppLinkQueryString(url: string, user: any, segment?: string) {
  const urlObj = new URL(url);
  let pathname = urlObj.pathname;
  const queryParams = { lang: user?.locale, df: user?.dateFormat || "MM/DD/YYYY", tf: user?.timeFormat || "hh:mm a" };

  if (segment) {
    pathname = `${urlObj.pathname.replace(/\/$/, "")}/${segment}`;
  }

  let queryStr = "";

  const queryString = new URLSearchParams(urlObj.search);

  for (const [key, value] of queryString.entries()) {
    queryStr += queryStr ? `&${key}=${value}` : `${key}=${value}`;
  }

  Object.entries(queryParams).forEach(([key, value]) => {
    queryStr += queryStr ? `&${key}=${value}` : `${key}=${value}`;
  });

  let finalUrl = `${urlObj.origin}${pathname}`;

  if (queryStr) {
    finalUrl += `?${queryStr}`;
  }
  if (urlObj.hash) {
    finalUrl += urlObj.hash;
  }

  return finalUrl;
}
