import React, { useEffect, useState } from "react";
import QuickLink from "./QuickLink";
import useSession from "hooks/session/session";
import useApplications from "hooks/applications";
import useFeatureFlags from "hooks/utils/featureFlags";
import { LocalizedText, useLocale } from "portal-common-ui";

const QuickLinks = (props: any) => {
  const session = useSession();
  const { user } = session;
  const isInternal = user.internal;
  const { settledBatchReport, hierarchyType } = props;
  const { translateToString } = useLocale();
  const { appList } = useApplications();
  const [quickLinks, setQuickLinks] = useState<any>([]);
  const { accountsLandingPageQuickLinks } = useFeatureFlags();

  useEffect(() => {
    const activeApps: any = {};
    const assignedApplication: any = {};
    const quickLinks: { appId: string; segment: string; label: any; active: boolean; ssoType: string }[] = [];

    const apps = appList.map((app: any) => {
      activeApps[app.assignedApplication.applicationGuid] = app.status === "ACTIVE";
      assignedApplication[app.assignedApplication.applicationGuid] = app.assignedApplication;
      return app.assignedApplication.applicationGuid;
    });

    accountsLandingPageQuickLinks.forEach((link: any) => {
      if (quickLinks.length === 4) {
        return;
      }

      const isApplicable =
        link.userType === "ALL" ||
        (link.userType === "INTERNAL" && isInternal) ||
        (link.userType === "EXTERNAL" && !isInternal);

      const allowedPortfolio = link.portfolio === "ALL" || link.portfolio === hierarchyType;

      if (apps.includes(link.appId) && isApplicable && allowedPortfolio) {
        const permissions = assignedApplication[link.appId].applicationRoleAssociations?.[0]?.permissions;
        if (
          !permissions ||
          !link.permissionKey ||
          permissions.some((x: any) => x.permissionGuid === link.permissionKey)
        ) {
          let segment = link.url;

          if (link.report && link.report === "settledBatchReport") {
            segment = segment.replace("{reportId}", settledBatchReport?.reportId);
          }

          quickLinks.push({
            appId: link.appId,
            segment: segment,
            label: translateToString(link.labelKey) ? <LocalizedText localeKey={link.labelKey} /> : link.label,
            active: activeApps[link.appId],
            ssoType: assignedApplication[link.appId].ssoType,
          });
        }
      }
    });

    setQuickLinks(quickLinks);
  }, [appList, settledBatchReport]);

  let linkHeight = "xl:h-[75px]";
  if (quickLinks.length === 3) {
    linkHeight = "xl:h-[100px]";
  } else if (quickLinks.length < 3) {
    linkHeight = "xl:h-[150px]";
  }

  return (
    <div className="p-5 bg-white rounded shadow xl:col-span-1 col-span-3">
      <div className="text-xl font-medium">
        <LocalizedText localeKey="quickLinks.title" />
      </div>
      <div className="flex flex-col mt-4 xl:h-[300px]">
        {quickLinks.map((ql: any) => (
          <div key={`${ql.segment}${ql.appId}`} className={`flex hover:bg-theme-info-background active:bg-blue-200`}>
            <div className="w-full">
              <QuickLink
                appId={ql.appId}
                segment={ql.segment}
                label={ql.label}
                active={ql.active}
                ssoType={ql.ssoType}
                height={linkHeight}
              />
            </div>
          </div>
        ))}
        {quickLinks.length < 2 && (
          <div
            className={`${
              !quickLinks.length ? "lg:h-auto xl:h-[300px]" : "h-[150px] hidden"
            } xl:flex xl:text-center xl:justify-center`}
          >
            <div className="self-center">
              <LocalizedText localeKey="quickLinks.note" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(QuickLinks);
