import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import useSession from 'hooks/session/session';
import { BVLoader20, LocalizedText } from 'portal-common-ui';

const Logout = () => {
  const session = useSession();
  useEffect(() => {
    if (session.authenticated) {
      setTimeout(() => session.handleLogout(), 3000);
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-screen w-screen bg-theme-on-primary before:bg-theme-on-primary before:bg-full before:bg-skyline before:bg-no-repeat before:bg-bottom before:fixed before:h-screen before:w-screen before:opacity-25 before:content-[''] before:top-0 before:left-0">
      {<BVLoader20 
        bgColor={'transparent'}
        fill={'transparent'}
      />}
      {session.authenticated && <span className="mb-4 t-lg:mb-8 text-20px text-theme-primary mt-[200px] font-medium"><LocalizedText localeKey="logOut.loadingMessage"/></span>}
      {/* {!session.authenticated && <Redirect to="/login" />} */}
    </div>
  );
};

export default Logout;
