// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GA4React from 'ga-4-react';
import config from 'config/config';

const GAListener = (props) => {
  console.log('config.activeBrand.analytics.enableDebug : ',config.activeBrand.analytics.enableDebug);
  console.log('config.activeBrand.analytics.trackingId : ',config.activeBrand.analytics.trackingId);
  
  useEffect(() => {
    const ga4react = new GA4React(config.activeBrand.analytics.trackingId);
      ga4react.initialize().then((ga4) => {
        ga4.pageview(props.history.location.pathname)   
      },(err) => {
        console.error(err)
      });
  }, []);

  return props.children;
};

GAListener.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(GAListener);
