import React, { useEffect, useState, useCallback } from 'react'
import EllipsisText from "react-ellipsis-text";
import { useGlobal } from 'libs/reactn/useGlobal';
import Modali, { useModali } from 'modali';
import useSession from 'hooks/session/session';
import usePermissions from 'hooks/session/permissions';
import config from 'config/config';
import RegisterMid from "components/RegisterMid/RegisterMid";
import useProvisionedLink from 'hooks/utils/provisionedLink';
import useCustomerOrientation from 'hooks/customerOrientation';
import { LocalizedText, Button } from 'portal-common-ui';
import useFeatureFlags from 'hooks/utils/featureFlags';
import Products from './Products.tsx';
import { AnalyticsIcon } from 'assets/icons';
import { Card } from 'components/Card';
import NewApplicationList from './NewApplicationList';
import { useTags } from "portal-common-ui";
import ProductRedirectionBanner from 'components/ProductCard/ProductRedirectionBanner'

const Header = () => {
  const session = useSession();
  const { firstVist, user } = session;
  const [requestAccessModal, toggleRequestAccessModal] = useModali({
    overlayClose: false,
    large: false,
    centered: true,
  });
  const onRequestAccess = () => {
    toggleRequestAccessModal();
  };
  if (user.isGuestUser) {
    return(
      <div className="col-xs-12">
          <h1 className="text-theme-info font-semibold text-3xl mb-4" data-test="homepage-heading">
          <LocalizedText localeKey="selfRegistration.requestAccess.welcomeTitle" templateVariables={{ firstName: user.firstName }} />
          </h1>
          <p className="text-theme-medium font-light text-lg">
              <LocalizedText localeKey="selfRegistration.requestAccess.subTitle" id="subtitle" /> 
              <p className="text-theme-medium font-light text-lg mt-2">
              <LocalizedText localeKey="selfRegistration.requestAccess.clickRequestAccess" id="clickRequestAccess" />
          </p>
          </p>
          <div className="whitespace-nowrap mr-2">
            <button
              title="Request Access"
              testDataAttribute="home-request-new-access"
              className="text-theme-primary border-theme-primary border px-4 py-2 text-sm font-bold"
              onClick={onRequestAccess}
              minWidth={150}
            >
              <LocalizedText localeKey="selfRegistration.requestAccess.title" id="requestAccess-title" />
            </button>
          </div>
          <Modali.Modal {...requestAccessModal}>
          <div className="flex items-center justify-center min-w-[350px] w-full h-full">
            <RegisterMid
              onCloseDialog={toggleRequestAccessModal}
              popupType={"requestAccess"}
            />
          </div>
        </Modali.Modal>
      </div>
    );
  } else {
    return(
        <div className="col-xs-9">
            { firstVist
                ? <h1 className="text-theme-info font-normal sml:text-2xl sml:font-medium" data-test="homepage-heading"><LocalizedText localeKey="homepage.titleFirstVisit" /></h1>
                : <h1 className="text-theme-info font-normal sml:text-2xl sml:font-medium" data-test="homepage-heading"><LocalizedText localeKey="homepage.title" templateVariables={{ firstName: user.firstName }}/></h1>
            }
            <p className="mt-4 text-theme-medium font-normal sml:hidden"><LocalizedText localeKey="homepage.subtitle" /></p>
        </div>
    );
  }
};

const CustomerOrientation = props => {
    const [ orientation, setOrientation ] = useGlobal("orientation");
    const handleClick = () => {
      props.markAsRead('onboarding.products.analytics');
    };
    const {
        productName,
        productIcon
    } = orientation;
    if (props.analyticsIsNew) {
        return(
            <div className="max-w-6xl">
                <Card>
                    <div className="card-container flex items-center p-4">
                        <AnalyticsIcon className="w-card-img h-card-img" />
                        <div className="card-body pl-5">
                            <div className="card-title">
                                <h2 className="font-normal text-theme-dark">
                                    You now have access to our <span className="font-bold italic text-theme-info">{productName}</span> product.
                                    Would you like to take a tour of its features now?
                                </h2>
                            </div>
                            <div className="card-actions mt-5">
                                <button id="btn-yes-orientation" className="btn btn-primary mr-1"
                                   onClick={props.analyticsUrl}
                                   aria-label={`Navigate to ${productName}`}
                                   target="_blank" rel="noopener noreferrer">
                                    Yes, please!
                                </button>
                                <button id="btn-no-orientation" aria-label="Close this message"
                                        className="btn btn-secondary" onClick={handleClick} >
                                    No Thanks
                                </button>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    } else {
        return null;
    }
};


const Home = () => {
    const analyticsSSOUrl = config.API.analyticsSSO.replace('{0}', config.activeBrand.brandGuid.toLowerCase());
    const { handleClick: provisionedLinkClick } = useProvisionedLink(analyticsSSOUrl);
    const customerOrientation = useCustomerOrientation();
    const [analyticsIsNew, setAnalyticsIsNew] = useState(false);
    const markAsRead = (notificationKey) => {
      if (customerOrientation.hasUnreadTooltip(notificationKey)) {
        customerOrientation.markAsRead(notificationKey);
      }
    }
    const [dismissedNewApplicatioAlert] = useGlobal('dismissedNewApplicatioAlert');
    const { updateApplicationAlertDismissed } = useTags();
    const { accountsApplicationManagementEnabled, accountsGuestUserEnabled } = useFeatureFlags();
    const {hasPermission} = usePermissions();
    const session = useSession();
    const { user } = session;

    const analyticsClickHandler = (event) => {
      event.stopPropagation();
      provisionedLinkClick(event);
      markAsRead('onboarding.products.analytics');
    };

    useEffect(() => {
      if (!customerOrientation.isLoading) {
        const showNewLabel = customerOrientation.hasUnreadTooltip('onboarding.products.analytics');
        setAnalyticsIsNew(showNewLabel);
      }
    }, [customerOrientation.isLoading, customerOrientation.isSaving]);

    const onForeverDismiss = useCallback(async (userId, applicationsIds) => {
      await updateApplicationAlertDismissed(userId, applicationsIds);
    }, []);

    return (
      <div className="flex flex-col flex-initial">
        <Header />
        {!dismissedNewApplicatioAlert && accountsApplicationManagementEnabled && (
          <NewApplicationList onForeverDismiss={onForeverDismiss} />
        )}
        <CustomerOrientation
          analyticsUrl={analyticsClickHandler}
          analyticsIsNew={analyticsIsNew}
          markAsRead={markAsRead}
        />
        {user.isGuestUser ? "" : <Products />}
        {accountsApplicationManagementEnabled && hasPermission("SUBSCRIPTION_MANAGER") && <ProductRedirectionBanner />}
      </div>
    );
}

export default Home