import AppIcon from "components/Application/AppIcon";

function ApplicationTile({
  appIconid,
  appIconHeight,
  appIconWidth,
  appIconDisabled,
  applicationName,
  applicationDescription,
  onClick,
}) {
  return (
    <div data-test="application-tile" className={`flex flex-col items-center`} onClick={onClick}>
      <AppIcon
        id={appIconid}
        height={appIconHeight}
        width={appIconWidth}
        disabled={appIconDisabled}
      />
      <div className="text-sm font-medium text-center p-1">{applicationName}</div>
      <div className="text-xs font-normal text-center p-1">
        {applicationDescription}
      </div>
    </div>
  );
}

export default ApplicationTile;
