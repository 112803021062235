import { useGlobal } from 'libs/reactn';

const useSortState = () => {

    const [sortings, setSortings] = useGlobal('sortings');

    const updateSortings = (newSortings) => setSortings({
        ...sortings,
        ...newSortings,
    });

    return {
        sortings,
        updateSortings,
    }
}

export default useSortState;