import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import PublicLayout from 'core/layout/PublicLayout';

const PublicRoute = ({ children, component: Component, ...rest }) => {
  const hasComponent = (Component !== undefined);

  return (
    <Route
      {...rest}
      render={props => (
        <PublicLayout>
          {hasComponent && <Component {...props} />}
          {!hasComponent && children}
        </PublicLayout>
      )}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

PublicRoute.defaultProps = {
  component: undefined,
  children: [],
};

export default PublicRoute;
