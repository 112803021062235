import React, { useState, useEffect } from "react";
import useTranslations from "hooks/utils/translations";
import { BVLoader20, NotificationCard, LocalizedText, useUser } from "portal-common-ui";
import ApplicationsList from "./ApplicationsList";
import useApplications from "hooks/applications";
import { isDeepEqual } from "react-use/lib/util";

interface ApplicationsRolesTabProps {
  targetUser: any;
  isDiscarding: any;
  onUpdate(assignedApplication: any): any;
  canEditApplicatinsAndRoles?: boolean;
  hasFullPortfolioAssigned?: boolean;
  targetHierarchies: string[];
  appFilters?: string[];
  loadingSubscribedApps?: boolean;
  subscriptionError?: boolean;
}

const ApplicationsRolesTab = ({
  targetUser,
  onUpdate,
  isDiscarding,
  canEditApplicatinsAndRoles = false,
  hasFullPortfolioAssigned = false,
  targetHierarchies,
  appFilters = undefined,
  loadingSubscribedApps = false,
  subscriptionError = false,
}: ApplicationsRolesTabProps) => {
  const { translate } = useTranslations();
  const { getAuthenticatedSessionUser } = useUser();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [currentUserApps, setCurrentUserApps] = useState<any[]>([]);
  const { getApplicationsList, appList, internalAppList, loading } = useApplications();
  const [appUpdated, setAppUpdated] = useState(false);

  useEffect(() => {
    getAuthenticatedSessionUser().then((data: any) => {
      setLoggedInUser(data);
      getApplicationsList();
    });
  }, []);

  useEffect(() => {
    setApplications();
  }, [targetUser, loggedInUser, appList]);

  useEffect(() => {
    if (isDiscarding) {
      setApplications();
    }
  }, [isDiscarding]);

  useEffect(() => {
    const editUserApps = targetUser.userApplicationAssignments.results;
    setAppUpdated(false);
    for (const changedApp of currentUserApps) {
      const editUserApp = editUserApps.find(
        (item: any) => item.assignedApplication.applicationGuid === changedApp.applicationGuid
      );
      if (isApplicationUpdated(changedApp, editUserApp) && changedApp.applicationGuid !== "MERCHANT_PORTAL_ACCOUNTS") {
        setAppUpdated(true);
        break;
      }
    }
  }, [currentUserApps]);

  const isApplicationUpdated = (changedApp: any, editUserApp: any) => {
    const newValidApplication =
      changedApp.new && changedApp.assigned && (changedApp.isPermissionRequired ? changedApp.roleId : true);
    const applicationDeleted = !changedApp.new && !changedApp.assigned;
    const roleChanged = changedApp.roleId !== editUserApp?.roleId;
    const editUserAppOptionalPermissionIds = editUserApp?.optionalPermissionIds ?? [];
    const optionalPemissionsChanged = !isDeepEqual(
      changedApp.optionalPermissionIds?.sort(),
      editUserAppOptionalPermissionIds?.sort()
    );
    return (
      newValidApplication ||
      applicationDeleted ||
      (changedApp.assigned && (roleChanged || optionalPemissionsChanged))
    );
  };

  const setApplications = () => {
    const userApps = targetUser.userApplicationAssignments.results;
    const userApplicationGuid = userApps.map((app: any) => app.assignedApplication.applicationGuid);
    const loggedInUserApps = loggedInUser?.roleObject?.roleType === "INTERNAL" ? internalAppList : appList;
    const apps = loggedInUserApps.map((app: any) => {
      const { assignedApplication, applicationPlanIds, applicationId, newlyAdded } = app;
      const applicationGuid = assignedApplication.applicationGuid;
      const targetUserAppData = userApps.find(
        (item: any) => item.assignedApplication.applicationGuid === applicationGuid
      );
      const isPermissionRequired = app.assignedApplication?.applicationRoleAssociations?.length > 0;
      const sysnetDisabled =
        !targetUser.dataAccess?.find((h) => h?.nodes[0]?.selectedChild.label === "9305") &&
        applicationGuid === "SYSNET" &&
        targetUser?.roleObject?.roleType === "EXTERNAL";
      return {
        assignedApplication: {
          applicationRoleAssociations: targetUserAppData?.assignedApplication?.applicationRoleAssociations,
        },
        isPermissionRequired,
        assigned: userApplicationGuid.includes(applicationGuid),
        new: !userApplicationGuid.includes(applicationGuid),
        disabled: applicationGuid === "MERCHANT_PORTAL_ACCOUNTS" || !canEditApplicatinsAndRoles || sysnetDisabled,
        applicationGuid,
        name: <LocalizedText localeKey={assignedApplication.name} />,
        applicationId,
        optionalPermissionIds: targetUserAppData?.optionalPermissionIds ?? [],
        roleId: targetUserAppData?.roleId,
        userApplicationAssignmentId: targetUserAppData?.userApplicationAssignmentId,
        applicationPlanIds,
        newlyAdded,
        status: targetUserAppData?.status,
        targetUserUserid: targetUserAppData?.userId,
        userApps: userApps,
        brands: applicationGuid === "MERCHANT_PORTAL_ACCOUNTS" && targetUser.brands,
      };
    });
    setCurrentUserApps(apps);
  };

  const isAppDisabled = (app: any) => {
    const sysnetDisabled =
      !targetUser.dataAccess?.find((h) => h?.nodes[0]?.selectedChild.label === "9305") &&
      app.applicationGuid === "SYSNET";
    return app.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS" || !canEditApplicatinsAndRoles || sysnetDisabled;
  };

  const handleToggleChange = (checked: boolean, applicationGuid: string) => {
    const changedApps = currentUserApps.map((app: any) => {
      if (app.applicationGuid === applicationGuid) {
        return { ...app, assigned: checked };
      } else {
        return app;
      }
    });
    onUpdate(changedApps);
    setCurrentUserApps(changedApps);
  };

  const onRolePermissionChanged = (selectedApp: any, role: any, optionalPermissionIds: any, brands: any) => {
    const updatedApps = currentUserApps.map((app) => {
      const brandUpdated = !isDeepEqual(brands?.sort(), targetUser.brands?.sort()) && role?.roleType === "INTERNAL" && selectedApp?.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS";
      let updatedApp = { ...app, disabled: isAppDisabled(app) || brandUpdated, brandUpdated };
      if (app.applicationGuid === selectedApp.applicationGuid) {
        return {
          ...updatedApp,
          optionalPermissionIds,
          roleId: role.roleId,
          roleGuid: role.roleGuid,
          assignedApplication: { applicationRoleAssociations: [{ role }] },
          brands,
        };
      }
      return updatedApp;
    });
    setCurrentUserApps(updatedApps);
    onUpdate(updatedApps);
  };

  const showLoader = loading || loadingSubscribedApps || appList?.length === 0;
  return (
    <>
      {showLoader &&
        <div className="relative h-full	w-full -mt-2.5 -ml-2.5">
          <BVLoader20 />
        </div>
      }
      {!showLoader && subscriptionError && 
        <div className="w-[627px] m-5">
          <NotificationCard
            type="error"
            title={<LocalizedText localeKey="createUser.alerts.cannotCreateUserBeforeAdmin.title"/>}
            messageLine1={<LocalizedText localeKey="createUser.alerts.cannotCreateUserBeforeAdmin.message1"/>}
          />
        </div>
      }
      {!showLoader &&           
        <ApplicationsList
          applications={currentUserApps}
          appFilters={appFilters}
          currentUser={loggedInUser}
          targetUser={targetUser}
          handleToggleChange={handleToggleChange}
          onRolePermissionChanged={onRolePermissionChanged}
          canEditApplicatinsAndRoles={canEditApplicatinsAndRoles}
          type="EDIT"
          appUpdated={appUpdated}
          hasFullPortfolioAssigned={hasFullPortfolioAssigned}
          targetHierarchies={targetHierarchies}
        />
    }
    </>
  );
};

export default ApplicationsRolesTab;
