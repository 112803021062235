import React from 'react';
import { ReactComponent as FrameSvg } from "assets/img/Frame.svg";
import { LocalizedText } from 'portal-common-ui';
import ReactDOMServer from 'react-dom/server';

interface ApplicationSuccessModalProps {
  response: string;
  title: string;
  textBody: string;
  image: string; 
}

const imageSvg = (
  <FrameSvg title="Frame of demo analytics" />
);

const ApplicationSuccessModal: React.FC<ApplicationSuccessModalProps> = ({ response, title, textBody, image, removeApplication }) => {
  return (
    <div className="flex flex-row gap-4 p-5 items-center">
      <div className="flex flex-row">
        <div className="flex flex-row gap-4 p-5 items-center">
          <div className="flex flex-row flex-grow">
            <div className="flex-grow">
              <div className="text-theme-success font-roboto font-medium text-xl" >{response}</div>
              <div className="mt-8 text-theme-dark font-bold">
                {(!removeApplication ?
                  (
                    <LocalizedText localeKey="productOfferings.modal.messages.successAdd" templateVariables={{ appName: title}}/>
                  ) :
                  (
                    <LocalizedText localeKey="productOfferings.modal.messages.successRemove" templateVariables={{ appName: title }}/>
                  )
                )}
              </div>
              <div className="mt-14 mb-6 w-1/2 text-theme-dark font-bold">{textBody}</div>
            </div>
            <div className="flex-grow mt-20">
              {imageSvg}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationSuccessModal;