import { PanelDropdown } from "portal-common-ui";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { LocalizedText } from "portal-common-ui";

export interface ICustomCreateUserButton {
    actionButtonPermission: boolean,
    onCreateUser: () => void;
    onCloneUser: () => void;
}

const CustomCreateUserButton: React.FC<ICustomCreateUserButton> = ({ actionButtonPermission, onCreateUser, onCloneUser }) => {
    return (
        <div>
            {actionButtonPermission && (
                <PanelDropdown
                    createUser="createUser"
                    titleKey={'userManagementPage.header.createUser'}
                    containerClass={`min-w-[192px] border border-solid border-gray-300 cursor-pointer text-center font-bold whitespace-nowrap`}
                    utilityClass="text-base btn-alt-dropdown p-2"
                    expandedContainerClass="border-2 top-[-2px] left-0"
                    arrowClass="text-base"
                    buttonIcon={faPlus}
                    buttonIconLabel="create-user-label"
                    buttonClass="h-[40px] w-[40px] btn-alt-dropdown"
                    onClick={onCreateUser}
                >
                    <div className="w-[188px]">
                    <button
                    name="createNewUser"
                    onClick={onCreateUser}
                    className="w-full z-50 bg-theme-on-primary btn my-0 text-theme-primary font-medium hover:bg-theme-info hover:bg-opacity-20 disabled:text-theme-medium text-left text-blue-700 pl-[16px] mx-0 normal-case"
                    data-test="user-management-create-new-user"
                    >
                        <LocalizedText localeKey={'userManagementPage.header.createNewUser'}/>
                    </button>
                    <button
                    name="cloneExistingUser"
                    onClick={onCloneUser}
                    className="w-full z-50 bg-theme-on-primary btn my-0 text-theme-primary font-medium hover:bg-theme-info hover:bg-opacity-20 rounded-none text-left pl-[16px] mx-0 text-blue-700 normal-case"
                    data-test="user-management-clone-user"
                    style={{ borderTop: "1px solid #d7dce1" }}
                    >
                        <LocalizedText localeKey={'userManagementPage.header.cloneExistingUser'} />
                    </button>
                    </div>
                </PanelDropdown>
            )}
        </div>
    )
}

export default CustomCreateUserButton