import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDatabase } from "@fortawesome/pro-light-svg-icons";

import useAlertQueue from 'hooks/alertQueue';
import UserEditorFooter from '../UserViewerEditor/UserEditorFooter';

import 'react-tabs/style/react-tabs.css';
import { isDeepEqual } from "react-use/lib/util";
import GroupInformationTab from "./GroupInformationTab";
import { useDataAccessGroup, Button, ButtonType, LocalizedText, useDateTimeFormats } from 'portal-common-ui';
import {BVLoader20} from "portal-common-ui";

import UsersAssignedTab from "./UsersAssignedTab";
import DataAccessTab from "./DataAccessTab";
import { NavigationWarning } from "portal-common-ui";
import { useUser } from 'portal-common-ui';
import useUserList from 'hooks/userList2';
import { useGlobal } from 'libs/reactn';
import useStateRef from "react-usestateref";

const DataGroupViewerEditor = ({
/*  Below is the raw api response for get group details by  groupId
  "id":"DAG id",
  "name":"DAG name",
  "description":"Description of DAG",
  "creteadByUser": "creteadByUser email",
  "countOfNodes": "countOfNodes",
  “createdDate”:”createdDate”,
  “lastUpdatedDate”:”lastUpdatedDate”,
  “firstName”:”firstName”,
  “lastName”:”lastName”,
  “assoicatedHierarchies” : [list Of Hierarchy] 
 } */
  dataAccessGroupDetails,
  onSaveSuccess,
}) => {
  const [isDAGDetailsChanged,setIsDAGDetailsChanged] = useState(false);
  const user = useUser();
  const {updateDataAccessGroup, isSaving} = useDataAccessGroup();
  const history = useHistory();
  const alertQueue = useAlertQueue();
  const [groupDetails, setGroupDetails] = useState(dataAccessGroupDetails);
  const methods = useForm({
    mode: 'onChange',
  });

  const [isDiscarding, setIsDiscarding] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(null);
  const [allTabsValid, setAllTabsValid] = useState(true);
  const [ignoreWarning, setIgnoreWarning] = useState(false);
  const [tabIndex, setTabIndex] = history.location?.state?.from === 'Add Another User' ? useState(2): useState(0);
  const userList = useUserList();
  const [usersAssignedList, setUsersAssignedList,usersAssignedListRef] = useStateRef([]);
  
  const [updatedDataAccess, setUpdatedDataAccess] = useState(null);
  const [allowFullPortfolioAccess, setAllowFullPortfolioAccess] = useState(false);
  const methodValues = methods.getValues();
  const [auth, setAuth] = useGlobal('auth');
  const { formatDateTime } = useDateTimeFormats();

  const [allowedGroupNames] = useState(auth.session.user?.dags); 


  useEffect(() => {
    history.location?.state?.from === '';
    const formValues = methods.getValues();
    setInitialFormValues(formValues);
  }, [groupDetails]);

  useEffect(() => {
    if (isDiscarding) {
      setGroupDetails(dataAccessGroupDetails);
      methods.reset(initialFormValues);
      setUpdatedDataAccess(null);
      setIsDiscarding(false);
    }
  }, [isDiscarding]);
   
      useEffect(() => {
        userList.setSearchCriteria({
          dagId: groupDetails.groupId
        });
        const fetch = async () => {
          const usersList = await userList.getRows({ request: { startRow: 0, endRow: 100, sortModel: [{colId : 'firstName'}] } });
          setUsersAssignedList(usersList);
        }
        fetch();
      }, []);

  useEffect(() => {
    let methodData = methodValues;
    methodData = {...methodValues};
    setIsDAGDetailsChanged(!isDeepEqual(methodData, initialFormValues) || hasDataAccessChanges());
  }, [updatedDataAccess, methodValues, initialFormValues]);

  const updateList = (deleteUserId) => {
    const newList = usersAssignedListRef.current?.filter(e => e?.userId !== deleteUserId)
    setUsersAssignedList(newList);
  }
  const getDataAccessChanges = () => {
    if (updatedDataAccess) {
      const nodeHierarchies = updatedDataAccess
        .filter(row => row && row?.midHierarchyIds?.length === 0)
        .map(row => row.hierarchyId);

      const midHierarchies = updatedDataAccess
        .filter(row => row && row?.midHierarchyIds?.length > 0)
        .flatMap(row => row.midHierarchyIds);

      const hierarchies = nodeHierarchies.concat(midHierarchies);
      return hierarchies; 
    }
    return null;
  };

  const hasValidChanges = ({newDataAccess}) => {
    const hasDataAccess = newDataAccess ? newDataAccess.length > 0 : 
                          updatedDataAccess ? updatedDataAccess.length > 0 : true; 
    return isValid && hasDataAccess ;
  };

  const hasDataAccessChanges = () => {
    const changes = getDataAccessChanges(); 
    return changes !== null;
  };

  const onDataAccessUpdate = (newDataAccess) => {
    setUpdatedDataAccess(newDataAccess);
    const valid = hasValidChanges({newDataAccess});
    setAllTabsValid(valid);
  }
  const { isValid } = methods.formState;
 

  const hasFormChanges = () => {
    setIsDAGDetailsChanged(!isDeepEqual(methods.getValues(), initialFormValues));
  }

  const showCancelWarning = (continueAction) => {
    alertQueue.enqueueAlert({
      alertType: 'modal',
      title: 'Warning',
      messageType: 'warning',
      continueCaption: 'Yes, Discard',
      continueCaptionKey: "shared.alerts.discardChanges.continueCaption",
      cancelCaption: 'No, Continue Editing',
      cancelCaptionKey: "shared.alerts.discardChanges.cancelCaption",
      continueAction:  () =>  {setIgnoreWarning(true);setIsDiscarding(true);},
      cancelAction: () => { },
      messageKey: "shared.alerts.discardChanges.message"
    });
  };

  const onDiscardChanges = () => {
    showCancelWarning();
  };

  const showError = () => {
    alertQueue.enqueueAlert({ 
      alertType: 'modal', 
      messageType: 'error',
      title: 'Update Data Access Group Error',
      message: 'We\'re sorry, but we were unable to make the updates you requested.  It\'s not you, it\'s us!  Please try again later'
    });
  };
 
  const disableWarning =  isDeepEqual(methods.getValues(), initialFormValues);
  
  const onSaveChanges = async () => {
    const updatedForm = methods.getValues();    
    const updatedGroup = {
      groupId: groupDetails.groupId,
      groupName: updatedForm.groupName?.trim(),
      groupDesc: updatedForm.groupDesc?.trimEnd(),
    };

    try {
      const resp = await updateDataAccessGroup(updatedGroup, groupDetails, updatedDataAccess);
       if (resp.status !== 201) {
        showError();
      } else {       
        onSaveSuccess();
        setIgnoreWarning(true);
        history.push(`/data-access-management`);      
      }
    }
    catch(err) {
      console.log(err);
      showError();
    }
  };
  
  const goBack = () => {
    history.push(`/data-access-management`);   
  };

  const onClickBackButton = () => {
    goBack();
  };

  const HeaderField = ({ label, value, noSeparator = false, children, labelKey }) => (
    <div className={`flex flex-col text-theme-medium min-h-[35px] px-6 ${noSeparator ? '' : 'border-r border-theme-light-border'}`}>
      <div className="text-xs font-normal pb-1 t-xl:pb-2">{labelKey ? <LocalizedText localeKey={labelKey}/> : label}</div>
      <div className="text-xs font-semibold text-theme-medium">{value}</div>
      {children}
    </div>
  );
const modifiedDate = groupDetails.lastUpdatedDate === null ? <LocalizedText localeKey="createUser.sections.applicationRoles.form.notApplicable"/> : formatDateTime(groupDetails.lastUpdatedDate)?.formattedDate + " " + formatDateTime(groupDetails.lastUpdatedDate)?.formattedTime;
const creationDate = groupDetails.createdDate === null ? <LocalizedText localeKey="createUser.sections.applicationRoles.form.notApplicable"/> : formatDateTime(groupDetails.createdDate)?.formattedDate;

const canEditGroupBasicInfo = user.authenticatedUser.email === groupDetails.createdByEmail ;

return (
    <>
     {isSaving && 
    <div className="relative min-h-[600px] min-w-full">
      <BVLoader20 />
    </div>
    }
      <div className={`${isSaving ? 'invisible' : ''}`} data-test= "data-access-management-view-edit-page">
        <div className="flex items-center justify-start bg-theme-on-primary min-h-[56px] t-xl:min-h-[72px] min-w-[1150]  mr-[-16px] ml-[-16px] mt-[-16px] xl:mr-[-30px] xl:ml-[-30px] t-xl:mt-[-30px] gap-4 xl:gap-8 border-theme-light-background border">
          <div className="pl-4 xl:pl-8">
            <Button
              id="button-back"
              buttonType={ButtonType.SecondaryButton}
              onClick={onClickBackButton}
              child={<FontAwesomeIcon icon={faArrowLeft} className="font-light text-base" />}
            />
          </div>
          <div className="text-2xl font-medium text-theme-dark min-w-[105px]" data-test="my-profile-cy">
            {groupDetails.name}
          </div>

        </div>
        <div className="flex">
          <FormProvider {...methods}>
            <section className="w-full min-w-[895px] xl:min-w-[1100px] max-w-[1780px]" data-test="view-dag-form-cy">
              <div className="flex items-start pt-2 t-xl:pt-4 justify-start bg-theme-on-primary my-3 t-xl:my-5 h-14 t-xl:min-h-[77px] border-theme-light-border border">
                <FontAwesomeIcon icon={faDatabase} size="2x" className="mx-6 text-theme-info mt-1 t-xl:mt-2" />
                <HeaderField label={'GROUP OWNER'} labelKey= "editDAG.header.groupOwner" value={groupDetails.createdByFirstName + " " + groupDetails.createdByLastName} />
                <HeaderField label="CREATION DATE" labelKey="editDAG.header.createDate" value={creationDate} />
                <HeaderField label="LAST MODIFIED" labelKey="editDAG.header.lastModified" value={modifiedDate} noSeparator />
              </div>
              <div id="tier-dropdown-portal" className="relative">
              </div>
              <div className="pt-0 t-xl:pt-2">
                <Tabs selectedTabClassName="react-tabs__tab--selected text-theme-dark"  selectedIndex={tabIndex} onSelect={(index) =>{setTabIndex(index)}} >
                  {/* <div className="border border-solid border-gray-200 h-16 absolute w-full border-b-0"/> */}
                  <TabList>
                    <Tab><LocalizedText localeKey="editDAG.sections.groupInfo.header"/></Tab>
                    <Tab><LocalizedText localeKey="createUser.sections.dataAccess.title"/></Tab>
                    <Tab data-test="data-access-management-user-assiged-tab"><LocalizedText localeKey="editDAG.sections.userAssigned.header"/></Tab>                    
                  </TabList>

                  <TabPanel forceRender>
                    <GroupInformationTab dataAccessGroupInfo={groupDetails} canEditGroupBasicInfo={canEditGroupBasicInfo} hasFormChanges={hasFormChanges} allowedGroupNames={allowedGroupNames}/>
                  </TabPanel>

                  <TabPanel forceRender>
                  {!isDiscarding && 
                   <DataAccessTab 
                      groupDetails={groupDetails}
                      onUpdate={onDataAccessUpdate}
                      allowFullPortfolioAccess={allowFullPortfolioAccess}
                      canEdit={canEditGroupBasicInfo} />
                  }
                  </TabPanel>

                  <TabPanel>
                    <UsersAssignedTab dataAccessGroupInfo={groupDetails} 
                    usersAssignedList={usersAssignedListRef.current}
                    setUsersAssignedList={setUsersAssignedList}
                    updateList={updateList}
                   />
                  </TabPanel>

                </Tabs>
              </div>
              {tabIndex !== 2 && canEditGroupBasicInfo &&
                <div>
                  <UserEditorFooter hasFormChanges={isDAGDetailsChanged} isValid={isValid && allTabsValid} isSaving={isSaving} onDiscardChanges={onDiscardChanges} onSaveChanges={onSaveChanges}/>
                </div>
              }
            </section>

        
          </FormProvider>

         { !ignoreWarning && !isDiscarding && <NavigationWarning
          warningLine1="Are you sure you wish to discard your changes without saving?"
          continueCaption="Yes, Cancel"
          cancelCaption="No, Return to wizard"
          disableWarning={disableWarning}
          skipWarningRouteList={['/loggedOut']}
      /> }
        </div>
      </div>
    </>
  )
};

export default DataGroupViewerEditor;