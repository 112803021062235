import React from 'react';
import { ModalProps, Product } from './types';
import { LocalizedText, useLocale } from 'portal-common-ui';

const formatPrice = (price: number, frequency: string) => {
  const freq = frequency === 'MONTHLY' ? 'mo' : frequency;
  return frequency === 'MONTHLY' ? <LocalizedText localeKey="productOfferings.modal.table.price" templateVariables = {{amount : price}}/> : `$${price}/${freq}`;
};

const ProductRow: React.FC<{ product: Product, productTitle: string, isLast: boolean }> = ({ product, productTitle, isLast, removeApplication }) => (
  <div className={`flex product-table mx-2 ${!isLast ? 'border-b border-theme-disabled' : ''}`}>
    <div 
      className={`py-5 w-full text-sm font-normal ${product.name === productTitle && !removeApplication ? 'text-theme-primary' : 'text-theme-disabled'}`}
    >
      {product.name}
    </div>
    <div className={`py-5 text-sm font-normal whitespace-nowrap ${product.name === productTitle && !removeApplication ? 'text-theme-primary' : 'text-theme-disabled'}`}>{formatPrice(product.price, product.frequency)}</div>
  </div>
);


const Header: React.FC<{ title: string }> = ({ title }) => (
  <div className="thead bg-theme-light-background">
    <div className="py-2 border-b border-theme-disabled w-full font-bold">
      <div className="ml-2 text-theme-dark">{title}</div>
    </div>
  </div>
);

const Body: React.FC<{ products: Product[], productTitle: string }> = ({ products, productTitle, removeApplication }) => (
  <div className="tbody">
    <div className='product-table flex flex-col pl-0'> 
      {products.map((product, index) => <ProductRow key={product.name} product={product} productTitle={productTitle} removeApplication={removeApplication} isLast={index === products.length - 1}/>)}
    </div>
  </div>
);

const Footer: React.FC<{ totalCost: number, title: string }> = ({ totalCost, title }) => {
  const {translateToString} = useLocale();
  const colorClass = title === translateToString("productOfferings.modal.table.newHeader") ? 'text-theme-success' : 'text-theme-dark';
  const fontWeightClass = title === translateToString("productOfferings.modal.table.newHeader") ? 'font-bold' : 'font-medium';
  return (
    <div className="tr flex mx-2 border-t border-theme-disabled">
      <div className={`py-2 w-full text-sm ${colorClass} ${fontWeightClass}`}>{title === translateToString("productOfferings.modal.table.newHeader") ? translateToString("productOfferings.modal.table.newCost") : translateToString("productOfferings.modal.table.currentCost")}</div>
      <div className={`py-2 text-sm whitespace-nowrap ${colorClass} ${fontWeightClass}`}>{formatPrice(parseFloat(totalCost.toFixed(2)), 'MONTHLY')}</div>
    </div>
  );
};

const ProductTable: React.FC<ModalProps> = ({ title, products, productTitle, removeApplication }) => {
  const totalCost = products.reduce((sum, product) => sum + product.price, 0);

  return (
    <div className="table-auto w-1/2 border border-theme-disabled flex flex-col justify-between">
      <div>
        <Header title={title} />
        <Body products={products} productTitle={productTitle} removeApplication={removeApplication}/>
      </div>
      <Footer totalCost={totalCost} title={title} />
    </div>
  );
};

export default React.memo(ProductTable);