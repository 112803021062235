import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType, LocalizedText } from "portal-common-ui";
import ProductModal from 'components/ProductSelectionModal/ProductModal';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from 'react-router-dom';
import { faArrowLeft, faCircleInfo, faStar } from '@fortawesome/pro-light-svg-icons';
import { useGlobal } from 'reactn';
import { useModali } from "modali";
import { useProductModalStore } from "store/productModalStore";

interface TableProps {
  children: any;
  type?: string;
  className?: string;
  borderClass?: string;
}

const UpgradeTag = (): JSX.Element => {
  return (
      <div className="bg-theme-info-background h-8 p-2 flex items-center text-xs text-theme-info">
          <span className="text-lg mr-2"><FontAwesomeIcon icon={faStar} size="xs" /></span>
          <LocalizedText localeKey="productOfferings.currentSubscription.upgradeAvailable"/>
      </div>
  );
};

const TableHeader = ({ children, className = "", borderClass = "" }: TableProps): JSX.Element => {
  return <th className={`py-2.5 px-5 font-semibold ${className}`}>{children}<div className={`sticky inline top-0 bottom-0 ${borderClass}`}></div>
  </th>;
};

const TableCell = ({ children, type, className = "" }: TableProps): JSX.Element => {
  return <td className={`py-2.5 px-5 font-medium ${className}`}>{children}</td>;
};

const TableRow = ({ children }: TableProps): JSX.Element => {
  return <tr className="h-16 border-t border-b">{children}</tr>;
};

type ProductData = {
  offeringId: string;
  offeringType: 'base' | 'upgrade' | 'bundle' | 'upgradeBundle';
  offeringName: string;
  offeringDescription: string;
  offeringPricing: {
    price: number,
    currency: string,
    frequency: string
  };
  isSubscribed: boolean;
  subscriptionId: string;
  isUpgrade: boolean;
  offeringGroupId: string;
  offeringPriorityInGroup: number;
  offeringCreationDateTime: string;
};

type PlanRowProps = {
  plan: ProductData;
  openModal(remove: boolean, plan: ProductData): void;
};

const CurrentSubscriptions: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const { setModalData } = useProductModalStore();
  const [subscribedPlans, setSubscribedPlans] = useState<ProductData[]>([]);
  const [cachedData] = useGlobal<ProductData[]>('offeringData'); // Retrieve offeringData from global state
  const [planModal, togglePlanModal] = useModali({ large: true, centered: true, overlayClose: false, keyboardClose: false });

  useEffect(() => {
    filterSubscribedPlans();
  }, [cachedData]);

  const openModal = (remove: boolean, plan: any, ) => {
    setModalData({
      buttonTitle: remove ? "Confirm Removal" : "Upgrade",
      title: plan.offeringName,
      offeringData: cachedData,
      myCurrentSubsription: true,
      removeApplication: !!remove,
    });
    togglePlanModal();
  };

  const PlanRow = ({ plan, openModal }: PlanRowProps) => {
    return (
      <TableRow>
        <TableCell>
          <div className="float-right mr-7 whitespace-nowrap flex">
            {hasUpgradeAvailable(plan.offeringGroupId, plan.offeringPriorityInGroup) ? (
              <Button
                buttonType={ButtonType.PrimaryButton}
                title="Upgrade"
                titleKey="productOfferings.mainPage.buttons.upgrade"
                onClick={() => openModal(false, plan)}
              />
            ) : (
              <Button
                buttonType={ButtonType.PrimaryButton}
                title="Upgrade"
                titleKey="productOfferings.mainPage.buttons.upgrade"
                buttonClass={"invisible"}
              />
            )}
            <Button
              title="Remove"
              titleKey="productOfferings.mainPage.buttons.remove"
              testDataAttribute="remove-product-popup"
              buttonClass="text-theme-danger font-roboto font-large"
              onClick={() => openModal(true, plan)}
            />
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const filterSubscribedPlans = () => {
    const subscribedPlans: ProductData[] = cachedData.filter((plan: ProductData) => plan.isSubscribed === true)
    setSubscribedPlans(subscribedPlans);
  }

  const calculateTotalCost = (subscribedProducts: ProductData[]): number => {
    let totalPrice = 0;

    for (const product of subscribedProducts) {
      totalPrice += product.offeringPricing.price;
    }
    return parseFloat(totalPrice.toFixed(2));
  };

  const hasUpgradeAvailable = (planGroupId: string, planPriority: number): boolean => {
    return cachedData.some((element: ProductData) => element.offeringGroupId === planGroupId && element.offeringPriorityInGroup > planPriority);
  }

  return (
    <div>
      <div className="flex items-start">
        <Button
          id="button-back"
          buttonType={ButtonType.SecondaryButton}
          onClick={history.goBack}
          className="btn btn-secondary-alt mt-0 mb-0 min-w-[50px] text-{nextButtonCaption}xl py-1.5 px-1 border-theme-light-border border-1"
          child={<FontAwesomeIcon icon={faArrowLeft} className='font-light text-base' />}
        />
        <h2 className="text-2xl font-medium text-theme-dark min-w-[105px] left">
          <LocalizedText localeKey="productOfferings.currentSubscription.title"/>
        </h2>
      </div>
      <div>
        {subscribedPlans.length > 0 ?
          <table className="border border-theme-light-border bg-white">
            <thead>
              <tr className="text-left">
                <TableHeader className="w-[500px] max-w-[500px]" borderClass="border border-l left-3/4"><LocalizedText localeKey="productOfferings.currentSubscription.headers.name"/></TableHeader>
                <TableHeader className="min-w-[200px]" borderClass="border border-l left-3/4"><LocalizedText localeKey="productOfferings.currentSubscription.headers.cost"/></TableHeader>
                <TableHeader className="min-w-[250px]"><LocalizedText localeKey="productOfferings.currentSubscription.headers.actions"/></TableHeader>
              </tr>
            </thead>
            <tbody>
              {subscribedPlans.map((plan: any) => (
                <TableRow>
                  <>
                    <TableCell
                      className="text-theme-primary"
                      type="name"
                    >
                      <div className="flex items-center">
                        {plan.offeringNameKey ? <LocalizedText localeKey={plan.offeringNameKey}/>: plan.offeringName}
                        {hasUpgradeAvailable(plan.offeringGroupId, plan.offeringPriorityInGroup) &&
                          <span className="ml-auto">
                            <UpgradeTag/>
                          </span>
                        }
                      </div>
                    </TableCell>
                    <TableCell className="text-theme-secondary font-medium">
                      ${plan.offeringPricing.price}
                    </TableCell>
                    <PlanRow
                      plan={plan}
                      cachedData={cachedData}
                      openModal={openModal}
                    />
                  </>
                </TableRow>
              ))}
            </tbody>
            <tfoot>
              <tr className="text-left h-16 text-lg">
                <TableCell className="w-[500px] max-w-[500px]"><LocalizedText localeKey="productOfferings.currentSubscription.monthlyTotal"/></TableCell>
                <TableCell className="min-w-[200px] text-theme-info">${subscribedPlans.length > 0 ? calculateTotalCost(subscribedPlans) : 0}</TableCell>
              </tr>
            </tfoot>
          </table>
          :
          <div className="flex gap-4 w-full mid-lg:w-[48rem] bg-[#E5F4FF] text-base p-4 border border-solid">
            <FontAwesomeIcon icon={faCircleInfo} size="2x" style={{ color: "#003C66", }} />
            <div>
              <p className="pb-2 font-bold"><LocalizedText localeKey="productOfferings.infoBanners.noSubscriptions.title"/></p>
              <p className="font-normal"><LocalizedText localeKey="productOfferings.infoBanners.noSubscriptions.message"/></p>
            </div>
          </div>
        }
      </div>
      <ProductModal toggleModal={togglePlanModal} productModal={planModal} />
    </div>
  )
}

export default CurrentSubscriptions