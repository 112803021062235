import React, { useEffect } from 'react'
import config from 'config/config';
import errorPageIcon from '../../assets/img/icon-sad.png';
import { useHistory } from 'react-router-dom';
import useFeatureFlags from 'hooks/utils/featureFlags';
import usePolicyLinks from 'hooks/utils/policyLinks';
import { LocalizedText, useLocale } from 'portal-common-ui';

export interface ErrorPageProps {
  errorPageType: string;
}

const ErrorPage:React.FC<ErrorPageProps> = ({ errorPageType}) => {
  const history = useHistory();
  let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails') || '{}');
  const brandLogo = brandDetails.logo;
  const translationPrefix = `ErrorPage.${errorPageType}`;
  const copyright = `common.${config.activeBrand.copyright}`;
  const htmlTitleKey = `common.${brandDetails.htmlTitle}`;
  const { translateToString } = useLocale();
  const { accountsPrivacyTermsLinksEnabled } = useFeatureFlags();
  const userLocale = localStorage.getItem('userLocale') || 'en-US';
  const { privacyPolicyLink, termsOfUseLink, regulatoryDisclosuresLink } = usePolicyLinks(userLocale);

  const titleDefault = <LocalizedText localeKey="ErrorPage.Error.Title" />
  const subTextDefault = <LocalizedText localeKey="ErrorPage.Error.SubText" />;
  const errorCodeDefault = <LocalizedText localeKey="ErrorPage.Error.ErrorCode" />;

  // todo: fix typescript errors
  const title = translateToString(`${translationPrefix}.Title`) ? <LocalizedText localeKey={`${translationPrefix}.Title`} /> : titleDefault;
  const subText = translateToString(`${translationPrefix}.SubText`) ? <LocalizedText localeKey={`${translationPrefix}.SubText`} /> : subTextDefault;
  const errorCode = translateToString(`${translationPrefix}.ErrorCode`) ? <LocalizedText localeKey={`${translationPrefix}.ErrorCode`} /> : errorCodeDefault;
  
  let htmlTitle = `${translateToString(htmlTitleKey)}`;
  document.title = htmlTitle ? htmlTitle : 'Account Error Page';

  return (
    <div className="error-page-container h-full font-normal w-screen flex flex-col overflow-auto">
      <header className={`flex lrg:justify-center ${config.activeBrand.isWhiteLabel ? 'invisible' : ''} p-12`}>
        {<img src={brandLogo} className='w-logo-page lrg:w-60 h-[35px]' alt="Brand Logo" />}
      </header>
      <main className='flex flex-col justify-center items-center flex-1 p-8'>
        <img src={errorPageIcon} className="lrg:w-18" alt="Error Icon" />
        <h1 className="lrg:text-2xl 2xl:w-2/6 lg:w-1/2 text-center text-theme-secondary mb-10 mt-8">
          {title}
        </h1>
        <p className="text-theme-medium mb-6">{errorPageType === 'UnknownEmployee' ? config.activeBrand.unknownUserMessage : subText}</p>
          <span className="flex items-center text-theme-danger font-bold px-4 bg-theme-danger bg-opacity-5" >{errorCode}</span>
        <p className="text-theme-medium mt-10 mb-10"><LocalizedText localeKey="ErrorPage.apologize" /></p>
        <a onClick={() => history.push('/')} role="button" className="btn btn-primary btn-alt p-1 pl-2">
          <span className="mt-0 w-1/2 p-2 cursor-pointer"><LocalizedText localeKey="ErrorPage.returnToLogin" /></span>
        </a>
      </main>
      <footer className="flex flex-col lrg:items-center text-theme-medium m-10 font-light">
        <div>&#169; <LocalizedText localeKey={copyright} /></div>
        {accountsPrivacyTermsLinksEnabled && <div>
          <a className={`hover:underline ${!privacyPolicyLink ? "invisible" : ""}`} href={privacyPolicyLink} target="_blank" rel="noopener noreferrer"><LocalizedText localeKey="footer.links.privacyPolicy" /></a>
              <span className={`px-4 ${!(privacyPolicyLink && termsOfUseLink) ? "invisible" : ""}`}> | </span>
          <a className={`hover:underline ${!termsOfUseLink ? "invisible" : ""}`} href={termsOfUseLink} target="_blank" rel="noopener noreferrer"><LocalizedText localeKey="footer.links.termsOfUse" /></a>
              <span className={`px-4 ${((privacyPolicyLink || termsOfUseLink) && regulatoryDisclosuresLink) ? "" : "invisible"}`}> | </span>
          <a className={`hover:underline ${!regulatoryDisclosuresLink ? "invisible" : ""}`} href={regulatoryDisclosuresLink} target="_blank" rel="noopener noreferrer"><LocalizedText localeKey="footer.links.regulatoryDisclosures" /></a>
        </div>
        }
      </footer>
    </div>
  )
};

export default ErrorPage