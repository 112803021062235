import React, { useState, useEffect } from "react";
import { useGlobal } from 'libs/reactn';
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import { ColumnFunctionCallbackParams } from 'ag-grid-community';
import { gridInterfaces, filter } from "portal-common-ui/dist/interfaces";
import {
  FilterBar,
  Grid,
  GridHeader,
  useLocale
} from "portal-common-ui";
import 'portal-common-ui/dist/portal-common-ui.css';
import useMerchantList from 'hooks/use-merchant-list';
import useFeatureFlags from "hooks/utils/featureFlags";
import { useApi } from 'portal-common-ui';
import config from 'config/config';
import { localeKeys } from "typings/translate";
import { isMobile, isDesktop, isMobileOnly} from 'react-device-detect';

const MerchantDemographics: React.FC = () => {
  const history = useHistory();
  const { translateToString } = useLocale();
  const { accountsPrivacyTermsLinksEnabled } = useFeatureFlags();
  const [filtersReceived, setFiltersReceived] = useState<boolean>(false);
  const [filtersUpdatedToggle, setFiltersUpdatedToggle] = useState<boolean>(false)
  const [, setFilters, filtersRef] = useStateRef<{[key: string]: any}>({});
  const [, setOmniSearch, omniSearchRef] = useStateRef<string[]>([]);
  const [ totalRowCount, setTotalRowCount ] = useGlobal('totalRowCount');
  const [loading, setLoading] = useState(false);
  const userUrl = config.API.userUrl2;
  const relativeUrl = '/hierarchy/profile/merchants/value';
  const { transformRow, sortMap, loaderColumnDefs } = useMerchantList();
  const options = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const merchantDemoApi = useApi(userUrl, options);
 

  const getQueryString = (filtersObj: {[key: string]: any}, searchStr: string) => {
    let queryString = `searchValueForDBAName=${searchStr}`;
    queryString += Object.keys(filtersObj).reduce((qs, key) => qs + `&${key}=${filtersObj[key]}`, '');

    // add required params if needed
    queryString = queryString.includes('searchValue=') ? queryString : queryString ? 'searchValue=&' + queryString: 'searchValue=';
    queryString = queryString.includes('searchValueForGroup=') ? queryString : queryString ? 'searchValueForGroup=&' + queryString: 'searchValueForGroup=';
    return queryString
  }

  const serverDatasource: gridInterfaces.IServerDatasource = {
    url: `${userUrl}${relativeUrl}`,
    reqType: 'get',
    sortMap,
    transformRow,
    queryParams: getQueryString(filtersRef.current, omniSearchRef.current.join(' ')),
    reqBody: {},
    filters: filtersRef.current,
    filtersUpdatedToggle,
  }

  useEffect(() => {
    const viewSingleRow = async () => {
      setLoading(true);
      await merchantDemoApi.get(`${relativeUrl}?searchValueForGroup=&searchValue=&searchValueForDBAName=&page=1&pageSize=100`);
      setLoading(false);
      if (merchantDemoApi.response.ok) viewGroup(merchantDemoApi.response?.data?.content?.results[0]);
    }
    if (totalRowCount === 1 && filtersReceived && !Object.keys(filtersRef.current)?.length && !omniSearchRef.current.length) viewSingleRow();
    
  }, [totalRowCount]);

  const viewGroup: (rowData: ColumnFunctionCallbackParams['data']) => void = (rowData) => {
    history.push({
      pathname: `/merchant-demographics/view/${rowData.hierarchyId}`,
      state: { 
        hideBackButton: totalRowCount === 1,
        midFilterApplied: filtersRef.current.searchValue ? true : false
      }
    });
  }

  const getActionItems: gridInterfaces.IActionsColumn['getActionItems'] = (data: ColumnFunctionCallbackParams['data']) => {

      return [
          {
              iconClass: `${isDesktop ? 'fa fa-regular' : 'fa fa-regular fa-eye'} text-theme-info`,
              captionKey: `${isDesktop ? 'merchantDemographicsPage.actions.viewDetails' : '' }`,
              captionClass: 'text-theme-info',
              handler: viewGroup,
          }
      ]
  }

  
  const onFiltersChanged = (newFilters: filter.IFilterData[], newOmniSearch: string[]) => {
    if (!filtersReceived) setFiltersReceived(true);
    setFilters(newFilters);
    setOmniSearch(newOmniSearch.filter(str => !!str));
    setFiltersUpdatedToggle(!filtersUpdatedToggle);
  }


  const filterOptions: { nameKey: localeKeys, [key: string]: any }[] = [
    {
      id: 1,
      nameKey: "merchantDemographicsPage.filterBar.filters.mid",
      type: "TEXT",
      field: "searchValue",
      placeholder: "MID",
      regex: "^[0-9]*$",
      errorMessageKey: "merchantDemographicsPage.filterBar.filters.errors.onlyNumbers"
    },
    {
      id: 2,
      nameKey: "merchantDemographicsPage.filterBar.filters.groupNumber",
      type: "TEXT",
      field: "searchValueForGroup",
      placeholder: "Group #",
      regex: "^[0-9]*$",
      errorMessageKey: "merchantDemographicsPage.filterBar.filters.errors.onlyNumbers"
    },
    
  ];

  const onGridLoadingChange = (gridState: { [key: string]: any }) => setTotalRowCount(gridState.totalRowCount);

  const renderCustomMerchantName = (data: any) => {
    return (
      <div>
        <div className="font-medium text-sm">
            {data.merchantName}
        </div>
        <div>
            {data.mid}
        </div>
    </div>
    );
  };

  return (
    <div>
      <GridHeader 
        headingKey="homepage.sidebar.merchantDemographics"
        rowCount={false}
        actionButtonPermission={false}
      />
      <FilterBar
        filterOptions={filterOptions}
        onClearHandler={() => setFilters([])}
        onSearchHandler={onFiltersChanged}
        omniSearchEnable={true}
        omniSearchProps={{placeholder: translateToString(isDesktop ? 'merchantDemographicsPage.filterBar.omnisearch.searchBy' : 'merchantDemographicsPage.filterBar.omnisearch.searchByName'), allowedSpaces: 5, regex: "^[a-zA-Z0-9 ]*$", minChars: 1, errorMessageKey:"common.noSpecialChars"}}
        className="ag-grid__filter demographic_filter bg-theme-on-primary border-b-0 border border-solid border-gray-300 sml:border-none"
        retainFilters
      >
      </FilterBar>
      {filtersReceived &&
        <Grid
          rowModelType="serverSide"
          serverDatasource={serverDatasource}
          rowIdFieldName="groupId"
          wrapperClass={`ag-grid__container w-full ${accountsPrivacyTermsLinksEnabled ? 'h-grid-height-for-footer' : ''} grid-common-ag-grid bg-theme-on-primary border border-solid border-gray-300 rounded-sm`}
          agGridClassName={`ag-grid ${accountsPrivacyTermsLinksEnabled ? '' : 'ag-grid-height'} w-full overflow-auto`}
          sortDefault="merchantName"
          onGridLoadingChange={onGridLoadingChange}
          loaderColumnDefs={loaderColumnDefs}
          loading={loading}
          paginationClass="ag-grid__footer flex justify-between items-center p-4 bg-white border border-solid border-white ring-1 ring-gray-300"
        >
          <Grid.CustomColumn
            field='merchantName'
            headerNameKey="midSelector.columns.merchantName"
            resizable
            accentuate
            minWidth={250}
            customComponent={renderCustomMerchantName}
            hide={isDesktop}
          />
          <Grid.TextColumn
            field='merchantName'
            headerNameKey="merchantDemographicsPage.columns.merchantName"
            resizable
            accentuate
            minWidth={250}
            hide={isMobile}
          />
          <Grid.TextColumn
            field='mid'
            headerNameKey="merchantDemographicsPage.filterBar.filters.mid"
            resizable
            minWidth={250}
            hide={isMobile}
          />
          <Grid.TextColumn
            field='groupNumber'
            headerNameKey="merchantDemographicsPage.columns.groupNumber"
            resizable
            minWidth={250}
            hide={isMobileOnly}
          />
          <Grid.ActionsColumn
            field='actions'
            headerNameKey="merchantDemographicsPage.columns.actions"
            minWidth={140}
            maxWidth={150}
            singleClickEdit
            pinned='right'
            suppressMovable
            getActionItems={getActionItems}
          />
          <Grid.Pagination />
        </Grid>
      }
    </div>
  );
};

export default MerchantDemographics;
