import React, { useState, useEffect} from "react";
import StepWizard from 'react-step-wizard';
import Select from 'react-select';
import { useFormContext } from "react-hook-form";
import { METHODS } from "http";
import { LocalizedText, useLocale } from "portal-common-ui";

const FormSelect = ({
  disabled = false,
  containerClass,
  inputClass,
  inputContainer = '',
  name,
  label,
  labelKey,
  labelPosition,
  options,
  defaultOption,
  onSelection,
  required,
  customValidate,
  isMulti = false,
  placeholder,
  sorted = true,
  locale = 'en-US',
  ...props
}) => {

  const [isVisited, setIsVisited] = useState(false);
  const {register, handleSubmit, watch, formState: { errors }, trigger, reset, getValues, setValue} = useFormContext();
  const containerClasses = `flex flex-col ${containerClass}`;
  const inputClasses = `${required ? 'required' : ''} flex-1 h-full bg-theme-on-primary border border-gray-300 text-gray-600 p-2 ${inputClass}`;
  const thisInput = register(name, { required: false, validate: customValidate });
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const { translateToString } = useLocale();
  const selectLabel = labelKey ? <LocalizedText localeKey={labelKey} /> : label
  const customOnChange = (val) => {
    onSelection(val);
    setSelectedOption(val);
    setValue(name, val);
    trigger(name);
  };
  React.useEffect(() => {
    setSelectedOption(defaultOption);
    onSelection(defaultOption);
    setValue(name, defaultOption);
  }, [options]);

  const customOnBlur = (e) => {
    trigger(name);
    thisInput.onBlur(e);
  };

  const customStyles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: '#F1F2F4' } : { ...base, backgroundColor: '#F2F9FF' };
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: '500', color: '#474B57', paddingRight: 6 }
        : { ...base, fontWeight: '500', color: '#005C9E', paddingRight: 6 };
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
    menuList: (provided) => ({
      ...provided,
      'maxHeight': '250px',
    }),
    option: (provided, state) => {
     const providedStyle = state.isSelected ? { ...provided, backgroundColor: 'transparent', color: 'inherit'} : provided; 
     return {
      ...providedStyle,
      'borderBottom': '1px solid #D7DCE1',
     }
    }
  };

  const sortByKey = (array, key = 'labelKey', order = 'asc') => {
    const getOptionLabel = (o) => key === 'labelKey' && o.labelKey ? translateToString(o.labelKey) : o[key]; 
    return array?.sort((a, b) => {
      const comparison = getOptionLabel(a)?.localeCompare(getOptionLabel(b), locale, { sensitivity: 'accent', ignorePunctuation: true, numeric: true });
      return (order === 'desc') ? (comparison * -1) : comparison;
    });
  }

  const handleOnChange = (selected, option) => {
    if (isMulti) {
      const { action, removedValue, removedValues } = option;
      if (selected.length && selected.find(option => option.value === "all")) {
        selected.length === options.length ? customOnChange(selected.filter(v => v.isFixed)) : customOnChange(sortByKey(options.slice(1), 'isFixed', 'desc')); 
      } else if ((action === 'remove-value' || action === 'pop-value') && removedValue && removedValue.isFixed) {
        return;
      } else if (action === 'deselect-option' && option.option && option.option.isFixed) {
        return;
      } else if (action === 'clear' && removedValues) {
        customOnChange(removedValues.filter(v => v.isFixed)); 
      } else {
        customOnChange(selected)
      }
    } else {
      customOnChange(selected)
    }
  };

  const sortedOptions = (sorted && options) ? sortByKey([...options]) : options;

  if (getValues(name) === undefined) {
    setValue(name, null);
  }

  useEffect(() => {
    if (options && options.length && defaultOption) {
      if (options.find(o => o.value == defaultOption.value)) {
        setSelectedOption(defaultOption);
        onSelection(defaultOption);
        setValue(name, defaultOption);
      }
    }  
  }, [defaultOption, options]);

  const filterOption = (option, rawInput) => {
    const searchTerm = rawInput.toLowerCase();
    const optionValue = option.data.labelKey ? translateToString(option.data.labelKey).toLowerCase() : option.data?.label.toLowerCase();
    return optionValue.includes(searchTerm);
  }

  return (
    <div className={containerClasses}>
      <div>
      {selectLabel && (
          <>
            <label className="text-xs font-medium leading-tight text-theme-dark" htmlFor={name}>
              {selectLabel}
            </label>
            {required && <span className="text-red-400 ml-1 text-xs">*</span>}
          </>
        )}
      </div>
      <div style={{position:"relative"}}>
        {errors[name] && errors[name].type === 'required' && <span className="form-error">{selectLabel}{' cannot be blank'}</span>}
        {errors[name] && errors[name].type === 'validate' && <span className="form-error">{'Invalid '}{selectLabel}</span>}
        <div className={`h-10 max-h-10 flex-1 w-96 sml:w-full ${inputContainer}`} data-test="form-select-cy">  
          <Select 
            name={name}
            width='70px'
            isDisabled={disabled ? 'disabled' : ''}
            inputId={props.inputId}
            placeholder={placeholder} 
            value={selectedOption}
            options={sortedOptions}
            maxMenuHeight={5}
            className={`flex-1 max-h-10 text-sm font-normal leading-snug text-theme-dark w-96 sml:w-full lrg:w-auto ${inputClass}`}
            styles={customStyles}
            onChange={handleOnChange}
            onBlur={customOnBlur}
            innerRef={thisInput.ref}
            isMulti={isMulti}
            filterOption={filterOption}
            {...props}
          />      
        </div>
      </div>
    </div>
  );
};

export default FormSelect;
