import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import FormField from "components/Forms/FormField";
import FormTextArea from "components/Forms/FormTextArea";
import useStateRef from 'react-usestateref';
import { validateDAGInfo } from "utils/Helper";
import { LocalizedText, useLocale } from "portal-common-ui";

const GroupInformationTab = ({dataAccessGroupInfo, canEditGroupBasicInfo, hasFormChanges, allowedGroupNames}) => {

  const methods = useFormContext();
  const [, setDuplicateGroupName ] = useStateRef(false);

  const {translateToString} = useLocale();

  const validateName = (input, field) => {
    const nameTest = validateDAGInfo(input, field);
    hasFormChanges();
    if (allowedGroupNames.filter(m => m !== dataAccessGroupInfo?.name?.toLowerCase()).includes(methods.getValues()?.groupName?.toLowerCase())) {
      setDuplicateGroupName(true);
      return translateToString("createDAG.warnings.groupNameExist");
    } else {
      setDuplicateGroupName(false);
    }
    if (!nameTest) {
      if(field==="Group Name")
        return translateToString('createDAG.sections.basicInfo.errors.invalidGroupName')
      else if(field==="Group Description")
        return translateToString('createDAG.sections.basicInfo.errors.invalidGroupDes')    
    }
  }
  return (
    <div className="bg-theme-on-primary rounded-sm border-gray-100 min-h-full">
      <div className="text-base font-medium text-theme-dark pb-9 pt-2">
        <LocalizedText localeKey="editDAG.sections.groupInfo.header"/>
      </div>
      
            <div className="flex flex-row flex-wrap text-theme-medium space-y-5 items-start justify-start pb-8 w-[620px] xl:w-[785px]" style={{ height: 148 }}>
              <FormField 
              name="groupName" 
              label="Group Name"  
              labelKey="dataAccessManagementPage.columns.groupName"
              defaultValue={dataAccessGroupInfo.name}
              placeholder={translateToString("createDAG.sections.basicInfo.groupPlaceholder")} 
              required maxLength={64} 
              disabled={!canEditGroupBasicInfo}
              inputClass="text-sm font-normal w-72 xl:w-[471px]" 
              customValidate={{custom: (e) => validateName(e, 'Group Name')}}
              errorKeys={{"required": "createDAG.sections.basicInfo.errors.groupNameBlank", 
                          "validate": "createDAG.sections.basicInfo.errors.invalidGroupName", 
                          "maxLength":"createDAG.sections.basicInfo.errors.groupNameMaxLength" }}
              />
              <div className="flex flex-row flex-wrap space-y-1 items-start justify-start pb-8 w-[620px] xl:w-[785px]" style={{ height: 148 }}>
                <FormTextArea 
                name="groupDesc" 
                label="Group Description" 
                labelKey="createDAG.sections.basicInfo.groupDescription"
                defaultValue={dataAccessGroupInfo.description}
                placeholder={translateToString("createDAG.sections.basicInfo.groupDesPlaceholder")} 
                maxLength={255} 
                disabled={!canEditGroupBasicInfo}
                inputClass="text-sm font-normal w-72 xl:w-[471px] min-h-[70px] max-h-[calc(27vh-6rem)] overflow-auto" 
                customValidate={(e) => validateName(e, 'Group Description')} 
                errorKeys={{"validate": "createDAG.sections.basicInfo.errors.invalidGroupDes", 
                             "maxLength":"createDAG.sections.basicInfo.errors.groupDesMaxLength" }}/>
                
              </div>
            </div>
          </div>
 
  )
};

export default GroupInformationTab;

