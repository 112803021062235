import React, { useState, useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import useSession from 'hooks/session/session';
import usePreferences from 'hooks/use-preferences';
import useProcessTranslations from 'hooks/utils/processTranslations';
import useTranslations from 'hooks/utils/translations';
import useFeatureFlags from 'hooks/utils/featureFlags';
import usePolicyLinks from 'hooks/utils/policyLinks';
import { BVLoader20, Button, ButtonType, LocalizedText, useUser } from 'portal-common-ui';
import config from 'config/config';
import useBrand from 'hooks/brand';
import { encryptData } from 'utils/Helper';
import { isMobile } from 'react-device-detect';

const SelfRegister = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [merchant, setMerchant] = useState('');
  const [publicJWK, setPublicJWK] = useState('');
  const [bankOrTaxId, setBankOrTaxId] = useState('');
  const [merchantError, setMerchantError] = useState();
  const [ddaError, setDdaError] = useState();
  const [formError, setFormError] = useState();
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const session = useSession();
  const translations = useTranslations();
  const { logo } = useBrand();
  const { getPublicKey } = useUser();
  const { translationsEnabled } = usePreferences();
  const { fetchAndSaveTranslations } = useProcessTranslations();
  const { accountsPrivacyTermsLinksEnabled, accountsGuestUserEnabled } = useFeatureFlags();

  // TODO: Obtain access to brand variable before user session is initialized
  let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));
  const brandLogo = brandDetails.logo;
  const brandName = config.activeBrand.businessName;
  const copyright = `common.${config.activeBrand.copyright}`;
  const locale = location.state?.locale;
  const { privacyPolicyLink, termsOfUseLink } = usePolicyLinks(locale || 'en-US');

  const history = useHistory();

  useEffect(() => {
    if (accountsGuestUserEnabled) {
      history.push('/');
    }
  }, [accountsGuestUserEnabled, history]);

  useEffect(() => {
    getPublicKey().then(res => {
      setPublicJWK(res);
    }).catch((err) => {
      console.log('error getting public key');
      console.log(err);
    });

    const processTranslations = async () => {
      try {
        if (translationsEnabled) {
          await fetchAndSaveTranslations(locale);
          localStorage.setItem('userLocale', locale);
        }
      } catch (err) {
        console.log('error with processing translations:', err);
        session.handleLogout('/SelfRegisterError');
      }
    }
    
    processTranslations();

  }, []);

  const validateMerchant = (merchantId) => {
    let merchantIsValid = true;
    const filterforMerchant = new RegExp('^[0-9]*$');
    if (merchantId === '') {
      merchantIsValid = false;
    } else if (!filterforMerchant.test(merchantId)) {
      merchantIsValid = false;
    } else if (merchantId.length > 16 || merchantId.length < 1) {
      merchantIsValid = false;
    }
    if (!merchantIsValid) setMerchantError(<LocalizedText localeKey="selfRegistration.alerts.invalid.merchantNumberInvalid"/>);
    return merchantIsValid;
  }

  const validateDda = (ddaNumber) => {
    let isDDAValid = true;
    const filterForDDA = new RegExp('^[a-zA-Z0-9]+[a-zA-Z0-9-]*$');
    if (ddaNumber === '') {
      isDDAValid = false;
    } else if (!filterForDDA.test(ddaNumber)) {
      isDDAValid = false;
    } else if (ddaNumber.length < 1) {
      isDDAValid = false;
    }
    if (!isDDAValid) setDdaError(<LocalizedText localeKey="selfRegistration.alerts.invalid.taxIdInvalid"/>);
    return isDDAValid;
  }

  const parseServerError = (errorResourceKey) => {
    let message = 'Sorry, something has gone wrong.'
    if (errorResourceKey === 'user.dda.invalid' || errorResourceKey === 'user.merchantnumber.invalid') {
      message = <LocalizedText localeKey="selfRegistration.alerts.noMatch.title"/>;
    }
    setFormError(message);
  };

  const resetErrorMessages = () => {
    setMerchantError('');
    setDdaError('');
    setFormError('');
  };

  const trimLeadingZeros = (input) => {
    const trimmed = input.replace(/^0+/, '');
    return trimmed;
  };

  const removeHyphens = (input) => {
    const trimmed = input.replace(/\-/g, '');
    return trimmed;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    resetErrorMessages();
    const trimmedMerchantNumber = trimLeadingZeros(merchant);
    const trimmedBankOrTaxId = trimLeadingZeros(removeHyphens(bankOrTaxId));
    const existingUser = location.state?.existingUser;

    let isFormValid = validateMerchant(trimmedMerchantNumber);
    isFormValid = validateDda(trimmedBankOrTaxId) && isFormValid;

    const encryptedBankOrTaxId =
      process.env.NODE_ENV === "development" && config.mockApi
        ? trimmedBankOrTaxId
        : encryptData(`${trimmedBankOrTaxId}`, publicJWK);

    if (isFormValid) {
      setLoading(true);
      const response = await session.register(trimmedMerchantNumber, encryptedBankOrTaxId, existingUser, locale);
      if (response) {
        setLoading(false);
        parseServerError(response?.message?.resourceKey);
      } else {
        setLoading(false);
        setRegistrationComplete(true);
      }
    }
  };

  if (registrationComplete) {
    return <Redirect to="/" />
  }

  return (
    <div className="self-registration h-screen w-screen bg-theme-on-primary before:bg-theme-on-primary before:bg-full before:bg-skyline before:bg-no-repeat before:bg-bottom before:fixed before:h-screen before:w-screen before:opacity-25 before:content-[''] before:top-0 before:left-0">
      {loading && <BVLoader20 />}
      <header className="fixed margin-0 top-[3vw] left-[3vw] med:max-w-[60%]">
        {!config.activeBrand.isWhiteLabel && <img className="w-logo-page h-[35px] w-auto" src={brandLogo} alt={brandName}/>}
      </header>
      <form className="flex flex-col justify-between top-[50%] -translate-y-1/2 med:transform-none med:top-[10%] sml:top-[6%] text-theme-dark self-registration-box md:bg-theme-light-background w-[90%] med:w-auto margin-0 mx-auto max-w-[717px] relative p-8 med:p-4  t-lg:pt-[50px] t-lg:pb-[50px] md:shadow-form" onSubmit={handleSubmit}>
        { formError &&
          <div className="flex items-center w-full p-4 border border-solid border-theme-danger my-14 mx-0" style={{ background: 'rgba(206, 35, 42, 0.05)'}} data-test="self-reg-error">
            <div className="w-5">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5241 15.5225 20 10 20C4.47754 20 0 15.5241 0 10C0 4.47915 4.47754 0 10 0C15.5225 0 20 4.47915 20 10ZM10.0004 12.0161C8.976 12.0161 8.14556 12.8465 8.14556 13.8709C8.14556 14.8953 8.976 15.7258 10.0004 15.7258C11.0248 15.7258 11.8552 14.8953 11.8552 13.8709C11.8552 12.8465 11.0248 12.0161 10.0004 12.0161ZM8.53689 10.8327L8.23778 5.34885C8.22266 5.07167 8.44331 4.83861 8.72089 4.83861H11.2767C11.5542 4.83861 11.7749 5.07167 11.7598 5.34885L11.4607 10.8327C11.4467 11.0893 11.2345 11.2902 10.9775 11.2902H9.02004C8.76306 11.2902 8.55089 11.0893 8.53689 10.8327Z" fill="#CE232A"/>
              </svg>
            </div>
            <p className="text-theme-danger text-sm mt-0 pl-4 font-normal">{formError}</p>
          </div>
        }
        <h1 className="mb-4 sml:mb-2 text-32px med:text-[22px] text-theme-secondary mt-0 font-normal med:font-semibold"><LocalizedText localeKey="selfRegistration.header"/></h1>
        <p className="text-theme-dark font-normal sml:text-sm mb-2"><LocalizedText localeKey="selfRegistration.subHeader"/></p>
        <p className='text-theme-dark font-normal sml:text-sm mb-4'><LocalizedText localeKey="selfRegistration.subHeaderMessage"/></p>
        <label className="text-theme-dark text-sm font-medium mb-1.5 t-lg:mb-2.5" htmlFor="merchant"><LocalizedText localeKey="selfRegistration.merchantNumber.title"/>{isMobile && <span className="text-red-400 ml-1 text-xs">*</span>}</label>
        <input className="border border-solid border-gray-200 mb-3 t-lg:mb-5 p-3 w-[458px] med:w-auto" name="merchant" type="text" onChange={event => setMerchant(event.target.value)} />
        { merchantError && <p className="text-theme-danger text-sm -mt-2 t-lg:-mt-2.5 mb-3 t-lg:mb-5">{merchantError}</p>}
        <label className="text-theme-dark text-sm font-medium mb-1.5 t-lg:mb-2.5" htmlFor="id"><LocalizedText localeKey="selfRegistration.taxID.title"/>{isMobile && <span className="text-red-400 ml-1 text-xs">*</span>}</label>
        <input className="border border-solid border-gray-200 mb-3 t-lg:mb-5 p-3 w-[458px] med:w-auto" name="id" type="password" onChange={event => setBankOrTaxId(event.target.value)} />
        { ddaError && <p className="text-theme-danger text-sm -mt-2 t-lg:-mt-2.5 mb-3 t-lg:mb-5">{ddaError}</p>}

        <Button
            titleKey="selfRegistration.button.completeRegistration"
            buttonClass='med:hidden'
            buttonType={ButtonType.PrimaryButton}
            isProcessing={loading}
            processingCaptionKey="selfRegistration.button.completeCaption"
            onClick={handleSubmit}
            testDataAttribute={'self-reg-submit-btn'}>
        </Button>

        <Button
            title="Complete Registration"
            buttonClass='hidden med:block'
            buttonType={ButtonType.MobilePrimary}
            isProcessing={loading}
            processingCaption="Registering..."
            onClick={handleSubmit}
            testDataAttribute={'self-reg-submit-mob-btn'}>
        </Button>


        
      </form>
      <footer className="fixed margin-0 bottom-[3vw] left-[3vw]">
		    <div id="copyright">&#169; <LocalizedText localeKey={copyright}/></div>
        {accountsPrivacyTermsLinksEnabled && <div>
          <a className={`hover:underline ${!privacyPolicyLink ? "invisible" : ""}`} href={privacyPolicyLink} target="_blank" rel="noopener noreferrer" data-test="self-reg-privacyPolicyLink"><LocalizedText localeKey="footer.links.privacyPolicy" /></a>
              <span className={`px-4 ${!(privacyPolicyLink && termsOfUseLink) ? "invisible" : ""}`}> | </span>
          <a className={`hover:underline ${!termsOfUseLink ? "invisible" : ""}`} href={termsOfUseLink} target="_blank" rel="noopener noreferrer"><LocalizedText localeKey="footer.links.termsOfUse" /></a>
        </div>}
      </footer>
    </div>
  )
};

export default SelfRegister;
