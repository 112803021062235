import React, { useEffect, useState } from "react";
import ApplicationItem from "./ApplicationItem";
import Modali, { useModali } from "modali";
import { useUser, usePortalAppContext, Button, ButtonType, BVLoader20, useLocale, LocalizedText } from "portal-common-ui";
import { userInterfaces, applicationInterfaces } from "portal-common-ui/dist/interfaces";
import useApplications from "hooks/applications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import RoleWithPermissionsSelector from "components/UserViewerEditor/RoleWithPermissionsSelector";
import AppIcon from "components/Application/AppIcon";
import { useGlobal } from "libs/reactn";

interface NewApplicationListProps {
  onForeverDismiss(userId: string, applicationIds: string): void;
}

const NewApplicationList = ({ onForeverDismiss }: NewApplicationListProps) => {
  const { applicationBulkAssignment } = useUser();
  const [auth, setAuth] = useGlobal("auth");
  const [closeMenuVisiblity, setCloseMenuVisiblity] = useState(false);
  const [roleSelectorModal, toggleRoleSelectorModal] = useModali({
    overlayClose: false,
    large: true,
    centered: true,
  });
  const [loddingModal, toggleLoddingModal] = useModali({
    overlayClose: false,
    large: false,
    centered: true,
  });
  const [selectedApp, setSelectedApp] = useState<applicationInterfaces.IAssignedApplication | null>(null);
  const applications = useApplications();
  const [_dismissedNewApplicatioAlert, setDismissedNewApplicatioAlert] = useGlobal("dismissedNewApplicatioAlert");
  const [allApplicationsDismissed, setAllApplicationDismissed] = useState(false);
  const loggedInUser: userInterfaces.IUserProfile | null = auth?.myProfile;
  const isAdmin =
    loggedInUser?.roleObject?.roleGuid === "ACCOUNT_ADMINISTRATOR" ||
    loggedInUser?.roleObject?.roleGuid === "THIRD_PARTY_ADMINISTRATOR";
  const apps = applications.appList?.filter(
    (product: any) =>
      applications.appLinksMap[product.assignedApplication.applicationGuid] &&
      new Date(product.createdDateTime).getTime() > 1696887594982 &&
      (isAdmin || product.newlyAdded)
  );
  const {translateToString} = useLocale();

  useEffect(() => {
    const allApps = apps.map((a: any) => a.applicationId);
    const filteredArray = loggedInUser?.dismissedApplications.filter((applicationId: any) =>
      allApps.includes(applicationId)
    );
    const dismissedAll = allApps?.length === filteredArray?.length;
    setAllApplicationDismissed(dismissedAll);
  }, [apps, loggedInUser?.dismissedApplications]);

  const { enqueueAlert } = usePortalAppContext();

  const confirmAssignUsers = (app: applicationInterfaces.IAssignedApplication) => {
    enqueueAlert({
      alertType: "modal",
      title: "Confirm Bulk Application Assignment",
      titleKey: "homepage.appManagement.alerts.title",
      messageType: "info",
      continueCaption: "Yes, Assign to All Users",
      continueCaptionKey: "homepage.appManagement.alerts.continueCaption",
      cancelCaption: "No, Cancel",
      cancelCaptionKey: "shared.alerts.cancelAction",
      continueAction: () => handleSubmitAssignUsers(app),
      cancelAction: () => setSelectedApp(null),
      message: <LocalizedText localeKey="homepage.appManagement.alerts.message" templateVariables = {{appName : translateToString(app?.name)}}/>
    });
  };

  const handleSubmitAssignUsers = async (
    selectedApp: applicationInterfaces.IAssignedApplication,
    selectedRole?: any,
    userPermissions?: any
  ) => {
    if (loggedInUser && selectedApp?.applicationId) {
      toggleLoddingModal(true);
      await applicationBulkAssignment({
        applicationId: selectedApp.applicationId,
        roleId: selectedRole?.roleId,
        roleGuid: selectedRole?.roleGuid,
        optionalPermissionIds: userPermissions,
        applicationPlanIds: selectedApp.applicationPlans.map((plan) => plan.planId),
      });
      const d = [...auth?.myProfile?.dismissedApplications] ?? [];
      d.push(selectedApp.applicationId);
      onForeverDismiss(loggedInUser.userId, d.join(", "));
      setAuth((newAuth: any) => {
        const newProfile = { ...newAuth.myProfile };
        newProfile.dismissedApplications = d;
        newAuth.myProfile = newProfile;
      });
      toggleLoddingModal(false);
      enqueueAlert({
        alertType: "toast",
        messageType: "success",
        message: <LocalizedText localeKey="homepage.appManagement.alerts.successAlert" templateVariables = {{appName : translateToString(selectedApp?.name)}}/>
      });
      setSelectedApp(null);
    }
  };

  const handleRoleAssignment = (app: applicationInterfaces.IAssignedApplication): void => {
    setSelectedApp(app);
    if (app.applicationRoleAssociations?.length) {
      toggleRoleSelectorModal();
    } else {
      confirmAssignUsers(app);
    }
  };

  function closeModal() {
    toggleRoleSelectorModal();
    setSelectedApp(null);
  }

  const handleDismiss = () => {
    if (isAdmin) {
      setCloseMenuVisiblity(!closeMenuVisiblity)
    } else if(loggedInUser) {
      setDismissedNewApplicatioAlert(true);
    }
  };

  function dismissAlert(dismissType: string) {
    if (dismissType === "FOREVER" && loggedInUser) {
      const applicationsIds = loggedInUser?.userApplicationAssignments?.results
        .map((app: applicationInterfaces.IUserApplicationAssignment) => app.applicationId)
        .join(", ");
      onForeverDismiss(loggedInUser?.userId, applicationsIds);
    }
    setDismissedNewApplicatioAlert(true);
    setCloseMenuVisiblity(!closeMenuVisiblity);
  }

  if (!loggedInUser || allApplicationsDismissed || !apps.length) {
    return <></>;
  }

  return (
    <div className="mt-8">
      <div className="flex flex-col gap-3.5 border border-solid divide-slate-300 2xl:w-2/3 xl:w-4/5 bg-white px-5">
        <div className="flex pt-5 pb-2 pr-5">
          <div className="flex flex-1 items-center">
            <span className="text-base font-bold text-theme-primary uppercase"><LocalizedText localeKey="homepage.appManagement.heading.title"/></span>
            <span className="text-sm font-medium text-theme-medium ml-1.5">
              <LocalizedText localeKey="homepage.appManagement.heading.message"/>
            </span>
          </div>
          <div className="flex relative">
            <button
              className="close float-right w-5 h-5 text-sm flex justify-center items-center text-theme-disabled"
              onClick={handleDismiss}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {closeMenuVisiblity && (
              <div className="absolute flex flex-col border border-solid border-gray-200 bg-white rounded-none top-5 right-1 slide-in-top animate-navSlideIn w-auto">
                <ul className="px-1">
                  <li className="py-1 my-1 whitespace-nowrap w-full hover:bg-info hover:bg-opacity-20 border-b">
                    <Button
                      buttonType={ButtonType.LinkButton}
                      onClick={() => dismissAlert("NOW")}
                      child={translateToString("homepage.appManagement.dismiss.forNow")}
                    />
                  </li>
                  <li className="py-1 my-1 whitespace-nowrap w-full hover:bg-info hover:bg-opacity-20">
                    <Button
                      buttonType={ButtonType.LinkButton}
                      onClick={() => dismissAlert("FOREVER")}
                      child={translateToString("homepage.appManagement.dismiss.forever")}
                    />
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        {isAdmin && (
          <div className="flex text-xs font-normal text-theme-info bg-theme-info-background">
            <div className="flex-auto p-2">
              <FontAwesomeIcon icon={faInfoCircle} size="2x" />
            </div>
            <div className="flex-auto p-1">
              <LocalizedText localeKey="homepage.appManagement.info"/>
            </div>
          </div>
        )}
        <div>
          {!applications.loading &&
            apps.map((product: applicationInterfaces.IUserApplicationAssignment, index: number) => {
              return (
                !loggedInUser?.dismissedApplications?.includes(product.assignedApplication.applicationId) && (
                  <ApplicationItem
                    key={product.assignedApplication.applicationGuid}
                    isAdmin={isAdmin}
                    name={translateToString(product.assignedApplication.name)}
                    description={translateToString(product.assignedApplication.description)}
                    onClick={() => handleRoleAssignment(product.assignedApplication)}
                    icon={product.assignedApplication.applicationGuid}
                    last={apps.length === index + 1}
                  />
                )
              );
            })}
        </div>
      </div>
      <Modali.Modal {...roleSelectorModal}>
        <div className="flex w-full py-2 border">
          <div className="flex items-center pl-5">
            <AppIcon id={selectedApp?.applicationGuid} height={35} width={35} />
          </div>
          <div className="flex items-center pl-2 font-medium text-[#3a3d46]">
            <LocalizedText localeKey="homepage.appManagement.assignment.heading" templateVariables= {{appName : translateToString(selectedApp?.name)}}/>
          </div>
        </div>
        <div className="px-5">
          <div className="text-xs font-normal flex my-4 text-theme-info bg-theme-info-background">
            <div className="flex-auto p-2">
              <FontAwesomeIcon icon={faInfoCircle} size="2x" />
            </div>
            <div className="flex-auto p-1">
              <LocalizedText localeKey="homepage.appManagement.assignment.info" templateVariables= {{appName : translateToString(selectedApp?.name)}}/>
            </div>
          </div>
          <hr className="divide-slate-100" />
        </div>
        <RoleWithPermissionsSelector
          currentUser={loggedInUser}
          targetUser={loggedInUser}
          onRolePermissionChanged={handleSubmitAssignUsers}
          onClose={closeModal}
          applicationGuid={selectedApp?.applicationGuid}
          selectedApp={selectedApp}
          type="BULK"
        />
      </Modali.Modal>
      <Modali.Modal {...loddingModal}>
        <div style={{ minHeight: "380px" }}>
          <BVLoader20 />
        </div>
      </Modali.Modal>
    </div>
  );
};

export default React.memo(NewApplicationList);
