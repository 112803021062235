import { useEffect, useState } from "react";
import config from "config/config";
import Card from "components/Dashboard/Card";
import useSession from "hooks/session/session";
import useApplications from "hooks/applications";
import HelpAndFQ from "components/Dashboard/HelpAndFQ";
import useFeatureFlags from "hooks/utils/featureFlags";
import QuickLinks from "components/Dashboard/QuickLinks";
import CalendarTool from "components/Dashboard/CalendarTool";
import { BVLoader20, LocalizedText } from "portal-common-ui";
import ReportGraphAndChart from "components/Dashboard/ReportGraphAndChart";

const Dashboard = () => {
  const session = useSession();
  const { user } = session;
  const isInternal = user.internal;
  const applications = useApplications();
  const portfolios = Object.values(user.userAccessHierarchyTypes);
  const hierarchyType = portfolios.length > 1 ? "G-MAS" : portfolios[0];
  const [hasReporting, setHasReporting] = useState(false);
  const { accountsLandingPageGraphReports, accountsLandingPageGraphEnabled } = useFeatureFlags();
  const [settledBatchReport, setSettledBatchReport] = useState<any>({});
  const [settledTransactionReport, setSettledTransactionReport] = useState<any>({});

  useEffect(() => {
    if (!applications.loading) applications.getApplicationsList();
  }, []);

  useEffect(() => {
    const apps = applications.appList.map((app: any) => {
      return app.assignedApplication.applicationGuid;
    });

    if (apps.includes("MERCHANT_PORTAL_REPORTING")) {
      setHasReporting(true);
    }
  }, [applications.appList]);

  useEffect(() => {
    const env: string = ["mdev", "mqa"].includes(config.env.name) ? config.env.name.replace("m", "") : config.env.name;
    if (accountsLandingPageGraphReports?.length) {
      const settledBatchReport = accountsLandingPageGraphReports.find(
        (report: any) =>
          report.env === env.toUpperCase() &&
          report.reportKey === "settled_batches" &&
          report.portfolio === hierarchyType
      );
      const settledTransactionReport = accountsLandingPageGraphReports.find(
        (report: any) =>
          report.env === env.toUpperCase() &&
          report.reportKey === "settled_transactions" &&
          report.portfolio === hierarchyType
      );
      setSettledBatchReport(settledBatchReport);
      setSettledTransactionReport(settledTransactionReport);
    }
  }, [accountsLandingPageGraphReports]);

  const options: any = { weekday: "long", year: "numeric", month: "short", day: "numeric" };
  const now = new Date();
  const formattedDate = now.toLocaleDateString(user?.languageAbbr, options);
  const showDashboard = !isInternal && hasReporting && accountsLandingPageGraphEnabled;

  if (applications.loading) {
    return (
      <div className="relative" style={{ height: "calc(100vh - 160px)" }}>
        <BVLoader20 />
      </div>
    );
  }

  return (
    <div className="py-10 md:px-5 lg:px-10 xl:px-20 text-theme-dark">
      <h1 className="font-bold text-3xl mb-8" data-test="homepage-heading">
        <LocalizedText localeKey="homepage.title" templateVariables={{ firstName: user.firstName }} />
      </h1>

      {/* hidden for dashboard users except in desktop view */}
      <div className={`${showDashboard && "hidden xl:block"}`}>
        {/* <div className="font-bold text-3xl mb-2">Today</div> */}
        <div className="font-medium text-base">
          {/* <div className={`mb-${!showDashboard ? "6" : "2"}`}>
            {formattedDate} {showDashboard && "vs"}
          </div> */}
          {showDashboard && (
            <div className="grid grid-cols-3 mb-6">
              <CalendarTool />
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-3 gap-0 xl:gap-6 mb-6">
        <div className="xl:block hidden col-span-2">
          {!showDashboard && <HelpAndFQ withoutReporting />}
          {showDashboard && (
            <>
              <div>
                <ReportGraphAndChart
                  reportId={settledTransactionReport?.reportId}
                  position={settledTransactionReport?.poistion}
                  heightClass="h-96"
                />
              </div>
              {/* <div className="flex gap-6">
                <ReportGraphAndChart reportId={12} position={1} heightClass="h-40" />
                <ReportGraphAndChart reportId={7415} position={1} heightClass="h-40" />
                <ReportGraphAndChart reportId={7415} position={1} heightClass="h-40" />
              </div> */}
            </>
          )}
        </div>
        <QuickLinks settledBatchReport={settledBatchReport} hierarchyType={hierarchyType} />
      </div>
      {showDashboard && (
        <div className="grid grid-cols-3 gap-0 xl:gap-6 mb-6">
          <div className="xl:block hidden col-span-2">
            <ReportGraphAndChart
              reportId={settledBatchReport?.reportId}
              position={settledBatchReport?.poistion}
              heightClass="h-96"
            />
          </div>
        </div>
      )}

      {/* if a user can see dashboard - mobile and tablet view - hidden for non dashboard users */}
      <div className={`${showDashboard ? "block xl:hidden" : "hidden"}`}>
        {/* <div className="font-bold text-3xl mb-2">Today</div> */}
        <div className="font-medium text-base">
          {/* <div className={`mb-${!showDashboard ? "6" : "2"}`}>
            {formattedDate} {showDashboard && "vs"}
          </div> */}
          {showDashboard && (
            <div className="grid md:grid-cols-2 grid-cols-1 mb-6">
              <CalendarTool />
            </div>
          )}
        </div>
      </div>

      {showDashboard && (
        <div className={`xl:hidden`}>
          <div className="mb-6">
            <ReportGraphAndChart
              reportId={settledTransactionReport?.reportId}
              position={settledTransactionReport?.poistion}
              heightClass="h-96"
            />
          </div>
          {/* <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-6 mb-6">
            <ReportGraphAndChart reportId={12} position={1} heightClass="h-40" />
            <ReportGraphAndChart reportId={7415} position={1} heightClass="h-40" />
            <ReportGraphAndChart reportId={7415} position={1} heightClass="h-40" />
          </div> */}
          <div className="mb-6">
            <ReportGraphAndChart
              reportId={settledBatchReport?.reportId}
              position={settledBatchReport?.poistion}
              heightClass="h-96"
            />
          </div>
        </div>
      )}

      <div className={`${!showDashboard && "xl:hidden lg:block"}`}>
        <HelpAndFQ />
      </div>
    </div>
  );
};

export default Dashboard;
