import React, {useCallback, useEffect, useState } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import useStateRef from 'react-usestateref';
import useBrand from "hooks/brand";
import useSession from 'hooks/session/session';
import useProcessTranslations from 'hooks/utils/processTranslations';
import usePreferences from 'hooks/use-preferences';
import useFeatureFlags from 'hooks/utils/featureFlags';
import useApplications from 'hooks/applications';
import { LogoutTimer, LogoutTimerLayout, useUser, authSession } from 'portal-common-ui';
import useGlobal from 'libs/reactn/useGlobal';
import config from 'config/config';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { createTheme, applyTheme } from 'hooks/utils/theme';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-blue.css';
import { LicenseManager } from 'ag-grid-enterprise';
import AlertManager from 'core/layout/AlertManager';
import NavigationHeader from 'components/Navigation/NavigationHeader';
import NavigationSidebar from 'components/Navigation/NavigationSidebar';
import Footer from './Footer';
import Overlay from 'components/Overlay/Overlay';

// LicenseManager.setLicenseKey('Global_Payments_MultiApp_5Devs_2Deployment_19_July_2020__MTU5NTExMzIwMDAwMA==15235c586c692a2118f440a9c96da436');
LicenseManager.setLicenseKey('CompanyName=Global Payments Inc.,LicensedGroup=Global Payments,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=0,AssetReference=AG-016609,ExpiryDate=19_July_2022_[v2]_MTY1ODE4NTIwMDAwMA==457b1f6a8e3c82293861890e3bb2da7d');

const PermissionedLayout = (props) => {
    const user = useUser();
    const ldClient = useLDClient();
    const history = useHistory();
    const [, setCurrentTranslatedLocale, currentTranslatedLocaleRef] = useStateRef(null);

    // Obtain Brand from User Session
    const { theme, logo } = useBrand();
    const session = useSession();
    const { fetchAndSaveTranslations } = useProcessTranslations();
    const { areTranslationsEnabled, enabledLocales } = usePreferences();
    const { accountsPrivacyTermsLinksEnabled } = useFeatureFlags();
    const { appList } = useApplications();
    const userDisplayName = `${session?.user?.firstName} ${session?.user?.lastName}`;
    const userInitials = `${session?.user?.firstName?.charAt(0)}${session?.user?.lastName?.charAt(0)}`;
    const userEmailAddress = session?.user?.email;
    // const brand = session.user.brand.toLowerCase();
    let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));
    const brand = brandDetails.brandGuid.toLowerCase();
    const brandLogo = brandDetails.logo;
    const brandName = config.activeBrand.businessName;
    const themeItems = theme(brand);
    const [logoutTimer] = useGlobal('logoutTimer');

    useEffect(()=> {
        applyTheme(createTheme(themeItems));
        if (session?.postLoginRedirect) {
          history.push(session.postLoginRedirect);
        }
    },[]);

    useEffect(() => {

      const initialzeLDandFetchTranslations = async () => {
        const u = session.user;
        const userLocale = u?.languageAbbr;
        const localeHasChanged = userLocale !== currentTranslatedLocaleRef.current;
        const assignedApplications = appList.map(a => a?.assignedApplication?.applicationGuid);

        const processTranslations = async (enabledLanguages) => {
          try {
            const translationsFeatureEnabled = areTranslationsEnabled(enabledLanguages); 
            if (translationsFeatureEnabled && localeHasChanged) {
              setCurrentTranslatedLocale(userLocale);
              await fetchAndSaveTranslations(userLocale);
            }
          } catch (err) {
            console.log('error with processing translations:', err);
            // await session?.handleLogout('/LoginError');
          }
        } 
        
        if (ldClient && config.launchDarkly.clientId) {
          try {          
            const ldResponse = await ldClient.identify({
              key: u.externalId,
              custom: {
                language: userLocale || 'en-US',
                accountsRole: u.role.key,
                assignedApplications
              },
            });
            processTranslations(ldResponse?.['accounts-enabled-languages']);
          } catch (err) {
            console.log('error with initializing LD:', err);
          }
        } else if (config.mockApi) {
          processTranslations(enabledLocales);
        }
      }

      initialzeLDandFetchTranslations();
  
    }, [session, appList]);

    return (
        <div className="flex h-screen overflow-hidden" data-theme={brand}>
            <AlertManager />
            <NavigationSidebar />
            <LogoutTimer
              isRunning={logoutTimer.timerIsRunning}
              loginRedirect={<Redirect to="/login" />} 
              isAuthenticated={session.authenticated}
            >
              <Overlay dark className={'z-[1053]'} />
              <LogoutTimerLayout
                responseTimer={logoutTimer.countdownSeconds}
                logout={() => history.push("/logout")}
                renew={session.resetTimer}
              />
            </LogoutTimer>

            <main className="flex flex-col w-full overflow-x-hidden">
                <div className='flex-1 flex flex-col'>
                  <NavigationHeader
                    userInitials={userInitials}
                    userDisplayName={userDisplayName}
                    userEmailAddress={userEmailAddress}
                    brandName={brandName}
                    brandLogo={brandLogo}
                    brand={brand}
                    contacts={config.activeBrand.contacts}
                    isWhiteLabel={config.activeBrand.isWhiteLabel} 
                  />
                  <article className="flex-1 p-4" data-test="layout-container">
                      {props.children}
                  </article>
                </div>
                {accountsPrivacyTermsLinksEnabled && <Footer />}
            </main>
        </div>
    );
};

export default PermissionedLayout;