import React, { useState, useEffect } from "react";
import Modali, { useModali } from 'modali';
import useFeatureFlags from 'hooks/utils/featureFlags';
import usePermissions from 'hooks/session/permissions';
import useDataAccess from "hooks/utils/dataAccess";
import { DataAccessEditor, NotificationCard, Button, LocalizedText, useUser } from 'portal-common-ui';
import RegisterMid from "components/RegisterMid/RegisterMid";
import { isMobileOnly } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";


const DataAccessTab = ({
  user,
  onSuccessfulMidRegister,
  onUpdate,
  canEdit,
  canRegisterMid = false,
  allowFullPortfolioAccess,
  setSavedGroup, 
  selectedGroups,
  subscriptionError = false,
}) => {

  const [canSave, setCanSave] = useState(true);
  const [rowIsEditing, setRowIsEditing] = useState(false);
  const [updatedDataAccessHierarchies, setUpdatedDataAccessHierarchies] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  const { getAuthenticatedSessionUser } = useUser();
  const { enabledSelectMethods, formatInitialDataAccess } = useDataAccess();
  const { accountsSmallMerchantMidSelectionEnabled, accountsRequestAccessStatusEnabled } = useFeatureFlags();
  const [sessionUser, setSessionUser] = useState(null);
  const hasVTAssigned = !!sessionUser?.userApplicationAssignments?.results.find(a => a?.assignedApplication?.applicationGuid === 'VT');

  const [registerMidModal, toggleRegisterMidModal] = useModali({
    overlayClose: false,
    large: false,
    centered: true,
  });

  useEffect(() => {
    const fetchSessionUser = async () => {
      const loggedInUser = await getAuthenticatedSessionUser();
      setSessionUser(loggedInUser);
    };

    fetchSessionUser();
  }, []);

  useEffect(() => {
    if (user.dataAccess) {
      const hierarchies = formatInitialDataAccess(user.dataAccess);
      setUpdatedDataAccessHierarchies(hierarchies);
    }
  }, [user.dataAccess]);

  const onRowAddOrUpdate = (updatedRow, mids) => {
    let newHierarchies = updatedDataAccessHierarchies;
    const existingRow = newHierarchies.find(row => row.rowKey === updatedRow.rowKey);
    if (existingRow) {
      existingRow.hierarchyId = updatedRow?.selectedHierarchy?.hierarchyId,
      existingRow.midHierarchyIds = (mids?.length > 0 ? mids?.map(m => m?.hierarchyId || m) : updatedRow.selectedMids?.map(m => m?.hierarchyId || m)),
      existingRow.dagId = updatedRow?.selectedGroup?.id
    } else {
      newHierarchies.push({
        rowKey: updatedRow.rowKey,
        hierarchyId: updatedRow?.selectedHierarchy?.hierarchyId,
        midHierarchyIds:(mids?.length > 0 ? mids?.map(m => m?.hierarchyId || m) : updatedRow.selectedMids?.map(m => m?.hierarchyId || m)),
        dagId: updatedRow?.selectedGroup?.id
      });
    }
    const hasFullPortfolioAssigned = updatedRow.nodes ? updatedRow.nodes[0]?.selectedChild.label === 'All' : false;
    setUpdatedDataAccessHierarchies(newHierarchies);
    onUpdate(newHierarchies, hasFullPortfolioAssigned);
  };

  const onRowDeleted = (rowKey) => {
    let newHierarchies = [...updatedDataAccessHierarchies];
    newHierarchies = newHierarchies.filter(r => r.rowKey !== rowKey);
    setUpdatedDataAccessHierarchies(newHierarchies);
    onUpdate(newHierarchies, false);
  };

  const onBulkRowUpdate = (updatedRows) => {
    const newHierarchies = updatedRows.map(updatedRow => {
      return {
        rowKey: updatedRow.rowKey,
        hierarchyId: updatedRow?.selectedHierarchy?.hierarchyId,
        midHierarchyIds:(updatedRow.selectedMids?.map(m => m?.hierarchyId || m)),
        dagId: updatedRow?.selectedGroup?.id
      }
    })
    setUpdatedDataAccessHierarchies(newHierarchies);
    onUpdate(newHierarchies, false);
  }

  const onRowIsEditing = (isEditing) => {
    setRowIsEditing(isEditing);
  };

  const onRegisterNewMid = () => {
    toggleRegisterMidModal();
  };

  const allowSmallMerchantMidSelection = accountsSmallMerchantMidSelectionEnabled && sessionUser?.roleObject?.roleType === "EXTERNAL" && !hasVTAssigned;

  return (
    <>
      { isMobileOnly ?
        <div className="flex gap-4 w-full mid-lg:w-[48rem] bg-[#FFFAE5] text-base p-4 border border-solid">
          <FontAwesomeIcon icon={faCircleInfo} size="2x" style={{color: "#C79F00",}} />
          <div>
            <p className="pb-2 font-bold"><LocalizedText localeKey="editUser.sections.dataAccess.mobileError.title"/></p>
            <p className="font-normal"><LocalizedText localeKey="editUser.sections.dataAccess.mobileError.message"/></p>
          </div>
        </div>
      :      
      <div className="bg-theme-on-primary rounded-sm border-gray-100 min-w-full h-full overflow-auto">
        {!rowIsEditing && 
        <div class="flex flex-row">
          <div className="w-full grow font-normal text-theme-dark pb-2">
            <LocalizedText localeKey="editUser.sections.dataAccess.subtitle" />
          </div>
          {!accountsRequestAccessStatusEnabled && canRegisterMid && 
          <div className="whitespace-nowrap mr-2">
            <Button 
              title={<LocalizedText localeKey="editUser.buttons.registerMID" />}
              iconClasses="fal fa-plus"
              testDataAttribute="profile-register-new-mid"
              buttonType="SecondaryButton"
              onClick={onRegisterNewMid}
              minWidth={150}
			  />
          </div>
          }
        </div>
        }
        {rowIsEditing && 
        <div className="ml-[-20px]">
        </div>
        }
        {subscriptionError && 
          <div className="w-[627px] m-5">
              <NotificationCard
                type="error"
                title={<LocalizedText localeKey="createUser.alerts.cannotCreateUserBeforeAdmin.title"/>}
                messageLine1={<LocalizedText localeKey="createUser.alerts.cannotCreateUserBeforeAdmin.message1"/>}
              />
          </div>
        }
 
        <div className="flex bg-theme-on-primary rounded-sm border-gray-100" style={{minHeight: 'calc(100vh - 490px)'}}>
          <div className={`flex items-top space-y-5 gap-x-4 items-start justify-start py-4 px-0 bg-theme-on-primary rounded-sm h-full ${allowSmallMerchantMidSelection ? 'w-full' : ''}`} >
            <div className="flex items-top space-y-5 gap-x-4 items-start justify-start py-0 px-8 pl-0 bg-theme-on-primary rounded-sm min-w-full h-full" >
              <DataAccessEditor 
                // preSelectedPortfolio="T-MAS"
                dataRows={user.dataAccess?.filter(m => m?.searchBy?.value !== 'DataAccessGroup')?.concat(user.dataAccess?.filter(m => m?.searchBy?.value === 'DataAccessGroup').filter((item, index, objects) => {
                  if (index === 0 || item?.selectedGroup?.id !== objects[index - 1]?.selectedGroup?.id) {
                      return item;
                  } 
              }))}
                allowFullPortfolioAccess={allowFullPortfolioAccess}
                showEmptyAccessError
                setCanSave={setCanSave} 
                showViewMids 
                hasMidsExcludedFromLoggedInUser={user.hasMidsExcludedFromLoggedInUser}
                onRowIsEditing={onRowIsEditing} 
                lazyLoadItems 
                onRowAddOrUpdate={onRowAddOrUpdate}
                onRowDeleted={onRowDeleted}
                onBulkRowUpdate={onBulkRowUpdate}
                canEdit={canEdit}
                onSetPageTitle={setPageTitle}
                redirectSingleLineageToMidSelector={false}
                setSavedGroup={setSavedGroup} 
                hideSelectedMidsText
                selectedGroups={selectedGroups}
                enabledSelectMethods={enabledSelectMethods}
                allowSmallMerchantMidSelection={allowSmallMerchantMidSelection}
              /> 
            </div>
          </div>
        </div>
        <Modali.Modal {...registerMidModal}>
          <div className="flex items-center justify-center min-w-[350px] w-full h-full">
            <RegisterMid
              onCloseDialog={toggleRegisterMidModal}
              onSuccessfulMidRegister={onSuccessfulMidRegister}
              popupType={"registerMid"}
            />
          </div>
        </Modali.Modal>
    </div>  
  }
  </>
  )
};

export default DataAccessTab;

