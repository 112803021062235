import React, { useEffect, useState } from "react";
import config from "config/config";
import useSession from "hooks/session/session";
import { useLandingPage } from "store/landingPage";
import { BVLoader20, useApi } from "portal-common-ui";

interface ReportGraphAndChartProps {
  reportId: number;
  position: number;
  heightClass: string;
}

const ReportGraphAndChart: React.FC<ReportGraphAndChartProps> = ({ reportId, position = 0, heightClass }) => {
  const reportsUrl = config.API.notificationsUrl.replace(
    "SharedNotifications/api/v1",
    "nextgen/reports/api/v2/reports"
  );
  const session = useSession();
  const { user } = session;
  const reportApi = useApi(reportsUrl);
  const visualizationApi = useApi(reportsUrl, { cachePolicy: "no-cache" });
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);
  const { fromDate, toDate } = useLandingPage();
  const portfolios = Object.values(user.userAccessHierarchyTypes);
  const hierarchyType = portfolios.length > 1 ? "G-MAS" : portfolios[0];
  const [visualizations, setVisualizations] = useState<Array<string>>([]);

  const fetchReport = async () => {
    setLoading(true);
    await reportApi.get(`${reportId}?hierarchyType=${hierarchyType}&language=${user?.languageAbbr}`);
    if (reportApi.response.ok) {
      setReport(reportApi.response.data);
      const filters = [
        {
          v1: fromDate,
          type: "BETWEEN",
          id: reportApi.response.data?.primaryDateFilterId,
          v2: toDate,
        },
      ];

      await visualizationApi.get(
        `${reportId}/visualizations?filters=${JSON.stringify(filters)}&hierarchyType=${hierarchyType}`
      );
      if (visualizationApi.response.ok) {
        setVisualizations(visualizationApi.response.data);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (fromDate && toDate && reportId) {
      fetchReport();
    }
  }, [fromDate, toDate, reportId, user?.languageAbbr]);

  return (
    <>
      {loading && (
        <div className={`w-full relative ${heightClass}`}>
          <BVLoader20 />
        </div>
      )}
      {visualizations && visualizations.length > 1 && !loading && (
        <div className="bg-white rounded shadow">
          <iframe title="Report Graph" className={`w-full ${heightClass}`} src={visualizations[position]} />
        </div>
      )}
    </>
  );
};

export default React.memo(ReportGraphAndChart);
