import { CellShape, useLocale } from 'portal-common-ui';

export interface IUserListItems {
    groupId: string,
    name: string,
    createdByFirstName: string,
    createdByLastName: string,
    countOfNodes: number,
    createdByEmail: string,
}
  
export interface IUserList {
    email: string,
    userId: string,
    brand: string,
    firstName: string,
    lastName: string,
    roleGuid: string,
    role: string,
    lastLoginDate: Date | null,
    status: string,
    statusGuid: string,
    canEditStatus: boolean,
}

const useUserList = () => {
    const { translateToString } = useLocale();
    const transformRow = (rows: any[]) => {
        const inRows = rows;
        const outRows = inRows.map(inRow => {
            let roleName = inRow.accountsApplicationRoleGuid
                .split('_')
                .map(r => r.charAt(0) + r.substr(1).toLowerCase())
                .join('');
            const roleKey = 'applications.mp-accounts.role.merchant'+roleName+'.name';
            const statusKey = 'userManagementPage.filterBar.filters.status.' + inRow.status.toLowerCase();
            
            let outRow: IUserList = {
                email: inRow.email,
                userId: inRow.userId,
                brand: `${inRow.brands.join(', ')} ${inRow.hasInaccessibleBrands ? '*' : ''}`,
                firstName:inRow.firstName,
                lastName:inRow.lastName,
                roleGuid: inRow.accountsApplicationRoleGuid,
                role: translateToString(roleKey),
                lastLoginDate: inRow.lastLoginDate ? inRow.lastLoginDate : null,
                status: translateToString(statusKey),
                statusGuid: inRow.status.toUpperCase(),
                canEditStatus: true
            };
            return outRow;
        });
        return outRows;
    };

    const sortMap = { 
        'default': 'LAST_NAME', 
        'lastName': 'LAST_NAME', 
        'firstName': 'FIRST_NAME', 
        'email': 'EMAIL', 
        'role': 'MP_ACCOUNTS_APP_ROLE_GUID', 
        'lastLoginDate': 'LAST_LOGIN_DATE',
        'status': 'STATUS' 
    };

    const loaderColumnDefs = [
        {width:'15%', cellShape:CellShape.Bar},
        {width:'15%', cellShape:CellShape.Bar},
        {width:'25%', cellShape:CellShape.Bar},
        {width:'10%', cellShape:CellShape.Bar},
        {width:'10%', cellShape:CellShape.Bar},
        {width:'10%', cellShape:CellShape.Bar},
        {width:'8%', cellShape:CellShape.Pill},
        {width:'7%', cellShape:CellShape.Ellipsis},
    ];

    return {
        transformRow,
        sortMap,
        loaderColumnDefs,
    }
}

export default useUserList;