import React from "react";
import Modali, { useModali } from "modali";
import AppIcon from "components/Application/AppIcon";
import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType, LocalizedText, AccessibleIcon } from "portal-common-ui";

interface IApplicationItem {
  name: string;
  description: string;
  last: boolean;
  onClick(): void;
  icon: any;
  isAdmin: boolean;
}

const ApplicationItem = ({ name, description, last = false, onClick, icon, isAdmin = false }: IApplicationItem) => {
  const [learnMoreModal, toggleLearnMoreModal] = useModali({
    overlayClose: true,
    centered: true,
  });

  return (
    <>
      <div className="flex my-3">
        <div className="flex items-center justify-center">
          <AppIcon id={icon} width={32} height={32} />
        </div>
        <div className="flex flex-1 w-64 items-center text-sm font-medium text-[#3a3d46] pl-4">{name}</div>
        {/* <div className="flex flex-1 items-center justify-start text-[#3a3d46] text-xs font-normal">{description}</div> */}
        {isAdmin && (
          <div className="flex items-center justify-center w-52">
            <Button
              buttonType={ButtonType.SecondaryButton}
              className="text-sm border"
              buttonClass="whitespace-nowrap"
              onClick={onClick}
              child={
                <>
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />{" "}
                  <LocalizedText localeKey="homepage.appManagement.buttons.assignToAll" />
                </>
              }
            />
          </div>
        )}
        {!isAdmin && (
          <div className="flex items-center justify-center pl-4">
            <Button
              buttonType={ButtonType.LinkButton}
              child={
                <>
                  <LocalizedText localeKey="homepage.appManagement.buttons.learnMore"/> <AccessibleIcon icon={faArrowRight} labeledBy="learn-more-link" className="ml-2" />
                </>
              }
              onClick={toggleLearnMoreModal}
            />
          </div>
        )}
      </div>
      {!last && <hr className="divide-slate-100" />}
      <Modali.Modal {...learnMoreModal}>
        <div className="flex items-center w-full py-2 border">
          <div className="flex items-center pl-5">
            <AppIcon id={icon} height={35} width={35} />
          </div>
          <div className="flex-1 items-center pl-2 font-medium text-[#3a3d46]">{name}</div>
          <button
            className="close float-right w-5 h-5 text-sm flex justify-center items-center text-theme-disabled mr-3"
            onClick={toggleLearnMoreModal}
          >
            <AccessibleIcon icon={faTimes} labeledBy="close-learn-more" size="lg" />
          </button>
        </div>
        <div className="px-5 mb-5">
          <div className="my-4 font-medium">
            <LocalizedText localeKey="shared.description" />
          </div>
          <div className="text-sm font-normal">{description}</div>
        </div>
        <div className="inline-flex items-center justify-end p-4 bg-theme-light-background border w-full h-[64px]">
          <Button
            title={<LocalizedText localeKey="homepage.appManagement.dismiss.dismiss" />}
            buttonType="SecondaryButton"
            testDataAttribute="dismiss-learn-more-button"
            onClick={toggleLearnMoreModal}
          />
        </div>
      </Modali.Modal>
    </>
  );
};

export default ApplicationItem;
