import { create } from "zustand";

type State = {
  fromDate: any;
  toDate: any;
};

type Action = {
  setDate: (dates: any) => void;
};

export const useLandingPage = create<State & Action>()((set) => ({
  fromDate: "",
  toDate: "",
  setDate: async (dates: any) => {
    set((state) => ({
      ...state,
      fromDate: dates.from,
      toDate: dates.to,
    }));
  },
}));
