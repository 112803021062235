import React, { useEffect } from "react";
import AppIcon from "components/Application/AppIcon";
import ApplicationButton from "components/Application/ApplicationButton";
import useApplications from 'hooks/applications';
import useTranslations from 'hooks/utils/translations';
import { LocalizedText, NotificationCard } from "portal-common-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { isMobileOnly } from 'react-device-detect';

const Products: React.FC = () => {
    const applications = useApplications();
    const { translate } = useTranslations();

    useEffect(() => {
        applications.getApplicationsList(); 
    }, []);

    return (
        <div className="mt-4">
            <dl className="products" data-test="products">
                <dt className="mb-3 text-theme-medium">
                    <h4><LocalizedText localeKey="homepage.products.title" /></h4>
                </dt>
                {isMobileOnly && 
                  <NotificationCard
                    type="info"
                    messageLine1={<LocalizedText localeKey="homepage.products.mobileInfo"/>}
                  />
                }
                <div className="flex flex-wrap sml:flex-nowrap sml:flex-col">
                    {applications.loading 
                        ? <div className="mt-6 text-theme-medium"><LocalizedText localeKey="shared.loading" />...</div>
                        : applications.appList.map( ( product ) => {
                            return applications.appLinksMap[product.assignedApplication.applicationGuid] && (
                                <div key={product.assignedApplication.applicationGuid} className="sml:w-full">
                                    <ApplicationButton
                                        applicationGuid={product.assignedApplication.applicationGuid}
                                        ssoType={product.assignedApplication.ssoType}
                                        appLinksMap={applications.appLinksMap}
                                    >
                                        <dd className="mr-3 mb-3 bg-theme-on-primary sm:border rounded-md sm:border-gray-200 sml:shadow-lg flex justify-center items-center relative h-[214px] w-[218px] sml:w-auto sml:h-auto sml:mr-0 sml:justify-between sml:p-4">
                                            <div className="flex flex-col h-[140px] items-center justify-between sml:h-auto sml:flex-row">
                                                {product.newlyAdded && (
                                                    <span className="bg-theme-success text-white text-xs px-1 rounded-lg absolute top-3 right-2.5">
                                                        <LocalizedText localeKey="productOfferings.mainPage.pills.new"/>
                                                    </span>
                                                )}
                                                <AppIcon id={product.assignedApplication.applicationGuid} height={65} width={65} />
                                                <div className="text-theme-secondary font-normal text-base leading-5 h-[50px] sml:h-auto sml:font-medium sml:ml-4 sml:whitespace-nowrap">
                                                    <span><LocalizedText localeKey={product.assignedApplication.name} id={`${product.assignedApplication.applicationGuid}-label`}/></span>
                                                </div>
                                                {/* leaving the following code in case it is reinstated after the MVP deployment */}
                                                {/* { product.name === "Customer Engagement"
                                                    ?
                                                    <div className="multiline flex flex-col items-start max-w-footer-multiline">
                                                        <span className="leading-tight">{product.name}</span>
                                                        <span className="links leading-tight">
                                                            <a className="text-11px font-bold text-theme-primary no-underline" onClick={props.analyticsUrl} target="_blank">GO TO APP</a> 
                                                            {showLearnMore && <LearnMoreLink />}
                                                        </span>
                                                    </div>
                                                    :
                                                    <span>{product.name}</span>
                                                } */}
                                                {/* { product.badge && props.analyticsIsNew && <span className="pill ml-5">NEW</span>} */}
                                            </div>
                                            <FontAwesomeIcon icon={faAngleRight} size="lg" className="hidden sml:block" />
                                        </dd>
                                    </ApplicationButton>
                                </div>
                            )
                        })
                    }
                </div>
            </dl>
        </div>
    );
};

export default Products;