export function applyTheme(theme) {
    const root = document.documentElement;
    Object.keys(theme).forEach((cssVar) => {
      root.style.setProperty(cssVar, theme[cssVar]);
    });
}

export function createTheme({
    // brandPrimary,
    // white,
    // black,
    // brandSecondary,
    // accentLighter,
    // accentLight,
    // accentDark,
    // brandTertiary,
    // disabled,
    // info,
    // success,
    // successAlt,
    // warning,
    // danger,
    // dangerAlt,
    // greyLight,
    // grey,
    // greyDark,
	// greyMediumDark,
	// greyLightBorder,
  //   textLight,
  //   normal,
    // dark,
    // muted,
    // pill,
    // alertContent,
    // alertClose,
    // alertDanger,
    // alertWarning,
    // alertInfo,

    // Colors after new naming ->
    themePrimary,
    themePrimaryHover,
    themeOnPrimary,
    themeSecondary,
    themeLightBorder,
    themeDisabled,
    themeMedium,
    themeDark,
    themeLightBackground,
    themeInfo,
    themeInfoBackground,
    themeSuccess,
    themeWarning,
    themeWarningBackground,
    themeDanger,

    // Mobile Support
    themeDisabledMobile,
  }) {
    return {
        // "--theme-brand-primary":brandPrimary,
        // "--theme-white": white,
        // "--theme-black": black,
        // "--theme-brand-secondary":brandSecondary,
        // "--theme-accent-lighter": accentLighter,
        // "--theme-accent-light": accentLight,
        // "--theme-accent-dark": accentDark,
        // "--theme-brand-tertiary": brandTertiary,
        // "--theme-disabled": disabled,
        
        // "--theme-info": info, done (to be removed)
        
        // "--theme-success": success,
        // "--theme-success-alt": successAlt,
        // "--theme-warning": warning,
        // "--theme-danger": danger,
        // "--theme-danger-alt": dangerAlt,
        // "--theme-grey-light": greyLight,
        // "--theme-grey": grey,
        // "--theme-grey-dark": greyDark,
        // "--theme-grey-medium-dark": greyMediumDark,
        // "--theme-gray-light-border": greyLightBorder,
        // "--theme-text-light": textLight,
        // "--theme-normal": normal,
        // "--theme-dark": dark,
        // "--theme-muted": muted,
        // "--theme-pill": pill,
        // "--theme-alert-content": alertContent,
        // "--theme-alert-close": alertClose,
        // "--theme-alert-danger": alertDanger,
        // "--theme-alert-warning": alertWarning,


        // "--theme-alter-info": alertInfo,


        // Colors after new naming ->
        "--theme-primary":themePrimary,
        "--theme-primary-hover":themePrimaryHover,
        "--theme-on-primary":themeOnPrimary,
        "--theme-secondary":themeSecondary,
        "--theme-light-border":themeLightBorder,
        "--theme-disabled": themeDisabled,
        "--theme-medium":themeMedium,
        "--theme-dark":themeDark,
        "--theme-light-background":themeLightBackground,
        "--theme-info":themeInfo,
        "--theme-info-background":themeInfoBackground,
        "--theme-success":themeSuccess,
        "--theme-warning":themeWarning,
        "--theme-warning-background":themeWarningBackground,
        "--theme-danger":themeDanger,

        // Mobile Support
        "--theme-disabled-mobile": themeDisabledMobile,

    };
  }