import React, { useState, useEffect} from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { useUser, Button, ButtonType, NotificationCard, LocalizedText } from 'portal-common-ui';
import useAlertQueue from 'hooks/alertQueue';
import FormField from "components/Forms/FormField";
import { encryptData } from 'utils/Helper';
import config from 'config/config';
import { validateEmail as validateEmailHelper} from "utils/Helper";

interface RegisterMidProps {
  onCloseDialog: () => void;
  onSuccessfulMidRegister(): void;
  popupType: any;
};

const RegisterMid: React.FC<RegisterMidProps> = ({ 
  onCloseDialog,
  onSuccessfulMidRegister,
  popupType
}) => {

  const methods = useForm({
    mode: 'onChange',
  });
  const options = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const { registerMid, isSaving, getPublicKey, requestAccess } = useUser();
  const alertQueue = useAlertQueue();
  const [activeNotification, setActiveNotification] = useState(null);
  const [publicJWK, setPublicJWK] = useState('');
  const values = methods.getValues();
  const isEmailValid = !methods.getFieldState('email').invalid;
  const isMerchantIdValid = !methods.getFieldState('merchantId').invalid;

  useEffect(() => {
    if (popupType === 'requestAccess') {
      if (values?.email?.length > 0) {
        methods.resetField('merchantId');
      } else if (values?.merchantId?.length > 0) {
        methods.resetField('email');
      }
    }
  }, [values?.email, values?.merchantId])

  useEffect(() => {
    methods.reset({merchantId: '', ddaOrTaxId: ''});
  }, [methods.reset]);

  useEffect(() => {
    getPublicKey().then((res: any) => {
      setPublicJWK(res);
    }).catch((err: any) => {
      console.log('error getting public key');
      console.log(err);
    });
  }, []);

  const validateMerchant = (merchantId:string) => {
    let merchantIsValid = true;
    const filterforMerchant = new RegExp('^[0-9]*$');
    if (!filterforMerchant.test(merchantId)) {
      merchantIsValid = false;
    } 
    return merchantIsValid;
  }

  const validateDda = (ddaNumber:string) => {
    let isDDAValid = true;
    const filterForDDA = new RegExp('^[a-zA-Z0-9]+[a-zA-Z0-9-]*$');
    if (!filterForDDA.test(ddaNumber)) {
      isDDAValid = false;
    } 
    return isDDAValid;
  }

  const onRequestAccess = async () => {
    const formValues = methods.getValues();
    const resp = await requestAccess({
      merchantNumber: formValues.merchantId,
      email:formValues.email,
    });
    if (resp) {
      alertQueue.enqueueAlert({
        alertType: 'toast',
        messageType: 'success',
        messageKey: 'selfRegistration.requestAccess.alerts.accessRequestReceived',
        transitionDuration: 4000,
      });
      onCloseDialog();
    }
  }

  const onRegisterMid = async () => {
    setActiveNotification(null);
    const formValues = methods.getValues();
    const resp = await registerMid({
      merchantNumber: formValues.merchantId,
      ddaOrTaxId:
        process.env.NODE_ENV === "development" && config.mockApi
          ? formValues.ddaOrTaxId
          : encryptData(`${formValues.ddaOrTaxId}`, publicJWK),
    });
    if (resp && resp.ok) {
      if (onSuccessfulMidRegister) {
        onSuccessfulMidRegister();
      }
      alertQueue.enqueueAlert({
        alertType: 'toast',
        messageType: 'success',
        message: 'MID successfully registered.  Please note that changes may take a few minutes to reflect on the screen.',
        messageKey: 'editUser.sections.dataAccess.registerMID.messages.registeredMsg',
        transitionDuration: 4000,
      });
      onCloseDialog();
    } else if(resp.data?.errorCode == "user.hierarchyAssignment.alreadyAssigned") {
      setActiveNotification({
        type: 'warning',
        title: '',
        message: <LocalizedText localeKey="editUser.sections.preferences.alerts.alreadyHasDataAccess.message" />
      });
    }
    else {
      setActiveNotification({
        type: 'error',
        title: '',
        message: <LocalizedText localeKey="selfRegistration.alerts.noMatch.title" />
      });
    }
  };
  
  const onCancel = () => {
    methods.reset({keepDirty: false, keepErrors: false, keepValues: false});
    onCloseDialog();
  };

  const { isValid, isDirty } = methods.formState;
  const notificationVisible = activeNotification ? 'visible' : 'invisible';

  const validateEmail = (input: string) => {
    const emailInput = input.trim();
    const emailTest = validateEmailHelper(emailInput);
    return emailTest;
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col w-[500px] h-full" data-test="profile-register-mid-modal">
        <div className="items-center pl-6 font-normal text-base text-theme-dark pt-4 pb-2 border ">
          {popupType=== "registerMid" ? <LocalizedText localeKey="editUser.buttons.registerMID" /> : <LocalizedText localeKey="selfRegistration.requestAccess.title" />}
        </div>
        <div className="pl-0">
          {popupType === "registerMid" && <div className="items-center pl-6 text-xs text-theme-dark pt-4 pb-4">
            <LocalizedText localeKey="editUser.sections.dataAccess.registerMID.description" />
          </div>}      
          <div className="items-center w-[410px] pl-6 space-y-3">
            <FormField 
              disabled={isSaving || values?.email?.length > 0}
              name="merchantId"
              labelKey="editUser.sections.dataAccess.registerMID.merchantIDNumber"
              label= "Merchant ID"
              defaultValue=""
              required 
              maxLength={16}
              inputClass="w-96" 
              customValidate={validateMerchant} 
              errorKeys={{"required": "editUser.sections.dataAccess.registerMIDErrors.midNumberBlank", 
                          "validate": "editUser.sections.dataAccess.registerMIDErrors.invalidMidNumber", 
                          "maxLength":"editUser.sections.dataAccess.registerMIDErrors.midNumberMaxLength" }}
            />
            {popupType === 'requestAccess' ? (
            <FormField
              disabled={isSaving || values?.merchantId?.length > 0} 
              name="email" 
              label="Account Owner/Admin Email Address"
              labelKey="selfRegistration.requestAccess.EmailAccountOwner" 
              defaultValue=""
              required 
              maxLength={254} 
              inputClass="w-96 sml:w-full"
              containerClass="sml:w-full"
              customValidate={validateEmail} 
              errorKeys={{"required" : "createUser.sections.basicInfo.form.errors.emailBlank", 
                          "validate": "createUser.sections.basicInfo.form.errors.invalidEmail",
                          "maxLength":"createUser.sections.basicInfo.form.errors.emailMaxLength"}}
          />
            ) : (
              <FormField 
                disabled={isSaving}
                name="ddaOrTaxId"
                password
                labelKey="selfRegistration.taxID.title" 
                label= "Bank Account Number or Tax ID"
                defaultValue=""
                required 
                maxLength={16} 
                inputClass="w-96" 
                customValidate={validateDda} 
                errorKeys={{"required": "editUser.sections.dataAccess.registerMIDErrors.bankACNumberBlank", 
                            "validate": "editUser.sections.dataAccess.registerMIDErrors.invalidBankACNumber", 
                            "maxLength":"editUser.sections.dataAccess.registerMIDErrors.bankACNumberMaxLength" }}
              />
            )}
          </div>
          <div className={`pt-4 pl-6 mr-4 h-36`}>
            {activeNotification && 
            <NotificationCard
              type={activeNotification.type}
              title={activeNotification.title}
              messageLine1={activeNotification.message} 
              messageLine2={activeNotification.message2}
            />
            }
          </div>
        </div>
        <div className="inline-flex space-x-48 items-center justify-start pt-3 pb-2.5 bg-theme-light-background border mt-2" style={{border: '1px solid #D7DCE1', height: 64}}>
          <div className="flex items-start justify-start pl-2 pr-4">
              <div className="inline-flex flex-col items-center justify-start px-4 py-2 rounded-sm">
                  <Button
                    titleKey="shared.alerts.cancel"
                    buttonType={ButtonType.LinkButton}
                    buttonClass="text-red-700"
                    testDataAttribute="profile-mid-register-cancel"
                    onClick={onCancel}
                  />
              </div>
          </div>
          <div className="flex-grow grid grid-cols-1 place-items-end space-x-2.5 items-start x-4 px-4">
              <div>
                <Button
                  titleKey={popupType === "registerMid" ? "applications.mp-accounts.perm.registerMid.name" : "selfRegistration.requestAccess.title"}
                  title={popupType === "requestAccess" ? "Request Access" : ""}
                  buttonType={ButtonType.PrimaryButton}
                  disabled={!(isEmailValid && isMerchantIdValid) || !isDirty || (popupType === "requestAccess" && (!values?.email || values?.email.length === 0) && (!values?.merchantId || values?.merchantId.length === 0))}
                  testDataAttribute= "profile-mid-register-submit"
                  buttonClass = "whitespace-nowrap"
                  isProcessing={isSaving}
                  processingCaption={popupType === "registerMid" ? "Registering..." : "Requesting..."}
                  processingCaptionKey={popupType === "registerMid" ? "editUser.buttons.processingCaptions.registering" : ""}
                  onClick={popupType === "registerMid" ? onRegisterMid : onRequestAccess}>
                </Button>
              </div>
          </div>
        </div>
      </div>
    </FormProvider>
    );
}

export default RegisterMid;
