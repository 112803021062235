import { create } from "zustand";

type State = {
  applicationSubscribed: boolean;
  buttonTitle: string;
  title: string;
  offeringData: any;
  myCurrentSubsription: boolean;
  removeApplication: boolean;
};

type Action = {
  setModalData: (props: any) => void;
  setApplicationSubscribed: (value: boolean) => void;
};

export const useProductModalStore = create<State & Action>()((set) => ({
  applicationSubscribed: false,
  buttonTitle: "",
  title: "",
  offeringData: "",
  myCurrentSubsription: false,
  removeApplication: false,
  setApplicationSubscribed: async (value: boolean) => {
    set((state) => ({
      ...state,
      applicationSubscribed: value,
    }));
  },
  setModalData: async (props: any) => {
    set((state) => ({
      ...state,
      ...props,
    }));
  },
}));
