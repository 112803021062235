import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import FormSelect from "components/Forms/FormSelect";
import CheckList from "components/CheckList/CheckList";
import { isMobileOnly } from 'react-device-detect';
import { useRoles, Button, NotificationCard, LocalizedText, useLocale, useUser } from "portal-common-ui";
import { isDeepEqual } from "react-use/lib/util";
import useApplications from "hooks/applications";
import useFeatureFlags from "hooks/utils/featureFlags";
import { Option } from "components/UserWizard/SelectOption";

const RoleWithPermissionsSelector = ({
  roleLabel = <LocalizedText localeKey="createUser.sections.applicationRoles.form.roleTitle"/>,
  permissionsLabel = <LocalizedText localeKey="createUser.sections.applicationRoles.form.permission" />,
  currentUser,
  targetUser,
  onRolePermissionChanged,
  targetHierarchies,
  applicationGuid = null,
  selectedApp,
  onClose,
  type,
  brandDisabled = false,
  hasFullPortfolioAssigned
}) => {
  const { accountsBrandListEnabled, accountsOptionalPermissionsEnabled } = useFeatureFlags();
  const { appId, getRoles, getPermissions, getNotificationForRoleChange } = useRoles(
    null,
    applicationGuid,
    selectedApp?.applicationId
  );
  const [selectedRole, setSelectedRole] = useState(null);
  const [defaultOption, setDefaultOption] = useState(null);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const applications = useApplications();
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [userHasMoreBrands, setUserHasMoreBrands] = useState(false);
  const [activeNotification, setActiveNotification] = useState(null);
  const { translateToString } = useLocale();
  const { authenticatedUser: sessionUser, getAuthenticatedSessionUser } = useUser();
  const [maxHeight, setMaxHeight] = useState('70vh');
  const showBrandsDropdown =
    type === "EDIT" &&
    accountsBrandListEnabled &&
    selectedRole?.roleType === "INTERNAL" &&
    selectedApp.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS";

  useEffect(() => {
    const fetchRoles = async () => {
      if (targetUser?.email) {
        let roleGuid = '';
        const userResult = await getAuthenticatedSessionUser();
        userResult?.userApplicationAssignments?.results?.forEach((app) => {
          if (app.assignedApplication.applicationGuid === selectedApp.applicationGuid) {
            roleGuid = app.assignedApplication.applicationRoleAssociations?.[0]?.role?.roleGuid;
          }
        });
        getRoles(targetUser.email?.trim(), roleGuid).then((res) => {
          setRoles(res);
          if (selectedApp?.roleId) {
            const userRole = res.find((x) => x.roleId === selectedApp?.roleId);
            if (userRole) {
              setSelectedRole(userRole);
              setDefaultOption(userRole);
              setUserPermissions(selectedApp?.optionalPermissionIds);
            }
          }
        });
      } else {
        setRoles([]);
      }
    };
    fetchRoles();
    return () => {
      setDefaultOption();
      setSelectedRole();
    };
  }, [targetUser?.email, applicationGuid]);

  const onRoleSelected = (role) => {
    if (role?.roleId !== selectedRole?.roleId) {
      setUserPermissions([]);
      setSelectedRole(role);
      if (selectedApp.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS" && type === "EDIT") {
        setActiveNotification(
          getNotificationForRoleChange(targetUser?.roleObject, role, hasFullPortfolioAssigned)
        );
      }
    }
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      setIsLoadingPermissions(true);
      let userAppId = "";
      applications.appList.forEach((app) => {
        if (app.applicationId === appId) {
          userAppId = app.userApplicationAssignmentId;
        }
      });
      const payload = {
        applicationId: appId,
        roleId: selectedRole?.roleId,
        hierarchyIds: targetHierarchies
      }
      const response = await getPermissions(undefined, payload);
      const list = response?.permissions?.map((p) => ({
        label: p.permissionGuid.toLowerCase().split("_").join(" "),
        labelKey: p.name,
        value: p.permissionId,
        selected: p.permissionType == "DEFAULT" || (selectedApp?.roleId === selectedRole?.roleId && selectedApp?.optionalPermissionIds?.indexOf(p.permissionId) > -1),
        disabled: p.permissionType == "DEFAULT",
      }));
      setPermissions(list || []);
      setIsLoadingPermissions(false);
    };

    if (selectedRole?.roleId) {
      fetchPermissions();
    }
  }, [selectedRole]);

  useEffect(() => {
    if (showBrandsDropdown) {
      const brandsOptions = currentUser.brands
        .map((brand) => ({
          value: brand,
          label: brand,
        }))
        .sort((a, b) => (a.value > b.value ? 1 : -1));

      setSelectedBrands(brandsOptions.filter((b) => selectedApp.brands.indexOf(b.label) !== -1));

      if (brandsOptions.length > 1) {
        brandsOptions.unshift({ label: translateToString('createUser.sections.basicInfo.form.selectBrands.allBrands'), value: "all" });
      }
      setBrands(brandsOptions);

      const userHasMoreBrands = targetUser.brands.some((b) => currentUser.brands.indexOf(b) === -1);
      setUserHasMoreBrands(userHasMoreBrands);
    }
  }, [targetUser, currentUser, showBrandsDropdown]);

  useEffect(() => {
    if (isMobileOnly) {
      const updateMaxHeight = () => setMaxHeight(`${window.innerHeight * 0.7}px`);
      updateMaxHeight();
      window.addEventListener('resize', updateMaxHeight);
      return () => window.removeEventListener('resize', updateMaxHeight);
    }
  }, []);

  const handlePermission = (data) => {
    setPermissions(data);
    const op = data.reduce((optional, item) => {
      if (!item.disabled && item.selected) {
        optional.push(item.value);
      }
      return optional;
    }, []);
    setUserPermissions(op);
  };

  const methods = useForm({
    mode: "onChange",
  });

  const handleRoleAndPermission = () => {
    onRolePermissionChanged(selectedApp, selectedRole, userPermissions, selectedBrands.map(b => b.value));
    onClose();
  };

  const newApplication = !selectedApp?.roleId && selectedRole;
  const roleUpdated = selectedRole && (selectedApp?.roleId !== selectedRole?.roleId || !isDeepEqual(selectedApp?.optionalPermissionIds?.sort(), userPermissions?.sort()));
  const brandUpdated = showBrandsDropdown && !isDeepEqual(selectedApp?.brands?.sort(), selectedBrands.map(b => b.value).sort());

  const enableUpdate = newApplication || roleUpdated || brandUpdated;

  const brandInvalid = showBrandsDropdown && selectedBrands?.length === 0;

  const multiBrandAvailable = brands.length > 1;

  const roleNotValid = activeNotification?.type === "error";

  return (
    <FormProvider {...methods}>
      <div className="bg-white rounded-sm border-gray-100 p-4 overflow-auto" style={{ minHeight: "325px", maxHeight }}>
        <div className="grid grid-cols-2 lrg:grid-cols-1">
          <div>
            <div className="text-normal font-medium pb-2 text-theme-dark">
              {roleLabel}: <span className="text-theme-danger">*</span>
            </div>
            <div className="pb-8">
              <FormSelect
                name="role"
                defaultOption={defaultOption}
                required
                options={roles}
                onSelection={onRoleSelected}
                inputClass={"w-full"}
                disabled={!roles.length}
                placeholder={translateToString('createUser.sections.basicInfo.form.selectRoles.placeholder')}
                id="edit-user-role"
                instanceId="edit-user-role"
                inputId="edit-user-role-input"
              />
              {activeNotification && (
                <div className="pt-4 w-96 sml:w-full">
                  <NotificationCard
                    type={activeNotification.type}
                    title={activeNotification.title}
                    messageLine1={activeNotification.message}
                    messageLine2={activeNotification.message2}
                  />
                </div>
              )}
              {showBrandsDropdown && (
                <div className="mt-4">
                  <div className="text-normal font-medium pb-2 text-theme-dark">
                  <LocalizedText localeKey="editUser.sections.applicationAndRoles.brandLabel"/>: <span className="text-theme-danger">*</span>
                  </div>
                  <FormSelect
                    components={{
                      Option,
                    }}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    name="brand"
                    defaultOption={selectedBrands}
                    required
                    inputClass="w-96"
                    options={brands}
                    onSelection={(value) => setSelectedBrands(value)}
                    placeholder={translateToString("editUser.sections.personalInformation.brands.placeholder")}
                    isMulti={multiBrandAvailable}
                    sorted={false}
                    disabled={!multiBrandAvailable || brandDisabled}
                  />
                  {userHasMoreBrands && (
                    <span className="italic text-xs">
                      {'* '}<LocalizedText localeKey="editUser.sections.personalInformation.brands.outsideOfAccess" />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          {accountsOptionalPermissionsEnabled && selectedRole?.roleId && (
            <CheckList
              inputWidth="w-full"
              fieldLabel={<>{permissionsLabel}:</>}
              lableClass="text-normal font-medium block mb-2 text-theme-dark"
              options={permissions}
              onChange={handlePermission}
              containerClass="lrg:w-full ml-4 lrg:ml-0 !my-0"
              locale={sessionUser.languageAbbr}
              isLoading={isLoadingPermissions}
            />
          )}
        </div>
      </div>
      <div className="inline-flex items-center justify-start p-4 bg-theme-light-background border w-full h-[64px]">
        <div className="flex items-start justify-start px-4">
          <button
            type="button"
            onClick={onClose}
            className="text-sm font-bold leading-snug text-center whitespace-nowrap text-theme-danger"
            data-test="cancel-role-button"
          >
           <LocalizedText localeKey="shared.alerts.cancel"/>
          </button>
        </div>
        <div className="flex flex-grow justify-end">
          <Button
            title="Update Role"
            buttonType="PrimaryButton"
            disabled={!enableUpdate || brandInvalid || roleNotValid}
            onClick={handleRoleAndPermission}
            testDataAttribute="update-role-button"
            titleKey={`createUser.buttons.${type === "BULK" ? "assignRole" : "updateRole"}`}            />
        </div>
      </div>
    </FormProvider>
  );
};

export default RoleWithPermissionsSelector;