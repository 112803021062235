import config from "config/config";
import { useFlags } from "launchdarkly-react-client-sdk";

export const accountsLandingPageQuickLinks = [
  {
    appId: "MERCHANT_PORTAL_REPORTING",
    url: "report-builder",
    label: "Create custom report",
    labelKey: "",
    permissionKey: "CAN_CREATE_CUSTOM_REPORTS",
  },
  {
    appId: "MERCHANT_PORTAL_REPORTING",
    url: "messages/inbox",
    label: "Create message",
    labelKey: "",
    permissionKey: "VIEW_MESSAGES",
  },
  {
    appId: "MERCHANT_PORTAL_REPORTING",
    url: "reports",
    label: "Reports",
    labelKey: "",
    permissionKey: "CAN_VIEW_REPORTS",
  },
  {
    appId: "ACE_CUSTOMER_ENGAGEMENT",
    url: "",
    label: "Analytics",
    labelKey: "",
    permissionKey: "",
  },
];

const useFeatureFlags = () => {
  const flags = useFlags();

  return config.mockApi
    ? {
        accountsDataAccessManagementEnabled: true,
        accountsMerchantDemographicsEnabled: true,
        accountsBrandListEnabled: true,
        accountsOptionalPermissionsEnabled: true,
        accountsUserManagementLockedFilterEnabled: true,
        accountsCloneUserEnabled: true,
        accountsApplicationManagementEnabled: true,
        accountsMultiPortfolioEnabled: false,
        accountsPrivacyTermsLinksEnabled: true,
        accountsMerchantDemographicsPrimaryContactEnabled: true,
        accountsRequestAccessStatusEnabled: true,
        accountsLandingPageEnabled: false,
        accountsLandingPageQuickLinks: accountsLandingPageQuickLinks,
        accountsLandingPageGraphReports: [],
        accountsLandingPageGraphEnabled: true,
        accountsEnabledLanguages: [
          {
            label: "English (US)",
            value: "en-US",
          },
          {
            label: "Mexican Spanish",
            value: "es-MX",
          },
        ],
      }
    : flags;
};

export default useFeatureFlags;
