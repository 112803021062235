import React from "react";
import { components } from "react-select";

export const Option = (props) => {
  const {
    getValue,
    options,
    isSelected,
    data: { isFixed, value },
  } = props;

  const showSelected =
    isSelected ||
    (getValue().length &&
      getValue().length === options.length - 1 &&
      value === "all");

  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={showSelected}
        onChange={() => null}
        disabled={isSelected && isFixed}
      />{" "}
      <label>{value === "all" ? <b>{props.label}</b> : props.label}</label>
    </components.Option>
  );
};
