export const convertOfferingDataToProducts = (offerings) => {
    return offerings.map(({ offeringName, offeringPricing, isSubscribed, isUpGrade, offeringGroupId, offeringPriorityInGroup, offeringId, subscriptionId }) => ({
      name: offeringName,
      price: offeringPricing.price,
      frequency: offeringPricing.frequency,
      currency: offeringPricing.currency,
      isSubscribed: isSubscribed,
      isUpGrade: isUpGrade,
      groupId: offeringGroupId,
      offeringPriorityInGroup: offeringPriorityInGroup,
      planId: offeringId,
      subscriptionId: subscriptionId,
    }));
};