import React from "react";

const Card = props => {
  return (
      <div className="card flex my-12 mx-0 bg-theme-on-primary border border-solid border-gray-200 rounded-md">
          {props.children}
      </div>
  );
};


export default Card;