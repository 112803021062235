import React, { useRef } from "react";
import useStateRef from "react-usestateref";
import ReadOnlyFormField from "components/Forms/ReadOnlyFormField";
import { ReactComponent as PenImage } from "assets/icons/pen.svg";
import { Pill, BVLoader20, HierarchyDescriptorLabel, LocalizedText } from "portal-common-ui";
import Modali from "modali";
import BasicInformationCreateUser from "./BasicInformationCreateUser";
import CheckToggle from "components/Forms/CheckToggle";
import UserProfileOverview from "components/Profile/UserProfileOverview";
import useFeatureFlags from "hooks/utils/featureFlags";


const ReviewConfigurationTab = ({
  hasFullPortfolio,
  onPrevStep,
  basicInfoState,
  saveBasicInfoState,
  resetRole,
  dataAccessState,
  reviewConfigState,
  saveReviewConfigState,
  setShowNotification,
  showNotification,
  isSaving,
  basicInfoModal,
  toggleBasicInfoModal,
  setSelectedRole,
  selectedRoleRef,
  header,
  footer,
  changeStep,
  selectedGroups,
  latestApplications,
}) => {
  const [sendEmail, setSendEmail, sendEmailRef] = useStateRef(
    reviewConfigState.sendEmail
  );
  const userInfoRef = useRef(null);
  const { accountsApplicationManagementEnabled, accountsBrandListEnabled } = useFeatureFlags();

  const isFullPortfolioSelected = (selectedHierarchyRow) => {
    return (
      selectedHierarchyRow.nodes &&
      selectedHierarchyRow.nodes[0] &&
      selectedHierarchyRow.nodes[0].selectedChild &&
      selectedHierarchyRow.nodes[0].selectedChild.label === "All"
    );
  };

  const getPillData = (selectedHierarchyRow) => {
    let midsPillSkin = "theme-info";
    let midsPillLabel = <LocalizedText localeKey="shared.dataAccess.fullPortfolio" />
    if (!isFullPortfolioSelected(selectedHierarchyRow)) {
      if (
        selectedHierarchyRow.selectedMids === undefined ||
        selectedHierarchyRow.selectedMids.length === 0
      ) {
        midsPillLabel = <LocalizedText localeKey="shared.dataAccess.allMIDS" />;
      } else {
        midsPillLabel = (<>{selectedHierarchyRow.selectedMids.length} <LocalizedText localeKey="shared.dataAccess.mids" /></>); // of ${availableMids.length}
        midsPillSkin = "theme-success";
      }
    }
    return {
      midsPillSkin,
      midsPillLabel,
    };
  };

  const renderRows = () => {
    return (
      <div className="flex flex-col">
        {dataAccessState.filter(m => m?.searchBy?.value !== 'DataAccessGroup').map((row, idx) => {
          const directMIDPortfolios = row?.searchBy?.value === "MID" && row?.selectedMids && Array.from(new Set(row?.selectedMids.map((m) => m.affiliation)));
          if (row) {
            let pillData = getPillData(row);
            return (
              <div className="flex items-center justify-center" key={idx}>
                <div className="flex justify-start w-[320px] xl:w-[360px]">
                  <HierarchyDescriptorLabel 
                    tiers={row.nodes} 
                    selectedPortfolios={directMIDPortfolios || [row.selectedPortfolio]} 
                    portfolioClass="min-w-[40px] mx-2 my-auto text-theme-info font-medium text-lg"
                  />
                </div>
                <Pill
                  themeColor={pillData.midsPillSkin}
                  labelText={pillData.midsPillLabel}
                />
              </div>
            );
          }
        })}
      </div>
    );
  };

  const onEditPersonalInfo = () => {
    changeStep("basicInfo");
  };

  const toggleEmailSent = () => {
    const nextState = !sendEmailRef.current;
    setSendEmail(nextState);
    const newReviewConfigState = reviewConfigState;
    newReviewConfigState.sendEmail = nextState;
    saveReviewConfigState(newReviewConfigState);
  };

  const clonedHeaderWithPropsChange = React.cloneElement(header, {
    title: "Create New User - Edit Personal Information",
  });
  const clonedFooterWithPropsChange = React.cloneElement(footer, {
    nextButtonCaption: "Apply Changes",
  });

  const { brand, role } = basicInfoState;
  const brandDisplay =
    brand &&
    (brand.length ? brand.map((b) => b.label).join(", ") : brand.label);

  return (
    <>{!accountsApplicationManagementEnabled ? 
      <div className="bg-theme-on-primary border rounded-sm border-theme-light-border border-t-0 border-b-0 relative w-full wizard-container-h min-h-wizard-container-inner-md t-xl:min-h-wizard-container-inner-xl t-2xl:min-h-wizard-container-inner-2xl">
        {isSaving ? (
          <BVLoader20 bgColor={"#FFFFFF"} />
        ) : (
          <div>
            <div className="inline-flex flex-col t-xl:space-y-5 items-start justify-start py-2 t-xl:py-4 px-2 xl:px-8 bg-theme-on-primary rounded-sm w-full">
              <ReadOnlyFormField label="User Information" labelKey="createUser.sections.reviewConfiguration.reviewConfUserInfoTab.userInfoTab">
                <div
                  className="px-2 xl:px-4 py-4 w-[465px] xl:w-[540px] text-base border text-theme-dark font-normal grid grid-cols-1"
                  ref={userInfoRef}
                >
                  <div>
                    {basicInfoState.firstName} {basicInfoState.lastName}
                  </div>
                  <div className="grid place-items-start mt-0.5">
                    {basicInfoState.email}
                  </div>
                  <div className="mt-4">
                    <span className="text-theme-medium"><LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfUserInfoTab.role"/> </span>
                    <span className="font-semibold">
                      {basicInfoState.role && basicInfoState.role.label}
                    </span>
                  </div>
                  {accountsBrandListEnabled && role && role.roleType === "INTERNAL" && (
                    <div className="mt-4">
                      <span className="text-theme-medium"><LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfUserInfoTab.brands"/>: </span>
                      <span className="font-semibold">{brandDisplay}</span>
                    </div>
                  )}
                </div>

                <div
                  className="p-4 border ml-4 cursor-pointer"
                  onClick={onEditPersonalInfo}
                  data-test="edit-user-info-cy"
                >
                  <PenImage />
                </div>
              </ReadOnlyFormField>
              <ReadOnlyFormField label="Data Access" labelKey="createUser.sections.dataAccess.title">
                <div className="bg-theme-on-primary rounded-sm px-2 xl:px-4 py-2 data-access-h min-h-[54px] xl:min-h-[62px] border text-theme-dark overflow-y-auto overflow-x-hidden w-[465px] xl:w-[540px]">
                  <div className="flex">{dataAccessState && renderRows()}</div>
                {selectedGroups?.filter(m => m)?.map((item, index) => (
                    <div className="flex flex-col justify-center mt-px h-full text-base font-medium text-gray-600 px-1">
                     {item.name}
                    </div>
                  ))}
                  </div>
                <div
                  className="p-4 border ml-4 flex items-start cursor-pointer"
                  onClick={() => changeStep("dataAccess")}
                  data-test="edit-data-access-cy"
                  aria-label="Edit Data Access"
                  id="edit-data-access"
                >
                  <button aria-labelledby="edit-data-access">
                    <PenImage />
                  </button>
                </div>
              </ReadOnlyFormField>
              <div className="min-w-[630px] xl:min-w-[777px] flex justify-end py-2 t-xl:py-4">
                <div className="text-base font-medium text-gray-600 px-6">
                  <LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfAdditionalOpt.adtionalTabToggle"/>
                </div>
                <CheckToggle
                  defaultChecked={sendEmail}
                  onChange={toggleEmailSent}
                />
              </div>
            </div>
            <Modali.Modal {...basicInfoModal}>
              <div className="flex-col items-center justify-center min-w-[700px] h-full">
                {clonedHeaderWithPropsChange}
                <BasicInformationCreateUser
                  hasFullPortfolio={hasFullPortfolio}
                  formData={basicInfoState}
                  saveStep={saveBasicInfoState}
                  resetRole={resetRole}
                  setShowNotification={setShowNotification}
                  showNotification={showNotification}
                  setSelectedRole={setSelectedRole}
                  selectedRoleRef={selectedRoleRef}
                />
                {clonedFooterWithPropsChange}
              </div>
            </Modali.Modal>
          </div>
        )}
      </div>
      :
      <div className="bg-theme-on-primary border rounded-sm border-theme-light-border border-t-0 border-b-0 relative w-full h-[380px] t-xl:h-full overflow-y-auto" data-test="user-information-cy">
        {isSaving ? (
          <BVLoader20 bgColor={"#FFFFFF"} />
        ):(
          <UserProfileOverview
          onPrevStep={onPrevStep}
          basicInfoState={basicInfoState}
          dataAccessState={dataAccessState}
          changeStep={changeStep}
          selectedGroups={selectedGroups}
          editable={true}
          userProfile={basicInfoState}
          latestApplications={latestApplications}
          type="create"
          reviewConfigState={reviewConfigState}
          saveReviewConfigState={saveReviewConfigState}
        />
        )}
      </div>
      }
    </>
  );
};

export default ReviewConfigurationTab;
