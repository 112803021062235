import React , {useState, useRef,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {IndexTooltip} from 'index-design-system-react';
import {useClickAway} from 'react-use';
import { LocalizedText, useLocale } from 'portal-common-ui';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


const WizardStepHeader = ({title, subtitle = '', showToolTip, toolTipTextKey, toolTipText, toolTipHeader, hidden, toolTipClassName, currentStepIndex, totalSteps}) => {

  const [openToolTip, setopenToolTip] = useState('invisible');
  const toolRef = useRef('');

  const onOpenTooltip =()=>{
    setopenToolTip('visible');
  }
  useClickAway(toolRef, () => {
    setopenToolTip('invisible');
  });
  const {translateToString} = useLocale();
  return (
    <div className={`${hidden && 'hidden'} w-full bg-theme-on-primary`}>
      
      {/* <div className="w-full flex space-x-12 items-center justify-start px-4" style={{height: 24}}>
          <div className="flex justify-start text-base font-medium leading-normal text-theme-dark flex gap-6 xl:gap-12 items-center">
            <div className="w-auto">{title}</div>
            <div className="flex justify-end">
              <div className="text-xs xl:text-sm text-theme-medium w-full font-normal">{subtitle}&nbsp;</div>
            </div>
        </div>         
      </div> */}

      <div className='flex border border-theme-light-border py-6 px-4 items-center'>
        <h2 className='m-0 leading-none font-medium text-theme-secondary'><LocalizedText localeKey="createUser.stepHeader.title" /></h2>
        <p className='ml-4'><LocalizedText localeKey="createUser.stepHeader.heading" /></p>
      </div>

      <div className='flex justify-between items-center border border-x-4 border-t-0 border-theme-light-border bg-theme-light-background p-4'>
        <div>
          <p className='font-normal text-sm mb-2'><LocalizedText localeKey="createUser.stepHeader.steps" templateVariables={{ currentStepIndex, totalSteps }} /></p>
          <h2 className='m-0 leading-none font-medium text-theme-info text-lg'>{title}</h2>
        </div>

        <div>
          <CircularProgressbar 
            value={(currentStepIndex/totalSteps) * 100}
            strokeWidth={15}
            className="w-10 h-10"
          />
        </div>
        
      </div>



      {/* <div>
  
      </div> */}

      
      {showToolTip &&
        <div className='flex items-center justify-center cursor-pointer' ref={toolRef} onClick={onOpenTooltip}>
          <div className='relative' >
            <div>
              < FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className={` absolute text-sm text-theme-dark font-normal rounded-lg ${openToolTip} w-56 right-0 left-0 h-56 z-20 ${toolTipClassName ? toolTipClassName:""}`}>
              <IndexTooltip
                arrow="top"
                error="false"
                text={toolTipTextKey ? translateToString(toolTipTextKey) : toolTipText}
                theme="light"
              />
            </div>
          </div>
          <div className='whitespace-pre italic text-theme-medium font-normal text-sm'>
            {toolTipHeader}
          </div>
        </div>}
    </div>
  );
};

export default WizardStepHeader;