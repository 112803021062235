import { LocalizedText } from "portal-common-ui";

const steps = [

    {
        stepName: 'selectUserToClone',
        title: <LocalizedText localeKey="cloneUser.sections.selectUserCloneTab.title"/>,
        headerText: <LocalizedText localeKey="cloneUser.sections.selectUserCloneTab.header"/>,
        headerSubtext: '',
        nextStep: 'basicInfo',
        isActive: true,
        isPending: false,
        isComplete: false,
    },
    {
        stepName: 'basicInfo',
        title: <LocalizedText localeKey="cloneUser.sections.newUserInfoTab.title"/>,
        headerText: <LocalizedText localeKey="cloneUser.sections.newUserInfoTab.header"/>,
        headerSubtext: '',
        nextStep: 'reviewProfile',
        isActive: false,
        isPending: true,
        isComplete: false,
        prevStep: "selectUserToClone"
    },
    {
        stepName: 'reviewProfile',
        title: <LocalizedText localeKey="cloneUser.sections.reviewConfTab.title"/>,
        headerText: <LocalizedText localeKey="cloneUser.sections.reviewConfTab.header"/>,
        headerSubtext: '',
        nextStep: 'creationComplete',
        isActive: false,
        isPending: true,
        isComplete: false,
        prevStep: "basicInfo"
    },
    {
        stepName: 'creationComplete',
        title: <LocalizedText localeKey="createUser.sections.creationComplete.title"/>,
        headerText: <LocalizedText localeKey="createUser.sections.creationComplete.heading"/>,
        nextStep: null,
        prevStep: 'reviewProfile',
        isActive: false,
        isComplete: false,
        isPending: true,
      },

]


export default steps;