import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from "react-hook-form";
import CreateWizard from 'components/CreateWizard/CreateWizard';
import { ReactComponent as DataAccessGroupSvg } from "assets/img/data-access-group-wizard.svg";
import { LocalizedText, NavigationWarning, useDataAccessGroup } from "portal-common-ui";
import useWizard from "hooks/wizard";
import steps from './steps'
import BasicDataAccessInfo from "components/DataAccessGroup/BasicDataAccessInfo";
import DataAccessTab from "components/DataAccessGroup/DataAccessTab";
import UserGroupCreationSuccess from "components/DataAccessGroup/UserGroupCreationSuccess";
import useAlertQueue from 'hooks/alertQueue';
import "./CreateDataAccessGroup.scss";
import { useGlobal } from 'libs/reactn';

const CreateDataAccessGroup = () => {
  const alertQueue = useAlertQueue();
  const [unmounted,setMount] = useState(false);
  const [basicInfoState, setBasicInfoState] = useState({});
  const { getNextStep, getPrevStep, updateWizardSteps } = useWizard();
  const imageSvg = <DataAccessGroupSvg title="Person sitting at a desk thinking of technology" />;
  const history = useHistory();
  const methods = useForm({ mode: 'onChange' });

  const [currentStep, setCurrentStep] = useState(steps[0].stepName);
  const [wizardSteps, setWizardSteps] = useState(steps);
  const [dataAccessState, setDataAccessState] = useState(null);
  const [allowFullPortfolioAccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [updatedDataRows, setUpdatedDataRows] = useState();
  const [canSaveDataAccess, setCanSaveDataAccess] = useState(false);
  const { createDataAccessGroup } = useDataAccessGroup();
  const [newGroupCreatedId, setNewGroupCreatedId] = useState('');
  const [auth, setAuth] = useGlobal('auth');
  const [allowedGroupNames] = useState(auth.session.user?.dags); 

  const readyToCreateGroup = currentStep === 'dataAccess' && updatedDataRows?.filter(m => m && m?.selectedHierarchy !== null || m?.selectedMids ).length !== 0 && updatedDataRows;
  let nextButtonCaptionKey = 'createUser.buttons.nextStep';
  if (readyToCreateGroup) {
    nextButtonCaptionKey = isSaving ? 'createDAG.buttons.creatingGroup' : 'createDAG.buttons.createGroup';
  }

  const onNextStep = () => {
    const nextStep = getNextStep(wizardSteps, currentStep);
    setCurrentStep(nextStep);
  };

  const onPrevStep = () => {
    const prevStep = getPrevStep(wizardSteps, currentStep);
    setCurrentStep(prevStep);
  };

  const onDataAccessUpdate = (updatedDataRows) => {
    setUpdatedDataRows(updatedDataRows);
  };

  const showPrevStep = currentStep !== steps[0].stepName;

  const onStepChange = (wizardStepStatus) => {
    const updatedWizardSteps = updateWizardSteps(wizardSteps, wizardStepStatus)
    setWizardSteps(updatedWizardSteps);
  };

  const defaultFormValues = {
    groupName:'',
    groupDesc:''
  };


  const isDirty = () => {
    // manually check if form is dirty, methods.formState.isDirty is bugged
    const formValues = methods.getValues();
    const dirty = !Object.keys(formValues).concat(Object.keys(defaultFormValues)).every(key => (formValues[key] === defaultFormValues[key]) || (formValues[key] === undefined && defaultFormValues[key] === undefined) );
    return dirty;
    // return methods.formState.isDirty;
  };

  const disableWarning = currentStep === steps[steps.length - 1].stepName || !isDirty() || isSaving;
 
  const customIsValid = () => {
    if (currentStep === 'dataAccess') {
      return canSaveDataAccess;
    } else  {
      return methods.formState.isValid;
    } 
  };
  const onWarningConfirmed = () => {
    if(currentStep === steps[steps.length - 1].stepName) {
      clearState();
      setReload();
    }
   }

  const onCancel = () => {    
    history.push('/data-access-management');
  };

  if(unmounted){
    return null
  }

  const onSetBasicInfo = (newState) => {
    setBasicInfoState(newState);
  };


  const tryWithNewDataGroupName = () => {
    setIsSaving(false);
  }

  const showDuplicateGroupNameError = () => {
    alertQueue.enqueueAlert({
      alertType: 'modal',
      title: 'Group Creation Failed',
      messageType: 'error',
      continueAction: () => { tryWithNewDataGroupName() },
      message: 'A data access group with this name already exists.  Please select another name.',
    });
  };
  
  const onCreateDataAccessGroup = async () => {
    const nodeHierarchies =
    updatedDataRows
        .filter(row => row && row.selectedMids.length === 0)
        .map(row => row.selectedHierarchy.hierarchyId);
    const midHierarchies =
    updatedDataRows
        .filter(row => row && row.selectedMids.length > 0)
        .flatMap(row => row.selectedMids)
        .map(m => m?.hierarchyId ? m.hierarchyId : m);

    const hierarchies = nodeHierarchies.concat(midHierarchies);
    try {
      setIsSaving(true);
      const resp = await createDataAccessGroup({
        name: basicInfoState.groupName?.trim(),
        description: basicInfoState.groupDesc?.trimEnd(),
        hierarchies,
      });

      if (resp.errorCode === 'dataAccessGroup.name.alreadyAssigned') {
        showDuplicateGroupNameError();
      } else if (resp.status !== 201) {
        setIsSaving(false);  
      }
      else {
       const newDagList = allowedGroupNames.concat(basicInfoState.groupName.toLowerCase());
        setAuth(newAuth => {
          newAuth.session.user.dags = newDagList;          
        });
        setIsSaving(false);
        setNewGroupCreatedId(resp.content);
        onNextStep();
      }
    } catch (e) {
      console.log(e)
      alert(e)
    }
  };
       
  const navigateToUsersAssignedTab = () => {
      history.push(`/data-access-group/view/${newGroupCreatedId}`,  { from: 'Add Another User' } );
  }

  const onSaveAndContinue = () => {

    switch (currentStep) {
      case 'basicInfo':
        onSetBasicInfo(methods.getValues());
        onNextStep();
        break;
      case 'dataAccess':
        setDataAccessState(updatedDataRows);        
        onCreateDataAccessGroup();  
      }
  }

  const setReload = () => {
    setMount(true)
    setTimeout(() => {
      setMount(false)
    }, 200)
  }

  const clearState = () => {
    setCurrentStep(steps[0].stepName);
    setBasicInfoState(defaultFormValues);  
    setDataAccessState(null);
    setUpdatedDataRows(null);
    setIsSaving(false);
  }

  return (
    <FormProvider {...methods}> 
      <CreateWizard 
        steps={steps}
        backToButtonKey="createDAG.buttons.backToDAGList"
        backToButtonRoute={'/data-access-management'}
        imageSvg={imageSvg}
        title={<LocalizedText localeKey="createDAG.title"/>}
        subTitle={<LocalizedText localeKey="createDAG.subTitle"/>}
        onCancel={onCancel}
        onNextStep={onSaveAndContinue}
        showPrevStep={showPrevStep}
        onPrevStep={onPrevStep}
        onStepChange={onStepChange}
        currentStep={currentStep}
        wizardSteps={wizardSteps}
        nextButtonCaptionKey={nextButtonCaptionKey}
        customIsValid={customIsValid}
        showButtonLoader={isSaving}
        hideHeader={currentStep === 'success'}
        hideFooter={currentStep === 'success'}
      >

      <BasicDataAccessInfo
          stepName="basicInfo"
          formData={basicInfoState}
          saveStep={onSetBasicInfo}
          onSaveAndContinue={onSaveAndContinue}
          allowedGroupNames={allowedGroupNames}
      />

      <DataAccessTab
          stepName="dataAccess"
          allowFullPortfolioAccess={allowFullPortfolioAccess}
          dataRows={dataAccessState}
          setDataAccessState={setDataAccessState}
          onSaveAndContinue={onSaveAndContinue}
          saveStep={setDataAccessState}
          updatedDataRows={updatedDataRows}
          onDataAccessUpdate={onDataAccessUpdate}
          setCanSave={setCanSaveDataAccess}
        />

        <UserGroupCreationSuccess
          stepName="success"
          formData={basicInfoState}
          navigateToUsersAssignedTab={navigateToUsersAssignedTab}
        />

      </CreateWizard>

      <NavigationWarning
          warningLine1="This will exit the Create New Data Access Group Wizard and you will lose all of the information you have entered."
          warningLine1Key = "createDAG.warnings.cancelWarning"
          warningLine2="Do you wish to cancel and discard your changes?"
          warningLine2Key="createDAG.warnings.discardWarning"
          continueCaption="Yes, Cancel"
          continueCaptionKey="createUser.alerts.navigationWarning.continueCaption"
          cancelCaption="No, Return to wizard"
          cancelCaptionKey="createUser.alerts.navigationWarning.cancelCaption"
          onWarningConfirmed={onWarningConfirmed}
          disableWarning={disableWarning}
          skipWarningRouteList={['/loggedOut']}
      />
    </FormProvider>
  );
};

export default CreateDataAccessGroup;