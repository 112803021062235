import React from 'react';
import { faSync} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonType, LocalizedText, useLocale } from 'portal-common-ui';

const WizardStepFooter = ({enableNextStep, showPrevStep, onNextStep, onPrevStep, onCancel, nextButtonCaption = "Next Step", nextButtonCaptionKey = "", isSaving, hidden, showButtonLoader }) => {

  const { translateToString } = useLocale();
  let disableNext = (typeof enableNextStep === 'function') ? !enableNextStep() : !enableNextStep;
  return (
    <div className={`${hidden && 'hidden'} inline-flex items-center justify-start pt-3 pb-2.5 bg-theme-light-background border w-full border-theme-light-border`} style={{height: 64}}>
      <div className="flex items-start justify-start pl-6 pr-4">
          <div className="inline-flex flex-col items-center justify-start px-4 py-2 rounded-sm">
              <Button titleKey="shared.alerts.cancel" disabled={isSaving || showButtonLoader} buttonType={ButtonType.LinkButton} buttonClass="text-red-700" onClick={onCancel} testDataAttribute="wizard-cancel-button" />
          </div>
      </div>
      <div className="inline-flex flex-grow justify-end grid-cols-2 place-items-end items-start">
          <div>
            {showPrevStep &&
              <Button
                titleKey="createUser.buttons.previousStep"
                buttonType={ButtonType.LinkButton}
                disabled={isSaving || showButtonLoader}
                onClick={onPrevStep}
                testDataAttribute="wizard-previous-button"
              />
            }
          </div>
          <div>

            <Button
              disabled={disableNext || isSaving || showButtonLoader}
              buttonType={ButtonType.PrimaryButton}
              // buttonClass="inline-flex flex-row mr-6 items-center justify-end px-4 py-2 border rounded-sm text-theme-on-primary border-gray-300 bg-accent-light text-sm font-bold leading-snug text-center text-theme-on-primary disabled:text-theme-medium disabled:bg-transparent disabled:border-0"
              onClick={onNextStep}
              testDataAttribute={`wizard-${(nextButtonCaptionKey ? translateToString(nextButtonCaptionKey) : nextButtonCaption).replace(/\s/g, '-').toLowerCase()}-button`}
                
              title={ <div className={`${showButtonLoader ? 'flex space-x-2 border px-2  border-theme-light-border border-box rounded-sm' : ''} `}>
                        {showButtonLoader && <div><FontAwesomeIcon icon={faSync}></FontAwesomeIcon></div>}
                        <div>{nextButtonCaptionKey ? <LocalizedText localeKey={nextButtonCaptionKey} /> : nextButtonCaption}</div>
                      </div>
                    }

            />

          </div>
      </div>
    </div>
  );
};

export default WizardStepFooter;

