const localConfig = {
  env: {
    name: 'mqa',
  },
  API: {
    userUrl: 'https://secure-api-qa.np-reporting.globalpay.com/nextgen/userservicenew/api/v1',
    selfRegisterUrl: 'https://api-qa.np-reporting.globalpay.com/Accounts/api/v2/users/register/merchant',
    notificationsUrl: 'https://api-qa.np-reporting.globalpay.com/SharedNotifications/api/v1/',
    hierarchyUrl: 'https://secure-api-qa.np-reporting.globalpay.com/nextgen/hierarchy/api/v1',
    applicationUrl: 'https://secure-api-qa.np-reporting.globalpay.com/nextgen/application/api/v1',
    authUrl:  'https://secure-api-qa.np-reporting.globalpay.com/nextgen/authentication/api/v1.2',
    sysnetUrl: 'https://testsite-gpnna.sysnetqaa.com/services/clientapi/oauth/callback/oidc',
    reportingUrl: 'https://qa.np-reporting.globalpay.com/v2',
    citiReportingUrl: 'https://citi-qa.np-reporting.globalpay.com/v2',
    vtUrl: 'https://qa.np-vt.globalpay.com',
    giftUrl: 'https://qa-ui.gift.ovation.us',
    dimsUrl: 'https://qa3.np-disputesmanagement.heartland.us',
    analyticsSSO: "https://api-qa.np-reporting.globalpay.com/nextgen/analytics/api/v2/external/analytics/{0}?canHandle302Response=false",
    translationsUrl: 'https://secure-api-qa.np-reporting.globalpay.com/nextgen/translations/api/v1'
  },
  features: {
    mfaEnabled: true,
    showSysnet: true,
    showReporting: true,
    dataAccessGroupLimit: 100,
    enablePendo: true,
  },
  analytics: {
    enableDebug: true,
    trackingId: 'UA-81886938-2'
  },
  launchDarkly: {
    clientId: '6269984a5ce81a14f5bb9326',
  },
  logRocket: {
    sdkServer: 'https://qa.np-logrocket.globalpay.com',
    ingestServer: 'https://qa.np-logrocket.globalpay.com',
    appID: 'globalpay-dev/accounts-qa'
  }
};

export default localConfig;
