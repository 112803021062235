import { useGlobal } from 'libs/reactn';

const useFilterState = () => {

    const [filterItems, setFilterItems] = useGlobal('filterItems');

    const updateFilterItems = (filters) => setFilterItems({
        ...filterItems,
        ...filters,
    })

    return {
        filterItems,
        updateFilterItems,
    }
}

export default useFilterState;