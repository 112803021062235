import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import {useClickAway} from 'react-use';
import UserAvatar from "assets/libs/react-user-avatar";
import ProfileSettingsIcon from "assets/icons/mob-profile-settings.svg";
import LogoutIcon from "assets/icons/mob-logout-img.svg";
import useSession from 'hooks/session/session';
import AccountSupportPanel from './AccountSupportPanel';
import ApplicationsPanel from './ApplicationsPanel';
import useFeatureFlags from 'hooks/utils/featureFlags';
import './UserAvatar.scss';
import { Button, ButtonType, LocalizedText } from 'portal-common-ui';
import { localeKeys } from "typings/translate";
 
interface SubNavMenuProps {
  userInitials: string;
  userDisplayName: string;
  userEmailAddress: string;
  toggleSubNav: (type: string) => void;
  toggleOverlay: (label: string) => void;
  type: string;
  contacts: { name: string; phone: string; email: string }[];
  subNavItems: { name: string; nameKey: localeKeys, url: string; }[];
}

const SubNavMenu: React.FC<SubNavMenuProps> = ({ userInitials, userDisplayName, userEmailAddress, contacts, subNavItems, toggleSubNav, toggleOverlay, type }) => {
  const history = useHistory();

  const clickItemHandler = (item: { name: string; url: string; } ) => {
    toggleSubNav(type);
    history.push(item.url);
  };

  const session = useSession();
  const { user } = session;

  const ref = useRef(null);
  useClickAway(ref, () => {
      // will find a better solution to the set timout
      setTimeout(() => toggleOverlay(''), 200);
  });

  if (type === "profile") {
    return(
      <div className="navigation--header__menu profile nav-header-menu-item slide-in-top animate-navSlideIn" ref={ref}>
        <figure id="active-user" className="flex items-center px-4 py-4 border-b border-solid border-gray-300" role="group" aria-label={`The current user is ${userDisplayName}`}>
          <UserAvatar size="40" name={userInitials} />
          <figcaption className="ml-2 flex flex-col text-theme-dark text-sm font-medium" aria-describedby="active-user">
            <span className="truncate w-52">{userDisplayName}</span>
            <span className="text-xs text-theme-medium font-normal">{userEmailAddress}</span>
          </figcaption>
        </figure>
        {/* TODO: List Component */}
        <ul>
          { subNavItems.map( ( item, index) => {
				if(item.name==="Logout"){
					return <li className="px-4 py-3 whitespace-nowrap w-full hover:bg-theme-info hover:bg-opacity-20 border-t border-solid border-gray-300" key={index}>
            <Button
              buttonType={ButtonType.LinkButton}
              // buttonClass="text-sm text-accent-light font-medium"
              onClick={() => clickItemHandler(item)}
						  child={
                <div className="flex">
                  <img className="mr-2" src={LogoutIcon} alt="logout"/>
                  <LocalizedText localeKey={item.nameKey} />
                </div>
            }
					  />

					</li>
				}
				else if(!user.isGuestUser){
					return <li className="px-4 py-3 whitespace-nowrap w-full hover:bg-theme-info hover:bg-opacity-20 " key={index}>
            <Button
              buttonType={ButtonType.LinkButton}
              onClick={() => clickItemHandler(item)}
						  child={
                <div className="flex">
                  <img className="mr-2" src={ProfileSettingsIcon} alt="profile-settings"/>
                  <LocalizedText localeKey={item.nameKey} />
                </div>
            }
					  />
          
          </li>
				
				}
			}
          )}
        </ul>
      </div>
    );
  }

  if (type === "support") {
    return <AccountSupportPanel contacts={contacts} toggleOverlay={toggleOverlay} ref={ref} />
  }

  return(
    <div className="navigation--header__menu nav-header-menu-item slide-in-top animate-navSlideIn" ref={ref}>
      <ApplicationsPanel />
    </div>
  );

};

export default SubNavMenu;