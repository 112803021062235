import { useEffect, useState } from "react";
import CheckToggle from "components/Forms/CheckToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import  ApplicationTile  from "components/UserWizard/ApplicationTile";
import useApplications from "hooks/applications";
import { Button, BVLoader20, LocalizedText, useLocale, ButtonType, useUser, NotificationCard } from "portal-common-ui";
import {IndexTooltip} from 'index-design-system-react';

const ApplicationsTab = ({ setSelectedApplications, selectedCards, setSelectedCards, availableApplications, updateAvailableApplications, loadingSubscribedApps, serverError }) => {
  const { loading, filterAppList } = useApplications();
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const { translateToString } = useLocale();
  const { getAuthenticatedSessionUser } = useUser();
  const [loadingApplicationId, setLoadingApplicationId] = useState(null);
  const allCardIds = availableApplications.map(
    (application) => application.applicationId
  );
  
  const allDefaultTrue = [
    availableApplications.find((obj) => obj.assignedApplication.applicationGuid === "MERCHANT_PORTAL_ACCOUNTS")?.applicationId,
  ];

  useEffect(() => {
    const latestApplications = availableApplications.filter(app =>
      selectedCards.includes(app.applicationId)
    );
    setSelectedApplications(latestApplications);
  }, [selectedCards, availableApplications]);

  const applicationsWrapperClass =
    "flex flex-wrap flex-row items-stretch gap-6 w-full shrink-0";
    const applicationsClass = (application) => {
      let applicationGuid = application.assignedApplication.applicationGuid;
      let applicationId = application.assignedApplication.applicationId;
      let isInactive = application.assignedApplication.status === 'INACTIVE';
      return `flex flex-col relative align-center items-center px-3 py-4 h-full w-[220px] border-2 ${
        isInactive ? "text-theme-disabled border-theme-light-border hover:bg-gray-200" :
        (applicationGuid === "MERCHANT_PORTAL_ACCOUNTS" || !selectedCards.includes(applicationId)
          ? "border-theme-light-border"
          : "border-theme-primary")
      }`;
  };

  const toggleSelectAll = (isCardSelected) => {
    if (isCardSelected) {
      const activeCardIds = availableApplications.filter(app => app.assignedApplication.status !== 'INACTIVE').map(app => app.applicationId);
      setSelectedCards(activeCardIds); // select all active cards
    } else {
      setSelectedCards(allDefaultTrue); // Deselect all cards
    }
  };

  const toggleCardSelection = (cardId) => {
    let updatedSelectedCards;
    let SelectedCardsArray = selectedCards.filter((id) => id !== cardId);
    let combinedIdArr = [...SelectedCardsArray, ...allDefaultTrue];

    if (selectedCards.includes(cardId)) {
      updatedSelectedCards = combinedIdArr.filter(
        (value, index, self) => self.indexOf(value) === index
      );
    } else {
      updatedSelectedCards = [...selectedCards, cardId];
    }

    updatedSelectedCards = updatedSelectedCards.filter(cardId => {
      const application = availableApplications.find(app => app.applicationId === cardId);
      return application && application.assignedApplication.status !== 'INACTIVE';
    });

    setSelectedCards(updatedSelectedCards);
  };

  const onRefreshInactiveStatus = async (applicationId) => {
    setLoadingApplicationId(applicationId);
    const userSession = await getAuthenticatedSessionUser({ forceReload: true });
    if (userSession) {
      const apps =
        sessionUser?.roleObject?.roleType === "INTERNAL"
          ? filterAppList(userSession, true)
          : filterAppList(userSession);
      updateAvailableApplications(apps);
    }
    setLoadingApplicationId(null);
  };

  if (serverError) {
    return (
    <>
      <div
        data-test="add-user-application-tab"
        className="bg-theme-on-primary border border-theme-light-border border-t-0 border-b-0 overflow-y-auto overflow-x-hidden w-full h-[380px] t-xl:h-full"
      >
        <div className="w-[627px] m-5">
          {serverError === 'subscription.manager.not.found' && 
          <NotificationCard
            type="error"
            title={<LocalizedText localeKey="createUser.alerts.cannotCreateUserBeforeAdmin.title"/>}
            messageLine1={<LocalizedText localeKey="createUser.alerts.cannotCreateUserBeforeAdmin.message1"/>}
          />
          }
          </div>
      </div>
    </>);
  }

  if (loadingSubscribedApps) {
    return (
      <>
        <div
          data-test="add-user-application-tab"
          className="bg-theme-on-primary border border-theme-light-border border-t-0 border-b-0 overflow-y-auto overflow-x-hidden w-full h-[380px] t-xl:h-full"
        >
          <BVLoader20 />
      </div>
    </>);

  }
  return (
    <>
      <div
        data-test="add-user-application-tab"
        className="bg-theme-on-primary border border-theme-light-border border-t-0 border-b-0 overflow-y-auto overflow-x-hidden w-full h-[380px] t-xl:h-full"
      >
        <div className="flex flex-col items-top space-y-5 gap-x-4 items-start justify-start py-4 px-4 xl:px-8 rounded-sm h-full">
          
          <div className="flex justify-between lrg:justify-end w-full">
            <div className="font-medium text-lg lrg:hidden"><LocalizedText localeKey="createUser.sections.Applications.subtitle.availableApplications"/></div>
            <div className="flex gap-x-4">
              <CheckToggle
                onChange={toggleSelectAll}
                value={{
                  selected:
                    availableApplications.filter(app => app.assignedApplication.status !== 'INACTIVE').length > 1 &&
                    availableApplications.filter(app => app.assignedApplication.status !== 'INACTIVE').length === selectedCards.length
                }}
                dataTestAttribute="toogle-switch"
              />
              <div className="font-medium text-lg">{<LocalizedText localeKey="createUser.sections.Applications.subtitle.selectAll"/>}</div>
            </div>
          </div>
          <div className={applicationsWrapperClass}>
            {loading ? (
              <div className="mt-6 text-theme-medium"><LocalizedText localeKey="shared.loading" />...</div>
            ) : (
              availableApplications
              .map((application) => {
                const isInactive = application.assignedApplication.status === 'INACTIVE';
                return application.assignedApplication.applicationGuid !== "MERCHANT_PORTAL_ACCOUNTS" ? (
                  <div className="flex flex-col justify-center" key={application.applicationId}>
                    <div
                      key={application.applicationId}
                      className={`${applicationsClass(application)} absolute`}
                      data-test="application-tile-container"
                      onMouseEnter={() => setHoveredCardId(application.applicationId)}
                      onMouseLeave={() => setHoveredCardId(null)}
                    >
                      {(hoveredCardId === application.applicationId && isInactive &&
                        <IndexTooltip
                          arrow="bottom"
                          error="false"
                          controlled="true"
                          tooltip-content-align="left"
                          text={translateToString("createUser.sections.Applications.inactiveAppTooltip")}
                          theme="light"
                          className="absolute text-sm text-theme-dark font-normal rounded-lg w-64 available-apps-tooltip-left-align h-56 z-20"
                        />
                      )}
                      {selectedCards.includes(application.applicationId) ? (
                        <FontAwesomeIcon
                          className={`${application.assignedApplication.applicationGuid !==
                              "MERCHANT_PORTAL_ACCOUNTS"
                              ? "text-theme-primary"
                              : "text-theme-disabled"
                            } absolute -top-2 -right-2`}
                          data-test="circle-check-icon"
                          style={{ height: "30px", width: "30px" }}
                          icon={faCircleCheck}
                        />
                      ) : null} 
                      <ApplicationTile
                        appIconid={application.assignedApplication.applicationGuid}
                        appIconDisabled={isInactive ||
                          application.assignedApplication.applicationGuid ===
                          "MERCHANT_PORTAL_ACCOUNTS"
                        }
                        appIconHeight={60}
                        appIconWidth={60}
                        applicationName={
                          <LocalizedText
                            localeKey={application.assignedApplication.name} id={`${application.assignedApplication.name}-label`}
                          />}
                        applicationDescription={
                          <LocalizedText
                            localeKey={application.assignedApplication.description}
                          />}
                        selected={selectedCards.includes(application.applicationId)}
                        onClick={() =>
                          toggleCardSelection(application.applicationId)
                        }
                      />
                    </div>
                    {isInactive ? (loadingApplicationId === application.applicationId ?
                      <div className="flex items-center justify-center">
                        <FontAwesomeIcon icon={faSpinner} size="lg" spin className="text-sm" />
                      </div> :
                      <div className="flex items-center justify-center">
                        <Button
                          id="check-inactive-status"
                          className="text-theme-info hover:text-theme-primary transition-colors duration-300"
                          title="Refresh"
                          buttonType={ButtonType.LinkButton}
                          onClick={() => onRefreshInactiveStatus(application.applicationId)}
                          testDataAttribute="refresh-inactive-app"
                        />
                      </div>) : ""
                    }
                  </div>
                ) : null
              })
              )}
            </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationsTab;
