import React from "react";
import useStateRef from "react-usestateref";
import useAlertQueue from "hooks/alertQueue";
import { BVLoader20, LocalizedText } from "portal-common-ui";
import CheckToggle from "components/Forms/CheckToggle";
import usePermissions from 'hooks/session/permissions';
import UserProfileOverview from "components/Profile/UserProfileOverview"


const ReviewProfile = ({
    changeStep,
    userToClone,
    isSaving,
    newUserDetails,
    saveReviewConfigState,
    basicInfoState,
    onPrevStep,
    resetRole,
    reviewConfigState,
    setDeactivateUser,
    deactivateUserFlag,
}) => {
    const { enqueueAlert } = useAlertQueue();
    const { hasPermission } = usePermissions();
    const canActivateDeactivate = hasPermission('ACTIVATE_DEACTIVATE_USER_ACCOUNT_STATUS');
    const [sendEmail, setSendEmail, sendEmailRef] = useStateRef(
        reviewConfigState?.sendEmail
    );
    const [sendScheduleReports, setSendScheduleReports, sendScheduleReportsRef] = useStateRef(
        reviewConfigState?.cloneScheduledReports
    );

    const toggleEmailSent = () => {
        const nextState = !sendEmailRef.current;
        setSendEmail(nextState);
        const newReviewConfigState = reviewConfigState;
        newReviewConfigState.sendEmail = nextState;
        saveReviewConfigState(newReviewConfigState);
    };

    const toggleScheduleReportsSent = () => {
        const nextState = !sendScheduleReportsRef.current;
        setSendScheduleReports(nextState);
        const newReviewConfigState = reviewConfigState;
        newReviewConfigState.cloneScheduledReports = nextState;
        saveReviewConfigState(newReviewConfigState);
    };

    const toggleDeactivateUser = (value: boolean) => {
      if (value) {
        enqueueAlert({
          alertType: "modal",
          title: "Warning",
          titleKey: "shared.alerts.titles.warning",
          messageType: "warning",
          continueCaptionKey: "userManagementPage.alerts.warning.deActivateUser.continueCaption",
          cancelCaptionKey: "shared.alerts.cancelAction",
          continueAction: () => setDeactivateUser(true),
          cancelAction: () => setDeactivateUser(false),
          messageKey: "cloneUser.warnings.deactivateUser"
        });
      } else {
        setDeactivateUser(false);
      }
    };

    const isReportingApplicationAssigned = userToClone?.userApplicationAssignments?.results.some((app: any) => 
      app.assignedApplication.applicationGuid === "MERCHANT_PORTAL_REPORTING"
    );

    return (
      <form className="w-full h-full">
        <div
          className="bg-theme-on-primary border border-t-0 border-b-0 rounded-sm border-theme-light-border relative w-full h-[380px] t-xl:h-full overflow-y-auto"
          data-test="clone-user-review-profile"
        >
          {isSaving ? (
            <BVLoader20 bgColor={"#FFFFFF"} />
          ) : (
            <div className="p-4">
              <div className="flex not-italic font-bold text-sm leading-6 text-theme-dark">
                <LocalizedText localeKey="cloneUser.sections.reviewConfTab.subTitle"/>
              </div>
              <UserProfileOverview
                basicInfoState={basicInfoState}
                dataAccessState={userToClone?.dataAccess}
                userProfile={userToClone}
                selectedGroups={[]}
                changeStep={changeStep}
                editable={true}
                type="clone"
              />
              <div className="flex not-italic pt-2 font-bold text-sm leading-6 text-theme-dark">
               <LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfAdditionalOpt.additionalOption"/>
              </div>
              <div className="flex-1 px-6 xl:px-6 py-4 text-sm border text-theme-dark font-normal w-[872px] xl:w-[872px] lrg:w-auto">
                <div className="min-w-[630px] xl:min-w-[777px] flex justify-start py-2 t-xl:py-4">
                  <CheckToggle
                    defaultChecked={sendEmail}
                    onChange={toggleEmailSent}
                  />
                  <div className="text-sm font-medium text-gray-600 px-6">
                    <LocalizedText localeKey="createUser.sections.reviewConfiguration.reviewConfAdditionalOpt.adtionalTabToggle"/>
                  </div>
                </div>
                {isReportingApplicationAssigned && (
                  <div className="min-w-[630px] xl:min-w-[777px] flex justify-start py-2 t-xl:py-4">
                    <CheckToggle
                      defaultChecked={sendScheduleReports}
                      onChange={toggleScheduleReportsSent}
                    />
                    <div className="text-sm font-medium text-gray-600 px-6">
                      <LocalizedText localeKey= "cloneUser.sections.reviewConfTab.cloneSupport"/>
                    </div>
                  </div>
                )}
                {canActivateDeactivate && userToClone?.status === "ACTIVE" && (
                  <div className="min-w-[630px] xl:min-w-[777px] flex justify-start py-2 t-xl:py-4">
                    <CheckToggle
                      defaultChecked={false}
                      value={{ selected: deactivateUserFlag }}
                      onChange={toggleDeactivateUser}
                      dataTestAttribute="clone-user-deactivate-user"
                    />
                    <div className="text-sm font-medium text-gray-600 px-6">
                      <LocalizedText localeKey="cloneUser.sections.reviewConfTab.additionalToggle" templateVariables={{fullName :userToClone?.fullName}}/>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    );
}



export default ReviewProfile;