import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import FormField from "components/Forms/FormField";
import FormTextArea from "components/Forms/FormTextArea";
import useStateRef from 'react-usestateref';
import { validateDAGInfo } from "utils/Helper";
import { LocalizedText, useLocale } from "portal-common-ui";

const BasicDataAccessInfo = ({
  formData,
  allowedGroupNames
}) => {
    const [, setDuplicateGroupName ] = useStateRef(false);
    const methods = useFormContext();
    const { translateToString } = useLocale();

  const validateName = (input, field) => {
    const nameTest = validateDAGInfo(input, field);
    if (allowedGroupNames.includes(methods.getValues()?.groupName?.toLowerCase())) {
      setDuplicateGroupName(true);
      return translateToString('createDAG.warnings.groupNameExist');
    } else {
      setDuplicateGroupName(false);
    }
    if (!nameTest) {
      if(field==="Group Name")
        return translateToString('createDAG.sections.basicInfo.errors.invalidGroupName')
      else if(field==="Group Description")
        return translateToString('createDAG.sections.basicInfo.errors.invalidGroupDes')
    }
  }
  
   useEffect(() => {
    methods.reset(formData);
  }, [methods.reset]);

  return (
    <>
      <form className="w-full">
        <div className="bg-theme-on-primary p-4 border border-theme-light-border border-t-0 border-b-0 w-full overflow-x-hidden overflow-y-auto h-[380px] t-xl:h-full" data-test="data-access-management-basicinfo">
            <div className="flex flex-row flex-wrap space-y-10 items-start justify-start pb-8 w-[620px] xl:w-[785px]" style={{ height: 148 }}>
              <FormField 
              name="groupName" 
              label="Group Name"  
              labelKey="createDAG.sections.basicInfo.groupName"
              placeholder={translateToString("createDAG.sections.basicInfo.groupPlaceholder")} 
              required maxLength={64} 
              inputClass="text-sm font-normal w-72 xl:w-[471px]" 
              customValidate={{custom: (e) => validateName(e, 'Group Name')}}
              errorKeys={{"required": "createDAG.sections.basicInfo.errors.groupNameBlank", 
                          "validate": "createDAG.sections.basicInfo.errors.invalidGroupName", 
                          "maxLength":"createDAG.sections.basicInfo.errors.groupNameMaxLength" }}  
             />
              <div className="flex flex-row flex-wrap space-y-1 items-start justify-start pb-8 w-[620px] xl:w-[785px]" style={{ height: 148 }}>
                <FormTextArea 
                  name="groupDesc" 
                  label="Group Description" 
                  labelKey="createDAG.sections.basicInfo.groupDescription"
                  placeholder={translateToString("createDAG.sections.basicInfo.groupDesPlaceholder")} 
                  maxLength={255} 
                  inputClass="text-sm font-normal w-72 xl:w-[471px] min-h-[70px] max-h-[calc(30vh-2rem)] overflow-auto" 
                  customValidate={(e) => validateName(e, 'Group Description')}
                  errorKeys={{"validate": "createDAG.sections.basicInfo.errors.invalidGroupDes", 
                             "maxLength":"createDAG.sections.basicInfo.errors.groupDesMaxLength" }}
                />
              </div>
            </div>
        </div>
      </form>
    </>
  );
}

export default BasicDataAccessInfo;