import { useState, useEffect, useRef } from 'react';
import { useGlobal } from 'libs/reactn';
import config from 'config/config';
import { useUser, useApi, useRoles } from 'portal-common-ui';
import useAlertQueue from 'hooks/alertQueue';
const useUserList = () => {

  const [apps, setApps] = useGlobal('apps');
  const user = useUser();
  const options = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userListUrl = config.API.userUrl2;
  const userListApi = useApi(userListUrl, options);
  const alertQueue = useAlertQueue();
  const {getRoleDisplayName} = useRoles();
  const [isLoading, setIsLoading] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPageCount, setCurrentPageCount] = useState(0);
  const searchCriteria = useRef(null);

  const setSearchCriteria = (search) => {
    if (search === null) {
      searchCriteria.current = null;  
    } else 
    {
      const searchObj = Object.getOwnPropertyNames(search).some(k => search[k] != 'undefined') ? search : null;
      searchCriteria.current = searchObj;  
    }
  };

  useEffect(() => {
    if(userListApi.error) {
      alertQueue.enqueueAlert({ alertType: 'modal', title: 'Error', messageType: 'error', message: 'An error occurred while processing your request.  Please try again later.' });
      setIsLoading(false);
    }
  }, userListApi.error);

  const getRows = async (params) => {
    try {
      setIsLoading(true);
      const pageSize = params.request.endRow - params.request.startRow;
      const startPage = (params.request.startRow / pageSize) + 1;
      // const criteriaQs = searchCriteria.current !== null ? `email=${searchCriteria.current.email}&firstName=${searchCriteria.current.firstName}&lastName=${searchCriteria.current.lastName}&` : '';
      // const criteriaQs = 'email=rj_merchant_user2@putsbox.com&';
      let sortBy = 'LAST_NAME';
      let descendingSort = false;
      if (params.request.sortModel.length > 0) {
        const colId =  params.request.sortModel[0].colId;
        sortBy = colId === 'firstName' ? 'FIRST_NAME' : 
                 colId === 'lastName' ? 'LAST_NAME' :
                 colId === 'email' ? 'EMAIL' : 
                 colId === 'role' ? 'MP_ACCOUNTS_APP_ROLE_GUID' :
                 colId === 'status' ? 'STATUS' : 
                 colId === 'lastLogin' ? 'LAST_LOGIN_DATE' : 
                 colId === 'brand' ? 'BRANDS' : '';
        descendingSort = params.request.sortModel[0].sort === 'desc';
      }
      // const urlWithSearch = `search?${criteriaQs}&page=${startPage}&pageSize=${pageSize}&sortBy=${sortBy}&descendingSort=${descendingSort}&hydrateApplications=true&includeInactive=true`;
      const searchParams = Object.assign(
        {}, 
        searchCriteria.current, 
        {
          accountsRoles: searchCriteria.current?.accountsRoles || [],
          merchantNumbers: searchCriteria.current?.merchantNumbers || [],
          page: startPage,
          pageSize,
          sortBy,
          descendingSort,
          hydrateApplications: false,
          hydrateHierarchies: false,
          includeInactive: params.request.excludeInactive ? false : true,
        });

      await userListApi.post('/search', searchParams);
      if (userListApi.response.ok) {
        const responsePayload = userListApi.response.data.content;
        const inRows = responsePayload.results;
        const outRows = inRows.map(inRow => {
          let outRow = {
            email: inRow.email,
            userId: inRow.userId,
            brand: `${inRow.brands.join(', ')} ${inRow.hasInaccessibleBrands ? '*' : ''}`,
            firstName:inRow.firstName,
            lastName:inRow.lastName,
            roleGuid: inRow.accountsApplicationRoleGuid
          };
          if (inRow) {
            // outRow.name =  (inRow.user.firstName && inRow.user.lastName) ? `${inRow.user.firstName} ${inRow.user.lastName}` : undefined;
            if (inRow.lastLoginDate) {
              outRow.lastLogin = new Date(inRow.lastLoginDate);
            } else {
              outRow.lastLogin = null;
            }
            if (outRow.roleGuid) {
              outRow.role = getRoleDisplayName(outRow.roleGuid);
            }
            const status = inRow.status;
            const toPascalCase = (str) => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
            outRow.status = toPascalCase(status);
            // outRow.canEditStatus = (outRow.status === 'Active' || outRow.status == 'Inactive') && (outRow.userId !== user.authenticatedUser.externalId);
            outRow.canEditStatus = true;
          }
          return outRow;
        });
        setTotalRowCount(responsePayload.totalCount);
        setCurrentPageCount(outRows.length);
        if (params && params.successCallback) params.successCallback(outRows, inRows.totalCount);
        setIsLoading(false);
        return outRows;
      } else {
        return [];
      }  
    }
    catch(err) {
      console.log(err);
    }
  };

  const getUserByEmail = async (email) => {
    try {
      await userListApi.get(`?email=${email}&fullUser=true&page=1&pageSize=25`);
      const list = userListApi.response.data.content;
      return list[0].user;
    }
    catch(err) {
      console.log(err);
    }
  };

  return {
    getRows,
    getUserByEmail,
    setSearchCriteria,
    totalRowCount,
    currentPageCount,
    isLoading,
  }
};

export default useUserList;
