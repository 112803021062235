import React, { useEffect } from "react";
import { useModali } from "modali";
import ProductCard from "components/ProductCard/ProductCard";
import { useUser, Button, ButtonType, LocalizedText } from "portal-common-ui";
import { useGlobal } from 'libs/reactn';
import useProductModel from "hooks/use-product-model";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { useProductModalStore } from "store/productModalStore";
import ProductModal from "components/ProductSelectionModal/ProductModal";

const ProductOfferings: React.FC = () => {

    const [global, setGlobal] = useGlobal(); // Use useGlobal hook
    const { authenticatedUser, isLoading } = useUser();
    const applicationBundlesUrl = `/users/plans`
    const subscribedApplicationUrl = `/users/subscriptions`
    const { applicationSubscribed } = useProductModalStore();
    const [productModal, toggleModal] = useModali({ large: true, centered: true, overlayClose: false, keyboardClose: false });
    const {data, loading, error} = useProductModel(applicationBundlesUrl, subscribedApplicationUrl, [applicationSubscribed]);
    const history = useHistory(); 

    useEffect(()=>{
      if(!isLoading && !loading){
        setGlobal({ offeringData: data });
      }
    },[data,isLoading,loading, applicationSubscribed])

    const checkForNewApplication = (planCreationDateTime: string) => {
      try {
        if(planCreationDateTime!==null){
          let lastloginDateTime : string = authenticatedUser.lastLoginDate;
          if(lastloginDateTime==="" || lastloginDateTime===null){
            return false;
          }
          const lastLoggedInDate = new Date(lastloginDateTime);
          const planCreationDateObj = new Date(planCreationDateTime);
          return planCreationDateObj > lastLoggedInDate;
        }
        return false;
      } 
      catch(err) {
        console.log(err);
        // setLoading(false);
      }
    };

    const navigateToCurrentSubscriptions:any = () => {
        history.push("/product-offerings/current-subscriptions");
    }

    return(
        <div className="-ml-4">
            <div className="flex flex-row gap-12">
            <h2 className="mr-6 ml-4 text-2xl font-medium whitespace-nowrap text-theme-dark"><LocalizedText localeKey="homepage.sidebar.productOfferings"/></h2>
            {data.length > 0 ? (
                <Button
                  buttonType={ButtonType.SecondaryButton}
                  title={"My Current Subscription Plan"}
                  titleKey="productOfferings.mainPage.buttons.currentSubscription"
                  defaultContainerClass={"inline-block"}
                  testDataAttribute="current-subscriptions"
                  onClick={navigateToCurrentSubscriptions}
                  disabled={loading}
                />
              ) : (
                ""
            )}
            </div>
            {isLoading || loading ? 
              <div className="mt-6 text-theme-medium"><LocalizedText localeKey="shared.loading" />...</div>
              :
              (data.length>0 ?
                <div className="flex flex-wrap flex-row items-start gap-2 h-full w-full shrink-0 bg-gray-100">
                  {data.map((product,index)=>(
                  <ProductCard
                    key={index}
                    dataTestAttribute="product-tile"
                    title={product.offeringName}
                    titleKey={product.offeringNameKey}
                    description={product.offeringDescription}
                    descriptionKey={product.offeringDescriptionKey}
                    price={product.offeringPricing}
                    isSubscribed = {product.isSubscribed}
                    isUpgrade={product.isUpgrade}
                    isBundle={product.offeringType==="bundle"}
                    isNewApplication = {checkForNewApplication(product.offeringCreationDateTime)}
                    cachedData={data}
                    toggleModal={toggleModal}
                  />
                  ))}
                </div>
              :
                <div className="flex gap-4 w-full mid-lg:w-[48rem] bg-[#E5F4FF] text-base p-4 border border-solid">
                  <FontAwesomeIcon icon={faCircleInfo} size="2x" style={{color: "#003C66",}} />
                  <div>
                    <p className="pb-2 font-bold"><LocalizedText localeKey="productOfferings.infoBanners.noProducts.title"/></p>
                    <p className="font-normal"><LocalizedText localeKey="productOfferings.infoBanners.noProducts.message"/></p>
                  </div>
                </div>
              )
            }
            <ProductModal productModal={productModal} toggleModal={toggleModal} />
        </div>
    )};

export default ProductOfferings;
