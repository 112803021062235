import forge from "node-forge";
import jwkToPem from "jwk-to-pem";

export const validateName = (input, fieldName) => {
  const trimmedInput = input.trim();
  let nameRegex;

  if (fieldName === 'lastName') {
    nameRegex = /^[\p{L} .,'-]*[\p{L} .,'-]$/u;
    return trimmedInput.length >= 1 && nameRegex.test(trimmedInput);
  } else {
    nameRegex = /^[\p{L} ]+[\p{L} .,'-]*[\p{L} .,'-]$/u;
    return trimmedInput.length > 1 && nameRegex.test(trimmedInput);
  }
};

export const validateEmail = (emailInput) => {
  const emailRegex =
    /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  return emailRegex.test(emailInput);
};

export const validateDAGInfo = (input, field) => {
  const nameRegex =
    field === "Group Description"
      ? /^$|^\s-|[a-z][a-z .,'-]*[a-z.,'-]\s*$/i
      : /^[a-z ][a-z .,'-]*[a-z .,'-]$/i;
  return field === "Group Description"
    ? nameRegex.test(input)
    : nameRegex.test(input) && input?.trim()?.length > 1;
};

export const encryptData = (data, publicJWK) => {
  // compatible with Java's RSA/ECB/OAEPWithSHA-256AndMGF1Padding
  const pubKeyPem = jwkToPem(publicJWK.keys[0]);
  const publicKey = forge.pki.publicKeyFromPem(pubKeyPem);
  const buffer = forge.util.createBuffer(data);
  const bytes = buffer.getBytes();
  const encrypted = publicKey.encrypt(bytes, "RSA-OAEP", {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create(),
    },
  });
  return forge.util.encode64(encrypted);
};
