import { useApi } from "portal-common-ui";
import { mapAppLinkQueryString } from "components/Dashboard/helper";

// retrieves a link on click from a provider.  Used for links that can expire, such as SSO links to external sites.
const useProvisionedLink = (linkProviderUrl, myProfile) => {
  const providerApi = useApi(linkProviderUrl);

  const handleClick = async (event) => {
    await providerApi.get();
    if (myProfile) {
      window.open(mapAppLinkQueryString(providerApi.response.data, myProfile));
    } else {
      window.open(providerApi.response.data);
    }
  };

  return {
    handleClick,
  };
};

export default useProvisionedLink;
