import React, { useState } from "react";
import Modali, { useModali } from 'modali';
import AddUserToDataAccessGroup from './AddUserToDataAccessGroup'
import usePermissions from 'hooks/session/permissions';
import { ColumnFunctionCallbackParams } from 'ag-grid-community';
import { Grid, gridInterfaces, OmniSearch, Button, ButtonType, LocalizedText, useLocale, AccessibleIcon } from 'portal-common-ui';
import 'portal-common-ui/dist/portal-common-ui.css';
import { useDataAccessGroup } from 'portal-common-ui';
import config from 'config/config';
import { CellShape } from 'portal-common-ui';
import useAlertQueue from 'hooks/alertQueue';
import useStateRef from "react-usestateref";
import {useDeepCompareEffect} from 'react-use';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";


interface Props {
    dataAccessGroupInfo: any;
    onSearch: any;
    updateGrid: any;
}

const AddUserButton: React.FC<Props> = ({ dataAccessGroupInfo, onSearch , updateGrid }) => {
    const [assignUserModal, toggleAssignUserModal] = useModali({
        overlayClose: false,
        large: true,
        centered: true,
    });

    const onAddUser = () => {
        toggleAssignUserModal();
    };

    const closeModal = () => {
        toggleAssignUserModal();
    }

    const { hasPermission } = usePermissions();
    const {translateToString} = useLocale();
    return (
        <div className=" flex items-center text-theme-dark gap-4">
            <div className="mb-0 mr-6 text-base text-theme-dark font-medium whitespace-nowrap"><LocalizedText localeKey="editDAG.sections.userAssigned.subHeader"/></div>
            <div className="flex-grow pb-2 grid place-items-end flex ag-grid__filter">
                <OmniSearch
                    searchHandler={onSearch}
                    placeholder={translateToString("userManagementPage.filterBar.omnisearch.searchBy")}
                    className="ag-grid__filter bg-theme-on-primary border-b-0 border border-solid border-gray-300"
                >
                </OmniSearch>
            </div>
            <div className=" grid grid-cols-1 place-items-end mr-6">

                {hasPermission('MANAGE_DATA_ACCESS_GROUPS') && hasPermission('UPDATE_OTHER_USERS') &&
                    <Button
                        buttonType={ButtonType.PrimaryButton}
                        onClick={onAddUser}
                        testDataAttribute="data-access-management-add-user"
                        child={[<AccessibleIcon icon={faPlus} classes="text-xl" labeledBy={"add-user-labelKey"}/>, <span className="ml-2 py-1"><LocalizedText localeKey="editDAG.button.addUser" id="add-user-labelKey"/></span>]}
                    />
                }
                <Modali.Modal {...assignUserModal}>
                    <div className="flex items-center justify-center w-full h-full">
                        <AddUserToDataAccessGroup
                            closeModal={closeModal}
                            dataAccessGroupInfo={dataAccessGroupInfo}
                            updateGrid={updateGrid}
                        />
                    </div>
                </Modali.Modal>
            </div>
        </div>
    );
};

const UsersAssignedTab: React.FC<Props> = ({ dataAccessGroupInfo, usersAssignedList, setUsersAssignedList, updateList }) => {
    const alertQueue = useAlertQueue();
    const [, setOmniSearch, omniSearchRef] = useStateRef<string[]>([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const userUrl = config.API.userUrl2;
    const relativeUrl = `/search`;
    const { deleteUserFromDAG, getUserHierarchyAssignmentId } = useDataAccessGroup();
    const [filtersUpdatedToggle, setFiltersUpdatedToggle] = useState<boolean>(false)

    const rowModelType = usersAssignedList?.length <= 25  ?  'clientSide': 'serverSide';

    const applySearchFilter = () => {
        if (omniSearchRef.current &&  (omniSearchRef.current[0]?.length >= 2 || omniSearchRef.current[0]?.length === 0 ) && omniSearchRef.current?.length <= 3) {
            const filteredList = usersAssignedList.filter(m => new RegExp(omniSearchRef.current.join('|').toLowerCase()).test(m?.firstName?.toLowerCase()) || new RegExp(omniSearchRef.current.join('|').toLowerCase()).test(m?.lastName?.toLowerCase()) || new RegExp(omniSearchRef.current.join('|').toLowerCase()).test(m?.email?.toLowerCase()));
            return filteredList;
        }
        return [...usersAssignedList];
    }
 
    const [filteredUsersAssignedList, setFilteredUsersAssignedList, filteredUsersAssignedListRef] = useStateRef(usersAssignedList);
    
    useDeepCompareEffect (() => {
        if (rowModelType === 'clientSide') {
            const newList = applySearchFilter();
            setFilteredUsersAssignedList(newList);
        }
    }, [usersAssignedList, omniSearchRef.current])
     
    const serverDatasource: gridInterfaces.IServerDatasource = {
        url: `${userUrl}${relativeUrl}`,
        reqType: 'post',
        sortMap: { 'default': 'FIRST_NAME' ,'name': 'FIRST_NAME', 'email': 'EMAIL'},
        reqBody:
        {
            "dagId": dataAccessGroupInfo.groupId,
            omniSearchValues: omniSearchRef.current,
        },
        filtersUpdatedToggle
    };

    const showDeleteWarning = (deleteUserId: string) => {
        alertQueue.enqueueAlert({
            alertType: 'modal',
            title: 'Deleting User from Data Access Group',
            messageType: 'warning',
            continueCaption: 'Yes, Delete',
            cancelCaption: 'Cancel',
            continueAction: async () => await deleteUser(deleteUserId),
            cancelAction: () => { },
            hasProcessing: true,
            processingCaption: "Deleting...",
            message: "Are you sure you want to delete this user from this data access group? This action will remove all of the MIDs associated with this group from the user's data access."
        });
    };

    const onDeleteUserClick: (rowData: ColumnFunctionCallbackParams['data']) => void = (rowData) => {
        showDeleteWarning(rowData.userId);
    }

    const onDeleteSuccess = () => {
        alertQueue.enqueueAlert({
            alertType: 'toast',
            messageType: 'primary',
            message: 'User has been successfully removed from this data access group.',
            transitionDuration: 6000,

        });
    };
    const updateGrid = (user) => {
        let newlyAddedUser = {
            firstName: user.value,
            email: user.label,
            userId: user.userId,
            lastName: ''
        };
        const newUser =  filteredUsersAssignedListRef.current;
        newUser.findIndex(m => m?.email === newlyAddedUser?.email) === -1 &&  newUser.push(newlyAddedUser); 
        const modifyOriginalList = usersAssignedList;
        modifyOriginalList.push(newlyAddedUser);
        setUsersAssignedList(modifyOriginalList);
        setFilteredUsersAssignedList(applySearchFilter());
        setFiltersUpdatedToggle(!filtersUpdatedToggle);
    }

    const deleteUser = async (deleteUserId: string) => {
        const userAssignmentResp = await getUserHierarchyAssignmentId(deleteUserId);
        const userHierarchyAssignmentId = userAssignmentResp.content.results.filter(m => m.hierarchyId === dataAccessGroupInfo.groupId).map(m => m.userHierarchyAssignmentId)[0];
        const resp = await deleteUserFromDAG(deleteUserId, userHierarchyAssignmentId);
        
        if (resp.status === 200) {
            onDeleteSuccess();   
            const newList =  filteredUsersAssignedListRef.current?.filter(e => e?.userId !== deleteUserId);
            updateList(deleteUserId);
            setFilteredUsersAssignedList(newList);
            setFiltersUpdatedToggle(!filtersUpdatedToggle);
        }
    }

    const fullNameGetter = (params: any) => {
        return params.data.firstName + ' ' + params.data.lastName;
    }

    const loaderColumnDefs = [
        { width: "31%", cellShape: CellShape.Bar },
        { width: "31%", cellShape: CellShape.Bar },
        { width: "38%", cellShape: CellShape.Bar },
    ];
    const { hasPermission } = usePermissions();
    
    const onSearch = (newOmniSearch: string[]) => {
        setOmniSearch(newOmniSearch);
        if ( (newOmniSearch[0]?.length >= 2 || newOmniSearch[0]?.length === 0 ) && newOmniSearch?.length <= 3) {
            setFiltersUpdatedToggle(!filtersUpdatedToggle);
        }       
    }

    const getActionItems: gridInterfaces.IActionsColumn['getActionItems'] = (data: ColumnFunctionCallbackParams['data']) => {
        return [

            {
                iconClass: 'fa fal fa-trash-alt text-lg text-theme-danger',
                captionKey: 'userManagementPage.actions.delete',                
                captionClass: 'text-theme-info',
                handler: onDeleteUserClick,
                dataTestAttribute: 'delete-assigned-user',
                onlyIcon: true,
            },
        ]
    }
   
    const customMessage = {
        title : <LocalizedText localeKey={'editDAG.sections.userAssigned.noUsersMessage'}/>,
        description: hasPermission('MANAGE_DATA_ACCESS_GROUPS') && hasPermission('UPDATE_OTHER_USERS') ? <LocalizedText localeKey="editDAG.sections.userAssigned.addMessage"/> : ''
    }
    return (
        <>
      <AddUserButton dataAccessGroupInfo={dataAccessGroupInfo} onSearch={onSearch} updateGrid={updateGrid}/>
        {rowModelType === 'clientSide' && <div className="pr-6 mb-2">
            <Grid
                rowModelType={'clientSide'}
                serverDatasource={serverDatasource}
                rowData={filteredUsersAssignedList}
                rowIdFieldName="userId"
                wrapperClass="ag-grid__container w-full grid-common-ag-grid bg-theme-on-primary border border-solid border-gray-300 rounded-sm"
                agGridClassName="ag-grid w-full userassigned-ag-grid-height overflow-auto"
                sortDefault="name"
                setTotalRowCount={setTotalRowCount}
                loaderColumnDefs={loaderColumnDefs}
                customMessage={usersAssignedList.length === 0 ? customMessage : ''}
            >
                <Grid.TextColumn
                    field='name'
                    headerNameKey="dataAccessGroup.columns.name"
                    valueGetter={fullNameGetter}
                    width="100%"
                    accentuate
                    sortable
                    resizable
                    suppressMovable
                />
                <Grid.TextColumn
                    field='email'
                    headerNameKey="dataAccessGroup.columns.email"
                    sortable
                    resizable
                    suppressMovable
                />
                <Grid.ActionsColumn
                    field='actions'
                    headerNameKey="dataAccessManagementPage.columns.actions"
                    minWidth={85}
                    maxWidth={140}
                    pinned='right'
                    hide={hasPermission('MANAGE_DATA_ACCESS_GROUPS') && hasPermission('UPDATE_OTHER_USERS') ? false : true}
                    getActionItems={getActionItems}
                    suppressMovable
                />
               
                <Grid.Pagination />
               
            </Grid>
        </div>}

        {rowModelType === 'serverSide' && <div className="pr-6 mb-2">
            <Grid
                rowModelType={'serverSide'}
                serverDatasource={serverDatasource}
                rowIdFieldName="userId"
                wrapperClass="ag-grid__container w-full grid-common-ag-grid bg-theme-on-primary border border-solid border-gray-300 rounded-sm"
                agGridClassName="ag-grid w-full userassigned-ag-grid-height overflow-auto"
                sortDefault="name"
                setTotalRowCount={setTotalRowCount}
                loaderColumnDefs={loaderColumnDefs}
                customMessage={usersAssignedList?.length === 0 ? customMessage : ''}
            >
                <Grid.TextColumn
                    field='name'
                    headerNameKey="dataAccessGroup.columns.name"
                    valueGetter={fullNameGetter}
                    width="100%"
                    sortable
                    resizable
                />
                <Grid.TextColumn
                    field='email'
                    headerNameKey="dataAccessGroup.columns.email"
                    sortable
                    resizable
                />
                <Grid.ActionsColumn
                    field='actions'
                    headerNameKey="dataAccessManagementPage.columns.actions"
                    minWidth={85}
                    maxWidth={85}
                    pinned='right'
                    hide={hasPermission('MANAGE_DATA_ACCESS_GROUPS') && hasPermission('UPDATE_OTHER_USERS') ? false : true}
                    getActionItems={getActionItems}
                />
               
                <Grid.Pagination />
               
            </Grid>
        </div>}

        
        </>

    )
};

export default UsersAssignedTab;