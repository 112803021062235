const useBrand = () => {

    const theme = brand => brand && require(`brands/${brand}/theme.js`).default;
        
    const logo = brand => {
        if ( brand ) {
          let ext = 'svg';
            return require(`brands/${brand}/logo.${ext}`).default; // Return logo as module
        }
    };

    return {
        theme,
        logo
    }
}

export default useBrand;