import { useGlobal } from 'libs/reactn';
import { useApi } from 'portal-common-ui';
import config from 'config/config';

const useProcessTranslations = () => {
  const [localeDictionary, setLocaleDictionary] = useGlobal('localeDictionary');
  const translationsApi = useApi(`${config.API.translationsUrl}`);

  const convertTrans = (transArr, locale) => {
    const apiTransObj = { globalTranslate: null };
    transArr.globalTranslate.forEach(transKey => {
      const newObj = {
        ...apiTransObj.globalTranslate,
        ...transKey,
      };
      apiTransObj.globalTranslate = newObj;
      apiTransObj.locale = locale;
    });
    return apiTransObj;
  };

  const fetchAndSaveTranslations = async (locale = 'en-US') => {
    if (localeDictionary?.locale !== locale) {
      await translationsApi.get(`translations/accounts.portal/${locale}?canHandle302Response=false`);
      if (translationsApi.response.ok) {
          try {
            const response = await fetch(config.mockApi ? translationsApi.response.data.url : translationsApi.response.data);
            const translationData = await response.json();
            const translationObj = convertTrans(translationData, locale);
            setLocaleDictionary(translationObj);
          } catch (error) {
            throw new Error('error with processing translations.', error);
          }
      } else {
        throw new Error('error with fetching translations.', translationsApi.response?.error);
      }
    }
  };


  return {
    fetchAndSaveTranslations,
  };
};

export default useProcessTranslations;

