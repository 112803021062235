import config from 'config/config';

const useTranslations = () => {
  const messages = {};
  messages.TaxDdaInvalid = 'The TAX ID/DDA entered can include a - z, A - Z, 0 - 9 and hyphen. Please correct your entry.';
  messages.TaxIdInvalid = 'The TAX ID/DDA entered can include a - z, A - Z, 0 - 9 and hyphen. Please correct your entry.';
  messages.MerchantNumberInvalid = 'The Merchant Number is incorrect. Check the Merchant Number you provided and try again.';
  messages.MerchantDdaNotFound = 'Merchant Number or DDA/ TAX ID Does not match our records. Please try again or contact your local customer care department.';
  messages['ErrorPage.UnknownEmployee.Title'] = 'Sorry, you are not authorized to view this site';
  messages['ErrorPage.UnknownEmployee.SubText'] = config.activeBrand.unknownUserMessage;
  messages['ErrorPage.UnknownEmployee.ErrorCode'] = 'ERR518112347IA';
  messages['ErrorPage.InactiveUser.Title'] = 'Sorry, an error has occurred while attempting to log you into the system.';
  messages['ErrorPage.InactiveUser.SubText'] = 'Please call the support number listed on your statement and provide the following error code:';
  messages['ErrorPage.InactiveUser.ErrorCode'] = 'ERR518112347IA / MPERR059';
  messages['ErrorPage.DeactivatedUser.Title'] = 'Sorry, an error has occurred while attempting to log you into the system.';
  messages['ErrorPage.DeactivatedUser.SubText'] = 'Please call your account administrator or customer support and provide the following error code:';
  messages['ErrorPage.DeactivatedUser.ErrorCode'] = 'ERR518112347IA / MPERR060';
  messages['ErrorPage.Error.Title'] = 'Sorry, an error has occurred while attempting to log you into the system.';
  messages['ErrorPage.Error.SubText'] = 'Please call the support number listed on your statement and provide the following error code:';
  messages['ErrorPage.Error.ErrorCode'] = 'MPERR068';
  messages['ErrorPage.UnexpectedLoginError.Title'] = 'Sorry, we were unable to log you into the system.';
  messages['ErrorPage.UnexpectedLoginError.SubText'] = 'We are temporarily unable to log you in at this time.  Please try again later.';
  messages['ErrorPage.UnexpectedLoginError.ErrorCode'] = 'ERR518112347IA';
  messages['ErrorPage.SelfRegisterError.Title'] = 'Sorry, an error has occurred while attempting to log you into the system.';
  messages['ErrorPage.SelfRegisterError.SubText'] = 'Please call the support number listed on your statement and provide the following error code:';
  messages['ErrorPage.SelfRegisterError.ErrorCode'] = 'MPERR069';
  messages['UserProfilePage.Security.Title']='Account Security'
  messages['UserProfilePage.Security.Subtitle']='Manage your account settings.'
  messages['UserProfilePage.mfaTitle']='Multi-Factor Authentication'
  messages['UserProfilePage.configureMFATitle']='Configure your authentication methods for accessing this application.'
  messages['UserProfilePage.configureMFA']='Configure MFA' 
  messages['applications.customer-engagement.name'] = 'Customer Engagement'; 
  messages['applications.mp-reporting.name'] = 'Reporting';
  messages['applications.sysnet.name'] = 'PCI Compliance';
  messages['applications.dims.name'] = 'Disputes Management';
  messages['applications.ces-gift.name'] = 'Gift Card';
  messages['applications.mp-accounts.name'] = 'Accounts';
  messages['applications.merlink.name'] = 'Merlink';
  messages['applications.customer-engagement.description'] = 'This is dummy Customer Engagement description'; 

  messages['applications.reputation-management.name'] = 'Social Reputation'; 
  messages['applications.reputation-management.description'] = 'Social Reputation description'; 
  messages['applications.competitive-benchmark.name'] = 'Benchmark'; 
  messages['applications.competitive-benchmark.description'] = 'Benchmark description'; 

  messages['applications.mp-reporting.description'] = 'This is dummy Reporting description';
  messages['applications.sysnet.description'] = 'This is dummy PCI Compliance description';
  messages['applications.dims.description'] = 'This is dummy Disputes Management description';
  messages['applications.mp-accounts.description'] = 'This is dummy Accounts description';
  messages['applications.merlink.description'] = 'This is dummy Merlink description';
  messages['applications.applicationRoleAssociations.noRole'] = 'No roles available';
  messages['applications.mp-reporting.role.acquirerAdmin.name'] = 'Third Party Administrator';
  messages['applications.mp-reporting.role.acquirerUser.name'] = 'Third Party User';
  messages['applications.mp-reporting.role.customerService.name'] = 'Customer Service';
  messages['applications.mp-reporting.role.customUser.name'] = 'Custom User';
  messages['applications.mp-reporting.role.internalEmployee.name'] = 'Internal Employee';
  messages['applications.mp-reporting.role.limitedPartner.name'] = 'Limited Partner';
  messages['applications.mp-reporting.role.masterFileAnalyst.name'] = 'Master File Analyst';
  messages['applications.mp-reporting.role.merchantAccountAdministrator.name'] = 'Account Administrator';
  messages['applications.mp-reporting.role.merchantApiUser.name'] = 'System Administrator';
  messages['applications.mp-reporting.role.merchantSalesSupportManager.name'] = 'Sales Support Manager';
  messages['applications.mp-reporting.role.merchantUser.name'] = 'Account User';
  messages['applications.mp-reporting.role.regionalProductManager.name'] = 'Regional Product Manager';
  messages['applications.mp-reporting.role.techSupportAdmin.name'] = 'System Administrator';
  messages['applications.mp-reporting.role.worldwideProductManager.name'] = 'Global Product Manager';

  const translate = (key) => {
    return messages[key];
  };

  return {
    translate,
  };
};

export default useTranslations;

