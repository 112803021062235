import config from 'config/config';
import sha1 from 'js-sha1';

export const pendoInit = (user) => {
    if (!config.features.enablePendo) {
        return
    }

    const {
        email,
        role = {},
        location = 'US',
        languageAbbr = 'english',
        brand: acquirerId,
    } = user;

    const appEnv = config.env.name;
    const hashedEmail = sha1(`${email}-${appEnv}`);

    pendo.initialize({
        sanitizeUrl: function (url) {
            const blacklist = ['token', 'state='];
            const hasSensitiveInfo = blacklist.find(key => url && url.toLowerCase().includes(key));
            return hasSensitiveInfo ? url.split('#')[0].split('?')[0] : url;
        },
        visitor: {
            id: hashedEmail,
            regionName: location,
            roleName: role.name,
            appEnv,
            userLanguage: languageAbbr
        },
        account: {
            id: acquirerId
        },
        excludeAllText: true
    });
}