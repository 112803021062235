import React, { FC, useEffect, useState } from 'react';
import { Button, ButtonType } from 'portal-common-ui';
import { ModalProps } from './types';
import { useHistory } from 'react-router-dom';
import { useProductModalStore } from "store/productModalStore";

const useLoadingButton = (action: () => Promise<void>) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await action();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleClick, isLoading };
};

const CancelButton: FC<{ onClick: () => Promise<void> }> = ({ onClick }) => {
  const { handleClick, isLoading } = useLoadingButton(onClick);

  return (
    <Button
      title="Cancel"
      titleKey= "shared.alerts.cancel"
      testDataAttribute="cancel-product-popup"
      buttonClass="text-theme-danger font-roboto font-medium"
      onClick={handleClick}
      disabled={isLoading}
    />
  );
};

const ConfirmButton: FC<{ onConfirm: () => Promise<void> }> = ({ onConfirm, removeApplication }) => {
  const { handleClick, isLoading } = useLoadingButton(onConfirm);

  return (
    <Button
      buttonType={ButtonType.PrimaryButton}
      title={!removeApplication ? "Confirm Purchase" : "Confirm Removal"}
      testDataAttribute="confirm-purchase"
      onClick={handleClick}
      disabled={isLoading}
      titleKey={!removeApplication ? "productOfferings.modal.buttons.purchase" : "productOfferings.modal.buttons.removal"}
    />
  );
};

const ReturnToProductOfferings: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    buttonType={ButtonType.LinkButton}
    title="Return to Product Offerings"
    titleKey="productOfferings.modal.buttons.return"
    testDataAttribute="return-to-product-offerings"
    onClick={onClick}
  />
);

const ConfigureUsersNow: FC<{ onConfirm: () => void }> = ({onConfirm}) => (
  <Button
    buttonType={ButtonType.SecondaryButton}
    title="Configure Users Now"
    testDataAttribute="configure-users-now"
    onClick={onConfirm}
    titleKey="productOfferings.modal.buttons.configure"
  />
);

const ModalFooter: FC<ModalProps & { onConfirm: () => void, showSuccessModal: boolean, setShowSuccessModal: (show: boolean) => void }> = ({ toggleModal, onConfirm, showSuccessModal, setShowSuccessModal }) => {
  const history = useHistory();
  const [isModalOpen,setModalOpen]=useState(true);
  const { setApplicationSubscribed, applicationSubscribed, removeApplication } = useProductModalStore();
  useEffect(()=>{
    if(!isModalOpen){
      history.push('/');
    }
  })
  const onConfigureUsers = () => {
    sessionStorage.setItem('restrictGetApplicationsList', 'true');
    toggleModal();
    setShowSuccessModal(false);
    setModalOpen(false);
  }
  const onGoToProductOfferings = () => {
    toggleModal();
    setTimeout(() => {
      setShowSuccessModal(false);
      setApplicationSubscribed(!applicationSubscribed);
      history.push('/product-offerings');
    }, 500);
  };

  return (
    <div className="bg-theme-light-background">
      <hr className="bg-theme-light-background shadow-lg" />
      <div className="p-5 flex justify-between">
        {showSuccessModal ? 
          <ReturnToProductOfferings onClick={onGoToProductOfferings} /> :
          <CancelButton onClick={toggleModal} />
        }
        {showSuccessModal ? 
          !removeApplication && <ConfigureUsersNow onConfirm={onConfigureUsers} /> :
          <ConfirmButton onConfirm={onConfirm} removeApplication={removeApplication}/>
        }
      </div>
    </div>
  );
};

export default React.memo(ModalFooter);