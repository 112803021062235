import React, { useEffect } from 'react';
import { Redirect, Route, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString, { stringify } from 'query-string';
import PermissionedLayout from 'core/layout/PermissionedLayout';
import useSession from 'hooks/session/session';
import config from 'config/config';

const PermissionedRoute = ({ children, component: Component, ...rest }) => {
  const session = useSession();
  const location = useLocation();
  const history = useHistory();
  const hasComponent = (Component !== undefined);

  useEffect(() => {
    if (!session.authenticated) {
      const hash = window.location.hash;
      const qs = queryString.parse(window.location.hash);      
      let forwardHash = hash;

      // if we have errors from a cancelled or successful password reset, delete them from the querystring
      if (hash.includes('AADB2C90091')) {
        delete qs['error'];
        delete qs['error_description'];
        forwardHash = stringify(qs);
      }
      let url = new URL(window.location.href);
      const urlPathname = url.pathname.replace(config?.activeBrand?.appBasePath, '');
      const postLoginRedirect = urlPathname + url.search + url.hash;
      history.push({ 
        pathname: '/login',
        search: forwardHash,
        state: { postLoginRedirect: postLoginRedirect }
      })
    }
  }, [session.authenticated]);

  return (
    session.authenticated &&
    <Route
      {...rest}
      render={props => (
        <PermissionedLayout>
          {hasComponent && <Component {...props} />}
          {!hasComponent && children}
        </PermissionedLayout>
      )}
    />
  );
};

PermissionedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

PermissionedRoute.defaultProps = {
  component: undefined,
  children: [],
};

export default PermissionedRoute;
