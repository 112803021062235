import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/pro-light-svg-icons";
import useFeatureFlags from 'hooks/utils/featureFlags';
import FormField from "components/Forms/FormField";
import { Option } from "components/UserWizard/SelectOption";
import { LocalizedText, useUser, useLocale } from 'portal-common-ui';
import FormSelect from "components/Forms/FormSelect";
import { validateName } from "utils/Helper";
import { useLocation } from "react-router";

const PersonalInformationTab = ({ user, isEditing, formValues, onUpdate, isDiscarding, targetUser }) => {
  const { accountsBrandListEnabled } = useFeatureFlags();
  const { brands: userBrands } = user;
  const { getAuthenticatedSessionUser } = useUser();
  const showBrandsDropdown = accountsBrandListEnabled && formValues?.role?.roleType === "INTERNAL";
  const { translateToString } = useLocale();

  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [userHasMoreBrands, setUserHasMoreBrands] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const currentUserResult = await getAuthenticatedSessionUser();
      const brandsOptions = currentUserResult.brands.map((brand) => ({
        value: brand,
        label: brand,
      })).sort((a, b) => (a.value > b.value) ? 1 : -1);
      
      setSelectedBrands(brandsOptions.filter(b => userBrands.indexOf(b.label) !== -1));

      if (brandsOptions.length > 1) {
        brandsOptions.unshift({ label: translateToString('createUser.sections.basicInfo.form.selectBrands.allBrands'), value: "all" })
      }
      setBrands(brandsOptions);

      const userHasMoreBrands = userBrands.some(
        (b) => currentUserResult.brands.indexOf(b) === -1
      );
      setUserHasMoreBrands(userHasMoreBrands);
    };
    fetch();
  }, [user]);

  useEffect(() => {
    if (isDiscarding) {
      setBrands([...brands]);
    }
  }, [isDiscarding]);

  const multiBrandAvailable = brands.length > 1;
  const location = useLocation();

  return (
    <div className="bg-theme-on-primary rounded-sm border-gray-100 min-h-full sml:w-auto">
      <div className="font-normal text-theme-dark pb-2">
        {/* <LocalizedText localeKey="editUser.sections.personalInformation.subtitle" /> */}
      </div>
      {/* <div className="inline-flex flex-col space-y-5 items-start justify-start py-3 px-4 bg-white rounded-sm border" style={{width: 774, height: 240}}> */}
      <div className="grid grid-cols-2 sml:grid-cols-1 gap-3 gap-x-3 items-start justify-start place-items-start pb-0">
        <FormField
          disabled={!isEditing}
          name="firstName"
          labelKey="userManagementPage.columns.firstName"
          label="First Name"
          defaultValue={user.firstName}
          containerClass="w-full"
          required
          maxLength={50}
          inputClass="w-96"
          customValidate={(e) => validateName(e, "firstName")}
          errorKeys={{"required": "createUser.sections.basicInfo.form.errors.fnameBlank", 
                      "validate": "createUser.sections.basicInfo.form.errors.invalidFName", 
                      "maxLength":"createUser.sections.basicInfo.form.errors.fnameMaxLength" }} 
        />
        <FormField
          disabled={!isEditing}
          name="lastName"
          labelKey="userManagementPage.columns.lastName"
          label="Last Name"
          defaultValue={user.lastName}
          containerClass="w-full"
          required
          maxLength={75}
          inputClass="w-96"
          // containerClass="ml-2"
          customValidate={(e) => validateName(e, "lastName")}
          errorKeys={{"required" : "createUser.sections.basicInfo.form.errors.lnameBlank", 
                      "validate": "createUser.sections.basicInfo.form.errors.invalidLName",
                      "maxLength":"createUser.sections.basicInfo.form.errors.lnameMaxLength"}}
        />
        <FormField
          disabled
          name="email"
          labelKey="userManagementPage.columns.email"
          label="Email"
          defaultValue={user.email}
          containerClass="med:w-full"
          inputClass="w-96"
          attached={<FontAwesomeIcon icon={faAt} style={{ color: '#D7DCE1'}}/>}
        />
      </div>
      {showBrandsDropdown && (
        <div className="mt-8">
          <hr
            className="border-theme-light-border w-full pb-4"
            style={{ height: 1.18 }}
          />
          <FormSelect
            components={{
              Option,
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            name="brand"
            label={<LocalizedText localeKey="createUser.sections.basicInfo.form.selectBrands.title" />}
            defaultOption={selectedBrands}
            required
            inputClass="w-96 sml:w-full"
            options={brands}
            onSelection={onUpdate}
            placeholder={translateToString("editUser.sections.personalInformation.brands.placeholder")}
            isMulti={multiBrandAvailable}
            sorted={false}
            disabled={!multiBrandAvailable}
          />
          {userHasMoreBrands && (
            <span className="italic text-xs">
              {'* '}<LocalizedText localeKey="editUser.sections.personalInformation.brands.outsideOfAccess" />
            </span>
          )}
        </div>
      )}

        <hr className="mt-8"/>

        <div className="mt-8 hidden lrg:block">
          <div className="flex">
            <span className="w-[100px] text-xs font-medium"><LocalizedText localeKey="editUser.accountInfo.creationDate"/>:</span><span className="text-sm text-black font-medium">{targetUser.createdDateText}</span>
          </div>
          <div className="flex">
            <span className="w-[100px] text-xs font-medium"><LocalizedText localeKey="editUser.accountInfo.lastLogin"/>:</span><span className="text-sm text-black font-medium">{targetUser.lastLoginDateText || 'N/A'}</span>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};

export default PersonalInformationTab;
