import usePolicyLinks from "hooks/utils/policyLinks";
import { LocalizedText } from "portal-common-ui";

const Footer = () => {
    const { privacyPolicyLink, termsOfUseLink, regulatoryDisclosuresLink } = usePolicyLinks();

    return (
        <footer className="flex justify-end lrg:justify-center items-center bg-theme-on-primary border-b border-theme-light-background py-4 pr-20 sml:pr-0 mt-1 z-10 text-xs font-light">
            <a 
                className={`hover:underline ${!privacyPolicyLink ? "hidden" : ""}`} 
                href={privacyPolicyLink} 
                target="_blank" 
                rel="noopener noreferrer"
                data-test="homepage-privacyPolicyLink"
            >
                <LocalizedText localeKey="footer.links.privacyPolicy" />
            </a>
            <span className={`px-4 ${!(privacyPolicyLink && termsOfUseLink) ? "hidden" : ""}`}> | </span>
            <a 
                className={`hover:underline ${!termsOfUseLink ? "hidden" : ""}`} 
                href={termsOfUseLink} 
                target="_blank" 
                rel="noopener noreferrer"
            >
                <LocalizedText localeKey="footer.links.termsOfUse" />
            </a>
            <span className={`px-4 ${((privacyPolicyLink || termsOfUseLink) && regulatoryDisclosuresLink) ? "" : "hidden"}`}> | </span>
            <a 
                className={`hover:underline ${!regulatoryDisclosuresLink ? "hidden" : ""}`} 
                href={regulatoryDisclosuresLink} 
                target="_blank" 
                rel="noopener noreferrer"
            >
                <LocalizedText localeKey="footer.links.regulatoryDisclosures" />
            </a>
        </footer>
    )
}

export default Footer;
