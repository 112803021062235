import React from "react";
import { Button, ButtonType } from 'portal-common-ui';
import { useFormState } from "react-hook-form";

const UserEditorFooter = ({hasFormChanges, isValid, isSaving = false, onDiscardChanges, onSaveChanges, userStatus}) => {

  const {isDirty} = useFormState();
  return (
    <div className="flex justify-between items-center py-4 bg-theme-light-background lrg:bg-theme-on-primary border sml:border-0 w-full">
          
      <div>
          <Button 
            disabled={!hasFormChanges}
            buttonType={ButtonType.LinkButton}
            buttonClass="text-red-700 sml:hidden"
            onClick={onDiscardChanges}
            testDataAttribute= "profile-edit-discard-changes"
            titleKey="editUser.buttons.discard"
          />
          {/* For Mobile View */}
          <Button 
            disabled={!hasFormChanges}
            buttonType={ButtonType.MobileSecondary}
            buttonClass="hidden sml:block ml-4"
            onClick={onDiscardChanges}
            testDataAttribute= "profile-edit-discard-mobile-changes"
            title="Discard"
            titleKey="editUser.buttons.discard"
          />
      </div>

      <div>
          <Button 
            titleKey="editUser.buttons.save"
            buttonType="PrimaryButton"
            buttonClass="sml:hidden"
            onClick={onSaveChanges}
            disabled={userStatus !== "APPROVAL_PENDING" ? !hasFormChanges || !isValid || isSaving : false}
            minWidth={150} 
            testDataAttribute="edit-save-changes-cy"
          />

          {/* For Mobile View */}
          <Button 
            title="Save Changes"
            titleKey="editUser.buttons.save"
            buttonType={ButtonType.MobilePrimary}
            onClick={onSaveChanges}
            buttonClass="hidden sml:block"
            disabled={!hasFormChanges || !isValid || isSaving}
            minWidth={150} 
            testDataAttribute="edit-save-mobile-changes-cy"
          />
      </div>

    </div>
    );
};

export default UserEditorFooter;


