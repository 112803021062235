import produce from "immer";

const useWizard = () => {
  const getNextStep = (wizardSteps, currentStep) => {
    return wizardSteps.find((step) => step.stepName === currentStep).nextStep;
  };

  const getPrevStep = (wizardSteps, currentStep) => {
    return wizardSteps.find((step) => step.stepName === currentStep).prevStep;
  };

  const updateWizardSteps = (wizardSteps, { activeStep, previousStep }) => {
    const updatedWizardSteps = produce(wizardSteps, (draft) => {
      const step = draft.find(
        (s) => s.stepName === draft[previousStep - 1]?.stepName
      );
      if (step) {
        step.isActive = false;
        step.isComplete = activeStep > previousStep;
        step.isPending = activeStep < previousStep;
      }

      const nextStep = draft.find((s) => s.stepName === draft[activeStep - 1]?.stepName);
      if (nextStep) {
        nextStep.isActive = true;
        nextStep.isPending = false;
        nextStep.isComplete = false;
      }
    });
    return updatedWizardSteps;
  };

  return {
    getNextStep,
    getPrevStep,
    updateWizardSteps,
  };
};

export default useWizard;
