import SelectCloneUser from "components/CloneUser/SelectCloneUser";
import CreateWizard from "components/CreateWizard/CreateWizard";
import React, { useState,useEffect } from "react";
import useStateRef from "react-usestateref";
import { FormProvider, useForm } from "react-hook-form";
import steps from "./steps";
import { ReactComponent as UserWizardSvg } from '../../assets/img/user-wizard.svg'
import { useHistory } from "react-router-dom";
import BasicInformation from "components/CloneUser/BasicInformationCloneUser";
import UserCreationSuccess from "components/UserWizard/UserCreationSuccess";
import useWizard from "hooks/wizard";
import { LocalizedText, NavigationWarning, useLocale, useRoles, useUser } from "portal-common-ui";
import ReviewProfile from "components/CloneUser/ReviewProfile";
import useAlertQueue from "hooks/alertQueue";
import useFeatureFlags from "hooks/utils/featureFlags";

const CloneUser: React.FC = () => {

    const history = useHistory();
    const [cloneExistingUser, setCloneExistingUser] =useState(false);
    const [currentStep, setCurrentStep] = useState(history.location?.state?.from ? steps[1].stepName  : steps[0].stepName);
    const [wizardSteps, setWizardSteps] = useState(history.location?.state?.from ?steps.filter(m => m.stepName !== 'selectUserToClone') : steps);
    const imageSvg = (<UserWizardSvg title="Person laying sideways propped upright with hand looking at laptop screen" />)
    const { getNextStep, getPrevStep, updateWizardSteps } = useWizard();
    const [userToClone, setUserToClone] = useState({});
    const [deactivateUserFlag, setDeactivateUser] = useState(false);
    const methods = useForm({ mode: "onChange" });
    const [unmounted, setMount] = useState(false);
    const [basicInfoState, setBasicInfoState] = useState({});
    const { getRoleDisplayName } = useRoles();
    const { cloneUser,deactivateUser } = useUser();
    const [isSaving, setIsSaving] = useState(false);
    const { enqueueAlert } = useAlertQueue();
    const [emailError, setEmailError] = useStateRef(false);
    const [userFetching, setUserFetching] = useState(false);
    const [reviewConfigState, setReviewConfigState] = useState({
        sendEmail: true,
        cloneScheduledReports: false,
      });
    const { accountsBrandListEnabled } = useFeatureFlags();

    const nextButtonCaptionKey =
    currentStep === "reviewProfile" ? "createUser.buttons.completeUserCreation" : "createUser.buttons.nextStep";

    const onCancel = () => {
        history.push("/user-management");
    }

    const onNextStep = () => {
        const nextStep = getNextStep(wizardSteps, currentStep);
        setCurrentStep(nextStep);
    }

    const isInternalRole = () => {
      const externalRoles = ["ACCOUNT_USER", "ACCOUNT_ADMINISTRATOR", "THIRD_PARTY_USER", "THIRD_PARTY_ADMINISTRATOR", "LIMITED_PARTNER"];
      return !externalRoles.includes(userToClone?.accountsApplicationRoleGuid || userToClone?.roleGuid || userToClone?.roleObject?.roleGuid);
    };

    const onSaveAndContinue = () => {
        switch (currentStep) {
            case "selectUserToClone":
                onSetBasicInfo(methods.getValues());
                onNextStep();
                break;
            case "basicInfo":
                onSetBasicInfo(methods.getValues());
                onNextStep();
                break;
            case "reviewProfile":
                onSetBasicInfo(methods.getValues());
                onCloneUser();
                break;
        }
    }

    const onCloneAnotherUser = () => {
        clearState();
        history.push('/clone/new');
      }

    useEffect(() => {
        if(history?.location?.state?.from?.userId)
        {
        setCloneExistingUser(true);
        setUserToClone(history?.location?.state?.from);       
        }
          }, [cloneExistingUser]);

    const saveBasicInfoState = (newState) => {
      newState.role = {
        label: userToClone?.roleObject?.label,
        roleType: isInternalRole() ? "INTERNAL" : "EXTERNAL",
      };
      setBasicInfoState(newState);
    };

    useEffect(() => {
      saveBasicInfoState(methods.getValues());
    }, [userToClone?.roleObject]);

    const onSetBasicInfo = (newState) => {
      saveBasicInfoState(newState);
    };
    
    const {translateToString} = useLocale();
    const onCloneUser = async () => {
        try {
          setIsSaving(true);
          const cloneUserData = {
            firstName: basicInfoState.firstName?.trim(),
            lastName: basicInfoState.lastName?.trim(),
            email: basicInfoState.email?.trim(),
            sourceEmail : userToClone.email,
            sendSetupEmail: reviewConfigState.sendEmail,
            cloneScheduledReports: reviewConfigState.cloneScheduledReports,  
          };
          if (
            basicInfoState?.role?.roleType === "INTERNAL"
          ) {
            cloneUserData.sourceBrands = basicInfoState?.brand?.map((b) => b.value);
          }
          const resp = await cloneUser (cloneUserData);
          if (resp.errorCode) {
            let err = resp.errorCode;
            let titleKey = "";
            if (err === "user.email.alreadyInUse") {
              titleKey = 'cloneUser.alreadyUsedEmail';
              err = translateToString("cloneUser.emailMessage");
            } else {
              titleKey = "Sorry, something has gone wrong.";
              err = "Our system has generated an error. Please try again.";
            }
            enqueueAlert({
              alertType: "modal",
              titleKey,
              messageType: "error",
              message: `${err}`,
            });
            setIsSaving(false);
          } else {
            if (deactivateUserFlag) {
              const response = await deactivateUser(userToClone?.userId);
              if (response.status !== 200) {
                throw new Error();
              }
              enqueueAlert({
                alertType: "toast",
                messageType: "info",
                message:
                  "User has been successfully deactivated.  Please note that changes may not immediately be reflected in the user list as our system processes the update.",
                transitionDuration: 6000,
              });
            }
            setIsSaving(false);
            onNextStep();
          }
         } catch (e) {
           console.log(e);
           alert(e);
         }
      };

    const customIsValid = () => {
      if (userFetching) {
        return false;
      }
      if (emailError && currentStep === "basicInfo")
        return false;
      if (currentStep === "selectUserToClone")
        return Object.keys(userToClone).length !== 0;
      if (isInternalRole() && accountsBrandListEnabled)
        return (
          methods.formState.isValid && methods.getValues()?.brand?.length > 0
        );
      else return methods.formState.isValid;
    };

    const showPrevStep =  currentStep==='basicInfo' && history.location?.state?.from? null :  currentStep !== steps[0]?.stepName ;

    const onPrevStep = () => {
      const prevStep = getPrevStep(wizardSteps, currentStep);
      setCurrentStep(prevStep);
    };

    if(unmounted){
        return null
      }

    const setReload = () => {
        setMount(true)
        setTimeout(() => {
            setMount(false)
        }, 200)
    }

    const clearState = () => {
        setCurrentStep(steps[0].stepName);
        setUserToClone({});
        setReviewConfigState({ sendEmail: true ,cloneScheduledReports:true});
    }
    const isDirty = () => {
        return Object?.keys(userToClone)?.length !== 0;
    }

    const disableWarning = currentStep === steps[steps.length - 1].stepName || !isDirty();


    const onWarningConfirmed = () => {
        if (currentStep === steps[steps.length - 1].stepName) {
            clearState();
            setReload();
        }
    }

    const resetRole = () => {
        setBasicInfoState({ ...basicInfoState, role: null});
      };

    const onStepChange = (wizardStepStatus: any) => {
        const updatedWizardSteps: any = updateWizardSteps(wizardSteps, wizardStepStatus);
        setWizardSteps(updatedWizardSteps);
      };

      const changeStep = (step) => {
        setCurrentStep(step);
      };

    return (
      <FormProvider {...methods}>
        <CreateWizard
          steps={steps}
          wizardSteps={wizardSteps}
          currentStep={currentStep}
          imageSvg={imageSvg}
          backToButtonKey="createUser.buttons.backToUsers"
          title={
            currentStep === "creationComplete"
              ? <LocalizedText localeKey="createUser.sections.creationComplete.heading"/>
              : <LocalizedText localeKey="cloneUser.title"/>
          }
          hideSubtitle={currentStep === "creationComplete"}
          subTitle={
            <LocalizedText localeKey="cloneUser.subTitle" />
          }
          onCancel={onCancel}
          nextButtonCaptionKey={nextButtonCaptionKey}
          onNextStep={onSaveAndContinue}
          customIsValid={customIsValid}
          showPrevStep={showPrevStep}
          onPrevStep={onPrevStep}
          onStepChange={onStepChange}
          hideFooter={currentStep === "creationComplete"}
          showButtonLoader={isSaving}
          isSaving={isSaving}
        >
          {!history.location?.state?.from && (
            <SelectCloneUser
              stepName="selectUserToClone"
              setUserToClone={setUserToClone}
            />
          )}

          <BasicInformation
            stepName="basicInfo"
            userToClone={userToClone}
            setUserToClone={setUserToClone}
            formData={basicInfoState}
            showRoleSelector={true}
            setEmailError={setEmailError}
            emailError={emailError}
            setUserFetching={setUserFetching}
            methods={methods}
            currentStepName={currentStep}
          />

          <ReviewProfile
            stepName="reviewProfile"
            changeStep={changeStep}
            userToClone={userToClone}
            isSaving={isSaving}
            basicInfoState={basicInfoState}
            saveReviewConfigState={setReviewConfigState}
            reviewConfigState={reviewConfigState}
            resetRole={resetRole}
            onPrevStep={onPrevStep}
            setDeactivateUser={setDeactivateUser}
            deactivateUserFlag={deactivateUserFlag}
          />

          <UserCreationSuccess
            stepName="creationComplete"
            formData={basicInfoState}
            sendEmail={reviewConfigState.sendEmail}
            cloneCaption={true}
            onCreateAnotherUser={onCloneAnotherUser}
          />
        </CreateWizard>
        <NavigationWarning
          warningLine1Key = "cloneUser.alerts.navigationWarnings.message"
          warningLine2Key="createUser.alerts.navigationWarning.message2"
          continueCaptionKey="createUser.alerts.navigationWarning.continueCaption"
          cancelCaptionKey="createUser.alerts.navigationWarning.cancelCaption"
          skipWarningRouteList={['/loggedOut']}
          onWarningConfirmed={onWarningConfirmed}
          disableWarning={disableWarning}
        />
      </FormProvider>
    );
}


export default CloneUser;