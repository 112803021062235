import React from 'react';
import useProvisionedLink from 'hooks/utils/provisionedLink';
import useFeatureFlags from "hooks/utils/featureFlags";
import useSession from 'hooks/session/session';
import useAlertQueue from 'hooks/alertQueue';
import { isMobileOnly } from 'react-device-detect';
import { useGlobal } from 'libs/reactn';
import { mapAppLinkQueryString } from 'components/Dashboard/helper';
interface ApplicationProps {
    applicationGuid: string;
    ssoType: string;
    appLinksMap: { [key: string]: any };
    extraClass?: string,
}

const ApplicationBtn: React.FC<ApplicationProps> = ({ applicationGuid, ssoType, appLinksMap, extraClass, children }) => {
    const { accountsSelfRegApplicationAvailabilityDelay, accountsEnabledMobileApplications } = useFeatureFlags();
    const session = useSession();
    const alertQueue = useAlertQueue();
    const [auth] = useGlobal('auth');
    const isMobileEnabled = !!accountsEnabledMobileApplications?.find((app: { [key: string]: string }) => app?.applicationGuid === applicationGuid);
    const provisionedLinkURL = `${appLinksMap[applicationGuid]}&applicationGuid=${applicationGuid}`;
    const { handleClick: provisionedLinkClick } = useProvisionedLink(provisionedLinkURL, auth.myProfile);

    const appsClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const appDelay = Math.round((accountsSelfRegApplicationAvailabilityDelay?.applications?.find(a => a.appGuid === applicationGuid)?.delaySeconds || 0) / 60);
        const timeElapsed = session.firstVist ? session.getElapsedSecondsSinceSelfRegistering() : 0;
        if (session.firstVist && 
            appDelay > 0 && 
            appDelay > timeElapsed) {
            console.log('show delay error');
            alertQueue.enqueueAlert({
                alertType: 'modal',
                title: 'Reporting Data Not Yet Available',
                messageType: 'error',
                continueCaption: 'OK',
                message: `Your reporting data is still being generated and will be ready in approximately ${appDelay - timeElapsed} minutes.  Once your data is generated, the Reporting application will become available.`,
            });
        } else if(isMobileOnly && !isMobileEnabled){
            alertQueue.enqueueAlert({
                alertType: 'modal',
                title: 'This application is unavailable on this device',
                titleKey: "homepage.products.mobileError.title",
                messageType: 'mobileInfo',
                continueCaption: 'OK',
                message: "A mobile-friendly version of this application will be available soon.  In the meantime, please use a desktop device to access the application.",
                messageKey: "homepage.products.mobileError.message",
            });
        }
         else if(ssoType === 'MP_CUSTOM') {
            provisionedLinkClick(event);
        } else {
            window.open(mapAppLinkQueryString(appLinksMap[applicationGuid], auth.myProfile));
        }
    };

    return ( 
        <button className={extraClass ? `${extraClass} w-full` : 'w-full'} onClick={appsClickHandler}>
            {children}
        </button>
    )
}

export default ApplicationBtn;