/* eslint-disable camelcase */
/* eslint-disable no-undef */

export default function useAuthSettings() {

  const initializeSettings = () => {
    let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));
    const port = (window.location.port) ? `:${window.location.port}` : '';
    const redirectUri = `https://${window.location.hostname}${port}${brandDetails.appBasePath}`;
    const postLogoutRedirectUri = redirectUri;
  
    let modifiedAuthority = '';
    let modifiedClientId = '';
    modifiedAuthority = brandDetails.b2cAuthority;
    modifiedClientId = brandDetails.b2cClientId;

    modifiedAuthority = modifiedAuthority.replace('/v2.0', '');
    const knownAuthorities = [];
    const knownAuthority = modifiedAuthority.split('/')[2];
    if (knownAuthority) knownAuthorities.push(knownAuthority);
  
    const msalConfig = {
      auth: {
        clientId: modifiedClientId,
        knownAuthorities,
        authority: modifiedAuthority,
        redirectUri,
        postLogoutRedirectUri,
        validateAuthority: false,
        navigateToLoginRequestUrl: true,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message) => {
            console.log(message);
          },
          piiLoggingEnabled: true,
        },
      },
    };
    return msalConfig;
  };

  const msalConfig = initializeSettings();


  return {
    msalConfig,
  };
}