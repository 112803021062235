import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useUser, useRoles, AlertCard, useLocale } from 'portal-common-ui';
import useValidateEmail from 'hooks/user/email'
import FormField from "components/Forms/FormField";
import FormSelect from "components/Forms/FormSelect";
import { useDebounce } from "react-use";
import config from 'config/config';
import { Option } from './SelectOption';
import CheckList from "components/CheckList/CheckList";
import useFeatureFlags from 'hooks/utils/featureFlags';
import { validateName, validateEmail as validateEmailHelper} from "utils/Helper";

const BasicInformation = ({ 
  hasFullPortfolio,
  formData,
  resetRole,
  setShowNotification,
  showNotification,
  setSelectedRole,
  selectedRoleRef,
  pemissions,
  setPemissions
}) => {

  const { accountsBrandListEnabled, accountsOptionalPermissionsEnabled } = useFeatureFlags();
  const formRef = useRef()
  const user = useUser();
  const { getAuthenticatedSessionUser } = useUser();
  const methods = useFormContext();
  const { getRoles, getPermissions, getNotificationForRoleChange } = useRoles();
  const { validate } = useValidateEmail();
  const [roles, setRoles] = useState([]);
  const [brands, setBrands] = useState([]);
  const [email, setEmail] = useState(formData.email);
  const [activeNotification, setActiveNotification] = useState(null);
  const [debouncedEmail, setDebouncedEmail] = useState(formData.email?.trim());
  const [selectedBrands, setSelectedBrands] = useState(formData.brand);
  const showBrandsDropdown = accountsBrandListEnabled && !!selectedRoleRef.current && selectedRoleRef.current.roleType === 'INTERNAL';
  let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));

  useEffect(() => {
    const fetchRoles = async () => {
      const userResult = await getAuthenticatedSessionUser();
      let roleGuid = '';
      userResult.userApplicationAssignments.results.forEach((app) => {
        if (app.assignedApplication.applicationGuid === accountsAppGuid) {
          roleGuid = app.assignedApplication.applicationRoleAssociations?.[0]?.role?.roleGuid;
        }
      });
      methods.setValue('role', null);
      if(debouncedEmail !== formData.email) {
        if(resetRole) resetRole();
      }
      
      if (debouncedEmail) {
          selectedRoleRef.current = null;
          const responseRoles = await getRoles(debouncedEmail, roleGuid);
          setRoles(responseRoles);
      } else {
        setRoles([]);
      }
    };
    
      fetchRoles();
    
  }, [debouncedEmail]);


  useEffect(() => {
    methods.reset(formData);
  }, [methods.reset]);

  // track when notification appears, because we immediately delete
  useEffect(() => {
    if (activeNotification) {
      setShowNotification(true);
    }
  }, [activeNotification]);

  useDebounce(() => {
      setDebouncedEmail(email);
    }, 
    2000,
    [email]
  );

  useEffect(() => {
    const fetch = async () => {
      const userResult = await getAuthenticatedSessionUser();
      const brandsOptions = userResult?.brands?.map(brand => {
        if (brand.toLowerCase() === brandDetails.brandGuid.toLowerCase() || userResult?.brands?.length === 1) {
          const fixedBrand = {value: brand, label: brand, isFixed: true};
          if (!selectedBrands) {
            setSelectedBrands([fixedBrand]);
          }
          return fixedBrand;
        } else {
          return {value: brand, label: brand};
        }
      });
      if (brandsOptions.length > 1) {
        brandsOptions.unshift({ label: translateToString('createUser.sections.basicInfo.form.selectBrands.allBrands'), value: "all" })
      }
      setBrands(brandsOptions);
    };
    fetch();
  }, [])

  const validateEmailAddress = async (input) => {
    const emailInput = input?.trim();
    setEmail(emailInput);
    if(user.authenticatedUser?.internal) {
      return true
    }
    const isValidEmail = await validate(emailInput)
    if (!isValidEmail) {
      return true
    }
    setRoles([]);
    return 'This email domain is not allowed.'
  }

  const debouncedValidateEmail = (input) => {
    const emailInput = input?.trim();
    const emailTest = validateEmailHelper(emailInput);
    if (emailTest) {
      setEmail(emailInput);
    } else {
      setEmail();
    }
    return emailTest;
  };

  const validateEmail = debouncedValidateEmail;

  const onRoleSelected = (newRole) => {
    setSelectedRole(newRole);
    if (formData && formData.role && newRole) {
      const notification = getNotificationForRoleChange(formData.role, newRole, hasFullPortfolio);
      setActiveNotification(notification);
      if (!notification) {
        setShowNotification(false);
      }
    }
  }

  const onBrandSelected = (brand) => {
    setSelectedBrands(brand);
  }

  const validateRole = () => {
    return (selectedRoleRef.current !== null);
  }

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await getPermissions(methods.getValues().role?.roleId);
      const list = response.permissions.map((p) => {
        return {
          label: p.permissionGuid.toLowerCase().split('_').join(' '),
          value: p.permissionId,
          selected: p.permissionType == "DEFAULT",
          disabled: p.permissionType == "DEFAULT",
        }
      })
      setPemissions(list)
    }
    if (methods.getValues().role?.roleId) {
      fetchPermissions();
    }
  }, [methods.getValues().role?.roleId])

  let defaultOption = null;
  const roleChange = formData.role?.roleId !== methods.getValues().role?.roleId;
  if (formData.role && roles.length > 0 ) {
    defaultOption = roles.find(r => r.roleId === formData.role.roleId) || null;
  }

  const multiBrandAvailable = brands.length > 1;
  const handlePermission = (permissions) => {
    setPemissions(permissions)
  }

  const { translateToString } = useLocale();

  return (
    <>
      {/* onReset={methods.handleReset} onSubmit={methods.handleSubmit()} */}
      <form ref={formRef} className="w-full">
        <div className="bg-theme-on-primary border border-t-0 border-b-0 border-theme-light-border w-full wizard-container-h min-h-wizard-container-inner-md t-2xl:min-h-wizard-container-inner-2xl" style={{maxHeight: 'calc(100vh - 225px)', overflowY: 'auto'}}>
          <div className="inline-flex flex-col space-y-2 t-xl:space-y-5 items-start justify-start py-4 px-5 xl:px-8 bg-theme-on-primary  rounded-sm w-full" style={{minHeight: 290}}>
              <div className="flex flex-row flex-wrap space-y-1 items-start justify-start pb-8 w-[620px] xl:w-[785px]" style={{height: 148}}>
                <FormField 
                  name="firstName" 
                  label="First Name" 
                  defaultValue={formData.firstName} 
                  required 
                  maxLength={50} 
                  inputClass="w-72 xl:w-96" 
                  customValidate={(e)=>validateName(e,'firstName')}
                  errorKeys={{"required": "createUser.sections.basicInfo.form.errors.fnameBlank", 
                              "validate": "createUser.sections.basicInfo.form.errors.invalidFName", 
                              "maxLength":"createUser.sections.basicInfo.form.errors.fnameMaxLength" }} 
                />
                <FormField 
                  name="lastName" 
                  label="Last Name" 
                  defaultValue={formData.lastName} 
                  required 
                  maxLength={75} 
                  containerClass="ml-4 !my-0" 
                  inputClass="w-72 xl:w-[370px] 2xl:w-96" 
                  customValidate={(e)=>validateName(e,'lastName')}
                  errorKeys={{"required" : "createUser.sections.basicInfo.form.errors.lnameBlank",
                              "validate": "createUser.sections.basicInfo.form.errors.invalidLName",
                              "maxLength":"createUser.sections.basicInfo.form.errors.lnameMaxLength"}}
                />
                <FormField 
                  name="email" 
                  label="Email Address" 
                  defaultValue={formData.email} 
                  required 
                  maxLength={254} 
                  inputClass="w-96" 
                  customValidate={{validate: validateEmail, custom: validateEmailAddress}} 
                  onBlur={validateEmail} 
                  attached={<i className="fal fa-at" style={{ color: '#D7DCE1' }}></i>} 
                  errorKeys={{"required" : "createUser.sections.basicInfo.form.errors.emailBlank", 
                              "validate": "createUser.sections.basicInfo.form.errors.invalidEmail",
                              "maxLength":"createUser.sections.basicInfo.form.errors.emailMaxLength"}}
                />
              </div>
              <hr className="border-theme-light-border w-full" style={{height: 1.18}} />
              <div className="flex flex-row flex-wrap space-y-1 items-start justify-start pb-1 xl:pb-8 w-[620px] xl:w-[785px] xl:h-[70px]">
              <FormSelect 
                name='role' 
                label='Select a role for this user'
                defaultOption={defaultOption}
                required inputClass="w-96"
                options={roles}
                onSelection={onRoleSelected}
                customValidate={validateRole} 
                disabled={!email || !roles.length} 
                placeholder={translateToString('createUser.sections.basicInfo.form.selectRoles.placeholder')}
                id='add-user-role'
                instanceId='add-user-role'
                inputId='add-user-role-input'
              />
              {accountsOptionalPermissionsEnabled && methods.getValues().role?.roleId &&
                <CheckList
                  fieldLabel="Role Permissions:"
                  options={pemissions}
                  onChange={handlePermission}
                  containerClass="xl:ml-4 !my-2 xl:!my-0 "
                  inputClass="!w-96"
                />
              }
            </div>
            {showBrandsDropdown &&
              <FormSelect
                components={{
                  Option
                }}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                name='brand' 
                label='Brand(s)'
                defaultOption={selectedBrands}
                required inputClass="w-96 z-100"
                options={brands}
                onSelection={onBrandSelected}
                placeholder='-SELECT BRANDS-'
                isMulti={multiBrandAvailable}
                sorted={false}
                disabled={!multiBrandAvailable}
                containerClass="!mt-0"
                id='add-user-brand'
                instanceId='add-user-brand'
                inputId='add-user-brand-input'
              />
              }
              {showNotification && activeNotification &&
              <div className="pt-2">
                <AlertCard 
                  type={activeNotification?.type || 'warning'}
                  title={activeNotification?.title}
                  messageLine1={activeNotification?.message} 
                  messageLine2={activeNotification?.message2}
                  widthClass={'w-[384px]'}
                  containerClass={'w-[326px]'}
                  messageClass={'max-w-[100%]'}
                />
              </div>
              }
          </div>
        </div>
      </form>
    </>
  );
}

export default BasicInformation;