import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { LocalizedText, AccessibleIcon } from "portal-common-ui";
import { isDesktop } from 'react-device-detect';

const ViewHideButton = ({ onView, onHide, lockedField, ...rest }) => {

    const [isProcessing, setIsProcessing] = useState(false);

    const handleOnView = async () => {
        setIsProcessing(true);
        await onView();
        setIsProcessing(false);
    }

    return (
        <>
            <span className="w-40 lrg:w-24">
                <button 
                    onClick={lockedField ? handleOnView : onHide}
                    disabled={isProcessing}
                    role="switch"
                    aria-checked={!lockedField}
                    aria-label={lockedField ? 'hiding field contents' : 'showing field contents'}
                    {...rest}
                >
                    {isDesktop && <><i className={`${lockedField ? 'fa fa-eye': 'fas fa-lock'} text-theme-info text-base font-medium ml-1`} ></i>
                    <span className="ml-1 mr-1 font-medium font-roboto text-left p-1 w-full text-theme-info">
                        {lockedField ? <LocalizedText localeKey="merchantDemographicsView.buttons.captions.view" /> : <LocalizedText localeKey="merchantDemographicsView.buttons.captions.hide" />}
                    </span></>}
                    {isProcessing && lockedField && <AccessibleIcon icon={faSpinner} localeKey="shared.loading" classes="fa-spin text-theme-dark"/>}
                </button>
            </span>
        </>
    );

};

export default ViewHideButton;
