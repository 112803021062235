import React from 'react'
import DataAccessGroupSuccessImg from '../../assets/img/data-access-group-success.svg'
import { useHistory } from "react-router-dom";
import usePermissions from 'hooks/session/permissions';
import { Button, ButtonType, LocalizedText } from 'portal-common-ui';


const UserGroupCreationSuccess = ({ formData, navigateToUsersAssignedTab}) => {
    const history = useHistory();
    const { hasPermission } = usePermissions();
    return (
        <>
            <div className="bg-theme-on-primary flex flex-col p-4 border border-theme-light-border border-b-0 w-full h-[90%]" data-test="data-access-management-successDAG">
                    <h1 className="font-medium text-5xl leading-6 text-green-700"><LocalizedText localeKey="createUser.sections.creationComplete.successPageData.successMessage"/></h1>
                    <p className="font-medium text-2xl leading-6 pb-2"><LocalizedText localeKey="createDAG.sections.addDataAccess.successGroup"/> <span className=" text-2xl leading-6 font-semibold text-blue-600">{formData.groupName}</span> <LocalizedText localeKey="createDAG.sections.addDataAccess.hasBeen"/></p>
                    <p className="font-medium text-2xl leading-6"> <LocalizedText localeKey="createDAG.sections.addDataAccess.createSuccess"/></p>
                    <br />
                    {
                        <>
                            <p className="font-normal text-lg leading-6"><LocalizedText localeKey="createDAG.sections.addDataAccess.assignMessage"/><br></br><LocalizedText localeKey="createDAG.sections.addDataAccess.otherGroupMessage"/></p><br></br>
                            <p className="font-normal text-sm leading-6 italic pb-5"><LocalizedText localeKey="createDAG.sections.addDataAccess.successNote"/></p>
                        </>}
                    {hasPermission('MANAGE_DATA_ACCESS_GROUPS') && hasPermission('UPDATE_OTHER_USERS') &&
                        <Button id="button-back"
                            onClick={navigateToUsersAssignedTab}
                            buttonType={ButtonType.PrimaryButton}
                            // buttonClass="inline-flex flex-col mr-3 items-center justify-end px-4 py-2 border rounded-sm border-gray-300 bg-theme-primary text-sm font-bold leading-snug text-center text-theme-on-primary disabled:text-theme-medium disabled:bg-transparent disabled:border-0"
                            testDataAttribute="data-access-management-adduser-button"
                            child={<span><LocalizedText localeKey="createDAG.buttons.addGroup"/></span>}
                        />
                    }
                <div>
                    <img className="absolute right-0 bottom-[4.5em]" alt='Data Access Success' style={{ height: 300 }} src={DataAccessGroupSuccessImg} />
                    </div>
            </div>
            <div className="flex items-center justify-end p-2 w-full gap-6 min-w-[662px] xl:min-w-[774px] border border-theme-light-border" style={{ height: 60, backgroundColor: "#F4F5F6" }}>
                <div style={{ cursor: "pointer" }} onClick={() => history.push('/data-access-management')} id="button-back" className="mx-2 text-sm font-medium text-theme-info" data-test="data-access-management-return-button">
                    <LocalizedText localeKey="createDAG.buttons.returnToDAG"/>
                </div>

                <Button
                    onClick={() => history.push('/data-access-group/new')}
                    id="button-back"
                    buttonType={ButtonType.PrimaryButton}
                    testDataAttribute="data-access-management-createanothergroup-button"
                    child={<span><LocalizedText localeKey="createDAG.buttons.createAnotherGroup"/></span>}
                />

            </div>
        </>
    )
}

export default UserGroupCreationSuccess;