import React, { useEffect } from "react";
import useStateRef from "react-usestateref";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CheckToggle = ({ defaultChecked = true, color='theme-info', unCheckedColor='theme-dark', onChange, disabled = false, value, dataTestAttribute }) => {

  const [, setIsChecked, isCheckedRef] = useStateRef(defaultChecked);

  useEffect(() => {
    if (typeof value !== "undefined") {
      setIsChecked(value?.selected);
    }
  }, [value]);

  const toggleChecked = () => {
    if (!disabled) {
      setIsChecked(!isCheckedRef.current);
      onChange(isCheckedRef.current);
    }
  };

  // tailwind uses this
  // 'bg-brand-primary', 'bg-grey-dark', 'ring-brand-primary', 'ring-grey-dark'

  const iconPosition = isCheckedRef.current ? 'end' : 'start';
  const activeColor = disabled ? 'gray-300' : isCheckedRef.current ? color : unCheckedColor;
  return (
    <div className="flex h-7 min-w-[46px] min-h-[24px]" data-test={dataTestAttribute}>
        <div className={`flex w-[46px] h-[24px] items-center justify-center relative rounded-full ring-2 ring-${activeColor} bg-${activeColor} ${!disabled && 'cursor-pointer'}`} onClick={toggleChecked}>
          <div className={`flex h-[20px] w-[40px] items-center justify-${iconPosition} relative rounded-full  ring-${activeColor} ring-1 bg-${activeColor}`}>
            <div className="flex items-center">
              {isCheckedRef.current && <FontAwesomeIcon icon={faCheck} className="text-theme-on-primary text-[15px] pr-1.5" />}
              <FontAwesomeIcon icon={faCircle} className="text-theme-on-primary text-base" />
            </div>              
          </div>
        </div>
        <div className="bg-gray-600"></div>
    </div>

  );
};

export default CheckToggle;