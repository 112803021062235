import config from 'config/config';
import { useApi } from 'portal-common-ui';

const useValidateEmail = (email) => {

  const env = config.env.name;

  // temporary workaround due to a config value for an old API being deleted thought it was still being used here
  const applicationUrl = config.API.authUrlV1 || config.API.authUrl.replace('v1.2', 'v1');
  const applicationApi =  useApi(`${applicationUrl}`);

  const validate = async (email = '') => {
    const relativeUrl = `/auth/verify/domain?email=${encodeURIComponent(email)}`;
    try {
      const resp = await applicationApi.get(relativeUrl);
      return typeof(resp) === 'boolean' ? resp : false
    } catch(e) {
      return false
    }
  };
  
  return {validate}

};

export default useValidateEmail;
