const mockApiConfig = {
  env: {
    name: 'dev',
  },
  API: {
    authUrl:  '/nextgen/authentication/api/v1.2',
    userUrl: '/nextgen/userservicenew/api/v1',
    selfRegisterUrl: '/Accounts/api/v2/users/register/merchant',
    notificationsUrl: '/SharedNotifications/api/v1/',
    hierarchyUrl: '/nextgen/hierarchy/api/v1',
    applicationUrl: '/nextgen/application/api/v1',
    sysnetUrl: '/services/clientapi/oauth/callback/oidc',
    reportingUrl: 'https://qa.np-reporting.globalpay.com',
    dimsUrl: 'https://qa3.np-disputesmanagement.heartland.us',
    analyticsSSO: "https://api-qa.np-reporting.globalpay.com/nextgen/analytics/api/v2/external/analytics/{0}?canHandle302Response=false",
    translationsUrl: '/nextgen/translations/api/v1'
  },
  features: {
    mfaEnabled: true,
    showSysnet: true,
    showReporting: true,
    dataAccessGroupLimit: 100,
    enablePendo: false,
  },
  analytics: {
    enableDebug: true,
    trackingId: 'UA-81886938-2'
  },
  launchDarkly: {
    clientId: '',
  },
  logRocket: {
    sdkServer: 'https://dev.np-logrocket.globalpay.com',
    ingestServer: 'https://dev.np-logrocket.globalpay.com',
    appID: 'globalpay-dev/accounts-dev'
  },
  brands: [{
    brandId: "tsys",
    baseUrl: "qa.np-accounts.tsys.com",
    appBasePath: "/",
    identityProvider: {
      authority: "https://gpcustomerqa.b2clogin.com/gpcustomerqa.onmicrosoft.com/b2c_1a_portal_tsys_susimfa/v2.0/",
      clientId: "07721394-54b4-44a1-83d2-a5a3fa3076f0"
    },
    analytics: {
      enableDebug: true,
      trackingId: 'G-6FJP0PZD5V'
    },
    packageUrl: "",
    logoPath: "/brands/tsys/logo.png",
    isWhiteLabel: true,
    primaryColor: "#06477c",
    secondaryColor: "#0F7DC4",
    businessName: "TSYS",
    copyRight: "Global Payments Direct, Inc. All rights reserved.",
    termsPolicyUrl: "https://storage.googleapis.com/mp-e8c2b92d-14e0-47d7-ba2f-7c510fc0d-images/mp-images/Accounts_Terms_Of_Use.pdf",
    errorMessages: {
      unknownUser: "Please call Broomfield: 800-543-5327/Omaha: 855-882-0507/Cayan: 800-951-6557 and provide the following error code:"
    },
    API: {
      analyticsSSO: "https://api-qa.np-reporting.globalpay.com/nextgen/analytics/api/v2/external/analytics/tsys?canHandle302Response=false",
    },
    contacts: [{
        name: "Broomfield",
        phone: "800-654-9256"
      },
      {
        name: "Omaha",
        phone: "800-228-2443"
      },
      {
        name: "Boston/Cayan",
        phone: "800-941-6557"
      },
      {
        name: "CPAY",
        phone: "800-449-8012"
      }
    ]
  },
  {
    brandId: "gpi",
    baseUrl: "qa.np-accounts.globalpaymentsintegrated.com",
    appBasePath: "/",
    identityProvider: {
      authority: "https://gpcustomerqa.b2clogin.com/gpcustomerqa.onmicrosoft.com/b2c_1a_portal_gpi_susimfa/v2.0/",
      clientId: "e24f52ea-af83-4ce3-abac-d97752f2140a"
    },
    analytics: {
      enableDebug: true,
      trackingId: 'G-TG63DZ8V6P'
    },
    packageUrl: "",
    logoPath: "/brands/gpi/logo.svg",
    isWhiteLabel: false,
    primaryColor: "#06477c",
    secondaryColor: "#0F7DC4",
    businessName: "Global Payments Integrated",
    copyRight: "Global Payments Direct, Inc. All rights reserved.",
    termsPolicyUrl: "https://storage.googleapis.com/mp-e8c2b92d-14e0-47d7-ba2f-7c510fc0d-images/mp-images/Accounts_Terms_Of_Use.pdf",
    errorMessages: {
      unknownUser: "Please call Broomfield: 800-543-5327/Omaha: 855-882-0507/Cayan: 800-951-6557 and provide the following error code:"
    },
    API: {
      analyticsSSO: "https://api-qa.np-reporting.globalpay.com/nextgen/analytics/api/v2/external/analytics/gpi?canHandle302Response=false",
    },
    contacts: [{
        name: "Broomfield",
        phone: "800-543-5327",
        email: "IntegratedCustomerCare@globalpay.com"
      },
      {
        name: "Omaha",
        phone: "855-882-0507",
        email: "IntegratedCustomerCareOMA@globalpay.com"
      },
      {
        name: "Cayan",
        phone: "800-951-6557"
      }
    ]
  },
  {
    brandId: "synovus",
    baseUrl: "qa.np-accounts.globalpay.com",
    appBasePath: "/synovus",
    identityProvider: {
      authority: "https://gpcustomerqa.b2clogin.com/gpcustomerqa.onmicrosoft.com/b2c_1a_portal_synovus_susimfa/v2.0/",
      clientId: "4f03e154-d745-4dbc-b7a5-cf59eeec915d"
    },
    analytics: {
      enableDebug: true,
      trackingId: 'G-MBJJXNQLHK'
    },
    packageUrl: "",
    logoPath: "/brands/synovus/logo.svg",
    isWhiteLabel: false,
    primaryColor: "#06477c",
    secondaryColor: "#0F7DC4",
    businessName: "Global Payments",
    copyRight: "Global Payments Direct, Inc. All rights reserved.",
    termsPolicyUrl: "https://storage.googleapis.com/mp-e8c2b92d-14e0-47d7-ba2f-7c510fc0d-images/mp-images/Accounts_Terms_Of_Use.pdf",
    errorMessages: {
      unknownUser: "Please call Broomfield - 800.654.9256 and provide the following error code:"
    },
    API: {},
    contacts: [{
      name: "Broomfield",
      phone: "800-654-9256"
    }]
  },
  {
    brandId: "hpy",
    url: "qa.np-accounts.globalpay.com/hpy",
    appBasePath: "/hpy",
    identityProvider: {
      authority: "https://gpcustomerqa.b2clogin.com/gpcustomerqa.onmicrosoft.com/b2c_1a_portal_tsys_susimfa/v2.0/",
      clientId: "d7917c78-6006-4266-a1bd-c482cdf30e08",
    },
    policy: "b2c_1a_portal_tsys_susimfa",
    packageUrl: "",
    logoPath: "/brands/tsys/logo.png",
    isWhiteLabel: true,
    primaryColor: "#06477c",
    secondaryColor: "#0F7DC4",
    businessName: "hpy",
    copyRight: "Global Payments Direct, Inc. All rights reserved.",
    termsPolicyUrl: "https://storage.googleapis.com/mp-e8c2b92d-14e0-47d7-ba2f-7c510fc0d-images/mp-images/Accounts_Terms_Of_Use.pdf",
    errorMessages: {
      unknownUser: "Please call the support number listed on your statement and provide the following error code:"
    },
    API: {},
  }]  
};
  
  export default mockApiConfig;