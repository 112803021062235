import { create } from "zustand";

type State = {
  appList: any[],
  internalAppList: any[],
  loading: boolean,
  loadingAvailableApps: boolean,
};

type Action = {
  setAppList: (appList: any[]) => void;
  setInternalAppList: (appList: any[]) => void;
  fetchAppList: (getProfileAPI: any, shouldRetryCallback: (profile: any) => boolean, retryDelay: number, maxRetries: number) => void;
};


export const useApplicationsStore = create<State & Action>()((set, get) => ({
  appList: [],
  internalAppList: [],
  loading: false,
  loadingAvailableApps: false,
  setAppList: (list: any[]) => {
    set((state) => ({
      ...state,
      appList: list,
    }));
  },
  setInternalAppList: (list: any[]) => {
    set((state) => ({
      ...state,
      internalAppList: list,
    }));
  },
  fetchAppList: async (getProfileAPI, shouldRetryCallback = (lastProfile: any, currentProfile: any) => false, retryDelay = 3000, maxRetries = 1) => {
    set({ loading: true });    
    let retries = 0;
    let shouldRetry = true;
    let lastProfile = null;
    let currentProfile = null;
    while (retries <= maxRetries && shouldRetry) {
      lastProfile = currentProfile;
      retries += 1;
      currentProfile = await getProfileAPI({forceReload: true});
      shouldRetry = shouldRetryCallback(lastProfile, currentProfile);
      if (!shouldRetry) break;
      await new Promise( resolve => {
        setTimeout(resolve, retryDelay)
      });
    }
    set({ loading: false });
    return currentProfile;
  },
}));

