import React, { useState, useRef } from 'react';
import ProductSelection from './ProductSelection';
import { ModalProps, ConfirmationMessageProps } from './types';
import { LocalizedText, useLocale } from 'portal-common-ui';
import ReactDOMServer from 'react-dom/server';

interface ProductModalContentProps extends ModalProps {
  onProductClick: (title: string) => void;
}

const ProductModalContent: React.FC<ProductModalContentProps> = ({ title, products, buttonTitle, onProductClick, myCurrentSubsription, removeApplication, setLatestSubscribedTitle }) => {
  const upgradedTitle = useRef(title);

  const handleProductClick = (title: string) => {
    let UpgradedProduct = products.find(product => product.name === title);
    if (myCurrentSubsription && !removeApplication) {
      let currentPriority = UpgradedProduct?.offeringPriorityInGroup;
      let groupId = UpgradedProduct?.groupId;
      
      // Keep searching for the next higher priority product
      while (currentPriority !== undefined && groupId) {
        const nextProduct = products.find(product => 
          product.groupId === groupId && 
          product.offeringPriorityInGroup === currentPriority + 1
        );
        
        if (nextProduct) {
          UpgradedProduct = nextProduct;
          break;
        }
        
        currentPriority++;
      }
      
      upgradedTitle.current = UpgradedProduct?.name || title;
    }
    setLatestSubscribedTitle(upgradedTitle.current);
    onProductClick(!removeApplication ? UpgradedProduct?.planId : UpgradedProduct?.subscriptionId);
  };

  return (
    <div>
      <hr className="bg-theme-light-background" />
      <div className="p-5 flex items-center">
        {!removeApplication ? (
          <p className="mt-2 text-theme-dark font-bold">
            <LocalizedText localeKey="productOfferings.modal.messages.addition" templateVariables={{appName: upgradedTitle.current}}/>
          </p>
        ) : (
          <div className='flex flex-col'>
          <p className="mt-2 text-theme-dark font-bold">
            <LocalizedText localeKey="productOfferings.modal.messages.removal" templateVariables={{appName: upgradedTitle.current}}/>
          </p>
          <p className="mt-2 text-theme-warning font-bold">
            <LocalizedText localeKey="productOfferings.modal.messages.remove"/>
          </p>
        </div>
        )}
      </div>
      <ProductSelection products={products} productTitle={title} buttonTitle={buttonTitle} onProductClick={handleProductClick(title)} myCurrentSubsription={myCurrentSubsription} removeApplication={removeApplication} />
      <ConfirmationMessage title={upgradedTitle.current} removeApplication={removeApplication}/>
    </div>
  );
};

const ConfirmationMessage: React.FC<ConfirmationMessageProps> = ({ title, removeApplication }) => (
  <p className="mt-8 ml-5 mb-10 text-theme-dark font-bold flex items-center">
    {!removeApplication ?
      <LocalizedText localeKey="productOfferings.modal.messages.addConfirmation" templateVariables={{appName : title}}/> 
    :
      <LocalizedText localeKey="productOfferings.modal.messages.removeConfirmation" templateVariables={{appName : title}}/>
    }
  </p>
);

export default ProductModalContent;