import React from "react";
import ApplicationsList from "components/UserViewerEditor/ApplicationsList";

function ApplicationRoles({ formData, selectedApplications, onRolePermissionChanged, targetHierarchies }) {
  return (
    <div
      data-test="add-user-application-roles"
      className="bg-theme-on-primary border border-theme-light-border border-t-0 border-b-0 overflow-y-auto overflow-x-hidden w-full h-[380px] t-xl:h-full"
    >
      {selectedApplications && (
        <ApplicationsList
          applications={selectedApplications}
          currentUser={null}
          targetUser={formData}
          onRolePermissionChanged={onRolePermissionChanged}
          targetHierarchies={targetHierarchies}
          type="CREATE"
          canEditApplicatinsAndRoles={true}
        />
      )}
    </div>
  );
}

export default ApplicationRoles;
