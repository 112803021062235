import React, { useEffect } from 'react';
import config from 'config/config';
import { createTheme, applyTheme } from 'hooks/utils/theme';
import useBrand from "hooks/brand";
import usePreferences from 'hooks/use-preferences';
import useProcessTranslations from 'hooks/utils/processTranslations';

const PublicLayout = (props) => {
  const { theme } = useBrand();
  const { translationsEnabled } = usePreferences();
  const { fetchAndSaveTranslations } = useProcessTranslations();
  let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));
  const brand = brandDetails.brandGuid.toLowerCase();
  const themeItems = theme(brand);
  const userLocale = localStorage.getItem('userLocale') || 'en-US';

  useEffect(()=> {
      applyTheme(createTheme(themeItems));

      const processTranslations = async () => {
        try {
          if (translationsEnabled) {
            await fetchAndSaveTranslations(userLocale);
          }
        } catch (err) {
          console.log('error with processing translations:', err);
        }
      }
      
      processTranslations();

  },[]);

  return (
    <div className="layout-wrapper" style={{
      overflowX: 'hidden',
      height: '100vh'
    }}>
      {props.children}
    </div>
  );
};

export default PublicLayout;
