import React, { useState } from "react";
import { DataAccessEditor } from 'portal-common-ui';
import useDataAccess from "hooks/utils/dataAccess";

const DataAccessTab = (props) => {
  const [pageTitle, setPageTitle] = useState('');
  const [dataAccessState, setDataAccessState] = useState(null);
  const [updatedDataRows, setUpdatedDataRows] = useState();
  const [saveGroup, setSavedGroup] = useState(false);
  const [allowFullPortfolioAccess, setAllowFullPortfolioAccess] = useState(true);
  const [canSaveDataAccess, setCanSaveDataAccess] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const { enabledSelectMethods } = useDataAccess();

  const onDataAccessUpdate = (updatedDataRows) => {
    setUpdatedDataRows(updatedDataRows);
    setSelectedGroups(updatedDataRows?.filter(m=>m && m?.searchBy?.value === 'DataAccessGroup' && m?.selectedGroup)?.map(m => m?.selectedGroup));
  };

  const onChangeHandler = (e) => {
    setAllowFullPortfolioAccess(e.target.value === "Internal");
  }

  return (
    <>
      <div className="flex items-center p-4" onChange={onChangeHandler} data-test="dataaccess-test-role-type">
        <input type='radio' value="Internal" name="roleType" checked={allowFullPortfolioAccess}/> <span className="m-2">Internal</span>
        <input type='radio' value="External" name="roleType" checked={!allowFullPortfolioAccess}/> <span className="m-2">External</span> 
      </div>
      <div data-test="dataaccess-hs-test">
        <div className="bg-theme-on-primary border border-theme-light-border border-t-0 border-b-0 overflow-y-auto overflow-x-hidden w-full wizard-container-h min-h-wizard-container-inner-md t-xl:min-h-wizard-container-inner-xl t-2xl:min-h-wizard-container-inner-2xl">
          <div className="flex items-top space-y-5 gap-x-4 items-start justify-start py-4 px-4 xl:px-8 bg-theme-on-primary rounded-sm h-full">
            <DataAccessEditor
              // preSelectedPortfolio="TSYS"
              allowFullPortfolioAccess={allowFullPortfolioAccess}
              dataRows={dataAccessState}
              onDataAccessUpdate={onDataAccessUpdate}
              setCanSave={setCanSaveDataAccess}
              onSetPageTitle={setPageTitle}
              setSavedGroup={setSavedGroup} 
              selectedGroups={selectedGroups}
              redirectSingleLineageToMidSelector={false}
              enabledSelectMethods={enabledSelectMethods}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DataAccessTab;
