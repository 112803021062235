import React from "react";
import { openHelpCenterLink } from "./helper";
import { isMobileOnly } from "react-device-detect";
import { Button, ButtonType, LocalizedText } from "portal-common-ui";
import { ReactComponent as HelpandfaqsMobile } from "assets/img/helpandfaqs_mobile.svg";
import { ReactComponent as HelpandfaqsDesktop } from "assets/img/helpandfaqs_desktop.svg";

const HelpAndFQ = ({withoutReporting = false}) => {
  const brandDetails = JSON.parse(`${sessionStorage.getItem("brandDetails")}`);

  return (
    <>
      {["TSYS", "GPI", "GPN"].includes(brandDetails?.brandGuid) && (
        <div className="bg-white p-5 flex md:items-end xl:items-center flex-col relative sm:w-full rounded shadow">
          <div className="flex flex-col items-center sm:w-full md:w-96">
            {(isMobileOnly || withoutReporting) && (
              <div className="mb-6 w-40">
                <HelpandfaqsMobile />
              </div>
            )}
            <div className="text-xl font-medium mb-2">
              <LocalizedText localeKey="homepage.help.helpAndFaq" />
            </div>
            <div className="text-base font-normal text-center w-[300px]">
              <LocalizedText localeKey="homepage.help.helpAndFaqMessageNew" />
            </div>
            <div className="flex mt-6 w-full items-center">
              <Button
                child={<LocalizedText localeKey="homepage.help.goToHelpCenter" />}
                onClick={openHelpCenterLink}
                id="go-to-help-center-button"
                buttonType={ButtonType.PrimaryButton}
                defaultContainerClass="flex w-full items-center justify-center"
                buttonClass="!p-4 !rounded !text-base !mr-0 sml:w-full justify-center"
              />
            </div>
          </div>
          {!isMobileOnly && !withoutReporting && (
            <div className="absolute md:-left-4 lg:left-0 xl:left-auto xl:right-0 bottom-0">
              <HelpandfaqsDesktop />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(HelpAndFQ);
