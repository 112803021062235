import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useUser, useRoles, NotificationCard, useLocale } from 'portal-common-ui';
import config from 'config/config';
import BasicInformation from "components/BasicInformation/BasicInformation";


const BasicInformationCreateUser = ({
  hasFullPortfolio,
  formData,
  resetRole,
  setShowNotification,
  showNotification,
  setSelectedRole,
  selectedRoleRef,
  pemissions,
  setPemissions
}) => {

  const formRef = useRef()
  const { getAuthenticatedSessionUser } = useUser();
  const methods = useFormContext();
  const { getPermissions, getNotificationForRoleChange } = useRoles();
  const [roles, setRoles] = useState([]);
  const [brands, setBrands] = useState([]);
  const [activeNotification, setActiveNotification] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState(formData.brand);
  const [loadingPermissions, setLoadingPermissions] = useState(false); 
  let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));
  const { translateToString } = useLocale();



  useEffect(() => {
    methods.reset(formData);
  }, [methods.reset]);

  // track when notification appears, because we immediately delete
  useEffect(() => {
    if (activeNotification) {
      setShowNotification(true);
    }
  }, [activeNotification]);


  useEffect(() => { 
    const fetch = async () => {
      const userResult = await getAuthenticatedSessionUser();
      const brandsOptions = userResult.brands.map(brand => {
        if (brand.toLowerCase() === brandDetails.brandGuid.toLowerCase() || userResult?.brands?.length === 1) {
          const fixedBrand = { value: brand, label: brand, isFixed: true };
          if (!selectedBrands) {
            setSelectedBrands([fixedBrand]);
          }
          return fixedBrand;
        } else {
          return { value: brand, label: brand };
        }
      });
      if (brandsOptions.length > 1) {
        brandsOptions.unshift({ label: translateToString('createUser.sections.basicInfo.form.selectBrands.allBrands'), value: "all" });
      }
      setBrands(brandsOptions);
    };
    fetch();
  }, [])


  const onRoleSelected = (newRole) => {
    setLoadingPermissions(true); 
    setSelectedRole(newRole);
    if (formData && formData.role && newRole) {
      const notification = getNotificationForRoleChange(formData.role, newRole, hasFullPortfolio);
      setActiveNotification(notification);
      if (!notification) {
        setShowNotification(false);
      }
    }
  }

  const onBrandSelected = (brand) => {
    setSelectedBrands(brand);
  }

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await getPermissions(methods.getValues().role?.roleId);
      const list = response.permissions.map((p) => {
        return {
          label: p.permissionGuid.toLowerCase().split('_').join(' '),
          labelKey: p.name,
          value: p.permissionId,
          selected: p.permissionType == "DEFAULT",
          disabled: p.permissionType == "DEFAULT",
        }
      })
      setPemissions(list)
      setLoadingPermissions(false); 
    }
    if (methods.getValues().role?.roleId) {
      fetchPermissions();
    }
  }, [methods.getValues().role?.roleId])

  let defaultOption = null;
  const roleChange = formData.role?.roleId !== methods.getValues().role?.roleId;
  if (formData.role && roles.length > 0) {
    defaultOption = roles.find(r => r.roleId === formData.role.roleId) || null;
  }

  const multiBrandAvailable = brands.length > 1;

  const handlePermission = (permissions) => {
    setPemissions(permissions)
  }


  return (
    <>
      {/* onReset={methods.handleReset} onSubmit={methods.handleSubmit()} */}
      <form ref={formRef} className="w-full h-full">
        <div className="bg-theme-on-primary p-4 inline-flex flex-col border border-theme-light-border border-t-0 border-b-0 w-full overflow-x-hidden h-[380px] t-xl:h-full">
            <BasicInformation
              resetRole={resetRole}
              onBrandSelected={onBrandSelected}
              multiBrandAvailable={multiBrandAvailable}
              formData={formData}
              selectedBrands={selectedBrands}
              brands={brands}
              selectedRoleRef={selectedRoleRef}
              methods={methods}
              defaultOption={defaultOption}
              onRoleSelected={onRoleSelected}
              pemissions={pemissions}
              handlePermission={handlePermission}
              loadingPermissions={loadingPermissions}           
              />
            {showNotification && activeNotification &&
              <div className="pt-2 " style={{ width: 384 }}>
                <NotificationCard
                  type={activeNotification?.type || 'warning'}
                  title={activeNotification?.title}
                  messageLine1={activeNotification?.message}
                  messageLine2={activeNotification?.message2}
                />
              </div>
            }

          </div>


      </form>
    </>
  );
}

export default BasicInformationCreateUser;