import { LocalizedText } from "portal-common-ui";
import React from "react";

const ReadOnlyFormField = ({ label, containerClass, labelClass, children, labelKey }) => {

  const containerClasses = `flex flex-col ${containerClass ?? 'm-2' }`;

  return (
    <div className={containerClasses}>
      <div className="flex flex-row items-start items-stretch justify-start" data-test={`${label.replace(/\s/g, '-').toLowerCase()}-cy`}>
        <div className={`readonly-formfield-label text-sm xl:text-base text-theme-dark font-medium bg-theme-light-background w-[100px] xl:w-[172px] py-4 min-w-92 px-2 xl:px-4 border ${labelClass}`}>{labelKey ? <LocalizedText localeKey={labelKey} /> : label}</div>
        <div className="readonly-formfield-value flex items-start justify-start">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyFormField;
