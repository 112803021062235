// import { useGlobal } from 'libs/reactn';
import { useUser } from 'portal-common-ui';

const usePermissions = () => {
  
  const user = useUser();

  // todo: Enum keys after typescript conversion
  const hasPermission = (permissionKey) => {
    return user.authenticatedUser.role.permissions.some(x => x.key === permissionKey);
  };

  return {
    hasPermission,
  }
};

export default usePermissions;
