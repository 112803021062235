import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { useRoles, NotificationCard, LocalizedText } from 'portal-common-ui';
import FormSelect from "components/Forms/FormSelect";
import CheckList from "components/CheckList/CheckList"; 
import useFeatureFlags from 'hooks/utils/featureFlags';

const RoleTab = ({ user, hasFullPortfolioAssigned = false, onUpdate, isEditing, onPermissionChaged, optionalPemissions, setOptionalPemissions }) => {

  const { appId, getRoles, getPermissions, getNotificationForRoleChange, getOptionalPermissions } = useRoles();
  const [selectedRole, setSelectedRole, selectedRoleRef] = useStateRef(null);
  const { permissions: roleObjectPerms, ...roleObject } = user.roleObject;
  const [defaultOption, setDefaultOption] = useState(roleObject);
  const [roles, setRoles] = useState([roleObject]);
  const [activeNotification, setActiveNotification] = useState(null);
  const {accountsOptionalPermissionsEnabled} = useFeatureFlags();
  const [loadingPermissions, setLoadingPermissions] = useState(false); 

  useEffect(() => {
    const fetchRoles = async () => {
      setLoadingPermissions(true); 
      if (user.email) {
        const responseRoles = await getRoles(user.email);
        setRoles(responseRoles);
        const userRole = responseRoles.find(x => x.label === user.role);
        if (userRole) setDefaultOption(userRole);
      } else {
        setRoles([]);
      }
    };
    fetchRoles();
  }, [user.email]);

  useEffect(() => {
    if (selectedRole) {
      updateRoleNotification(selectedRole);
    }
  }, [hasFullPortfolioAssigned]);

  const onRoleSelected = (role) => {
    updateRoleNotification(role);
    if (role && onUpdate) onUpdate(role);
  };

  const updateRoleNotification = (role) => {
    const notification = getNotificationForRoleChange(user.roleObject, role, hasFullPortfolioAssigned);
    setSelectedRole(role);
    setActiveNotification(notification);
  };

  useEffect(() => {
    const fetchPermissions = async () => {

     
      let userAppId = '';
      user.userApplicationAssignments?.results.forEach(app => {
        if (app.applicationId === appId) {
          userAppId = app.userApplicationAssignmentId
        }
      })
      const response = await getPermissions(selectedRole?.roleId, user.userId, userAppId);
      const optionalPermissionsResponse = await getOptionalPermissions(user.userId, userAppId)
      const optionalPermissionIds = optionalPermissionsResponse?.optionalPermissionIds || [];
      const list = response?.permissions?.map((p) => {
        return {
          label: p.permissionGuid.toLowerCase().split('_').join(' '),
          labelKey: p.name,
          value: p.permissionId,
          selected: p.permissionType == "DEFAULT" || optionalPermissionIds.indexOf(p.permissionId) > -1,
          disabled: p.permissionType == "DEFAULT",
        }
      })
      setOptionalPemissions(list || [])
      setLoadingPermissions(false);
    }

    if (selectedRole?.roleId) {
      fetchPermissions();
    }
  }, [selectedRole])

  const handlePermission = (permissions) => {
    setOptionalPemissions(permissions)
    onPermissionChaged(true)
  }

  return (
<div className="bg-theme-on-primary rounded-sm border-gray-100 min-h-full">
      <div className="flex sml:flex-col">
        <div className="w-1/2 sml:w-full">
          <div className="font-normal text-theme-dark pb-2">
            <LocalizedText localeKey="editUser.accountInfo.accountsRole" />
          </div>
          <div className="flex flex-col space-y-1 items-start justify-start pb-8 sml:pb-0 h-[148px] sml:h-auto">
            <FormSelect
              name='role'
              label=''
              defaultOption={defaultOption}
              // inputClass={'w-[528px]'}
              containerClass='w-full'
              options={roles}
              onSelection={onRoleSelected}
              disabled={!user.email || !roles.length}
              id='edit-user-role'
              instanceId='edit-user-role'
              inputId='edit-user-role-input'
            />
            {activeNotification &&
              <div className="pt-4">
                <NotificationCard
                  type={activeNotification.type}
                  title={activeNotification.title}
                  messageLine1={activeNotification.message}
                  messageLine2={activeNotification.message2}
                />
              </div>
            }
          </div>
        </div>
        {accountsOptionalPermissionsEnabled && selectedRole?.roleId &&
          <CheckList
            fieldLabelKey="createUser.sections.basicInfo.form.selectPermissions.title"
            lableClass="font-normal text-theme-dark block mb-2"
            options={optionalPemissions}
            onChange={handlePermission}
            containerClass="ml-4 !my-0 sml:ml-0 w-1/2 sml:w-full"
            isLoading={loadingPermissions} 
          />
        }
      </div>
  </div>  
  )
};

export default RoleTab;


