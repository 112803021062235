import React, { useState, useEffect, useMemo } from 'react';
import FormSelect from "components/Forms/FormSelect";
import { useRoles, Button, useLocale, useUser } from "portal-common-ui";

interface App {
  applicationGuid: string;
  applicationId: string;
  roleId: string;
  disabled: boolean;
}

interface TargetUser {
  email: string;
}

interface ApplicationRoleDropdownProps {
  app: App;
  targetUser: TargetUser;
  onRolePermissionChanged: (app: App, role: any, permissions: any[]) => void;
}

function ApplicationRoleDropdown({ app, targetUser, onRolePermissionChanged }: ApplicationRoleDropdownProps) {
  const { getRoles } = useRoles(null, app.applicationGuid, app.applicationId);
  const { getAuthenticatedSessionUser } = useUser();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        if (targetUser.email) {
          let roleGuid = '';
          const userResult = await getAuthenticatedSessionUser();
          userResult.userApplicationAssignments.results.forEach((item: any) => {
            if (item.assignedApplication.applicationGuid === app.applicationGuid) {
              roleGuid = item.assignedApplication.applicationRoleAssociations?.[0]?.role?.roleGuid;
            }
          });
          const res = await getRoles(targetUser.email?.trim(), roleGuid);
          setRoles(res);
        } else {
          setRoles([]);
        }
      } catch (error) {
        console.error(error);
        setRoles([]);
      }
    };
    setSelectedRole(null);
    fetchRoles();
  }, [targetUser.email, app.applicationGuid]);

  useEffect(() => {
    if (app.roleId) {
      const userRole = roles.find((x) => x.roleId === app.roleId);
      setSelectedRole(userRole || null);
    }
  }, [app.roleId, roles]);

  const onRoleSelected = (role: any) => {
    if (role) {
      setSelectedRole(role);
      onRolePermissionChanged(app, role, app?.optionalPermissionIds || [])
    }
  };

  const isDisabled = useMemo(() => !targetUser.email || !roles.length || app.disabled, [targetUser.email, roles.length, app.disabled]);
  //const PLACEHOLDER = "-SELECT ROLE-";
  const { translateToString } = useLocale();
  return (
    <div>
      <FormSelect
        name={app.applicationGuid}
        label=""
        options={roles}
        defaultOption={selectedRole}
        onSelection={onRoleSelected}
        inputClass={"w-full z-100 lrg:w-auto"}
        inputContainer={"lrg:w-auto"}
        disabled={isDisabled}
        placeholder={translateToString('createUser.sections.basicInfo.form.selectRoles.placeholder')}
        id={`${app.applicationGuid}-role`}
        instanceId={`${app.applicationGuid}-role`}
        inputId={`${app.applicationGuid}-role-input`}
      />
    </div>
  )
}

export default ApplicationRoleDropdown;