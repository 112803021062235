import React, { useEffect, useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { LocalizedText } from "portal-common-ui";

const FormField = ({ 
  attached,
  controlType = 'input',
  disabled = false,
  password = false,
  containerClass,
  inputClass,
  name,
  label,
  labelKey,
  defaultValue,
  labelPosition,
  required,
  maxLength,
  customValidate,
  placeholder,
  errorKeys
}) => {

  const methods = useFormContext();
  const {register, handleSubmit, watch, formState, trigger, reset} = methods;
  const containerClasses = `flex flex-col ${containerClass}`;
  const inputClasses = `${required ? 'required' : ''} ${disabled ? 'bg-theme-light-background' : ''} flex-1 h-full bg-theme-on-primary border border-theme-light-border text-gray-600 p-2 ${inputClass}`;
  const thisInput = register(name, { required, maxLength,validate: customValidate });
  const { errors } = useFormState({ control: methods.control });
  const fieldLabel = labelKey ? <LocalizedText localeKey={labelKey} /> : label;

  watch(name);

  const customOnChange = (val) => {
    trigger(name);
    thisInput.onChange(val);
  };

  const customOnBlur = (e) => {
    trigger(name);
    thisInput.onBlur(e);
  };

  return (
    <div className={containerClasses}>
      <div>
        <label className="text-xs font-medium leading-tight text-theme-dark" htmlFor={name}>{fieldLabel}</label>
        {required && <span className="text-red-400 ml-1 text-xs">*</span>}
      </div>
      <div style={{position:"relative"}}>
        {errors[name] && errors[name].type === 'maxLength' && <span className="form-error">{errorKeys && errorKeys.maxLength ?  <LocalizedText localeKey={errorKeys.maxLength} templateVariables={{maxLength}}/> : `${label} cannot exceed ${maxLength} characters`}</span>}
        {errors[name] && errors[name].type === 'required' && <span className="form-error">{errorKeys && errorKeys.required ? <LocalizedText localeKey={errorKeys.required}/>: `${label} cannot be blank`}</span>}
        {errors[name] && errors[name].type === 'validate' && <span className="form-error">{errorKeys && errorKeys.validate ? <LocalizedText localeKey={errorKeys.validate}/>: `Invalid ${label}`}</span>}
        {errors[name] && errors[name].type === 'custom' && <span className="form-error text-xs">{`${errors[name].message}`}</span>}
        <div className="h-10 flex-1">
          <input className={`${inputClasses} ${attached ? 'pr-6' : ''}`}
            defaultValue={defaultValue}
            name={name}
            id={name}
            disabled={disabled ? 'disabled' : ''}
            type={password ? 'password' : 'text'}
            onChange={customOnChange}
            onBlur={customOnBlur}
            ref={thisInput.ref}
            placeholder={placeholder}
            data-test="form-field"
          />
          {!errors[name] && attached &&
            <span className="absolute" style={{ top: 10, right: 10 }}>
              { attached }
            </span>
          }
        </div>
       
      </div>
    </div>
  );
};

export default FormField;