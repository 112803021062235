import React, { useState } from 'react';
import useUserList from 'hooks/userList2';
import {useDataAccessGroup, Button, ButtonType, LocalizedText, AccessibleIcon} from 'portal-common-ui';
import Select, { components } from 'react-select';
import { ReactComponent as TrashImage } from 'assets/icons/trash.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import useAlertQueue from 'hooks/alertQueue';

const AddUserToDataAccessGroup = ({ closeModal, dataAccessGroupInfo, updateGrid }) => {
    const alertQueue = useAlertQueue();
    const [inputValue, setInputValue] = useState('');
    const [rows, setRows] = useState([]);
    const userList = useUserList();
    const [isSelectingUser, setIsSelectingUser] = useState(true);
    const { addUserToDataAccessGroup } = useDataAccessGroup();
    const [selectedValue, setSelectedValue] = useState('');
    const [userListFetched, setUserListFetched] = useState(false);
    const [options, setOptions] = useState([]);
    const [isAddingUser, setIsAddingUser] = useState(false);

    const handleInputChange = (e, event) => {
        if (event.action === 'input-change' ) {
            setInputValue(e);
        }
        setUserListFetched(false);
    }

    const handleChange = (e, event) => {
        setSelectedValue(e);
        setInputValue('');
        setIsSelectingUser(false);
        setOptions([]);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && inputValue.length >= 2) {
            fetchSearchApiData(inputValue);
            e.preventDefault();
        }

        if (userList.isLoading) {
            e.preventDefault();
        }
    }

    const onCancel = () => {
        closeModal();
    };

    const onAddUserSuccess = () => {
        alertQueue.enqueueAlert({ 
          alertType: 'toast',
          messageType: 'success',
          message: 'The user has been successfully assigned to this data access group. Please note that changes may not be immediately reflected on the screen.',
          transitionDuration: 6000,
        });
      };

    const onApplyChanges = async () => {
        setIsAddingUser(true);
        const addUserPayload = {
            userId: selectedValue?.userId,
            dataAccessGroupId: dataAccessGroupInfo?.groupId
        }
        const resp = await addUserToDataAccessGroup(addUserPayload);
        closeModal();
        if(resp.status === 201){
            onAddUserSuccess();
            updateGrid(selectedValue);
        }  
        setIsAddingUser(false);
    };

    const MenuList = props => {
        const searchTokens = (inputValue).trim().split(' ') || [];
        const optionsToDisplay = options.filter(
            opt => new RegExp(searchTokens.join('|')).test(opt.value.toLowerCase()) || new RegExp(searchTokens.join('|')).test(opt.label.toLowerCase())
        );

        return (
            <div>
                <components.MenuList {...props}>
                    <div className="m-0 p-0">
                        {Array.isArray(props?.children) ? props?.children?.slice(0, 50) : props.children}
                    </div>
                </components.MenuList>
                {optionsToDisplay.length > 50 && <div className='pl-2 bg-slate-200 border text-[7px]' data-test="add-user-huge-search-results">
                    {"Your search has returned too many results to be displayed. Please refine your search by adding more info into the search box."}
                </div>}
            </div>

        );
    };

    const filterOption = (option, inputValue) => {
        const { label, value } = option;
        const searchTokens = (inputValue).trim().split(' ') || [];
        return new RegExp(searchTokens.join('|').toLowerCase()).test(value.toLowerCase()) || new RegExp(searchTokens.join('|').toLowerCase()).test(label.toLowerCase())
    }

    async function fetchSearchApiData (inputValue) {
        try {
            const searchTokens = (inputValue).trim().split(' ') || [];
            userList.setSearchCriteria({
                omniSearchValues: searchTokens
            });

            const usersList = await userList.getRows({ request: { startRow: 0, endRow: 100, sortModel: [], excludeInactive: true } });
            setRows(usersList);
            setOptions(usersList.map((o) => ({
                value: [o.firstName, o.lastName].join(' '),
                label: o.email,
                userId: o.userId
            })));
            setUserListFetched(true);
        } catch (error) {
            setError(error.message || 'Unexpected Error!');
        }
    }

    const deleteItem = () => {
        setSelectedValue('');
        setIsSelectingUser(true);
    }

    const Placeholder = (props) => {
        return (
            <components.Placeholder {...props}>
                {<AccessibleIcon icon={faMagnifyingGlass} classes='pointer-events-none text-xs mr-[10px] top-[15px] left-[12px]'labeledBy={"searchPlaceholder"}/>}
                {<LocalizedText localeKey="createDAG.sections.addDataAccess.searchMessage" id={"searchPlaceholder"}/>}
            </components.Placeholder>
        );
    };

    const shouldDisableAddUser = selectedValue === '';

    const noOptionsMessage = (
        <div className="text-theme-medium text-[10px]  text-left h-[18px]" data-test="add-user-no-options-msg">
            {userList.isLoading &&
                <>
                    <LocalizedText localeKey="filters.midSearch.searching"/>
                    <FontAwesomeIcon icon={faSpinner} size="md" spin className="text-sm pl-[4px]" />
                </>
            }
            {!userList.isLoading && inputValue.length < 2 &&
                <>
                    <LocalizedText localeKey="hierarchySelector.selectDataAccess.dataAccessGroup.search.minChar"/>
                </>
            }
            {!userList.isLoading && userListFetched && inputValue.length >= 2 && userList.totalRowCount === 0 &&
                <>
                    <LocalizedText localeKey="editDAG.sections.noMatchingMessage"/>
                </>
            }
        </div>
    );

    return (
        <div className="flex flex-col w-full h-full">
            <div className="w-full inline-flex  py-5 pr-0.5 bg-theme-on-primary border border-theme-light-border border-b-theme-light-border" style={{ height: 64 }}>
                <div className="flex pl-4 text-s text-theme-dark w-full font-semibold">
                    <LocalizedText localeKey="createDAG.sections.addDataAccess.header"/>
                </div>
            </div>
            {isSelectingUser && <div className="p-4 ag-grid__filter--input relative" style={{ height: 300 }}>
                <Select
                    className="w-full min-h-[20px] font-normal text-sm rounded focus:outline-none focus:shadow-outline border border-solid border-theme-light-border focus:border-theme-primary"
                    components={{ MenuList, Placeholder }}
                    styles={{
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            display: 'none',
                        }),
                        indicatorsContainer: (provided) => ({
                            height: 18,
                            width: 18,
                        }),                      
                        menuList: (provided, state) => ({
                            ...provided,
                            padding: 0,
                            height: state?.options?.length > 5 ? '210px' : '',
                            width: '100%' ,
                           
                        }),     
                        option: (provided, state) => ({
                            ...provided,    
                            'width':'100%',
                          })        
                    }}
                    inputValue={inputValue}
                    autoFocus
                    value={selectedValue}
                    isSearchable
                    options={options}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={onKeyDown}
                    noOptionsMessage={() => noOptionsMessage}
                    filterOption={filterOption}
                    formatOptionLabel={(option, { context }) => {
                        return context === 'value' ?
                            option.value : (
                                <div className="grid gap-0 grid-cols-12 auto-cols-max text-theme-dark border-b-2 m-0 p-0">
                                    <div className="text-[12px] col-span-6 font-bold">{option.value}  </div>
                                    <div className="text-[10px] col-span-6">{option.label}</div>
                                </div>);
                    }}
                />
            </div>}

            {!isSelectingUser &&
                <div className='p-4' style={{ height: 300 }}>
                    <div className="w-32 h-3 text-gray not-italic font-medium text-xs leading-3 pb-5">Selected Users</div>
                    <div className="border-2 h-auto w-full pl-2 pb-4">
                        <div className="flex pt-3">
                            <div className="grid gap-0 grid-cols-9 auto-cols-max text-theme-dark m-0 p-0 w-full">
                                <div className="text-[12px] font-bold col-span-3">{selectedValue.value}  {selectedValue.name} </div>
                                <div className="text-[12px] col-span-5">{selectedValue.email}</div>
                                <div className="text-[9px] col-span-1 flex justify-end pr-3">
                                    <button type="button" onClick={deleteItem} disabled={isAddingUser}><TrashImage /></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            }

            <div className="inline-flex space-x-70 items-center justify-start pt-3 pb-2.5 bg-theme-light-background border" style={{ border: '1px solid #D7DCE1', height: 64 }}>
                <div className="flex items-start justify-start pl-8 pr-4">
                    <div className="inline-flex flex-col items-center justify-start px-4 py-2 rounded-sm">
                        <Button buttonType={ButtonType.LinkButton} buttonClass="text-red-700" onClick={onCancel} testDataAttribute="add-user-cancel" title="Cancel" />
                    
                    </div>
                </div>
                <div className="flex-grow grid grid-cols-1 place-items-end space-x-2.5 items-start x-4">

                        <Button
                            title="Add User"
                            titleKey= "editDAG.button.addUser"
                            buttonType={ButtonType.PrimaryButton}
                            disabled={shouldDisableAddUser || isAddingUser}
                            isProcessing={isAddingUser}
                            processingCaption="Adding User..."
                            processingCaptionKey="editDAG.sections.processingCaption"
                            testDataAttribute="add-user-to-dag"
                            onClick={onApplyChanges}>
                        </Button>
  

                </div>
            </div>

        </div>
    );
};

export default AddUserToDataAccessGroup;