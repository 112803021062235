import React, { useEffect, useState } from "react";
import Modali, { useModali } from "modali";
import AppIcon from "components/Application/AppIcon";
import RoleWithPermissionsSelector from "./RoleWithPermissionsSelector";
import ApplicationRow from "./ApplicationRow";
import { LocalizedText } from "portal-common-ui";

interface TableProps {
  children: any;
  type?: string;
  className?: string;
}

const TableHeader = ({ children, className = "" }: TableProps): JSX.Element => {
  return <th className={`py-2.5 px-5 font-semibold ${className}`}>{children}</th>;
};

interface ApplicationsListProps {
  applications: any;
  appFilters?: string[];
  currentUser: any;
  targetUser: any;
  handleToggleChange: any;
  type: "CREATE" | "EDIT";
  onRolePermissionChanged: any;
  targetHierarchies: string[];
  canEditApplicatinsAndRoles: boolean;
  appUpdated?: boolean;
  hasFullPortfolioAssigned?: boolean
}

interface SelectedAppType {
  applicationGuid: string;
  assigned: boolean;
  name: string;
  id: string;
  roleId: any;
}

const ApplicationsList = ({
  applications,
  appFilters = undefined,
  currentUser,
  targetUser,
  handleToggleChange,
  type = "CREATE",
  onRolePermissionChanged,
  targetHierarchies,
  canEditApplicatinsAndRoles,
  appUpdated,
  hasFullPortfolioAssigned
}: ApplicationsListProps) => {
  const [selectedApp, setSelectedApp] = useState<SelectedAppType | null>(null);
  const [roleSelectorModal, toggleRoleSelectorModal] = useModali({
    overlayClose: false,
    large: true,
    centered: true,
  });
  const createFlowPadding = type === "CREATE" && "xl:px-8 xl:py-8";

  function editAssignedApplication(app: any) {
    setSelectedApp(app);
    toggleRoleSelectorModal();
  }

  function closeModal() {
    toggleRoleSelectorModal();
  }

  const getHeaderText = (type: string, headerType: string): any => {
    switch (headerType) {
      case 'role':
        return type === 'EDIT' ? <LocalizedText localeKey="editUser.sections.applicationAndRoles.applicationTable.appAssignedRole"/> : <LocalizedText localeKey="createUser.sections.applicationRoles.form.role"/>;
      case 'action':
        return type === 'EDIT' ? <LocalizedText localeKey="editUser.sections.applicationAndRoles.actions.title"/> : <LocalizedText localeKey="createUser.sections.applicationRoles.form.permission"/>;
      default:
        return "";
    }
  };

  const filteredApps = applications
    .filter(app => 
      appFilters === undefined 
      || appFilters.length === 0 
      || appFilters.some(x => x === app.applicationId) 
      || app.applicationGuid === 'MERCHANT_PORTAL_ACCOUNTS');
    

  return (
    <div className={`bg-theme-on-primary rounded-sm border-gray-100 h-full ${createFlowPadding} overflow-y-auto`}
          style={{paddingTop: '2vh', paddingLeft: '1vh'}}
    >
      {type === "EDIT" && <div className="font-normal text-theme-dark pb-2 my-6"><LocalizedText localeKey="editUser.sections.applicationAndRoles.title"/></div>}
      <table className="border border-theme-light-border">
        <thead>
        <tr className="bg-theme-light-background text-left">
          <TableHeader className="w-[300px] max-w-[300px]"><LocalizedText localeKey="createUser.sections.applicationRoles.form.name"/></TableHeader>
          <TableHeader className="min-w-[220px]">{getHeaderText(type, 'role')}</TableHeader>
          {type === "EDIT" && <TableHeader className="min-w-[120px]"><LocalizedText localeKey="editUser.sections.applicationAndRoles.applicationTable.applicationEnabled"/></TableHeader>}
          <TableHeader className="min-w-[150px] text-center">{getHeaderText(type, 'action')}</TableHeader>
                  </tr>
        </thead>
        <tbody>
          {type === "CREATE" ? 
            applications.filter((app: any) => app.applicationGuid !== "MERCHANT_PORTAL_ACCOUNTS").map((app: any) => (
              <ApplicationRow
                key={app.applicationGuid}
                app={app}
                type={type}
                handleToggleChange={handleToggleChange}
                targetUser={targetUser}
                onRolePermissionChanged={onRolePermissionChanged}
                editAssignedApplication={editAssignedApplication}
                canEditApplicatinsAndRoles={canEditApplicatinsAndRoles}
              />
            ))
          : filteredApps?.map((app: any) => (
              <ApplicationRow
                key={app.applicationGuid}
                app={app}
                type={type}
                handleToggleChange={handleToggleChange}
                targetUser={targetUser}
                onRolePermissionChanged={onRolePermissionChanged}
                editAssignedApplication={editAssignedApplication}
                canEditApplicatinsAndRoles={canEditApplicatinsAndRoles}
              />
            ))}
        </tbody>
      </table>
      <Modali.Modal {...roleSelectorModal}>
        <div className="">
          <div className="flex w-full py-2 border">
            <div className="flex items-center pl-5">
              <AppIcon id={selectedApp?.applicationGuid} disabled={!selectedApp?.assigned} height={50} width={50} />
            </div>
            <div className="flex items-center pl-2 font-medium text-[#3a3d46]">
            {selectedApp?.name} &nbsp;-&nbsp; <LocalizedText localeKey="createUser.sections.applicationRoles.editRole.rolesAndPermission"/>
            </div>
          </div>
        </div>
        <RoleWithPermissionsSelector
          currentUser={currentUser}
          targetUser={targetUser}
          selectedApp={selectedApp}
          onRolePermissionChanged={onRolePermissionChanged}
          targetHierarchies={targetHierarchies}
          applicationGuid={selectedApp?.applicationGuid || null}
          onClose={closeModal}
          type={type}
          brandDisabled={appUpdated}
          hasFullPortfolioAssigned={hasFullPortfolioAssigned}
        />
      </Modali.Modal>
    </div>
  );
};

export default ApplicationsList;
