import { useGlobal } from 'libs/reactn';
import config from 'config/config';
import { useApi } from 'portal-common-ui';
import { useEffect } from 'react';

const useCustomerOrientation = () => {
  
  const [orientation, setOrientation] = useGlobal('orientation');
  const [auth, setAuth] = useGlobal('auth');

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const notificationsApi = useApi(config.API.notificationsUrl, options);

  useEffect(() => {
    const fetch = async () => {
      let list = orientation.list;
      const userId = auth.session.user.externalId;
      await notificationsApi.get(`external/users/${userId}/notifications?messageFrom=onboarding@globalpay.com`);
      if (notificationsApi.response.ok) {      
        setOrientation(newOrientation => {
          list = notificationsApi.response.data.content;
          list.forEach(i => {
            i.message = i.message.replace('dBMText', '"dBMText"');
            i.message = i.message.replace('dTMText', '"dTMText"');
            i.message = i.message.replace('dOKBText', '"dOKBText"');
            i.message = JSON.parse(i.message);
          });
          newOrientation.list = [...list];
          newOrientation.isLoading = false;
        });
      }
    };
    if (orientation.list.length === 0 && !orientation.isLoading) {
      // setOrientation(newOrientation => newOrientation.isLoading = true);
      // fetch();
    }
  }, []);

  const hasUnreadTooltip = (toolTipKey) => {
    // let list = orientation.list;
    // return list.some(m => m.message.titleResourceKey === toolTipKey && !m.hasRead);
    return false;
  };

  const markAsRead = async (toolTipKey) => {
    let list = orientation.list;

    const toolTip = list.find(m => m.message.titleResourceKey === toolTipKey);
    if (toolTip) {
      setOrientation(newOrientation => {
        newOrientation.isSaving = true;
        const tooltipItem = newOrientation.list.find(i => i.message.titleResourceKey === toolTipKey);
        tooltipItem.hasRead = true;
      });
      const userId = auth.session.user.externalId;
      const patchData = { id: toolTip.id, hasRead: true};
      await notificationsApi.patch(`external/users/${userId}/notifications`, patchData);
      setOrientation(newOrientation => {
        newOrientation.isSaving = false;
      });
    }
  };

  return {
    hasUnreadTooltip,
    markAsRead,
    isLoading: orientation.isLoading,
    isSaving: orientation.isSaving,
  };
};

export default useCustomerOrientation;
