import useFeatureFlags from "./utils/featureFlags";
import { useLocale } from "portal-common-ui";

const usePreferences = () => {
    const { accountsEnabledLanguages } = useFeatureFlags();
    const { translateToString } = useLocale();
    
    const areTranslationsEnabled = (enabledLanguages) => !(enabledLanguages?.length <= 1 && enabledLanguages[0]?.value === 'en-US');

    const translationsEnabled = areTranslationsEnabled(accountsEnabledLanguages);

    const timeFormats = [
        { label: translateToString("editUser.sections.preferences.timeFormats.timeFormatAmPm"), value: 'hh:mm a'},
        { label: translateToString("editUser.sections.preferences.timeFormats.timeFormat24"), value: 'HH:mm'}
    ];
    
    const dateFormats = [
        { label: 'MM/DD/YYYY (07/15/2017)', value: 'MM/DD/YYYY' },
        { label: 'DD/MM/YYYY (15/07/2017)', value: 'DD/MM/YYYY' },
        { label: 'YYYY/MM/DD (2017/07/15)', value: 'YYYY/MM/DD' }
    ];

    return {
        areTranslationsEnabled,
        translationsEnabled,
        enabledLocales: accountsEnabledLanguages,
        timeFormats,
        dateFormats
    }
}

export default usePreferences;



