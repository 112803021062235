import React from 'react';
import {
  AnalyticsIcon,
  ReportingIcon,
  PCISysnetIcon,
  PCISysnetIconDisabled,
  DIMSIcon,
  DIMSIconDisabled,
  GiftIcon,
  GiftIconDisabled,
  AccountsIcon,
  AnalyticsIconDisabled,
  SocialReputationIcon,
  SocialReputationIconDisabled,
  BenchmarkIcon,
  BenchmarkIconDisabled,
  ReportingIconDisabled,
  AccountsIconDisabled,
  VirtualTerminalIcon,
  VirtualTerminalIconDisabled
} from "assets/icons";

interface AppIconProps {
    id: string;
    height: number;
    width: number;
    disabled?: boolean;
}

const AppIcon: React.FC<AppIconProps> = ({ id, height, width, disabled = false }) => {

    const analyticsIcon = disabled ? <AnalyticsIconDisabled height={height} width={width} /> : <AnalyticsIcon height={height} width={width} aria-labelledby={`${id}-label`}/>;
    const socialReputationIcon = disabled ? <SocialReputationIconDisabled height={height} width={width} /> : <SocialReputationIcon height={height} width={width} aria-labelledby={`${id}-label`}/>;
    const benchmarkIcon = disabled ? <BenchmarkIconDisabled height={height} width={width} /> : <BenchmarkIcon height={height} width={width} aria-labelledby={`${id}-label`}/>;

    const appMap: { [key: string]: any } = {
        'ACE_CUSTOMER_ENGAGEMENT': analyticsIcon,
        'CES_COMPETITIVE_BENCHMARK': benchmarkIcon,
        'CES_REPUTATION_MANAGEMENT': socialReputationIcon, 
        'MERCHANT_PORTAL_REPORTING': disabled ? <ReportingIconDisabled height={height} width={width} /> : <ReportingIcon height={height} width={width} aria-labelledby={`${id}-label`}/>,
        'SYSNET': disabled ? <PCISysnetIconDisabled height={height} width={width} /> : <PCISysnetIcon height={height} width={width} aria-labelledby={`${id}-label`}/>,
        'DIMS': disabled ? <DIMSIconDisabled height={height} width={width} /> :  <DIMSIcon height={height} width={width} aria-labelledby={`${id}-label`}/>,
        'MERCHANT_PORTAL_ACCOUNTS': disabled ? <AccountsIconDisabled height={height} width={width} /> : <AccountsIcon height={height} width={width} aria-labelledby={`${id}-label`}/>,
        'VT': disabled ? <VirtualTerminalIconDisabled height={height} width={width} /> : <VirtualTerminalIcon height={height} width={width} aria-labelledby={`${id}-label`} />,
        'CES_GIFT': disabled ? <GiftIconDisabled height={height} width={width} /> : <GiftIcon height={height} width={width} aria-labelledby={`${id}-label`} />
    };

    return appMap[id] || '';

}

export default AppIcon;