import { useGlobal } from 'libs/reactn';
import { localeKeys } from 'typings/translate';

// retrieves a link on click from a provider.  Used for links that can expire, such as SSO links to external sites.
const useAlertQueue = () => {

  interface alertTypes {
    alertType: 'toast' | 'modal',
    messageType: 'success' | 'warning' | 'info' | 'error' | 'mobileInfo',
    hasProcessing?: boolean,
    transitionDuration?: number,
    message?: string, 
    message2?: string,
    title?: string,
    continueCaption?: string, 
    cancelCaption?: string, 
    processingCaption?: string,
    messageKey?: localeKeys, 
    message2Key?: localeKeys,
    titleKey?: localeKeys,
    continueCaptionKey?: localeKeys,
    cancelCaptionKey?: localeKeys,
    processingCaptionKey?: localeKeys,
    continueAction?: () => any;
    cancelAction?: () => any;
  }
  
  const [alerts, setAlerts] = useGlobal('alerts');

  const getHasAlerts = () => {
    return alerts.alertList.length > 0;
  };

  const enqueueAlert = ({ 
    alertType, 
    messageType, 
    continueAction, 
    cancelAction, 
    transitionDuration, 
    hasProcessing,
    message, 
    message2,
    title,
    continueCaption, 
    cancelCaption, 
    processingCaption, 
    messageKey, 
    message2Key, 
    titleKey, 
    continueCaptionKey, 
    cancelCaptionKey,  
    processingCaptionKey, 
  }: alertTypes) => {
    setAlerts(newAlerts => {
      newAlerts.alertList.push({ 
        alertType, 
        messageType, 
        continueAction, 
        cancelAction, 
        transitionDuration, 
        hasProcessing,
        message, 
        message2,
        title,
        continueCaption, 
        cancelCaption, 
        processingCaption, 
        messageKey, 
        message2Key, 
        titleKey, 
        continueCaptionKey, 
        cancelCaptionKey,  
        processingCaptionKey,
      });
    });
  };

  const dequeueAlert = () => {
    const currentAlert = alerts.alertList[0];
    setAlerts(newAlerts => {
      newAlerts.alertList.shift();
    });
    return currentAlert;
  };

  return {
    hasAlerts: getHasAlerts(),
    enqueueAlert,
    dequeueAlert,
  };
}

export default useAlertQueue;
