'use strict';
import React, { useState, useEffect } from "react";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Overlay from 'components/Overlay/Overlay';
import { useUser } from 'portal-common-ui';
import useAlertQueue from "hooks/alertQueue";
import { LocalizedText, AccessibleIcon } from "portal-common-ui";
import { useRef } from "reactn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType } from 'portal-common-ui';
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

const DeleteUserWarning = (props) => {
  const user = useUser();
  const alertQueue = useAlertQueue();
  const ticketRef  = useRef();
  const [ticketId, setTicketId]= useState('');

  const onContinueClick = async () => {
    const ticketId = ticketRef.current.value;
    try {
      await user.deleteUser(props.userId, ticketId);
      alertQueue.enqueueAlert({ alertType: 'toast', messageType: 'info', messageKey: "userManagementPage.toasts.info.deleteUser.message"});
      props.onCancel();
    } catch(e) {
      alertQueue.enqueueAlert({ alertType: 'toast', transitionDuration: 10000, messageType: 'error', messageKey: "userManagementPage.toasts.error.deleteUser.message"});
      props.onCancel();
    }
  }
  const onCloseModalClick = () => {
    props.onCancel();
  };
  

  const onCancelClick = () => {
    props.onCancel();
  }
  
  const icon = faExclamationTriangle;
  const utilityClasses = { background: 'bg-theme-warning', textColor: 'text-theme-warning', btnBorder: 'border border-solid border-theme-warning', btnBackground: 'bg-theme-warning' };
  const titleKey = <LocalizedText localeKey="shared.alerts.titles.warning" />;
  const continueCaptionKey = <LocalizedText localeKey="userManagementPage.alerts.warning.deleteUser.continueCaption" />;
  const cancelCaptionKey =  <LocalizedText localeKey="shared.alerts.cancel" />;

  return (
    <>
      <Overlay dark/>
      <div className={`alert flex sml:flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-alert med:w-[95%] sml:text-center shadow-alert bg-theme-on-primary z-[1054]`} data-test="alert-container">
          <div className={`w-14 flex items-center justify-center ${utilityClasses.background} sml:w-full sml:py-4 sml:bg-theme-on-primary` } data-test="alert-icon">
            <AccessibleIcon icon={icon} labeledBy="alert-title-label" classes={`text-2xl ${utilityClasses.background} text-theme-on-primary sml:text-theme-warning sml:bg-theme-on-primary`}/>
            <p className={`text-xl font-medium leading-4 ml-2 ${utilityClasses.textColor} hidden sml:block`}>{titleKey}</p>
          </div>
        <div className="content w-full h-full p-4 bg-theme-on-primary text-theme-medium">
          <button className="close bg-theme-medium text-theme-on-primary float-right w-5 h-5 rounded-full text-xs flex justify-center items-center sml:hidden" onClick={onCloseModalClick}>
            <FontAwesomeIcon icon={faXmark} />
          </button>

          <h1 className={`text-xl font-medium leading-4 mb-5 mt-2 ${utilityClasses.textColor} sml:hidden`} data-test="alert-title">{titleKey}</h1>
          <p className="text-base w-alert-message  med:w-auto font-normal leading-5 text-theme-medium mb-4" data-test="alert-message">
              <span className="block">
                <LocalizedText localeKey="userManagementPage.alerts.warning.deleteUser.message" />
              </span>
              <input 
                className="border border-solid border-gray-200 my-3 p-2 med:w-full" 
                type="text" 
                ref={ticketRef}
                value={ticketId}
                onChange={e => setTicketId(e.target.value)}
                data-test= "user-management-delete-user-desc"
              />
          </p>
          <div className="buttons">

            <Button
              // buttonClass={`btn btn-primary btn-alt mr-2 ${!ticketId ? "bg-[#f2f2f2] border-0 text-[#c2c2c2] hover:bg-[#f2f2f2] hover:border-0 hover:text-[#c2c2c2] cursor-not-allowed disabled:bg-[#f2f2f2]" : ''}`}
              buttonType={ButtonType.PrimaryButton}
              defaultContainerClass={"inline-block"}
              buttonClass="sml:hidden"
              onClick={onContinueClick} 
              testDataAttribute="user-management-delete-continue-caption"
              disabled={!ticketId}
              child={continueCaptionKey}
            />
            <Button
              buttonType={ButtonType.MobilePrimary}
              defaultContainerClass={"inline-block"}
              buttonClass="hidden sml:block"
              onClick={onContinueClick} 
              testDataAttribute="user-management-mob-delete-continue-caption"
              disabled={!ticketId}
              child={continueCaptionKey}
            />
            {cancelCaptionKey &&
            <>
              <Button
                buttonType={ButtonType.SecondaryButton}
                defaultContainerClass={"inline-block"}
                buttonClass="sml:hidden"
                onClick={onCancelClick}
                child={cancelCaptionKey}
              />
              <Button
                buttonType={ButtonType.MobileSecondary}
                defaultContainerClass={"inline-block"}
                buttonClass="hidden sml:block"
                onClick={onCancelClick}
                child={cancelCaptionKey}
              />
            </>
          }
          </div>
        </div>
      </div>
    </>
  )
};

export default DeleteUserWarning;