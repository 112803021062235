import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openHelpCenterLink } from "components/Dashboard/helper";
import { faSquareArrowUpRight, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { Button, ButtonType, LocalizedText, AccessibleIcon } from "portal-common-ui";

type contactsType = { name: string; phone: string; email: string }[];
interface SupportLocationProps {
  contacts: contactsType;
}

const SupportLocationList: React.FC<SupportLocationProps> = ({ contacts }) => {
  return (
    <ul className="panel__list">
      {contacts.map((location) => {
        const { name, phone, email } = location;
        return (
          <li className="p-3 flex justify-between items-start odd:bg-theme-on-primary">
            {name && <span className="text-theme-dark font-medium">{name}</span>}
            <div className="flex flex-col items-end">
              {phone && <p className="text-theme-medium">{phone}</p>}
              {email && (
                <a className="text-theme-info font-bold text-sm p-0 hover:underline" href={`mailto:${email}`}>
                  {email}
                </a>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

interface AccountSupportPanelProps {
  toggleOverlay: (label: string) => void;
  contacts: contactsType;
}

const AccountSupportPanel = React.forwardRef<HTMLDivElement, AccountSupportPanelProps>(
  ({ toggleOverlay, contacts }, ref) => {
    return (
      <div
        className="bg-theme-on-primary flex flex-col absolute top-0 right-[-9rem] sml:right-[-7rem] border-solid rounded-none
                overflow-hidden sm:min-w-supportSubNav h-screen sml:w-screen animate-slideinFromRight origin-right ease-in shadow-account-support z-30"
        ref={ref}
      >
        <header>
          <nav className="panel__navigation h-header p-4 flex justify-between items-center">
            <h3 className="text-theme-secondary text-lg sml:mx-auto">
              <LocalizedText localeKey="homepage.help.title" />
            </h3>
            <button onClick={() => toggleOverlay("")} data-test="support-close">
              <AccessibleIcon
                icon={faXmark}
                localeKey="shared.alerts.closeButtonAltText"
                size="lg"
                classes="panel__close hover:text-theme-dark text-lg"
              />
            </button>
          </nav>
        </header>
        <section className="">
          <div className="panel__headline">
            <p className="text-theme-dark font-bold p-4 sml:bg-theme-light-background sml:border">
              <LocalizedText localeKey="homepage.help.subTitle" />
            </p>
            <div className="p-4">
              <p className="text-sm text-theme-dark mb-4">
                <LocalizedText localeKey="homepage.help.description" />
                <span className="mx-0.5"></span>
                <LocalizedText localeKey="homepage.help.callToActionText" />
              </p>
              <Button
                onClick={openHelpCenterLink}
                buttonType={ButtonType.PrimaryButton}
                buttonClass="sml:hidden mb-4"
                child={[
                  <span className="mt-0 p-2">
                    <LocalizedText localeKey="homepage.help.helpCenter" id="cta-button-label" />
                  </span>,
                  <AccessibleIcon icon={faSquareArrowUpRight} labeledBy="cta-button-label" classes="mr-2" />,
                ]}
              />

              <Button
                onClick={openHelpCenterLink}
                buttonType={ButtonType.MobilePrimary}
                buttonClass="hidden sml:block"
                child={[
                  <span className="p-2">
                    <LocalizedText localeKey="homepage.help.helpCenter" id="cta-button-label" />
                  </span>,
                  <FontAwesomeIcon icon={faSquareArrowUpRight} className="mr-2" />,
                ]}
              />
            </div>
          </div>
        </section>
        <hr className="mx-4" />
        <section>
          <div className="panel__headline">
            <p className="text-theme-dark font-bold p-4 sml:bg-theme-light-background sml:border">
              <LocalizedText localeKey="homepage.help.contact.title" />
            </p>
            <div className="p-4">
              <p className="text-sm mb-3 text-theme-dark">
                <LocalizedText localeKey="homepage.help.contact.message1" />
              </p>
              <p className="text-sm text-theme-dark">
                <LocalizedText localeKey="homepage.help.contact.message2" />
              </p>
            </div>
          </div>
          {/* <SupportLocationList contacts={contacts} /> */}
        </section>
      </div>
    );
  }
);

export default AccountSupportPanel;
