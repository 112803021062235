import { useEffect, useState } from 'react';
import { useApi, useLocale } from 'portal-common-ui';
import config from 'config/config';
import productTitleDescriptionMapping from 'components/ProductCard/productTitleDescriptionMapping';

interface ProductOfferingModel {
  offeringId: string,
  offeringType: 'base' | 'upgrade' | 'bundle' | 'upgradeBundle';
  offeringName: string;
  offeringDescription: string;
  offeringPricing: {
    price: number,
    currency: string,
    frequency: string
  };
  isSubscribed: boolean;
  subscriptionId: string;
  isUpgrade: boolean;
  offeringGroupId: string;
  offeringPriorityInGroup: number;
  offeringCreationDateTime: string;
  offeringNameKey: string,
  offeringDescriptionKey: string
}

export const useProductModel = (
  apiUrl1: string,
  apiUrl2: string,
  deps: any[]
) => {
  const [data, setData] = useState<ProductOfferingModel[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const {translateToString} = useLocale();
  const options = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userUrl = config.API.userUrl2;
  const applicationBundleApi = useApi(userUrl, options);
  const subscribedApplicationApi = useApi(userUrl, options);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch data from the first API
        const allAppsResponse = await applicationBundleApi.get(apiUrl1);
        const allAppsData = (allAppsResponse.content);
        // Fetch data from the second API
        const subscribedAppsResponse = await subscribedApplicationApi.get(apiUrl2);
        const subscribedAppsData = subscribedAppsResponse ?  extractApplicationPlanIds(subscribedAppsResponse) : [];
        let flattenedData: any = []

        for (const bundle of allAppsData) {
          flattenedData = [...flattenedData, ...bundle.applicationBundles]
        }

        const offeringData: any = deduplicateArray(flattenedData, "planId").map((plan: any) => {
          return {
            offeringId: plan.planId,
            offeringType: isBundle(plan) ? "bundle" : "base",
            offeringName: translateToString(plan.name) ? translateToString(plan.name) : plan.name,
            offeringNameKey: productTitleDescriptionMapping[plan.name]
              ? productTitleDescriptionMapping[plan.name].nameKey
              : null,
            offeringDescription: translateToString(plan.description)
              ? translateToString(plan.description)
              : plan.description,
            offeringDescriptionKey: productTitleDescriptionMapping[plan.name]
              ? productTitleDescriptionMapping[plan.name].descKey
              : null,
            offeringPricing: {
              price: plan.planPricing ? plan.planPricing.price : "",
              currency: plan.planPricing ? plan.planPricing.billingCurrency : "",
              frequency: plan.planPricing ? plan.planPricing.billingPeriod : "",
            },
            isSubscribed: checkSubscribed(plan.planId, subscribedAppsData),
            subscriptionId: checkSubscriptionId(plan.planId, subscribedAppsResponse),
            isUpgrade: isPlanUpgrade(plan, deduplicateArray(flattenedData, "planId"), subscribedAppsData),
            offeringGroupId: plan.groupId,
            offeringPriorityInGroup: plan.planPriorityWithinGroup,
            offeringCreationDateTime: plan.createDateTime,
            offeringApps: plan.assignedApplications,
          };
        });

        const filteredOfferingData= offeringData.filter((offering:ProductOfferingModel)=>!isUpgradeSubscribed(offering,offeringData))

        setData(filteredOfferingData);
        setIsLoading(false);
        setError(null);
      } catch (error) {
        console.log(error);
        setError('Error fetching data');
        setIsLoading(false);
      }
    };

    fetchData();
  }, deps);

  return { data, loading, error };
};

const deduplicateArray = (inputArray: any[], key: string) => {
  const seenKeys = new Set<any>();
  return inputArray.filter((item) => {
    if (!seenKeys.has(item[key])) {
      seenKeys.add(item[key]);
      return true;
    }
    return false;
  });
};

const extractApplicationPlanIds = (apiResponse: any): string[] => {
  const results = apiResponse?.content?.results || [];
  const applicationPlanIds: string[] = [];

  for (const result of results) {
    const planId = result.planId;
    applicationPlanIds.push(planId);
  }

  return applicationPlanIds;
};

function isBundle(data: any): boolean {
  if (data.hasOwnProperty('assignedApplications') && data.assignedApplications && data.assignedApplications.length > 1) {
    return true;
  }
  return false;
}

const checkSubscribed = (planId: any, applicationPlanIds: string | any[]) => {
  if (planId && applicationPlanIds.includes(planId)) {
    return true;
  }
  return false;
};

const checkSubscriptionId = (planId: string, subscribedAppsResponse: any)=>{
  const subscriptionObj = subscribedAppsResponse?.content?.results.find(obj => obj.planId === planId );
  if(subscriptionObj){
    return subscriptionObj.subscriptionId
  }
  else{
    return ""
  }
}

const isUpgradeSubscribed = (offering: ProductOfferingModel, offeringData: ProductOfferingModel[]): boolean=>{
  if(!offering.isSubscribed){
    const plansWithSameGroupId = offeringData.filter(
      (otherPlan) => otherPlan.offeringGroupId === offering.offeringGroupId
    );

    const checkUpgradeSubscribed = plansWithSameGroupId.some((otherPlan) => {
      return (
        otherPlan.offeringPriorityInGroup > offering.offeringPriorityInGroup &&
        otherPlan.isSubscribed
      );
    });
    return checkUpgradeSubscribed;
  }
  return false
}

const isPlanUpgrade = (
  plan: any,
  deduplicatedPlans: any[],
  subscribedPlans: string[]
): boolean => {
  // Find all plans with the same groupId
  const plansWithSameGroupId = deduplicatedPlans.filter(
    (otherPlan) => otherPlan.groupId === plan.groupId
  );

  // Check if there's any plan with a lower priority and is subscribed
  const isUpgrade = plansWithSameGroupId.some((otherPlan) => {
    return (
      otherPlan.planPriorityWithinGroup < plan.planPriorityWithinGroup &&
      checkSubscribed(otherPlan.planId, subscribedPlans)
    );
  });
  return isUpgrade;
};

export default useProductModel;