
window['__gp_brands_config__'] = {
  "apps": [
    {
      "appName": "accounts",
      "url": "dev.np-accounts.tsys.com",
      "env": "managed-dev",
      "b2cClientId": "fc850e5b-53f9-424b-bf4a-77cd8e427e15",
      "brandId": "tsys"
    },
    {
      "appName": "accounts",
      "url": "dev.np-accounts.globalpaymentsintegrated.com",
      "env": "managed-dev",
      "b2cClientId": "447dedab-769b-4b32-a291-8e312b5bf668",
      "brandId": "gpi"
    },
    {
      "appName": "accounts",
      "url": "dev.np-accounts.globalpay.com/synovus",
      "env": "managed-dev",
      "b2cClientId": "36540075-841d-443e-896d-35bdb2f2755a",
      "brandId": "synovus"
    },
    {
      "appName": "accounts",
      "url": "dev.np-accounts.globalpay.com/hpy",
      "env": "managed-dev",
      "b2cClientId": "c8de5b35-cf41-4ec6-a570-fa4fc6d808c8",
      "brandId": "hpy"
    },
    {
      "appName": "accounts",
      "url": "qa.np-accounts.tsys.com",
      "env": "managed-qa",
      "b2cClientId": "07721394-54b4-44a1-83d2-a5a3fa3076f0",
      "brandId": "tsys"
    },
    {
      "appName": "accounts",
      "url": "qa.np-accounts.globalpaymentsintegrated.com",
      "env": "managed-qa",
      "b2cClientId": "e24f52ea-af83-4ce3-abac-d97752f2140a",
      "brandId": "gpi"
    },
    {
      "appName": "accounts",
      "url": "qa.np-accounts.globalpay.com/synovus",
      "env": "managed-qa",
      "b2cClientId": "4f03e154-d745-4dbc-b7a5-cf59eeec915d",
      "brandId": "synovus"
    },
    {
      "appName": "accounts",
      "url": "qa.np-accounts.globalpay.com/hpy",
      "env": "managed-qa",
      "b2cClientId": "d7917c78-6006-4266-a1bd-c482cdf30e08",
      "brandId": "hpy"
    },
    {
      "appName": "accounts",
      "url": "cert.accounts.tsys.com",
      "env": "cert",
      "b2cClientId": "25c3411c-ec13-419c-b465-909440241f82",
      "brandId": "tsys"
    },
    {
      "appName": "accounts",
      "url": "cert.accounts.globalpaymentsintegrated.com",
      "env": "cert",
      "b2cClientId": "f9dc3d7f-3866-4d24-93c3-870cc2c0bf22",
      "brandId": "gpi"
    },
    {
      "appName": "accounts",
      "url": "cert.accounts.globalpay.com/synovus",
      "env": "cert",
      "b2cClientId": "825ede3b-fb52-4299-9b78-f365117bdcd4",
      "brandId": "synovus"
    },
    {
      "appName": "accounts",
      "url": "cert.accounts.globalpay.com/hpy",
      "env": "cert",
      "b2cClientId": "c9d4eceb-33e6-4c6a-9956-f74a6ae50025",
      "brandId": "hpy"
    },
    {
      "appName": "accounts",
      "url": "accounts.tsys.com",
      "env": "prod",
      "b2cClientId": "c0364849-eaa7-47bc-af23-31acb1ac45a8",
      "brandId": "tsys"
    },
    {
      "appName": "accounts",
      "url": "accounts.globalpaymentsintegrated.com",
      "env": "prod",
      "b2cClientId": "ac296f81-8e2a-4452-a318-324a1d54eca9",
      "brandId": "gpi"
    },
    {
      "appName": "accounts",
      "url": "accounts.globalpay.com/synovus",
      "env": "prod",
      "b2cClientId": "12b68891-ce0d-4d95-a3e6-d0586a42f392",
      "brandId": "synovus"
    },
    {
      "appName": "accounts",
      "url": "accounts.globalpay.com/hpy",
      "env": "prod",
      "b2cClientId": "6b6741a6-4a46-4896-bd09-7036ac8acb36",
      "brandId": "hpy"
    },
  ],
  "brands": [
    {
      "brandId": "tsys",
      "policy": "b2c_1a_portal_tsys_susimfa",
      "packageUrl": "",
      "logoPath": "/brands/tsys/logo.png",
      "appBasePath": "/",
      "isWhiteLabel": true,
      "primaryColor": "#06477c",
      "secondaryColor": "#0F7DC4",
      "businessName": "TSYS",
      "copyRight": "Global Payments Direct, Inc. All rights reserved.",
      "termsPolicyUrl": "https://storage.googleapis.com/mp-e8c2b92d-14e0-47d7-ba2f-7c510fc0d-images/mp-images/Accounts_Terms_Of_Use.pdf",
      "errorMessages": {
        "unknownUser": "Please call Broomfield: 800-543-5327/Omaha: 855-882-0507/Cayan: 800-951-6557 and provide the following error code:",
       },
      "contacts": [
        { 
          "name": "Broomfield",
          "phone": "800-654-9256",
        },
        { 
          "name": "Omaha",
          "phone": "800-228-2443",
        },
        { 
          "name": "Boston/Cayan",
          "phone": "800-941-6557",
        },
        { 
          "name": "CPAY",
          "phone": "800-449-8012",
        },
      ],
    },
    {
      "brandId": "gpi",
      "policy": "b2c_1a_portal_gpi_susimfa",
      "packageUrl": "",
      "logoPath": "/brands/gpi/logo.svg",
      "appBasePath": "/",
      "isWhiteLabel": false,
      "primaryColor": "#06477c",
      "secondaryColor": "#0F7DC4",
      "businessName": "Global Payments Integrated",
      "copyRight": "Global Payments Direct, Inc. All rights reserved.",
      "termsPolicyUrl": "https://storage.googleapis.com/mp-e8c2b92d-14e0-47d7-ba2f-7c510fc0d-images/mp-images/Accounts_Terms_Of_Use.pdf",
      "errorMessages": {
        "unknownUser": "Please call Broomfield: 800-543-5327/Omaha: 855-882-0507/Cayan: 800-951-6557 and provide the following error code:",
       },
      "contacts": [
        { 
          "name": "Broomfield",
          "phone": "800-543-5327",
          "email": "IntegratedCustomerCare@globalpay.com",
        },
        { 
          "name": "Omaha",
          "phone": "855-882-0507",
          "email": "IntegratedCustomerCareOMA@globalpay.com",
        },
        { 
          "name": "Cayan",
          "phone": "800-951-6557",
        },
      ],
    },
    {
      "brandId": "synovus",
      "policy": "b2c_1a_portal_synovus_susimfa",
      "packageUrl": "",
      "logoPath": "/brands/synovus/logo.svg",
      "appBasePath": "/synovus",
      "isWhiteLabel": false,
      "primaryColor": "#06477c",
      "secondaryColor": "#0F7DC4",
      "businessName": "Global Payments",
      "copyRight": "Global Payments Direct, Inc. All rights reserved.",
      "termsPolicyUrl": "https://storage.googleapis.com/mp-e8c2b92d-14e0-47d7-ba2f-7c510fc0d-images/mp-images/Accounts_Terms_Of_Use.pdf",
      "errorMessages": {
        "unknownUser": "Please call Broomfield - 800.654.9256 and provide the following error code:",
       },
      "contacts": [
        { 
          "name": "Broomfield",
          "phone": "800-654-9256",
        },
      ],
    },
    {
      "brandId": "hpy",
      "policy": "b2c_1a_portal_tsys_susimfa",
      "packageUrl": "",
      "logoPath": "/brands/tsys/logo.png",
      "appBasePath": "/hpy",
      "isWhiteLabel": true,
      "primaryColor": "#06477c",
      "secondaryColor": "#0F7DC4",
      "businessName": "hpy",
      "copyRight": "Global Payments Direct, Inc. All rights reserved.",
      "termsPolicyUrl": "https://storage.googleapis.com/mp-e8c2b92d-14e0-47d7-ba2f-7c510fc0d-images/mp-images/Accounts_Terms_Of_Use.pdf",
      "errorMessages": {
        "unknownUser": "Please call the support number listed on your statement and provide the following error code:",
       },
      "contacts": [],
    },
  ]
};
