export const shouldAddVTHierarchy = (sessionUser: { [key: string]: any }, createUserApps: any[], createUserDataAccess: any[]) => {
    const isExternalUser = sessionUser?.roleObject?.roleType === "EXTERNAL";
    const hasSingleVTHierarchy = sessionUser?.dataAccess?.find((h: any) => h?.selectedPortfolio === 'VT')?.selectedMids.length === 1;
    const hasVTAppAssigned = createUserApps?.some(app => app?.applicationGuid === 'VT');
    const hasVTMidsAssigned = createUserDataAccess?.some(h => h?.selectedMids?.length && h.selectedMids.some((m: any) => m.affiliation === 'VT')); // only from selected MIDs, not full portfolio

    return isExternalUser && hasSingleVTHierarchy && hasVTAppAssigned && !hasVTMidsAssigned;

}

export const getVTHierarchyToAdd = (sessionUser: { [key: string]: any }) => sessionUser?.dataAccess?.find((h: any) => h.selectedPortfolio === 'VT')?.selectedMids[0]?.hierarchyId;
