import React, { useEffect, useState } from "react";
import useSession from "hooks/session/session";
import Select, { components } from "react-select";
import { useLandingPage } from "store/landingPage";
import { LocalizedText } from "portal-common-ui";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function formatDateToYYYYMMDD(date: any) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const CalendarTool = () => {
  const session = useSession();
  const { user } = session;
  const now = new Date();
  let yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  const [startDate, setStartDate] = useState<any>(yesterday);
  const [endDate, setEndDate] = useState<any>(new Date());
  const { setDate } = useLandingPage();

  const durationOptions: any = [
    { value: "Yesterday", label: "calender.duration.yesterday" },
    { value: "LastSevenDays", label: "calender.duration.lastSevenDays" },
    { value: "LastThirtyDays", label: "calender.duration.lastThirtyDays" },
    { value: "ThisMonth", label: "calender.duration.thisMonth" },
    { value: "LastMonth", label: "calender.duration.lastMonth" },
  ];

  const handleMobileSelect = (data: any) => {
    let startDay = new Date(now);
    let endDay = new Date(now);
    switch (data.value) {
      case "Yesterday":
        startDay = yesterday;
        endDay = new Date(now);
        break;
      case "LastSevenDays":
        startDay.setDate(now.getDate() - 6);
        endDay = new Date(now);
        break;
      case "LastThirtyDays":
        startDay.setDate(now.getDate() - 29);
        endDay = new Date(now);
        break;
      case "ThisMonth":
        startDay = new Date(now.getFullYear(), now.getMonth(), 1);
        endDay = new Date(now);
        break;
      case "LastMonth":
        startDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        endDay = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
    }
    setStartDate(startDay);
    setEndDate(endDay);
    setDate({ to: formatDateToYYYYMMDD(endDay), from: formatDateToYYYYMMDD(startDay) });
  };

  useEffect(() => {
    handleMobileSelect({ value: "Yesterday" });
  }, []);

  const CustomMobileSingleValue = ({ children, ...props }: any) => {
    const options: any = { year: "numeric", month: "short", day: "numeric" };
    const formattedStartDate = startDate.toLocaleDateString(user?.languageAbbr, options);
    const formattedEndDate = endDate.toLocaleDateString(user?.languageAbbr, options);
    return (
      <components.SingleValue {...props}>
        <div className="relative mx-2 flex items-center">
          <FontAwesomeIcon icon={faCalendar} className="mr-2 pb-0.5" />
          <LocalizedText localeKey={children} />
          &nbsp;({formattedStartDate} - {formattedEndDate})
        </div>
      </components.SingleValue>
    );
  };

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <LocalizedText localeKey={props.label} />
      </components.Option>
    );
  };

  const IndicatorsContainer = () => <div />;

  return (
    <Select
      options={durationOptions}
      components={{ IndicatorsContainer, SingleValue: CustomMobileSingleValue, Option }}
      styles={{
        singleValue: (base) => ({ ...base, color: "#0074C7" }),
      }}
      onChange={handleMobileSelect}
      defaultValue={durationOptions[0]}
    />
  );
};

export default React.memo(CalendarTool);
