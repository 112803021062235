import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import config from 'config/config';
import {BrowserRouter as Router} from 'react-router-dom';

import configureStore from 'store/configureStore';
import addReactNDevTools from 'reactn-devtools';
import {
  AnalyticsIcon,
  NavbarAppsIcon
} from 'assets/icons';
import './index.scss';
import { createServer, Response } from 'miragejs';
import { makeMockServer } from './mirage/mockServer';
import { loadState } from 'store/browserStorage';
import { getBrands } from 'portal-common-ui';

let brandDetails;

const container = document.getElementById('root');
const root = createRoot(container);
const state = loadState() || {
  overlay: {
    isActive: false,
    dark: false,
  },
  auth: {
    authenticated: false,
    isLoggingIn: false,
    isRegistering: false,
    session: null,
  },
  apps: {
    appList: [],
    analyticsUrl: null,
  },
  alerts: {
    alertList: [],
  },
  orientation: {
    isActive: false,
    productName: 'Customer Engagement',
    productIcon: <AnalyticsIcon/>,
    list: [],
    isLoading: false,
    isSaving: false,
  },
  filterItems: {},
  sortings: {},
  logoutTimer: {},
  localeDictionary: null,
};

if (window.Cypress) {
  let otherDomains = []
  let methods = ["get", "put", "patch", "post", "delete"]

  createServer({
    environment: "test",
    routes() {
      for (const domain of ["/", ...otherDomains]) {
        for (const method of methods) {
          this[method](`${domain}*`, async (schema, request) => {
            let [status, headers, body] = await window.handleFromCypress(
              request
            )
            return new Response(status, headers, body)
          })
        }
      }
    },
  })
} else if (process.env.NODE_ENV === 'development' && config.mockApi) {
    makeMockServer();
}

configureStore(state);
addReactNDevTools({});


async function prepareApp() {
  let visitedUrl=window.location.origin;
  let basePath=window.location.pathname;
  const applicationUrl = config.API.applicationUrl;
  const baseUrl = applicationUrl;
  brandDetails = await getBrands(visitedUrl,basePath,baseUrl);
  console.log('brandDetails',brandDetails);

  const favicon = document.getElementById("favicon");
  favicon.href = brandDetails.favicon;
  config.activeBrand = brandDetails;
}

import { useLDClient, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarkly.clientId,
    context: {
      "kind": "user",
      anonymous: true,
    },
  });

  prepareApp().then(() => {
    root.render(
        <LDProvider>
          <Router basename={brandDetails.appBasePath}>
            <App />
          </Router>
        </LDProvider>
    );
  });

})();

// TODO: Update src/logo192/512.png to appropriate brand dynamically
// TODO: Update src/manifest.json to appropriate brand dynamically
// TODO: Update src/favicon.ico to appropriate brand dynamically
// TODO: Isolate themes as a node_module and import the library, remove it from root of project