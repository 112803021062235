import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";

import { useDataAccessGroup, BVLoader20 } from 'portal-common-ui';
import useAlertQueue from 'hooks/alertQueue';
import DataGroupViewerEditor from 'components/DataGroupViewerEditor/DataGroupViewerEditor';

const ViewDataAccessGroupDetails = () => {
  const { getDAGById, isLoading} = useDataAccessGroup();
  const alertQueue = useAlertQueue();
  const params = useParams();
  const history = useHistory();
  const [dataAccessGroupDetails, setDataAccessGroupDetails] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const dataAccessGroupResult = await getDAGById(params.groupId, true);
      if (dataAccessGroupResult?.ok) {
        setDataAccessGroupDetails(dataAccessGroupResult.dag);
      } else {
        if (dataAccessGroupResult?.error?.status) {
          alertQueue.enqueueAlert({ 
            alertType: 'modal',
            title: 'Sorry, something has gone wrong',
            messageType: 'error',
            message: 'Our system has generated an error while attempting to retrieve this record.  It\’s not you, it\’s us!  Please try again later.',
            continueCaption: 'Back to data access list',
            continueAction: () => history.goBack(),
          });    
        }
      }
    };
    fetch();
  }, [params.groupId]);

  const onSaveSuccess = () => {
    alertQueue.enqueueAlert({ 
      alertType: 'toast',
      messageType: 'success',
      message: 'Data Access Group has been successfully updated.',
      transitionDuration: 6000,
     
    });   
  };

  if (isLoading) {
    return (
    <div className="relative min-h-[600px]">
      <BVLoader20 />
    </div>
    );
  }

    if (!isLoading && dataAccessGroupDetails) {
    return ( 
      <DataGroupViewerEditor 
        dataAccessGroupDetails={dataAccessGroupDetails}
        onSaveSuccess={onSaveSuccess}
        >

      </DataGroupViewerEditor>
    );  
  }

  return <div />;
};

export default ViewDataAccessGroupDetails;
