import React from "react";
import { faExclamation, faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { LocalizedText, AccessibleIcon } from "portal-common-ui";

const Toast = ({message, messageKey, onClose, transitionDuration = 300, messageType = 'success' }) => {

  // tailwind workaround to load dynamically generated classnames: bg-info (text must be scanned by tailwind preprocessor when generating the CSS)  
  const durationClass = `duration-${transitionDuration}`;
  const slideInClass = transitionDuration > 3000 ? 'animate-slideinFromTopExtended' : 'animate-slideinFromTop';
  let messageTypeSuffixClass = '';
  let iconClass = '';
  switch (messageType) {
    case 'success':
      iconClass = faCheck;
      messageTypeSuffixClass ='theme-success';
      break;
    case 'error':
      iconClass = faXmark;
      messageTypeSuffixClass ='theme-danger';
      break;
    case 'warning':
      iconClass = faExclamation;
      messageTypeSuffixClass ='theme-warning';
      break;  
    case 'info':
    default:
      iconClass = faInfo;
      messageTypeSuffixClass ='theme-info';
  } 

  return (
    <div className="toast__wrapper flex justify-center fixed top-0 w-full z-50">
      <div className={`toast ${slideInClass} top-4 bg-${messageTypeSuffixClass} w-auto h-12 relative flex justify-between items-center py-2.5 px-4
        transition-all ${durationClass} `} data-test="toast-container">
        <div className="flex items-center">
          <div className="rounded-full bg-theme-on-primary h-3.5 w-3.5 flex justify-center items-center ml-2 mr-6" style={{ fontSize: '.60rem'}} aria-hidden="true" data-test="toast-icon">
            <AccessibleIcon icon={iconClass} localeKey="empty.string" classes={`font-semibold text-${messageTypeSuffixClass} bg-theme-on-primary mt-0.5 `}/>
          </div>
          <span className="text-theme-on-primary text-xs font-normal" data-test="toast-text">
            {messageKey ?  <LocalizedText localeKey={messageKey} /> : message}
          </span>
        </div>
        <button className="cursor-pointer" onClick={onClose} data-test="toast-close-button">
          <AccessibleIcon icon={faXmark} localeKey="shared.alerts.closeButtonAltText" classes="rounded-full text-medium ml-2 text-theme-on-primary" />
        </button>
      </div>
    </div>
  );
};


export default Toast;